import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { LibrariesService, FeaturedPlaylistsService } from './_services';
import { LibrariesTree } from './_models';
import { AppStateService, CacheService, Events, MessageService, UtilitiesService } from '../../../core/services';
import { FeatureArea } from '../../../shared/view-models';
import { FileUploadModalService } from '../file-upload-modal/file-upload-modal.service';
import { ContentViewStateService } from '../../../shared/services';
import { WizardService } from 'src/app/playlist-builder/_services';
import { ContentService } from '../content-container/content/_services';
import { IGetContentParams } from '../../api-models/content';

@Component({
	selector: 'portal-lib-tree',
	templateUrl: './lib-tree.component.html',
	styleUrls: ['./lib-tree.component.scss']
})
export class LibTreeComponent implements OnInit, OnDestroy {
	@ViewChild('searchInputField') searchInputField: ElementRef;

	public searchTerm: string;
	public searchTermChanged: Subject<string> = new Subject<string>();
	private subs: Subscription[] = [];
	readonly featureArea = FeatureArea;

	get instantPlaylistsActive(): boolean {
		const { clientHasAppleTvs, product } = this.appStateService;
		return (
			this.featuredPlaylistsService.featuredPlaylists?.length > 0 &&
			!clientHasAppleTvs &&
			this.wizardService.currentStep().name !== 'choose-music' &&
			!product.Route.includes('web')
		);
	}

	constructor(
		public appStateService: AppStateService,
		private cacheService: CacheService,
		private contentService: ContentService,
		private cvStateService: ContentViewStateService,
		public featuredPlaylistsService: FeaturedPlaylistsService,
		private fileUploadModalService: FileUploadModalService,
		public librariesService: LibrariesService,
		private messageService: MessageService,
		private utilService: UtilitiesService,
		public wizardService: WizardService
	) {
		this.contentSearchClear();
		this.searchTermChanged.pipe(debounceTime(400)).subscribe((searchTerm) => {
			this.librariesService.closeLibraryAndCategoryFolders(this.librariesService.libraries);
			this.librariesService.closeLibraryAndCategoryFolders(this.librariesService.favoriteLibraries);
			this.featuredPlaylistsService.showFeaturedPlaylists = false;
			this.cvStateService.viewState.treeSelection = null;
			this.librariesService.favoritesIsExpanded = false;

			this.cvStateService.viewState.contentSearchActive = true;
			this.cvStateService.viewState.searchTerm = searchTerm;
			this.cvStateService.viewState.contentListCurrentPage = 1;

			const args: IGetContentParams = {
				isMusicStep: this.wizardService.currentStep()?.name === 'choose-music',
				create24ContentOnly: this.utilService.activeFeatureIsC24()
			};

			this.contentService.getContentBySearchTerm(args).subscribe((contentList) => {
				this.cvStateService.contentList = contentList;
			});
		});
	}

	ngOnInit(): void {
		this.fileUploadModalService.getVoiceTalentList();
		this.librariesService.favoritesIsExpanded = false;
		this.librariesService.getLibraries(this.appStateService.product.Id).subscribe((libraries: LibrariesTree[]) => {
			this.librariesService.libraries = libraries;
			if (libraries?.length > 0) {
				this.featuredPlaylistsService.getFeaturedPlaylists();
			}
		});
	}

	contentSearchClear(): void {
		this.subs.push(
			this.messageService.subscribe(Events.onContentSearchClear, () => {
				this.searchInputField.nativeElement.value = '';
			})
		);
	}

	public onFavoritesClick(): void {
		this.cvStateService.viewState.treeSelection = 'favorites';
		this.librariesService.favoritesIsExpanded = !this.librariesService.favoritesIsExpanded;
		this.cvStateService.viewState.contentListVisible = false;
		this.librariesService.closeLibraryAndCategoryFolders(this.librariesService.favoriteLibraries);
	}

	public onFeaturedPlaylistsClick(): void {
		this.featuredPlaylistsService.showFeaturedPlaylists = !this.featuredPlaylistsService.showFeaturedPlaylists;
		this.cvStateService.viewState.contentListVisible = false;
		//Close all regular libraries
		this.librariesService.libraries.forEach((library) => {
			library.isExpanded = false;
		});
	}

	public onSearchKeyup(searchTerm: string): void {
		if (searchTerm.length > 0) {
			this.searchTermChanged.next(searchTerm);
		} else {
			this.cvStateService.onContentSearchClear();
		}
	}

	ngOnDestroy(): void {
		this.cacheService.contentList = [];
		this.cacheService.libraries = [];
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
