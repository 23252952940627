import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { AppStateService, ContentSerializerService, TypeGuardService, UtilitiesService } from '../../../../../core/services';
import { ContentFilesHold, ContentFilesVideo, Hold, Video } from '../_models';
import { ContentView } from 'src/app/shared/view-models/content/content-view';
import { ContentViewService } from './content-view.service';
import { Create24Service } from 'src/app/user-tools/create-24/create-24.service';
import { environment } from '../../../../../../environments/environment';
import { CategoriesTree } from '../../../library-tree/_models';
import { Content, IGetContentParams } from 'src/app/shared/api-models/content';
import { ContentViewStateService } from 'src/app/shared/services';
import { LibrariesService } from '../../../library-tree/_services';
import { CacheService } from '../../../../../core/services';
import { CategoriesService } from '../../../library-tree/_services';
import { WizardService } from 'src/app/playlist-builder/_services';
import { FileUploadModalService } from '../../../file-upload-modal/file-upload-modal.service';
import { FeatureArea } from 'src/app/shared/view-models';
import { Create24Area } from 'src/app/user-tools/create-24/_models/create-24-state.enum';

@Injectable({
	providedIn: 'root'
})
export class ContentService {
	constructor(
		private appStateService: AppStateService,
		private cacheService: CacheService,
		private categoriesService: CategoriesService,
		private contentSerializerService: ContentSerializerService,
		private contentViewService: ContentViewService,
		private create24Service: Create24Service,
		private cvStateService: ContentViewStateService,
		private fileUploadModalService: FileUploadModalService,
		private httpClient: HttpClient,
		private librariesService: LibrariesService,
		private typeGuardService: TypeGuardService,
		private utilService: UtilitiesService,
		private wizardService: WizardService
	) {}

	public getContentBreadcrumbs(contentId: number): Observable<string[]> {
		return this.httpClient.get<string[]>(`${environment.contentUrl}Content/${contentId}/Breadcrumbs`);
	}

	public getContentFiles$(content: Hold | Video) {
		return this.httpClient.get<(ContentFilesHold | ContentFilesVideo)[]>(`${environment.contentUrl}Content/${content.Id}/ContentFiles`).pipe(
			map((contentFiles) => this.processContentFiles(content, contentFiles)),
			switchMap((content) => this.setAdditionalContentProperties(content))
		);
	}

	public getContentFilesLight$(content: Hold | Video) {
		return this.httpClient.get<(ContentFilesHold | ContentFilesVideo)[]>(`${environment.contentUrl}ContentLight/${content.Id}/ContentFiles`).pipe(
			map((contentFiles) => this.processContentFiles(content, contentFiles)),
			switchMap((content) => this.setAdditionalContentProperties(content))
		);
	}

	private processContentFiles(content: Hold | Video, contentFiles: (ContentFilesHold | ContentFilesVideo)[]): Hold | Video {
		content.contentFiles = contentFiles.filter((file) => !file.IsDeleted);
		content.contentType = this.contentSerializerService.contentTypeIdToContentType(content.ContentTypeId);

		if (this.typeGuardService.contentIsVideo(content)) {
			content.thumbnailUrl =
				content.contentFiles.length > 0 ? `${environment.contentUrl}File/${this.thumbnailUrlArg(content)}${content.contentFiles[0].Id}/Thumbnail` : '';
		} else if (this.typeGuardService.contentIsHold(content)) {
			content.contentFiles.forEach((contentFile) => this.processHoldContentFile(content, contentFile));
		}

		return content;
	}

	private processHoldContentFile(content: Hold, contentFile: ContentFilesHold | ContentFilesVideo): void {
		if (this.typeGuardService.contentFileIsHold(contentFile)) {
			const urlArg = content.ContentTypeId === 1 ? 'Music' : 'Message';
			contentFile.previewUrl = `${environment.contentUrl}File/Hold/${urlArg}/${contentFile.Id}/Preview`;
			contentFile.previewBtnTextColor = 'white-text';
			contentFile.isMusicTrack = content.ContentTypeId === 1;

			if (contentFile.VoiceTalent) {
				const isFemale = contentFile.VoiceTalent.TalentGender === 'Female';
				contentFile.previewBtnColor = isFemale ? 'pink-bg' : 'blue-bg';
				contentFile.previewBtnIcon = isFemale ? 'fa-female' : 'fa-male';
				contentFile.previewBtnText = contentFile.VoiceTalent.TalentName;
				contentFile.iconColor = isFemale ? 'pink-text' : 'blue-text';
			}
		}
	}

	private setAdditionalContentProperties(content: Hold | Video) {
		if (this.typeGuardService.contentIsVideo(content) && content.contentFiles[0]) {
			content.thumbIcon = this.contentSerializerService.icon(content);
			content.previewUrl = `${environment.contentUrl}File/Video/${content.contentFiles[0].Id}/Preview`;
			if (content.IsChannel) {
				content.thumbnailUrl = 'assets/channel-thumb.jpg';
			}
		}
		return of(content);
	}

	private thumbnailUrlArg(content: Hold | Video): string {
		const contentTypeId: number = content.ContentTypeId;
		if (contentTypeId === 1 || contentTypeId === 2 || contentTypeId === 3) {
			return 'Hold/Message/';
		} else {
			return 'Video/';
		}
	}

	public contentView(): ContentView {
		const layout = this.contentViewService.viewState.layout;
		const product = this.appStateService.product?.Route;
		const { activeFeatureArea } = this.appStateService;

		switch (activeFeatureArea) {
			case FeatureArea.PLAYLIST_BUILDER:
				switch (layout) {
					case 'list-view':
						if (product === 'hold') {
							return 'holdPbList';
						}
						return 'videoPbList';
					case 'card-view':
						if (product === 'hold') {
							return 'holdPbCard';
						}
						return 'videoPbCard';
				}
			case FeatureArea.CONTENT_MANAGER:
				//Content Manager does not have a card view
				if (product === 'hold') {
					return 'holdCmList';
				}
				return 'videoCmList';

			case FeatureArea.PLAYLIST_DETAILS:
				return 'playlistDetailsList';

			case FeatureArea.FEATURED_CONTENT:
				return 'featuredContent';

			case FeatureArea.NEWSLETTER:
				return 'newsletter';

			case FeatureArea.CREATE_24:
			case FeatureArea.CUSTOM_DATE_FEEDS:
				return 'videoC24List';
			default:
				return 'videoPbList';
		}
	}

	public getContentBySearchTerm(params?: IGetContentParams): Observable<any> {
		const paramString = this.buildQueryParams(params);
		const searchTerm = this.cvStateService.viewState.searchTerm;
		const isMusicStep = params.isMusicStep;

		return this.httpClient
			.get(`${environment.contentUrl}Content/ContentSearch/${this.libraryIds(isMusicStep)}/${searchTerm}?${paramString}`, {
				observe: 'response'
			})
			.pipe(
				tap((res: HttpResponse<Content[]>) => {
					this.cvStateService.totalItemsContentList = +res.headers.get('x-result-count');
					this.cvStateService.viewState.contentListVisible = true;
				}),
				map((res: HttpResponse<Content[]>) => {
					const contentArr: Content[] = res.body;
					if (contentArr) {
						return contentArr.map((content) => {
							return this.buildContentForSearch(content);
						});
					}
					return of([]);
				})
			);
	}

	private libraryIds(isMusicStep: boolean): string {
		return this.librariesService.libraries
			.filter((library) => (isMusicStep ? library.ContentLibraryTypeId === 1 : library.ContentLibraryTypeId !== 1))
			.map((library) => library.Id)
			.join(',');
	}

	public getContentForCategories(params: IGetContentParams): Observable<any> {
		const paramString = this.buildQueryParams(params);

		return this.httpClient
			.post(`${environment.contentUrl}ContentLibraryCategoriesLight/ContentForCategoriesFeatured2?${paramString}`, this.idArr(params.clickedCategory), {
				observe: 'response'
			})
			.pipe(
				tap((res: HttpResponse<Content[]>) => {
					const totalItems: number = +res.headers.get('x-result-count');
					this.cvStateService.viewState.contentListVisible = true;
					this.cvStateService.totalItemsContentList = totalItems;
				}),
				map((res: HttpResponse<Content[]>) => {
					const contentResponseArr: Content[] = res.body;
					if (contentResponseArr && contentResponseArr.length > 0) {
						return contentResponseArr
							.map((contentResponse) => {
								return this.buildContent(contentResponse);
							})
							.filter((content) => {
								const treeSelectionFavorites = this.cvStateService.viewState.treeSelection === 'favorites';
								return treeSelectionFavorites ? content.isFavorite : true;
							});
					}
					return of([]);
				})
			);
	}

	private buildContent(content: Content) {
		const isFavorite = this.librariesService.contentFavorites?.some((favorite) => favorite.Id === content.Id) ?? false;
		const isDisabled = content.IsDeleted;
		return {
			...content,
			isFavorite,
			favoriteBtnTooltip: isFavorite ? 'Remove from favorites' : 'Add to favorites',
			isDisabled,
			isGlobal: this.librariesService.selectedLibrary.isGlobal,
			displayedFeatureCodeId: content.DisplayedFeatureCodeId
		};
	}

	private buildContentForSearch(content: Content): any {
		const isFavorite = this.librariesService.contentFavorites?.some((favorite) => favorite.Id === content.Id) ?? false;
		const isGlobal = this.librariesService.libraries.find((lib) => lib.Id === content.LibraryId).IsGlobal;
		const isDisabled = content.IsDeleted;
		return {
			...content,
			isFavorite,
			favoriteBtnTooltip: isFavorite ? 'Remove from favorites' : 'Add to favorites',
			isDisabled,
			isGlobal,
			displayedFeatureCodeId: content.DisplayedFeatureCodeId
		};
	}

	private buildQueryParams({ isMusicStep, create24ContentOnly, hideDeleted }: IGetContentParams): string {
		const { sortParams, contentListCurrentPage, contentSearchActive } = this.cvStateService.viewState;
		const { orderByCol, orderDesc } = sortParams;
		const pageNumber = this.cvStateService.viewState.contentListCurrentPage;
		const voiceTalentId = this.cvStateService.viewState.voiceTalentId !== 0 ? this.cvStateService.viewState.voiceTalentId : null;
		let searchArea: string = '';
		let query: string = '';
		if (contentListCurrentPage) {
			searchArea = isMusicStep ? 'MUSIC' : this.searchArea();
		}

		query += `pageSize=${this.setPageSize()}&`;
		query += pageNumber ? `pageNumber=${pageNumber}&` : `${1}`;
		query += create24ContentOnly ? `create24ContentOnly=${create24ContentOnly}&` : '';
		query += `orderByCol=${orderByCol ?? ''}&`;
		query += `orderDesc=${orderDesc}&`;
		query += `hideDeleted=${hideDeleted ?? true}&`;
		query += searchArea ? `searchOptions=${searchArea}&` : '';
		if (this.appStateService.product.ProductName === 'On Hold' && !isMusicStep) {
			if (contentSearchActive) {
				query += `voiceTalents=${voiceTalentId ?? this.voiceTalentIds()}&`;
			} else {
				query += `voiceTalentId=${voiceTalentId ?? 0}&`;
			}
		}
		return this.utilService.trimEnd(query, '&');
	}

	private searchArea(): string {
		switch (this.create24Service.c24ActiveArea) {
			case Create24Area.NEW_VIDEO:
			case Create24Area.NEW_VIDEO_REPLACE:
				return 'BG-VIDEO';
			case Create24Area.NEW_IMAGE:
			case Create24Area.NEW_IMAGE_REPLACE:
				return 'BG-STILL';
			case Create24Area.IMAGE_LAYER_CREATE:
			case Create24Area.IMAGE_LAYER_REPLACE:
				return 'PLACED';
			case Create24Area.VIDEO_TEMPLATE:
			case Create24Area.EDIT_PROJECT:
				return 'CONTENT-C24';

			default:
				return 'ALL';
		}
	}

	private voiceTalentIds(): string {
		if (this.appStateService.product.ProductName === 'On Hold') {
			return this.fileUploadModalService.voiceTalentList.map((talent) => talent.Id).join(',');
		}
		return '';
	}

	private idArr(category?: CategoriesTree): number[] {
		//get all content for a category AND it's sub-categories
		if (category?.InverseParentCategory) {
			const catArr = category.InverseParentCategory.map((category) => {
				return category.Id;
			});
			catArr.push(category.Id);
			return catArr;
		}
		//get all content for a category with no sub-categories
		return [category?.Id];
	}

	private setPageSize(): number {
		if (this.cvStateService.viewState.treeSelection === 'favorites') {
			return 100;
		} else {
			return 25;
		}
	}

	public displayContent(payload): void {
		this.cvStateService.viewState.contentListVisible = true;
		const clickedCategory: CategoriesTree = payload[0];
		this.getContentForCategories({ clickedCategory }).subscribe((contentList) => {
			this.cvStateService.contentList = contentList;
			this.cacheService.storeContent(clickedCategory, contentList, this.cvStateService.totalItemsContentList);
		});
	}

	public getPage(number: number): void {
		const viewState = this.cvStateService.viewState;
		viewState.contentListCurrentPage = number;
		if (viewState.contentSearchActive) {
			const args: IGetContentParams = {
				isMusicStep: this.wizardService.currentStep()?.name === 'choose-music'
			};
			this.getContentBySearchTerm(args).subscribe((contentList) => {
				this.cvStateService.contentList = contentList;
			});
		} else {
			if (viewState.treeSelection !== 'favorites') {
				const args: IGetContentParams = { clickedCategory: this.categoriesService.clickedCategory };
				this.getContentForCategories(args).subscribe((contentList) => (this.cvStateService.contentList = contentList));
			}
		}
	}
}
