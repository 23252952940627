import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fabric } from 'fabric';
import { Subscription } from 'rxjs';

import { AppStateService, Events, FileUploadService, MessageService, SecurityService, UtilitiesService } from 'src/app/core/services';
import { CanvasService } from '../main-editor/canvas/_services';
import { ContentLibraries, Feeds } from 'src/app/shared/api-models/content';
import { Create24Service } from '../create-24.service';
import { CreateEditFeedItemService } from 'src/app/my-feeds/edit-feed/create-edit-feed-item/create-edit-feed-item.service';
import { LayersService, VideoControlsService } from '../main-editor/_services';
import { ExportToLibraryService } from './export-to-library.service';
import { StateService } from '../main-editor/_services';
import { ToSVGOverrideService } from '../main-editor/canvas/_services';
import { Video } from 'src/app/shared/components/content-container/content/_models';
import { Create24Area } from '../_models/create-24-state.enum';
import { ExportSVGService } from '../main-editor/_services';

@Component({
	selector: 'c24-export-to-library',
	templateUrl: './export-to-library.component.html',
	styleUrls: ['./export-to-library.component.scss']
})
export class ExportToLibraryComponent implements OnInit {
	public saveStateWindowVisible: boolean;
	private subs: Subscription[] = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		private appStateService: AppStateService,
		private canvasService: CanvasService,
		public create24Service: Create24Service,
		public createEditFeedItemService: CreateEditFeedItemService,
		private exportSVGService: ExportSVGService,
		public exportToLibraryService: ExportToLibraryService,
		private fileUploadService: FileUploadService,
		private layersService: LayersService,
		private messageService: MessageService,
		private router: Router,
		private securityService: SecurityService,
		public stateService: StateService,
		private toSVGOverrideService: ToSVGOverrideService,
		private utilService: UtilitiesService,
		private videoControlsService: VideoControlsService
	) {
		this.uploadCompleteSubscribe();
	}

	ngOnInit(): void {
		this.exportToLibraryService.saveState = null;
		this.exportToLibraryService.prevCreate24Area = this.create24Service.c24ActiveArea;
		this.stateService.exportWindowVisible = true;
		this.createEditFeedItemService.feedItem = this.createEditFeedItemService.newFeedItemInit();

		//Only this component should change the active feature area to c24-edit-project;
		if (!this.exportToLibraryService.isSidebar()) {
			this.create24Service.c24ActiveArea = Create24Area.EDIT_PROJECT;
		} else {
			this.exportToLibraryService.getFeeds();
		}

		this.exportToLibraryService.projectState = this.setProjectState();

		if (this.exportToLibraryService.projectState === 'edit') {
			this.saveStateWindowVisible = true;
		}
		if (this.stateService.state().c24ContentId) {
			this.exportToLibraryService.getContentAndContentFile().subscribe((content: Video) => {
				this.stateService.c24Content = content;
				this.exportToLibraryService.getLibraries();
			});
		} else {
			this.exportToLibraryService.getLibraries();
		}
	}

	private setProjectState(): 'new' | 'edit' {
		let activeUrl = this.router.routerState.snapshot.url;
		switch (true) {
			case this.utilService.includes(activeUrl, 'edit-project'):
				return 'edit';
			case this.utilService.includes(activeUrl, 'video-template'):
				if (this.appStateService.currentUser.IsEmployee && this.securityService.userRoleAdminOrVideo(this.appStateService.currentUser.Role)) {
					return 'edit';
				}
				return 'new';

			default:
				return 'new';
		}
	}

	onLibrarySelect(library: ContentLibraries): void {
		this.exportToLibraryService.c24ContentLibrary = library;
		this.stateService.c24Content.LibraryId = library.Id;
		//If changing libraries, null category so we don't try to use it
		this.exportToLibraryService.c24ContentCategory = null;
		this.exportToLibraryService.getCategories(library.Id);
	}

	public onSaveClick(): void {
		//Fixes a Fabric JS bug...svg position will be wrong if group not deselected
		this.canvasService.canvas.discardActiveObject().renderAll();
		if (this.canvasService.bgContent) {
			fabric.Image.fromURL(
				this.canvasService.bgContent.thumbnailUrl,
				(img) => {
					//Make sure image size matches canvas size
					img.scaleX = this.canvasService.canvasSize().width / img.width;
					img.scaleY = this.canvasService.canvasSize().height / img.height;

					this.canvasService.canvas.add(img);
					img.sendToBack();
					this.prepareCanvasForExport();
				},
				{ crossOrigin: 'anonymous' }
			);
			//If still image with just a colored bg
		} else {
			let blankBgColor = this.layersService.layers.find((layer) => layer.id === 0).canvasObj.blankBgColor;
			let rect = new fabric.Rect({
				name: 'rect',
				width: this.canvasService.canvasSize().width,
				height: this.canvasService.canvasSize().height,
				fill: blankBgColor
			});
			this.canvasService.canvas.add(rect);
			rect.sendToBack();
			this.prepareCanvasForExport();
		}
	}

	public onFeedSelect(feed: Feeds): void {
		this.createEditFeedItemService.feed = feed;
	}

	private prepareCanvasForExport(): void {
		this.videoControlsService.playClock = 5;
		this.canvasService.canvas.getObjects().forEach((obj) => {
			if (obj.name && obj.layerId > 0) {
				//Allows inclusion of layerId on export
				this.toSVGOverrideService.override(obj);
			}
		});
		this.canvasService.canvas.renderAll();
		const exportedImg = this.canvasService.canvas.toDataURL({ format: 'png' });
		this.exportToLibraryService.file = null; //weird thumbnail when editing a project without nulling first
		this.exportToLibraryService.file = this.utilService.dataURLtoFile(exportedImg, 'thumbnail.png');

		this.resetUIForSVGExport();
		const productRoute: string = this.activatedRoute.snapshot.paramMap.get('productRoute');
		this.exportToLibraryService.export(productRoute);

		if (this.createEditFeedItemService.feed) {
			this.fileUploadService.uploadFileViaGeneralUploadApi(this.exportToLibraryService.file, this.createEditFeedItemService.uploadData());
		}
		// console.log(this.exportSVGService.finalOutput(this.canvasService.canvas.toSVG()));
	}

	uploadCompleteSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.uploadComplete, (event) => {
				this.createEditFeedItemService.feedItem.contentFileId = event.body.Id;
				this.exportToLibraryService.createFeedItem();
			})
		);
	}

	public onOverwriteOrCopyClick(saveState: 'overwrite' | 'copy'): void {
		this.exportToLibraryService.saveState = saveState;
		this.saveStateWindowVisible = false;
	}

	private resetUIForSVGExport(): void {
		//Transitions/playhead position actually change canvas values,
		//so we reset them here before exporting
		this.canvasService.canvas
			.getObjects()
			.filter((obj) => obj.layerId >= 0)
			.forEach((obj) => {
				const layer = this.layersService.layers.find((layer) => layer.id === obj.layerId);
				obj.set('visible', this.objVisible(obj));
				obj.set('top', layer.canvasObj.top);
				obj.set('left', layer.canvasObj.left);
				obj.set('opacity', layer.canvasObj.opacity);
			});
	}

	private objVisible(obj): boolean {
		let layer = this.layersService.layers.find((layer) => layer.id === obj.layerId);
		if (obj.isFeedText || obj.isFeedImageBoundingBox) {
			return false;
		}
		if (layer?.visible) {
			if (this.create24Service.contentType() === 'image') {
				return true;
			} else {
				if (layer.start > 0) {
					return false;
				}
				return true;
			}
		} else {
			if (layer?.id > 0) {
				return false;
			}
			return true;
		}
	}

	ngOnDestroy() {
		this.videoControlsService.playClock = 0;
		this.stateService.exportWindowVisible = false;
		this.stateService.c24Content = new Video();
		this.createEditFeedItemService.feedItem = null;
		this.createEditFeedItemService.feed = null;
		this.exportToLibraryService.c24ContentCategory = null;
		this.exportToLibraryService.c24ContentLibrary = null;
		this.subs.forEach((sub) => sub.unsubscribe());

		//Change back to previous feature area setting so editor will know if bg is video or still
		this.create24Service.c24ActiveArea = this.exportToLibraryService.prevCreate24Area;
	}
}
