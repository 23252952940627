<!--THUMBNAIL-->
<div
	[ngClass]="{ 'setOpacity pointer': !content.isChannel, processingBorder: contentFileStateId() === 3 }"
	[ngStyle]="{ 'width.px': width(), 'height.px': height() }"
	class="relative mgn-bottom5 center border-radius"
	(click)="onVideoPreviewClick()"
>
	<div *ngIf="contentFileStateId() !== 3" class="setHeight100">
		<img
			class="setWidth100 border-radius setHeight100 animated fadeIn relative z box-shadow"
			src="{{ content.thumbnailUrl }}"
			onError="this.src='assets/no-image.jpg';"
		/>
		<i class="fa {{ content.thumbIcon }} absolute center-unknown-height-width z white-text ft-size22" aria-hidden="true"></i>
	</div>
	<div *ngIf="contentFileStateId() === 3">
		<div class="relative top32"><portal-spinner [height]="'htpx28'" [width]="'wdthpx28'" class=""></portal-spinner></div>
		<span style="bottom: -3.75rem" class="relative">Processing...</span>
	</div>
</div>
