import { Injectable } from '@angular/core';
import { fabric } from 'fabric';

import { CanvasService } from '../../../canvas/_services/canvas.service';
import { ContentViewStateService } from 'src/app/shared/services';
import { Create24Service } from 'src/app/user-tools/create-24/create-24.service';
import { IGroup, IRect, ILine, ITriangle } from '../../../_models';
import { Layer, LayerType } from '../../../_models';
import { LayersService } from '../../../_services/layers.service';
import { LayersTimelineWrapperService } from './layers-timeline-wrapper.service';
import { Video } from 'src/app/shared/components/content-container/content/_models';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { CountdownAndDateService } from '../../../create-layer-sidebar/countdown-and-date/countdown-and-date.service';
import { Create24Area } from 'src/app/user-tools/create-24/_models/create-24-state.enum';

@Injectable({
	providedIn: 'root'
})
export class InitService {
	constructor(
		private canvasService: CanvasService,
		private countdownAndDateService: CountdownAndDateService,
		private create24Service: Create24Service,
		private cvStateService: ContentViewStateService,
		private layersService: LayersService,
		private ltwService: LayersTimelineWrapperService,
		private utilService: UtilitiesService
	) {}

	public timelineInit(): void {
		//Re-initialize heights
		this.ltwService.canvas.setDimensions({ height: this.ltwService.canvasHeightInit });
		this.ltwService.layerDetailHeight = 940;

		//Sets canvas and layer detail height according to number of layers
		if (this.layersService.layers.length > 10) {
			let count = this.layersService.layers.length - 10;
			while (count > 0) {
				this.ltwService.layerDetailHeight = this.ltwService.layerDetailHeight + 34;
				this.ltwService.canvas.setDimensions({ height: this.ltwService.canvas.getHeight() + 34 });
				count = count - 1;
			}
		}
		this.setLayerDetailHeight();

		if (this.canvasService.canvasContainerWidth) {
			this.ltwService.setTimelineWidth(this.canvasService.canvasContainerWidth);

			this.ltwService.updateObjProps(this.ltwService.setObjWidth);
			this.ltwService.updateObjProps(this.ltwService.setObjStartEnd);

			this.ltwService.previousCanvasWidth = this.ltwService.canvas.getWidth();
			this.ltwService.updateObjProps(this.ltwService.removeGrid);
		}
		if (this.create24Service.contentType() === 'video') {
			this.addPlayhead();
			this.addGrid();
			this.addGridText();
		}

		this.ltwService.canvas.preserveObjectStacking = true;
		this.ltwService.canvas.renderAll();
	}

	public setLayerDetailHeight(): void {
		if (this.layersService.atLeastOneFeedLayer() && this.ltwService.layerDetailHeight >= 940) {
			this.ltwService.layerDetailHeight -= 107;
		}
	}

	public addBackgroundLayer(content?: Video | null): void {
		let layerName: string;
		let layerType: LayerType;
		let icon: string;
		const { c24ActiveArea } = this.create24Service;
		switch (c24ActiveArea) {
			case Create24Area.NEW_VIDEO:
				layerName = 'Background video';
				layerType = 'background-video';
				icon = 'fas fa-video';
				break;
			case Create24Area.NEW_IMAGE:
			case Create24Area.NEW_SIDEBAR:
				layerName = 'Background image';
				layerType = 'background-image';
				icon = 'fas fa-image';
				break;
		}
		let layer: Layer = new Layer(0, layerName, icon, 'dark', layerType, this.create24Service.contentType(), 0, 0);

		//If no bg content, init to blue
		layer.canvasObj.blankBgColor = !content ? '#59a7da' : null;
		this.layersService.layers.push(layer);
		this.addRect(this.layersService.layers[0], true);
	}

	public addRect(layer: Layer, lockMovementX?: boolean): void {
		let rect = new fabric.Rect({
			component: 'layers-timeline-wrapper',
			hoverCursor: lockMovementX ? 'default' : 'move',
			layerId: layer.id,
			width: 954,
			height: 25,
			cornerColor: '#2980b9',
			hasControls: lockMovementX ? false : true,
			borderColor: '#000',
			strokeWidth: 0,
			lockMovementX: lockMovementX ? lockMovementX : false,
			lockMovementY: true,
			fill: this.ltwService.colorStringToHex(layer.timelineObj.fill),
			top: 58,
			left: 5
		} as IRect);
		rect.setControlsVisibility({
			tr: false,
			tl: false,
			br: false,
			bl: false,
			ml: lockMovementX ? false : true,
			mt: false,
			mr: lockMovementX ? false : true,
			mb: false,
			mtr: false
		});
		//Disable ability for bg timeline bar to be selected as a group
		rect.selectable = layer.id === 0 ? false : true;
		if (layer.isDuplicate || this.countdownAndDateService.mode === 'edit') {
			rect.scaleX = layer.timelineObj.scaleX;
			rect.left = layer.start;
		}
		this.ltwService.canvas.add(rect);
	}

	private playheadSquare(): any {
		return new fabric.Rect({
			component: 'layers-timeline-wrapper',
			width: 8,
			height: 8,
			cornerColor: '#2980b9',
			borderColor: '#000',
			strokeWidth: 6,
			stroke: '#fdba1d',
			strokeLineJoin: 'round',
			lockMovementY: true,
			fill: '#fdba1d',
			top: 27,
			left: 4
		} as IRect);
	}

	private playheadTriangle(): any {
		let triangle = new fabric.Triangle({
			component: 'layers-timeline-wrapper',
			width: 12,
			height: 8,
			cornerColor: '#2980b9',
			borderColor: '#000',
			strokeWidth: 0,
			lockMovementY: true,
			fill: '#fdba1d',
			top: 41,
			left: 5
		} as ITriangle);
		triangle.rotate(180);
		triangle.setCoords();
		return triangle;
	}

	private playheadLine(): any {
		return new fabric.Line([10, 48, 10, this.ltwService.canvas.height], {
			strokeWidth: 2,
			stroke: '#fdba1d',
			lockMovementY: true
		} as ILine);
	}

	public addPlayhead(): void {
		this.ltwService.playhead = new fabric.Group([this.playheadSquare(), this.playheadTriangle(), this.playheadLine()], {
			component: 'layers-timeline-wrapper',
			layerId: -1,
			left: 0,
			lockMovementY: true,
			hasBorders: false,
			selectable: true
		} as IGroup);

		this.ltwService.playhead.setControlsVisibility(this.allControlsFalse());
		this.ltwService.canvas.add(this.ltwService.playhead);
	}

	private gridLine(leftPosition: number): any {
		return new fabric.Line([leftPosition, 0, leftPosition, 15], {
			strokeWidth: 1,
			stroke: '#23282D',
			hoverCursor: 'default',
			lockMovementY: true
		} as ILine);
	}

	private gridLines(): any[] {
		let canvasWidth: number = this.ltwService.canvas.getWidth();
		let counter = 5;
		let arr = [];
		while (counter < canvasWidth) {
			arr.push(this.gridLine(counter));
			counter = counter + canvasWidth / 8;
		}
		return arr;
	}

	public addGrid(): void {
		let grid = new fabric.Group(this.gridLines(), {
			component: 'layers-timeline-wrapper',
			layerId: -2,
			hoverCursor: 'default',
			lockMovementY: true,
			lockMovementX: true,
			hasBorders: false
		} as IGroup);
		grid.setControlsVisibility(this.allControlsFalse());
		this.ltwService.canvas.add(grid);
	}

	private gridText(leftPosition: number, time: number): any {
		return new fabric.Text(this.utilService.formatTime(time, true), {
			left: leftPosition,
			top: 5,
			fontSize: 11,
			hoverCursor: 'default',
			fontFamily: 'Arial'
		});
	}

	private gridTimeStamps(): any[] {
		if (this.canvasService.bgContent) {
			let canvasWidth: number = this.ltwService.canvas.getWidth();
			let counter = 15;
			let time = 0;
			let secsBetweenPoint: number = this.ltwService.contentDuration / 1000 / 8;
			let arr = [];
			while (counter < canvasWidth) {
				arr.push(this.gridText(counter, time));
				time = time + secsBetweenPoint;
				counter = counter + canvasWidth / 8;
			}
			return arr;
		}
	}

	public addGridText(): void {
		let gridText = new fabric.Group(this.gridTimeStamps(), {
			component: 'layers-timeline-wrapper',
			layerId: -3,
			lockMovementY: true,
			lockMovementX: true,
			hasBorders: false,
			hasControls: false,
			hoverCursor: 'default'
		} as IGroup);
		gridText.setControlsVisibility(this.allControlsFalse());
		this.ltwService.canvas.add(gridText);
	}

	private allControlsFalse(): any {
		return {
			tr: false,
			tl: false,
			br: false,
			bl: false,
			ml: false,
			mt: false,
			mr: false,
			mb: false,
			mtr: false
		};
	}

	public setGroupControls(): void {
		fabric.Group.prototype.setControlsVisibility(this.allControlsFalse());
	}
}
