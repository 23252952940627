import { Injectable } from '@angular/core';

import { AudioPreviewService, Events, MessageService, UtilitiesService } from '../../core/services';
import { ContentFilesHold } from '../../shared/components/content-container/content/_models';
import { PlaylistService } from './playlist.service';

@Injectable({
	providedIn: 'root'
})
export class PlaylistViewService {
	constructor(
		private audioPreviewService: AudioPreviewService,
		private messageService: MessageService,
		private playlistService: PlaylistService,
		private utilService: UtilitiesService
	) {}

	//Main preview button at top of playlist (hold)
	public onMainPreviewClick(): void {
		this.playlistService.playlist.previewActive = !this.playlistService.playlist.previewActive;

		if (this.playlistService.playlist.previewActive) {
			this.playlistService.previewSpinnerActive = true;
			this.playlistService.playlist.previewBtnIcon = 'fas fa-times';
			this.playlistService.previewUnsavedHoldPlaylist();
		} else {
			this.playlistService.playlist.previewBtnIcon = 'fas fa-play';
			this.playlistService.playlist.preview.pause();
		}
	}

	public previewBtnDisabled(): boolean {
		return this.playlistService.playlist.contentItems.length < 1 && !this.playlistService.playlist.musicContentItem;
	}

	public onVideoPreviewClick(): void {
		this.messageService.publish(Events.showPbModal, ['video-playlist-previewer']);
	}

	public onExpandClick(): void {
		this.messageService.publish(Events.showPbModal, ['playlist-expanded']);
	}

	public onShuffleClick(): void {
		//https://javascript.info/task/shuffle
		let contentItems = this.playlistService.playlist.contentItems;
		for (let i = contentItems.length - 1; i > 0; i--) {
			let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
			[contentItems[i], contentItems[j]] = [contentItems[j], contentItems[i]]; // swap elements
		}
	}

	public onThumbOrListViewClick(stringId: 'listView' | 'thumbView'): void {
		this.playlistService.listOrThumbView = stringId;
	}

	public onMusicTrackPreviewClick(): void {
		if (this.playlistService.playlist.musicContentItem) {
			let contentFile = this.playlistService.playlist.musicContentItem.contentFiles[0];

			this.audioPreviewService.contentItemAudioPreview(this.playlistService.playlist.musicContentItem, [contentFile as ContentFilesHold]);
			contentFile.previewActive = !contentFile.previewActive;
		}
	}

	public onRemoveMusicClick(): void {
		this.playlistService.playlist.musicContentItem = null;
	}

	public stopMusicTrackPreview(): void {
		if (!this.utilService.closest(event, '.music-track')) {
			if (this.playlistService.playlist.musicContentItem) {
				let contentFile: ContentFilesHold = this.playlistService.playlist.musicContentItem.contentFiles[0] as ContentFilesHold;
				if (contentFile.audioPreview) {
					contentFile.previewActive = false;
					contentFile.audioPreview.pause();
				}
			}
		}
	}
}
