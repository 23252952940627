import { Injectable } from '@angular/core';

import { AppStateService } from '../../core/services/app-state.service';
import { ContentItem } from '../../shared/view-models/content';
import { PbPlaylist } from '../../shared/view-models/content';
import { PlaylistJson, PlaylistItemJson } from '../../shared/api-models/content';

@Injectable({
	providedIn: 'root'
})
export class PlaylistJsonService {
	private stillDurationContentTypeIds: number[] = [4, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 43, 44, 1000006];
	private durationMsContentTypesIds: number[] = [1, 2, 6, 8, 9, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 1000007];

	constructor(private appStateService: AppStateService) {}

	public playlistJson(unsavedPlaylist: PbPlaylist, productTypeId: number): PlaylistJson {
		//deep clone so we don't add the music track to the playlist items
		//in the view
		let unsavedPlaylistCopy: PbPlaylist = JSON.parse(JSON.stringify(unsavedPlaylist));

		let playlistJson: PlaylistJson = new PlaylistJson();
		playlistJson.PlaylistId = unsavedPlaylistCopy.Id ? unsavedPlaylistCopy.Id : null;

		playlistJson.ClientId = this.appStateService.currentClient.Id;
		playlistJson.UserId = this.appStateService.currentUser.UserId;
		playlistJson.ProductId = productTypeId;
		playlistJson.PlaylistName = unsavedPlaylistCopy.Name;
		playlistJson.Version = unsavedPlaylistCopy.version ? playlistJson.Version : 'Version 3.0';
		playlistJson.ParagraphPauseMs = unsavedPlaylistCopy.ParagraphPause;
		playlistJson.PlaylistItems = this.playlistItemJson(unsavedPlaylistCopy);
		return playlistJson;
	}

	private playlistItemJson(playlistCopy: PbPlaylist): PlaylistItemJson[] {
		//if we have a music track, add it to front of playlist items
		if (playlistCopy.musicContentItem) {
			playlistCopy.contentItems.unshift(playlistCopy.musicContentItem);
		}

		return playlistCopy.contentItems.map((contentItem) => {
			let playlistItemJson: PlaylistItemJson = new PlaylistItemJson();

			if (contentItem.advancedSchedule) {
				playlistItemJson.AdvancedSchedule = contentItem.advancedSchedule;
			}
			playlistItemJson.Directory = contentItem.directory;
			playlistItemJson.MasterFileName = contentItem.masterFileName;
			playlistItemJson.ContentFileId = contentItem.contentFileId;
			playlistItemJson.ContentId = contentItem.contentId;
			playlistItemJson.ContentPreviewUrl = contentItem.previewUrl;
			contentItem.feedId ? (playlistItemJson.FeedId = contentItem.feedId) : null;
			playlistItemJson.Duration = contentItem.duration;
			playlistItemJson.IsMusicTrack = contentItem.isMusicTrack ? true : false;
			playlistItemJson.StartDate = contentItem.startDate;
			playlistItemJson.StopDate = contentItem.stopDate;
			playlistItemJson.ContentName = contentItem.contentName;

			if (!contentItem.isMusicTrack) {
				playlistItemJson.Order = contentItem.order;
				playlistItemJson.IsChannel = contentItem.isChannel;
				playlistItemJson.SilenceMusic = contentItem.silenceMusic;
			}
			this.videoOnlyProps(playlistItemJson, contentItem);
			return playlistItemJson;
		});
	}

	private videoOnlyProps(playlistJson: PlaylistItemJson, contentItem: ContentItem): void {
		if (this.appStateService.product?.Route !== 'hold') {
			if (contentItem.stillDuration) {
				playlistJson.StillDuration = contentItem.stillDuration;
			}
			playlistJson.Duration = this.setDuration(contentItem);
			if (contentItem.weatherOverride) {
				playlistJson.WeatherOverride = contentItem.weatherOverride;
			}
			playlistJson.ContentTypeId = contentItem.contentTypeId;
		}
	}

	private setDuration(contentItem: ContentItem): number {
		switch (true) {
			case this.stillDurationContentTypeIds.indexOf(contentItem.contentTypeId) > -1:
				return contentItem.stillDuration;

			case this.durationMsContentTypesIds.indexOf(contentItem.contentTypeId) > -1:
				return contentItem.duration;
		}
	}
}
