import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { fabric } from 'fabric';

import { AppStateService } from 'src/app/core/services';
import { CanvasService, CanvasObjService, CEventsService } from '../../canvas/_services';
import { ContentViewService } from 'src/app/shared/components/content-container/content/_services';
import { LayersService, TransitionService, UndoRedoService, VideoControlsService } from '../../_services';
import { Create24Service } from '../../../create-24.service';
import { Layer } from '../../_models';
import { InitService, LayersTimelineWrapperService, LtwEventsService } from './_services';
import { Video } from 'src/app/shared/components/content-container/content/_models';

@Component({
	selector: 'me-layers-timeline-wrapper',
	templateUrl: './layers-timeline-wrapper.component.html',
	styleUrls: ['./layers-timeline-wrapper.component.scss']
})
export class LayersTimelineWrapperComponent implements OnInit, OnDestroy {
	@ViewChild('fabricWrapper') outerWrapper: ElementRef;

	private subs: Subscription[] = [];

	constructor(
		private appStateService: AppStateService,
		private canvasService: CanvasService,
		private canvasObjService: CanvasObjService,
		private cEventsService: CEventsService,
		private contentViewService: ContentViewService,
		private create24Service: Create24Service,
		private dragulaService: DragulaService,
		private initService: InitService,
		public layersService: LayersService,
		private ltwEventsService: LtwEventsService,
		private ltwService: LayersTimelineWrapperService,
		private transitionService: TransitionService,
		private undoRedoService: UndoRedoService,
		private videoControlsService: VideoControlsService
	) {
		this.onBgContentSelect();
		this.onCreateLayerClick();
		this.onLayerDelete();
		this.onTimelineLayerSelect();
		this.onVideoControlsClick();
		this.onWindowResize();

		const bag: any = this.dragulaService.find('layers');
		if (bag !== undefined) {
			this.dragulaService.destroy('layers');
		}

		dragulaService.dropModel('layers').subscribe((value) => {
			this.ltwService.reorderTimelineObjsOnDrag(value.sourceModel);
			this.layersService.onLayerReorder(value.sourceModel);
			this.undoRedoService.recordState();
		});

		dragulaService.createGroup('layers', {
			//Prevents background layer from being dragged
			moves: (el, container, event): boolean => {
				return event.classList.contains('draggable');
			},
			//Prevents background layer from being reordered when dragging another layer
			accepts: (el, target, source, sibling): boolean => {
				if (sibling) {
					return sibling.classList.contains('draggable');
				}
			}
		});
	}

	ngOnInit(): void {
		if (!this.appStateService.getSessionItem('c24State')) {
			this.ltwService.canvas = new fabric.Canvas('canvas');
		}
		this.layersService.onMouseUp(this.ltwService.canvas);
		this.ltwEventsService.onPlayheadDrag();
		this.ltwEventsService.onObjectDrag();
		this.ltwEventsService.onCanvasClick();
		this.ltwEventsService.onObjectScale();
		this.ltwEventsService.onObjectChangeEnd();
		this.ltwEventsService.onSelectionCreated();
		fabric.Object.prototype.objectCaching = false;

		//Disables group selection for timeline objs if still image (group selection is N/A)
		if (this.create24Service.contentType() === 'image') {
			this.ltwService.canvas.selection = false;
		}
	}

	onWindowResize(): void {
		this.subs.push(
			this.cEventsService.windowResize$.subscribe((videoCanvasWidth: number) => {
				//Duration has to be set before timeline init
				if (this.ltwService?.contentDuration >= 0) {
					this.initService.timelineInit();
					this.initToStart();
					if (this.videoControlsService.isPlaying) {
						//Prevent buggy behavior by stopping playback on browser resize
						this.videoControlsService.isPlaying = false;
						this.stopPlayback();
					}
				}
			})
		);
	}

	onBgContentSelect(): void {
		this.subs.push(
			this.contentViewService.onC24BgContentSelect$.subscribe((payload: [Video, boolean]) => {
				let content: Video | null = payload[0];
				let replaceContent: boolean = payload[1];

				//Content can be null if starting with blank canvas
				if (content) {
					content.previewUrl = content.previewUrl.replace(/Preview/gi, 'Master');
				}
				this.canvasService.bgContent = null;

				//SetTimeout so video background will refresh on change
				setTimeout(() => {
					if (content) {
						this.canvasService.bgContent = content;
					}
					let duration: number = this.canvasService.bgContent?.contentFiles[0].DurationMs;

					// duration = duration > 0 ? duration : 100000;
					this.ltwService.contentDuration = duration;

					//Add content as bg layer if not replacing
					if (!replaceContent) {
						this.initService.addBackgroundLayer(content);
						this.layersService.activeLayer = this.layersService.layers[0];
						this.undoRedoService.recordState('Add background');
					} else {
						//Close content browser
						this.create24Service.popup = '';
						this.undoRedoService.recordState('Change background');
					}
					this.ltwService.canvas.bringToFront(this.ltwService.playhead);
					this.initService.timelineInit();
					this.initService.setGroupControls();
					this.ltwService.previousCanvasWidth = this.ltwService.canvas.getWidth();
				}, 10);
			})
		);
	}

	onCreateLayerClick(): void {
		this.subs.push(
			this.layersService.createLayerClicked$.subscribe((layer: Layer) => {
				let bgIsStillImage: boolean = this.create24Service.contentType() === 'image';

				//Add layer to front
				this.layersService.layers.unshift(layer);
				this.ltwService.updateObjProps(this.ltwService.pushObjsDown);
				this.initService.addRect(layer, bgIsStillImage);
				this.layersService.activeLayer = layer;

				//Still images have no playhead
				if (!bgIsStillImage) {
					this.ltwService.canvas.bringToFront(this.ltwService.playhead);
				}
				let obj = this.ltwService.canvas.getObjects().find((obj) => obj.layerId === layer.id);
				this.ltwService.setObjWidth(obj);
				this.ltwService.setObjStartEnd(obj);
				this.canvasService.canvas.renderAll();

				//Solves problem of needing a vertical scrolling canvas, just increase height
				if (this.layersService.layers.length > 10) {
					this.initService.timelineInit();
				}
				this.initService.setLayerDetailHeight();

				//Capture top position of each layer so we can properly re-order on drag/drop
				this.layersService.layers.forEach((layer, i) => {
					if (i > 0) {
						layer.timelineObj.top += 34;
					}
				});
			})
		);
	}

	onLayerDelete(): void {
		this.subs.push(
			this.layersService.layerDelete$.subscribe((layerId: number) => {
				this.ltwService.deleteObj(layerId);

				//Reset canvas and layer detail height
				if (this.layersService.layers.length > 9) {
					this.ltwService.layerDetailHeight -= 34;
					this.ltwService.canvas.setDimensions({ height: this.ltwService.canvas.getHeight() - 34 });
				}
				if (!this.layersService.atLeastOneFeedLayer() && this.ltwService.layerDetailHeight < 940) {
					this.ltwService.layerDetailHeight += 107;
				}
				this.layersService.activeLayer = this.layersService.layers[0];
			})
		);
	}

	onTimelineLayerSelect(): void {
		this.subs.push(
			this.layersService.activeLayerSelected$.subscribe((payload: [number, string] | null) => {
				if (payload) {
					let layerId: number = payload[0];
					let component: string = payload[1];
					if (component && component !== 'layers-timeline-wrapper') {
						let activeObj = this.ltwService.canvas.getObjects().find((obj) => obj.layerId === layerId);
						if (activeObj) {
							this.ltwService.canvas.setActiveObject(activeObj);
							this.ltwService.canvas.renderAll();
						}
					}
				}
			})
		);
	}

	onVideoControlsClick(): void {
		this.subs.push(
			this.videoControlsService.controlClicked$.subscribe((button: string) => {
				if (this.create24Service.contentType() === 'video') {
					//Deselects grouped objects
					this.canvasService.canvas.discardActiveObject().renderAll();
					if (this.cEventsService.isGroupSelected) {
						this.layersService.activeLayer = this.layersService.layers.find((layer) => layer.id === 0);
					}
					this.cEventsService.isGroupSelected = false;
					switch (button) {
						case 'play':
							this.videoControlsService.isPlaying = !this.videoControlsService.isPlaying;

							//Start video over if clicking play from end of video
							if (this.ltwService.contentDuration - this.ltwService.playClockMs() < 100) {
								this.initToStart();
							}
							this.videoControlsService.playIcon = this.videoControlsService.isPlaying ? 'fas fa-pause' : 'fas fa-play';

							//Begin playhead animation
							this.ltwService.animatePlayhead();
							this.ltwService.playhead.setCoords();

							if (this.videoControlsService.isPlaying) {
								//Play video
								this.videoControlsService.videoPlayer.nativeElement.play();

								let start = Date.now() - this.ltwService.playClockMs();
								//Update playclock every 10ms
								this.ltwService.clockInterval = setInterval(() => {
									let elapsedTime = Date.now() - start; //milliseconds elapsed since start

									//Update playhead position
									this.ltwService.playheadPos = this.ltwService.pixPerMs() * this.videoControlsService.playClock;

									//Fires transitions according to layer start/end times
									this.transitionService.beginTransition(this.transitionService.transitionIn);
									this.transitionService.beginTransition(this.transitionService.transitionOut);

									//Show/hide layer and start transitions according to playclock
									this.canvasObjService.showHideObjs();
									this.videoControlsService.playClock = elapsedTime / 1000;
								}, 10);
							} else {
								this.stopPlayback();
							}
							break;
						case 'toStart':
							this.initToStart();
							break;
						case 'toEnd':
							this.videoControlsService.videoPlayer.nativeElement.currentTime = this.ltwService.contentDuration;
							if (!this.videoControlsService.isPlaying) {
								this.ltwEventsService.updatePlayheadCoords(this.ltwService.canvas.getWidth() - 10);
								this.videoControlsService.playClock = this.ltwService.contentDuration / 1000;
							}
							break;
					}
				}
			})
		);
	}

	private initToStart(): void {
		if (this.videoControlsService.videoPlayer) {
			this.videoControlsService.videoPlayer.nativeElement.currentTime = 0;
		}

		this.videoControlsService.playIcon = 'fas fa-play';
		this.videoControlsService.playClock = 0;
		this.ltwEventsService.updatePlayheadCoords(0);

		//Reset positions/opacity back to original (transitions change object positions)
		this.canvasObjService.resetObjValues();

		//Reset durations back to total
		this.layersService.layers.forEach((layer) => {
			layer.transInDuration = layer.totalTransDuration;
			layer.transOutDuration = layer.totalTransDuration;
		});
	}

	private stopPlayback(): void {
		//Stop video
		this.videoControlsService.videoPlayer?.nativeElement.pause();

		//Stop clock
		clearInterval(this.ltwService.clockInterval);
		this.ltwService.playheadPos = this.ltwService.pixPerMs() * this.videoControlsService.playClock;
		this.canvasObjService.showHideObjs();
		this.layersService.layers.forEach((layer) => {
			layer.transitionInFired = false;
			layer.transitionOutFired = false;
			if (this.canvasObjService.midTransitionOut(layer)) {
				layer.transOutDuration =
					layer.totalTransDuration - (this.videoControlsService.playClock - layer.transitionOutStart / this.ltwService.pixPerMs()) * 1000;
			}
			if (this.canvasObjService.midTransitionIn(layer)) {
				layer.transInDuration = layer.totalTransDuration - (this.ltwService.playClockMs() - this.ltwService.pxToMs(layer.start));
			}
		});
	}

	ngOnDestroy() {
		this.canvasService.bgContent = null;
		clearInterval(this.ltwService.clockInterval);
		this.videoControlsService.playClock = 0;
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
