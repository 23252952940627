import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { AppStateService, Events, FileUploadService, MessageService, UtilitiesService } from '../../../../core/services';
import { ClientNameSearch, ContentRequestAttachments } from '../../../../shared/api-models/admin';
import { CreateEditRequestService, CreateEditViewStateService, HeightService } from '../../_services';
import { DetailsService, DetailsViewService } from '../_services';
import { environment } from '../../../../../environments/environment';
import { FileUploadStateService } from 'src/app/shared/services';

@Component({
	selector: 'd-internal-video-view',
	templateUrl: './internal-video-view.component.html',
	styleUrls: ['./internal-video-view.component.scss']
})
export class InternalVideoViewComponent implements OnInit, OnDestroy {
	public clientList: ClientNameSearch[];
	public percentComplete: number;

	private subs: Subscription[] = [];

	constructor(
		public appStateService: AppStateService,
		public createEditRequestService: CreateEditRequestService,
		public detailsService: DetailsService,
		public detailsViewService: DetailsViewService,
		private fileUploadService: FileUploadService,
		private fileUploadStateService: FileUploadStateService,
		public heightService: HeightService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		public utilService: UtilitiesService,
		public viewStateService: CreateEditViewStateService
	) {
		this.fileUploadAdvanceSequenceSubscribe();
		this.uploadProgressSubscribe();
		this.uploadCompleteSubscribe();
	}

	ngOnInit(): void {
		if (this.createEditRequestService.request.Id) {
			this.detailsService.getAttachments().subscribe((attachments: ContentRequestAttachments[]) => {
				this.createEditRequestService.request.attachments = attachments;
			});
		}
	}

	fileUploadAdvanceSequenceSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.fileUploadAdvanceSequence, () => {
				if (this.fileUploadStateService.currentStep().name === 'progressContentRequestAttachment') {
					const uploadData: { Id: number; FriendlyName: string } = new ContentRequestAttachments(
						this.createEditRequestService.request.Id,
						this.fileUploadStateService.file.name
					);
					this.fileUploadService.uploadAttachmentFile(uploadData, `${environment.adminUrl}ContentRequestAttachments`, 'ContentRequestAttachment');
				}
			})
		);
	}

	uploadCompleteSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.uploadComplete, (event) => {
				//clip component is listening to same message
				if (event.body.ContentRequestId) {
					//get uploaded file and attach to view
					this.httpClient
						.get(`${environment.adminUrl}ContentRequestAttachments/${event.body.Id}`)
						.subscribe((contentRequestAttachment: ContentRequestAttachments) => {
							this.createEditRequestService.request.attachments.push(contentRequestAttachment);
							this.percentComplete = 0;
						});
				}
			})
		);
	}

	uploadProgressSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.uploadProgress, (payload: any[]) => {
				const [event, key] = [...payload];

				if (key === 'ContentRequestAttachment') {
					this.percentComplete = Math.round((100 * event.loaded) / event.total);
				}
			})
		);
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
