import { Injectable } from '@angular/core';

import { AppStateService, UtilitiesService } from '../../core/services';
import { Route } from '../_models';
import { Table } from '../../shared/components/table/view-models';
import { TableConfigService } from './table-config.service';
import { UrlString } from '../_models/url-string.enum';

@Injectable({
	providedIn: 'root'
})
export class TableViewStateService {
	public activeUrl: string;
	public config: any;
	public pageTitleSnippet: string;
	public route: Route;
	public pageTitle: string;
	public pageNumber: number;
	public orderByCol: string;
	public orderDesc: boolean;
	public filterByStatus: string;
	public urlString: UrlString;
	public filterByRequestType: string;
	public searchTerm: string = '';
	public table: Table;
	public totalItems: number;
	public workflowFilter: string;
	public filterByTargetLibrary: string;

	constructor(private appStateService: AppStateService, private tableConfigService: TableConfigService, private utilService: UtilitiesService) {}

	public setState(
		pageTitleSnippet: string,
		pageNumber: number,
		orderByCol: string,
		filterByStatus: string,
		filterByRequestType: string,
		urlString: UrlString,
		workflowFilter: string,
		route: Route
	): void {
		this.pageTitleSnippet = pageTitleSnippet;
		this.pageNumber = pageNumber;
		this.orderByCol = orderByCol;
		this.filterByStatus = filterByStatus;
		this.filterByRequestType = filterByRequestType;
		this.urlString = urlString;
		this.workflowFilter = workflowFilter;
		this.route = route;
	}

	public checkRouteState(): Route {
		switch (true) {
			case this.utilService.includes(this.activeUrl, 'system-tools'):
				return 'system-tools';
			case this.utilService.includes(this.activeUrl, 'client-details'):
				return 'client-details';
			default:
				return 'content-requests';
		}
	}

	public setTable(item: string): void {
		switch (item) {
			case 'On-Hold':
				//Only Audio Content Creator can merge content requests
				if (this.appStateService.currentUser.Role === 'Audio Content Creator') {
					this.tableConfigService.holdConfig.paginationDisabled = true;
					this.tableConfigService.holdConfig.itemsPerPage = 500;
					this.table = this.tableConfigService.holdTableAudioContentCreator;
				} else {
					this.table = this.tableConfigService.holdTable;
				}
				this.config = this.tableConfigService.holdConfig;
				break;
			case 'Video':
				this.table = this.tableConfigService.videoTable;
				this.config = this.tableConfigService.videoConfig;
				break;
			default:
				this.table = this.tableConfigService.allProductsTable;
				this.config = this.tableConfigService.allProductsConfig;
				break;
		}
	}
}
