import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

import { AppStateService, DateTimeService, Events, MessageService, PlaylistJsonService } from '../../core/services';
import { ContentItem } from '../../shared/view-models/content';
import { environment } from '../../../environments/environment';
import { GetPlaylistDataService } from '../../shared/services';
import { PbPlaylist } from '../../shared/view-models/content';

@Injectable({
	providedIn: 'root'
})
export class PlaylistService {
	public playlist: PbPlaylist;
	public previewBtnIcon: string;
	public previewSpinnerActive: boolean;
	public listOrThumbView: string = 'listView';
	public orderArray: number[] = [];
	public orderCount: number = 0;
	public savedPlaylist: PbPlaylist;
	public selectedContentItem: ContentItem;

	constructor(
		private appStateService: AppStateService,
		private dateTimeService: DateTimeService,
		private getPlaylistDataService: GetPlaylistDataService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private playlistJsonService: PlaylistJsonService
	) {}

	public playlistHasContentAndIsVideo(): boolean {
		return this.playlist.contentItems.length > 0 && this.appStateService.product.Route !== 'hold';
	}

	public playlistInit(savedPlaylistId: number, getPlayerCapacityRange: () => void): void {
		let playlist;
		if (this.appStateService.getSessionItem('playlist')) {
			playlist = JSON.parse(this.appStateService.getSessionItem('playlist'));
		}
		if (playlist) {
			this.messageService.publish(Events.transitionOverlay, [null, false]);
			this.playlist = playlist;
			this.setOrderForExistingPlaylist();
			getPlayerCapacityRange();
			return;
		}
		if (savedPlaylistId) {
			this.getPlaylistDataService.getCompletePlaylist(savedPlaylistId).subscribe((playlist: PbPlaylist) => {
				if (!!playlist) {
					//Filter out deleted items from playlist builder
					playlist.contentItems = playlist.contentItems.filter((contentItem) => !contentItem.isDeleted);

					playlist.contentItems.forEach((item, i) => {
						//If second item is a music track, remove the first music track
						if (i === 1 && item.isMusicTrack) {
							playlist.contentItems.shift();
						}
					});

					this.playlist = this.setLoadedPlaylistProps(JSON.parse(JSON.stringify(playlist)));
					this.savedPlaylist = this.setLoadedPlaylistProps(JSON.parse(JSON.stringify(playlist)));
					this.playlist.isLoadedPlaylist = true;
					this.savedPlaylist.isLoadedPlaylist = true;
					this.setOrderForExistingPlaylist();
					this.messageService.publish(Events.transitionOverlay, [null, false]);
					getPlayerCapacityRange();
				}
			});
		} else {
			this.playlist = this.newPlaylistInit();
			this.playlist.contentItems = [];
			this.messageService.publish(Events.transitionOverlay, [null, false]);
			getPlayerCapacityRange();
		}
	}

	private setLoadedPlaylistProps(playlist: PbPlaylist): PbPlaylist {
		if (this.holdAndMusicTrackPresent(playlist)) {
			playlist.musicContentItem = playlist.contentItems.shift();
		}
		if (playlist.contentItems.length > 0) {
			playlist.isValid = true;
		}
		playlist.isWritable = true;
		playlist.duration = this.durationFriendlyFormat(this.getPlaylistDuration(playlist));
		playlist.statusView = 'Edit';
		if (playlist.status !== 'Scheduled') {
			playlist.startDate = this.dateTimeService.todayWithCurrentTimeUTC();
		}

		if (playlist.advancedSchedule) {
			playlist.advancedScheduleActive = true;
		}
		if (playlist.duration === 'NaN:NaN') {
			playlist.duration = '0:00';
		}
		return playlist;
	}

	private holdAndMusicTrackPresent(playlist: PbPlaylist): boolean {
		if (playlist.contentItems.length > 0) {
			return this.appStateService.product.Route === 'hold' && playlist.contentItems[0].isMusicTrack;
		}
	}

	public previewUnsavedHoldPlaylist(): void {
		this.httpClient
			.post(`${environment.contentUrl}Playlists/Preview`, this.playlistJsonService.playlistJson(this.playlist, this.appStateService.product.Id))
			.subscribe((response: any) => {
				let guid: string = response;
				this.playlist.preview = new Audio(environment.contentUrl + `Playlists/Preview/${guid}`);
				this.playlist.preview.play();

				this.onPlaylistPreviewEnd();
				this.previewSpinnerActive = false;
			});
	}

	private onPlaylistPreviewEnd(): void {
		this.playlist.preview.addEventListener('ended', (event) => {
			this.playlist.preview.pause();
		});
	}

	/************************
          PLAYLIST DURATION
    *************************/

	public updatePlaylistDuration(): void {
		this.playlist.duration = this.durationFriendlyFormat(this.getPlaylistDuration());
	}

	public getPlaylistDuration(playlist?: PbPlaylist): number {
		playlist = !!playlist ? playlist : this.playlist;
		let durationArr: any[] = [];
		let durationSum: number;
		if (playlist.contentItems.length < 1) {
			return 0;
		} else {
			playlist.contentItems.forEach((contentItem) => {
				durationArr.push(contentItem.duration);
				durationSum = durationArr.reduce((a, b) => a + b, 0);
			});
			if (this.appStateService.product.Route === 'hold') {
				if (playlist.contentItems.length > 0) {
					return durationSum + (playlist.contentItems.length + 1) * playlist.ParagraphPause + this.oneSecondPerClip(playlist);
				} else if (playlist.musicContentItem) {
					return playlist.musicContentItem.duration;
				} else {
					return 0;
				}
			} else {
				return durationSum;
			}
		}
	}

	public durationFriendlyFormat(milli: number): string {
		let formatString: string;
		if (milli > 3600000) {
			formatString = 'HH:mm:ss';
		} else {
			formatString = 'mm:ss';
		}
		return moment.utc(milli).format(formatString);
	}

	private oneSecondPerClip(playlist: PbPlaylist): number {
		let oneSecondPerClip: number = 0;
		playlist.contentItems.forEach((item) => {
			oneSecondPerClip = oneSecondPerClip + 1000;
		});
		return oneSecondPerClip;
	}

	public validatePlaylist(): void {
		if (this.playlistHasItemsAndMusicSelected()) {
			this.playlist.isValid = true;
		} else {
			this.playlist.isValid = false;
		}
		if (this.playlist.contentItems.length === 0 && !this.playlist.musicContentItem) {
			this.appStateService.removeSessionItem('playlist');
		}
	}

	private playlistHasItemsAndMusicSelected(): any {
		return this.playlist.contentItems.length > 0 || this.playlist.musicContentItem;
	}

	private newPlaylistInit(): PbPlaylist {
		let playlist: PbPlaylist = new PbPlaylist();
		playlist.ProductId = this.appStateService.product.Id;
		playlist.Name = 'New Playlist - ' + moment(new Date()).format('M/DD/YYYY');
		playlist.ClientId = this.appStateService.currentClient.Id;

		playlist.statusView = 'New';
		playlist.status = 'New';
		playlist.isWritable = true;
		playlist.duration = '0:00';
		playlist.isStopDateSelected = false;
		playlist.previewBtnIcon = 'fas fa-play';

		if (this.appStateService.product.Route === 'hold') {
			playlist.ParagraphPause = this.appStateService.currentClient.DefaultParagraphPauseMs;
		}
		return playlist;
	}

	private setOrderForExistingPlaylist(): void {
		this.orderArray.length = 0;
		for (let i = 1; i <= this.playlist.contentItems.length; i++) {
			this.orderArray.push(i);
		}
		this.orderCount = this.playlist.contentItems.length;
	}
}
