import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { debounceTime, map } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';
import * as jwt from 'jwt-decode';

import { AdminDataService, AppStateService, ClientSwitcherService, Events, MessageService, UtilitiesService } from '../../../core/services';
import { Clients, ContactInformation, Players, ProductTypes } from '../../api-models/admin';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'portal-client-switcher',
	templateUrl: './client-switcher.component.html',
	styleUrls: ['./client-switcher.component.scss']
})
export class ClientSwitcherComponent implements OnInit, OnDestroy {
	public currentClientName: string;
	public isLoading: boolean;
	public searchResults: any;
	public typeahead = new EventEmitter<string>();

	private activeUrl: string;
	private sub: Subscription = new Subscription();

	constructor(
		private adminDataService: AdminDataService,
		public appStateService: AppStateService,
		public clientSwitcherService: ClientSwitcherService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private router: Router,
		private utilService: UtilitiesService
	) {
		this.tokenSetSubscribe();
		this.typeahead.pipe(debounceTime(500)).subscribe((searchTerm) => {
			this.searchClients(searchTerm);
		});
	}

	ngOnInit() {
		if (!this.appStateService.currentClient.IsActive) {
			this.currentClientName = `${this.appStateService.currentClient.Name} - INACTIVE`;
		} else {
			this.currentClientName = this.appStateService.currentClient.Name;
		}
	}

	tokenSetSubscribe(): void {
		this.sub = this.messageService.subscribe(Events.tokenSet, () => {
			if (this.newClientIdSameAsToken()) {
				const clients$ = this.httpClient.get(environment.adminUrl + 'CoreClients/' + this.clientSwitcherService.newClientId);
				const contractedProducts$ = this.adminDataService.getContractedProducts$(this.clientSwitcherService.newClientId);
				const csrByClient$ = this.httpClient.get(`${environment.adminUrl}CoreClients/${this.clientSwitcherService.newClientId}/Csr`).pipe(map((res) => res[0]));
				const availableVideoPlayers$ = this.httpClient.get(
					`${environment.adminUrl}CorePlayers/AvailableVideoPlayers?clientId=${this.clientSwitcherService.newClientId}&userId=${this.appStateService.currentUser.UserId}`
				);

				forkJoin([clients$, contractedProducts$, csrByClient$, availableVideoPlayers$]).subscribe(
					(res: [Clients, ProductTypes[], ContactInformation, Players[]]) => {
						let [client, products, csr, players] = [...res];
						products = products.filter((p) => p.hasContract === 1);
						this.clientSwitcherService.newClientId = null;
						this.appStateService.setSessionItem('currentClient', JSON.stringify(client));
						this.appStateService.setClientAndUser();
						this.appStateService.setSessionItem('contractedProducts', JSON.stringify(products));
						this.appStateService.setSessionItem('csrByClient', JSON.stringify(csr));
						this.appStateService.setCsr();
						this.appStateService.setContractedProducts();
						this.appStateService.setSessionItem('clientHasAppleTvs', JSON.stringify(players.some((player) => player.PlayerModelId === 1000000)));
						this.appStateService.setClientHasAppleTvs();

						this.activeUrl = this.router.routerState.snapshot.url;
						const isLocationsPage = this.activeUrl === '/crm/client-details/locations';

						switch (true) {
							case isLocationsPage:
								location.reload();
								break;
							case this.utilService.includes(this.activeUrl, 'client-details'):
								this.router.navigate(['/crm/client-details/locations']);
								setTimeout(() => {
									location.reload();
								}, 1000);

								break;
							default:
								const url = localStorage.getItem('changeClientUrl');
								this.router.navigate([url]);
								break;
						}
					}
				);
			}
		});
	}

	public onClientChange(client): void {
		localStorage.setItem('changeClientUrl', 'crm/client-details/locations');
		this.clientSwitcherService.onClientChange(client);
	}

	private newClientIdSameAsToken(): boolean {
		const decodedToken = jwt(sessionStorage.getItem('authToken'));
		return this.clientSwitcherService.newClientId && this.clientSwitcherService.newClientId === +decodedToken.clientid;
	}

	public onClientSearchKeyup(searchTerm: string): void {
		this.typeahead.next(searchTerm);
	}

	private searchClients(searchTerm: string) {
		if (searchTerm.length < 1) {
			this.isLoading = false;
			return;
		}

		this.isLoading = true;

		this.httpClient
			.get(`${environment.adminUrl}CoreClients/NameSearch/${searchTerm}`)
			.subscribe((res: [{ CsrId: number; Id: number; Name: string; IsActive: boolean }]) => {
				this.searchResults = res.map((client) => {
					client['name'] = client.Name;
					return client;
				});

				this.searchResults = res.map((client) => {
					if (!client.IsActive) {
						client.Name = `${client.Name} - INACTIVE`;
					}
					return client;
				});
				this.isLoading = false;
			});
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}
}
