import { Component, Input, OnInit } from '@angular/core';

import { BrowserDetectorService, Events, FileUploadService, MessageService } from '../../../core/services';
import { FileUploadStateService } from '../../services';
import { Hold } from 'src/app/shared/components/content-container/content/_models';


@Component({
    selector: 'portal-file-upload-drop-target',
    templateUrl: './file-upload-drop-target.component.html',
    styleUrls: ['./file-upload-drop-target.component.scss']
})
export class FileUploadDropTargetComponent implements OnInit {

    @Input() borderColor: string;
    @Input() borderHidden: boolean;
    @Input() content: Hold;
    @Input() counterpartProps: {contentId: number, targetLibraryId: number};
    @Input() counterpartOrReplace: 'counterpart' | 'replace' | null;
    @Input() dropTargetHeight: string;
    @Input() fontSize: string;
    @Input() isSidebarFeedImage: boolean;
    @Input() activeUploadSequence: string;
    @Input() itemId: number;
    @Input() maxAllowedFileSize: number;
    @Input() paddingTop: string;
    @Input() previewUrl: string;
    @Input() restrictToType: 'video' | 'image' | null;
    @Input() showImagePreview: boolean;
    @Input() textColor: string;
    @Input() textPosition: string;

    public displayPreviewAsUrl: boolean; //if false, display as base64 preview
    public fileTooLarge: boolean;
    public isHovered: boolean;
    public unsupportedFile: boolean;
    

    constructor(
                public browserDetectorService: BrowserDetectorService,
                public fileUploadService: FileUploadService,
                public fileUploadStateService: FileUploadStateService, 
                private messageService: MessageService) {}

    ngOnInit() {
        if(this.previewUrl) {
            this.displayPreviewAsUrl = true;
        }
    }

    
    //Called when selecting file from computer
    public onChange(event): void {
        if (event.srcElement.files.length > 0) {
            let file: File = event.srcElement.files[0];
            this.validateFile(file);
        }
    }

    public onDrop(event: any): void {
        let file: File = event[0];
        this.validateFile(file);
    }

    public onDragHover(e: boolean): void {
        this.isHovered = e;
    }

    private validateFile(file: File | any): void {
        this.fileUploadStateService.setState(file, this.counterpartOrReplace, this.content, this.counterpartProps, null, this.itemId);
        this.fileUploadStateService.activeSequence = this.fileUploadStateService.viewState[this.activeUploadSequence];
        
        //Is the file type allowed?
        if (this.isASupportedFileType()) {
            if(!this.fileLargerThanMaxAllowed(file.size)) {
                this.fileUploadStateService.file.valid = true;
                this.fileUploadStateService.advance(this.itemId);
            } else {
                this.fileTooLarge = true;
                setTimeout(() => this.fileTooLarge = false, 4000)
            }
        } else {
            //Show file not supported error.
            this.fileNotSupported();
        }
    }

    public onRemoveImageClick(event: Event): void {
        event.stopPropagation();
        this.previewUrl = '';
        this.messageService.publish(Events.removeFeedImage, this.itemId);
    }

    private isASupportedFileType(): boolean {
        return this.fileUploadService.isASupportedFileType(this.restrictToType);
    }

    private fileNotSupported(): void {
        this.unsupportedFile = true;
        //revert back to initial drop zone text
        setTimeout(() => this.unsupportedFile = false, 3500)
    }

    private fileLargerThanMaxAllowed(fileSize: number): boolean {
        return this.maxAllowedFileSize && this.maxAllowedFileSize < fileSize;
    }
}
