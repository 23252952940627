import { Hold, Video } from 'src/app/shared/components/content-container/content/_models';

export class ClientNewsletter {
    Id: number;
    Title: string;
    UpdateDate: string;
    UpdatedByUserId: number;
    ContentArea: string;
    MainIntro: string;
    C24ShowcaseText: string;
    C24ShowcaseContentIds: string;
    PromoMessage: string;
    ProductsPagePromoBarHtml: string;
    ProductsPagePromoBarActive: boolean;
    ProductsPagePopupHtml: string;
    ProductsPagePopupActive: boolean;

    c24ShowcaseContent: (Hold | Video)[];
    contentIdsParsed: any;
}