import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AppStateService } from '../../../core/services/app-state.service';

export const AuthGuardService = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree => {
	const router = inject(Router);
	const appStateService = inject(AppStateService);

	// Check if the route is the login page
	const isLoginPage = route.routeConfig?.path === 'login';

	// Check if the user is an employee
	const isEmployee = appStateService.currentUser?.IsEmployee ?? false;
	const currentUrl = state.url;

	// If not on the login page, check authentication conditions
	if (!isLoginPage) {
		const hasValidAuthToken = sessionStorage.getItem('authToken') !== null && sessionStorage.getItem('authToken') !== 'null';
		const hasValidPin = sessionStorage.getItem('validPin') === 'true';

		// For employees, check both authToken and validPin. For non-employees, only check authToken
		if (!hasValidAuthToken || (isEmployee && !hasValidPin)) {
			if (currentUrl.length > 0) {
				appStateService.setSessionItem('unauthorizedUrl', JSON.stringify(currentUrl));
			}
			return router.createUrlTree(['/login']);
		}
	}

	// If on the login page and authenticated, redirect to my-products
	if (isLoginPage) {
		const hasValidAuthToken = sessionStorage.getItem('authToken') !== null && sessionStorage.getItem('authToken') !== 'null';
		const hasValidPin = sessionStorage.getItem('validPin') === 'true';

		if (hasValidAuthToken && (!isEmployee || (isEmployee && hasValidPin))) {
			return router.createUrlTree(['/my-products']);
		}
	}

	// Otherwise, allow the navigation
	return true;
};
