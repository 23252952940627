import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { AdminDataService, AppStateService, Events, MessageService, CacheService } from '../../../core/services';
import { ContractService } from './contract.service';
import { ContractTermsService } from '../contract-terms.service';
import { Contracts } from '../../../shared/api-models/admin';
import { environment } from 'src/environments/environment';
import { Users } from '../../../shared/api-models/admin';

type NameAndId = { Name: string, Id: number };

@Component({
    selector: 'c-contract',
    templateUrl: './contract.component.html',
    styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit {

    @Input() contract: Contracts;
    @Output() contractDeleteNotify = new EventEmitter<any>();
    @Output() onCreateOrUpdateNotify = new EventEmitter<any>();

    public activeSalesmen$: Observable<NameAndId[]> = this.adminDataService.getActiveSalesmen();
    public contractStatus: string;
    public downloadUrl: string;
    public isFormValid: boolean;

    constructor(
        private adminDataService: AdminDataService,
        private appStateService: AppStateService,
        private contractService: ContractService,
        public contractTermsService: ContractTermsService,
        private messageService: MessageService,
        private cacheService: CacheService) { }

    ngOnInit() {
        this.isFormValid = true;
        this.downloadUrl = `${environment.adminUrl}ContractFile/${this.contract.Id}`;
        
        //existing contract
        if (this.contract.Id) {
            this.contractService.getProductByContract(this.contract)
                .subscribe((productTypes: { productType: string, bgColor: string }) => {
                    if (productTypes) {
                        this.contract.productType = productTypes.productType;
                        this.contract.bgColor = productTypes.bgColor;
                        this.contractStatus = this.contractService.setContractStatus(this.contract);
                    } else {
                        this.contractStatus = 'UNDETERMINED STATUS';
                    }
                })
        }
        if (this.contract.SalespersonId) {
            this.contractService.getSalesperson(this.contract)
                .subscribe((salesperson: Users) => {
                    this.contract.salespersonName = `${salesperson.ContactInformation.FirstName} ${salesperson.ContactInformation.LastName}`;
                    this.cacheService.setUserCache(salesperson);
                })
        }
        //new contract
        if (!this.contract.Id) {
            this.contractStatus = this.contractService.setContractStatus(this.contract);
        }
    }

    onDateChanged(date: string, prop: string): void {
        this.contract[prop] = date;
    }

    public editDeleteContractEnabled(): boolean {
        return this.appStateService.currentClient.Id !== 1470;
    }

    onDropdownSelect(item: any, idProp: string, viewProp: string): void {
        this.contract[idProp] = item.Id;
        this.contract[viewProp] = item.name;
    }

    onInputChange(value: string, prop: string): void {
        if (prop === 'IsRenewed') {
            this.contract.IsRenewed = !this.contract.IsRenewed;
            this.contract.IsEarlyCancel = false;
            this.contract.IsFulfilledContractCancellation = false;
            this.earlyCancelNull();
            this.contract.FulfilledContractCancellationReason = null;
        }
        else if (prop === 'IsEarlyCancel') {
            this.contract.IsEarlyCancel = !this.contract.IsEarlyCancel;
            this.contract.IsFulfilledContractCancellation = false;
            this.contract.IsRenewed = false;
            this.contract.FulfilledContractCancellationReason = null;
        }
        else if (prop === 'IsFulfilledContractCancellation') {
            this.contract.IsFulfilledContractCancellation = !this.contract.IsFulfilledContractCancellation;
            this.contract.IsRenewed = false;
            this.contract.IsEarlyCancel = false;
            this.earlyCancelNull();
        } else {
            this.contract[prop] = value;
        }
    }

    private earlyCancelNull(): void {
        this.contract.EarlyCancelDate = null;
        this.contract.earlyCancelDateView = null;
        this.contract.EarlyCancelReason = null;
    }

    private deleteContract(): void {
        let confirmOk = confirm('Delete this contract?');
        if (confirmOk) {
            this.messageService.publish(Events.savingPreloader, 1);
            this.contractService.deleteContract(this.contract.Id)
                .subscribe(() => {
                    this.contractDeleteNotify.emit(this.contract.Id);
                    this.messageService.publish(Events.savingPreloader, 0);
                });
        }
    }

    /**************************
            EDIT/DELETE BUTTONS
    **************************/

    public onEditSaveClick(str: string): void {
        switch (str) {
            case "edit":
                this.contract.viewState = 'editMode';
                break;

            case "save":
                this.contract.viewState = 'readMode';
                this.messageService.publish(Events.savingPreloader, 1);

                //update contract
                if (this.contract.Id) {
                    this.contractService.putContract(this.contract)
                        .subscribe(() => {
                            this.onSaveSuccess();
                        })
                    //save new contract
                } else {
                    this.contractService.postContract(this.contract)
                        .subscribe((res) => {
                            this.onSaveSuccess();
                        })
                }
                break;
        }
    }

    private onSaveSuccess(): void {
        this.onCreateOrUpdateNotify.emit();
        this.messageService.publish(Events.savingPreloader, 0);
    }

    public onDeleteCancelClick(str: string): void {
        if (str === 'cancel') {
            this.contract.viewState = 'readMode';
            //delete canceled contract entry
            if (!this.contract.Id) {
                this.contractDeleteNotify.emit();
            }
        }
        if (str === 'delete') {
            this.deleteContract();
        }
    }
}
