import { Component, Input, OnInit } from '@angular/core';

import { AddToPlaylistService, ContentFavoritesService } from '../../_services';
import { ContentViewService } from '../../_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { PlayerCapacityService } from '../../../../../../playlist-builder/_services';
import { UtilitiesService } from 'src/app/core/services';
import { Video } from '../../_models';

@Component({
	selector: 'vc-video-pb-list-view',
	templateUrl: './video-pb-list-view.component.html',
	styleUrls: ['./video-pb-list-view.component.scss']
})
//Playlist Builder List View
export class VideoPbListViewComponent implements OnInit {
	@Input() canUseInPlaylist: boolean;
	@Input() content: Video;
	@Input() isEvenIndex: boolean;
	@Input() isFirstIndex: boolean;
	@Input() isLastIndex: boolean;

	public previewBtnText: string;
	public contentManagerVisible: boolean;
	public videoPreviewVisible: boolean;

	constructor(
		public addToPlaylistService: AddToPlaylistService,
		public contentFavoritesService: ContentFavoritesService,
		public contentViewService: ContentViewService,
		public cvStateService: ContentViewStateService,
		public playerCapacityService: PlayerCapacityService,
		public utilService: UtilitiesService
	) {}

	ngOnInit() {}
}
