import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService, CanDeactivateGuardService, ContractGuardService, UserGuardService } from 'src/app/core/services/route-guards';
import { ContentBrowserComponent } from './content-browser.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService, ContractGuardService, UserGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: ContentBrowserComponent
  },
  {
    path: 'playlist-in-progress',
    canActivate: [AuthGuardService, ContractGuardService],
    canDeactivate: [CanDeactivateGuardService],
    component: ContentBrowserComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentBrowserRoutingModule { }
