import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService, ContractGuardService } from '../core/services/route-guards';
import { PlayerPlaylistHistoryComponent } from './player-playlist-history.component';
import { PlaylistDetailsWrapperComponent } from './playlist-details-wrapper/playlist-details-wrapper.component';

const routes: Routes = [
	{
	  path: '',
	  canActivate: [AuthGuardService, ContractGuardService],
	  component: PlayerPlaylistHistoryComponent
	},
	{
	  path: 'details/:encryptedId',
	  canActivate: [AuthGuardService, ContractGuardService],
	  component: PlaylistDetailsWrapperComponent
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlayerPlaylistHistoryRoutingModule { }
