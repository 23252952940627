import { Component, Input, OnInit } from '@angular/core';

import { AppStateService, AudioPreviewService, ContentSerializerService, FileUploadService, SecurityService } from 'src/app/core/services';
import { ContentFilesHold, Hold } from '../../_models';
import { DetailsService } from '../../_services';
import { ContentVoiceTalents } from 'src/app/shared/api-models/content';
import { FileUploadStateService } from 'src/app/shared/services';

@Component({
    selector: 'd-content-file',
    templateUrl: './content-file.component.html',
    styleUrls: ['./content-file.component.scss']
})
export class ContentFileComponent implements OnInit {

    @Input() content: Hold;
    @Input() contentFile: ContentFilesHold;
    @Input() index: number;

    public deleteReplaceVisible: boolean;
    public deleteTooltipVisible: boolean;
    public replaceTooltipVisible: boolean;
    public selectedIndex: number;

    private deleteTimeout: any;
    private replaceTimeout: any;

    constructor(
        private appStateService: AppStateService,
        private audioPreviewService: AudioPreviewService,
        private contentSerializerService: ContentSerializerService,
        public detailsService: DetailsService,
        public fileUploadService: FileUploadService,
        public fileUploadStateService: FileUploadStateService,
        private securityService: SecurityService
    ) { }

    ngOnInit() {
        this.writeAccessCheck();
    }


    onPreviewClick(): void {
        this.audioPreviewService.contentItemAudioPreview(this.contentSerializerService.contentFileToContentItem(this.content, this.contentFile), this.content.contentFiles as ContentFilesHold[]);
        this.contentFile.previewActive = !this.contentFile.previewActive;
    }
    
    onVoiceTalentSelect(voiceTalent: ContentVoiceTalents): void {
        this.contentFile.VoiceTalentId = voiceTalent.Id;
        this.contentFile.VoiceTalent.TalentName = voiceTalent.TalentName;
        this.contentFile.VoiceTalent.TalentGender = voiceTalent.TalentGender;
        this.contentFile.previewBtnIcon = voiceTalent.TalentGender === 'Male' ? 'fa-male' : 'fa-female';
        this.contentFile.iconColor = voiceTalent.TalentGender === 'Male' ? 'blue-text' : 'pink-text';
        this.contentFile.isDirty = true;
    }

    public onDeleteClick(): void {
        let confirmOk: boolean = confirm(`Are you sure you want to delete content file "${this.contentFile.VoiceTalent.TalentName}"?`);
        if(confirmOk) {
            this.detailsService.deleteContentFile(this.contentFile, this.content);      
        }
    }

    public onReplaceInputClick(): void {
        this.fileUploadStateService.replacementProps = { contentFileId: this.contentFile.Id, targetLibraryId: this.content.LibraryId };
        this.fileUploadService.onReplaceInputClick();
    }

    public replaceButtonVisible(): boolean {
        return this.appStateService.currentUser.IsEmployee && this.securityService.contentManagerCsrOrAdmin();
    }

    private writeAccessCheck(): void {
        this.detailsService.writeAccessCheck(this.content, this.contentFile)
            .subscribe((writeAccess: boolean) => {
                this.contentFile.writeAccess = writeAccess;
            })
    }

    public onHoverBtnMouseenter(prop: string): void {
        if (prop === 'replace') {
            this.replaceTimeout = setTimeout(() => this.replaceTooltipVisible = true, 400);
            clearTimeout(this.deleteTimeout);
            this.deleteTooltipVisible = false;
        }
        if (prop === 'delete') {
            this.deleteTimeout = setTimeout(() => this.deleteTooltipVisible = true, 400);
            clearTimeout(this.replaceTimeout);
            this.replaceTooltipVisible = false;
        }
    }

    public onHoverBtnMouseleave(): void {
        this.replaceTooltipVisible = false;
        this.deleteTooltipVisible = false;
        clearTimeout(this.replaceTimeout);
        clearTimeout(this.deleteTimeout);
    }
}
