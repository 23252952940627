import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ContentSerializerService } from '../../../core/services';
import { ContentFiles } from '../../api-models/content';
import { Hold, Video } from '../content-container/content/_models';
import { ContentItem } from '../../view-models/content';

@Component({
    selector: 'portal-select-box',
    templateUrl: './select-box.component.html',
    styleUrls: ['./select-box.component.scss']
})
export class SelectBoxComponent implements OnInit {

    @Input() content: Hold | Video;
    @Input() contentItem: ContentItem;
    @Input() isAudioPreviewPicker: boolean;
    @Input() textColorClass: string;
    @Input() title: string;
    @Input() width: string;

    @Output() onPickerExitNotify: EventEmitter<ContentFiles> = new EventEmitter<ContentFiles>();
    @Output() onSelectNotify: EventEmitter<ContentFiles> = new EventEmitter<ContentFiles>();
    
    public audioPreviewVisible: boolean;


    constructor(
        private contentSerializerService: ContentSerializerService) { }

    ngOnInit() {
        if (this.content) {
            this.contentItem = this.contentSerializerService.contentToContentItem(this.content);
        }
    }

    public onAudioPreviewExit(): void {
        this.audioPreviewVisible = false;
    }

    public onItemSelect(selectedContentFile: ContentFiles): void {
        this.onSelectNotify.emit(selectedContentFile);
    }
}
