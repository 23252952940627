import { Content } from '../../../../api-models/content';
import { ContentFilesHold, ContentFilesVideo } from '.';

export class Video extends Content {
	breadcrumbs: string[];
	contentType: string;
	contentFiles: (ContentFilesHold | ContentFilesVideo)[];
	favoriteBtnTooltip: string;
	isFavorite: boolean;
	isSelected: boolean;
	previewUrl: string;
	detailsVisible: boolean;
	thumbIcon: string;
	thumbnailUrl: string;
	processingStateJson: string;
	isGlobal: boolean;
	writeAccess: boolean;
	isDirty: boolean;
	order: number;
	create24BlankBgColor: string;
	contentTypeId: number;
	startDate: string;
	stopDate: string;
	displayedFeatureCodeId: number;
	isDisabled: boolean;
}
