import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Hold, Video } from 'src/app/shared/components/content-container/content/_models';
import { CategoriesTree, LibrariesTree } from 'src/app/shared/components/library-tree/_models';
import { TicketStates, Users } from 'src/app/shared/api-models/admin';

@Injectable({
	providedIn: 'root'
})
export class CacheService {
	public contentList: { category: CategoriesTree; contentList: (Hold | Video)[]; totalItemsContentList: number }[] = [];
	public libraries: LibrariesTree[] = [];
	public ticketStatesCache: TicketStates[] = [];
	public userCache: Users[] = [];

	constructor() {}

	public ticketState$(apiCall: Observable<TicketStates>, ticketStateId: number): Observable<TicketStates> {
		let ticketStateCached: boolean = this.ticketStatesCache?.some((ticketState) => ticketState.Id === ticketStateId);
		//If ticket state already cached, return an observable of the ticket state
		if (ticketStateCached) {
			return of(this.ticketStatesCache.find((ticketState) => ticketState.Id === ticketStateId));
		}
		//If ticket state not cached, return the api call to get ticket state
		return apiCall;
	}

	public setTicketStateCache(stateOrStates: TicketStates | TicketStates[]): void {
		let isArray: boolean = Array.isArray(stateOrStates);

		//If incoming ticket states is an array, add each ticket state to cache
		if (isArray) {
			let ticketStates: TicketStates[] = stateOrStates as TicketStates[];
			ticketStates.forEach((ticketState) => {
				this.ticketStatesCache.push(ticketState);
			});

			//If incoming is a single ticket state, push to cache
		} else {
			let state: TicketStates = stateOrStates as TicketStates;
			this.ticketStatesCache.push(state);
		}
		this.removeDuplicates(this.ticketStatesCache);
	}

	public user$(apiCall: Observable<Users>, userId: number): Observable<Users> {
		let userCached: boolean = this.userCache?.some((user) => user.Id === userId);
		//If user already cached, return an observable of the user
		if (userCached) {
			return of(this.userCache.find((user) => user.Id === userId));
		}
		//If user not cached, return the api call to get user
		return apiCall;
	}

	public setUserCache(usersOrUser: Users | Users[]): void {
		let isArray: boolean = Array.isArray(usersOrUser);

		//If incoming users is an array, add each user to cache
		if (isArray) {
			let users: Users[] = usersOrUser as Users[];
			users.forEach((user) => {
				this.userCache.push(user);
			});

			//If incoming is a single user, push to cache
		} else {
			let user: Users = usersOrUser as Users;
			this.userCache.push(user);
		}

		this.removeDuplicates(this.userCache);
	}

	private removeDuplicates(cache): void {
		//REMOVES DUPLICATE ENTRIES
		//https://stackoverflow.com/questions/45439961/remove-duplicate-values-from-an-array-of-objects-in-javascript/45440277
		let uniqueCache = cache?.reduce((unique, o) => {
			if (!unique.some((obj) => obj?.Id === o?.Id)) {
				unique.push(o);
			}
			return unique;
		}, []);
		cache = uniqueCache;
	}

	public storeContent(category: CategoriesTree, contentList: (Hold | Video)[], totalItemsContentList: number): void {
		let categoryIdExists = this.contentList.some((entry) => entry.category?.Id === category?.Id);
		if (!categoryIdExists) {
			this.contentList.push({ category, contentList, totalItemsContentList });
		}
	}

	public storeLibrary(library: LibrariesTree): void {
		let libraryExists = this.libraries.some((lib) => lib.Id === library.Id);
		if (!libraryExists) {
			this.libraries.push(library);
		}
	}
}
