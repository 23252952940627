import { Injectable, isDevMode } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthUser } from '../../shared/api-models/auth/auth-user';
import { Clients, ContactInformation, ProductTypes } from '../../shared/api-models/admin';
import { environment } from '../../../environments/environment';
import { FeatureArea } from 'src/app/shared/view-models';

type UserStoreResponse = {
	ClientId: number;
	StateJson: string;
	UserId: number;
};

type UserStore = {
	key: string;
	value?: string;
};

@Injectable({
	providedIn: 'root'
})
export class AppStateService {
	public contractedProducts: ProductTypes[];
	public newC24Active: boolean;
	public csrByClient: ContactInformation;
	public currentUser: AuthUser;
	public currentClient: Clients;
	public logosActive: boolean;
	public screenLayoutsActive: boolean;
	public isDevMode: boolean;
	public clientHasAppleTvs: boolean;
	public product: ProductTypes;
	public activeUrl: string;
	public activeFeatureArea: FeatureArea;
	private userStore: UserStore[] = [];
	private http: HttpClient;

	constructor(httpBackend: HttpBackend) {
		this.http = new HttpClient(httpBackend);
		this.setClientAndUser();
		this.setContractedProducts();
		this.getUserDataStore();
		this.setCsr();
		this.setNewC24Active();
		this.setProduct();
		this.setClientHasAppleTvs();
		this.setActiveUrl();
		this.isDevMode = isDevMode();
		// this.isDevMode = false;
		this.logosActive = environment.logosActive;
		this.screenLayoutsActive = environment.screenLayoutsActive;
	}

	public setProductWrapper(route: string): void {
		this.setSessionItem('product', JSON.stringify(this.findProductSetColor(route)));
		this.setProduct();
	}

	private setProduct(): void {
		if (this.getSessionItem('product')) {
			this.product = JSON.parse(this.getSessionItem('product'));
		}
	}

	private findProductSetColor(route: string): ProductTypes {
		const contractedProducts = this.contractedProducts;
		const product = contractedProducts.find((product) => product.Route === route);
		switch (route) {
			case 'hold':
				product.color = 'blue';
				break;
			case 'radio':
				product.color = 'purple';
				break;
			default:
				product.color = 'red';
		}
		return product;
	}

	public setClientAndUser(): void {
		if (this.getSessionItem('currentClient')) {
			this.currentClient = JSON.parse(this.getSessionItem('currentClient'));
		}
		if (this.getSessionItem('currentUser')) {
			this.currentUser = JSON.parse(this.getSessionItem('currentUser'));
		}
	}

	public setContractedProducts(): void {
		if (this.getSessionItem('contractedProducts')) {
			this.contractedProducts = JSON.parse(this.getSessionItem('contractedProducts'));
		}
	}

	public setActiveUrl(): void {
		if (this.getSessionItem('activeUrl')) {
			this.activeUrl = JSON.parse(this.getSessionItem('activeUrl'));
		}
	}

	public setActiveFeatureArea(): void {
		if (this.getSessionItem('activeFeatureArea')) {
			this.activeFeatureArea = JSON.parse(this.getSessionItem('activeFeatureArea'));
		}
	}

	public setCsr(): void {
		if (this.getSessionItem('csrByClient') && this.getSessionItem('csrByClient') !== 'undefined') {
			this.csrByClient = JSON.parse(this.getSessionItem('csrByClient'));
		}
	}

	public setNewC24Active(): void {
		if (this.getSessionItem('newC24Active')) {
			this.newC24Active = JSON.parse(this.getSessionItem('newC24Active'));
		}
	}

	public setClientHasAppleTvs(): void {
		if (this.getSessionItem('clientHasAppleTvs')) {
			this.clientHasAppleTvs = JSON.parse(this.getSessionItem('clientHasAppleTvs'));
		}
	}

	/* Session setting lifetime is tied to the 
     lifetime of the browser window/tab session
     When a tab/window is closed session
     settings are lost. */

	public getSessionItem(retrievedKey: string): string {
		let keys = Object.keys(sessionStorage);
		for (let encodedKey of keys) {
			if (encodedKey !== 'authToken') {
				let decodedKey: string = decodeURIComponent(window.atob(encodedKey));
				if (decodedKey === retrievedKey) {
					return decodeURIComponent(window.atob(sessionStorage.getItem(encodedKey)));
				}
			}
		}
	}

	/*public so an entry can be manually removed from elsewhere
    in the app */
	public removeSessionItem(key: string): void {
		let encodedKey: string = window.btoa(key);
		sessionStorage.removeItem(encodedKey);
	}

	public setSessionItem(key: string, value: string) {
		let encodedKey: string = window.btoa(encodeURIComponent(key));
		let encodedValue: string = window.btoa(encodeURIComponent(value));
		sessionStorage.setItem(encodedKey, encodedValue);
	}

	/* User settings are persisted to the database
     and are restored every time the user logs in.
     User settings "follow" the user regardless
     of the browser or device they log in on. */

	public getUserDataStore() {
		this.http.get(`${environment.adminUrl}PortalAppState`, this.options()).subscribe((userStoreResponse: UserStoreResponse[]) => {
			if (userStoreResponse?.length > 0) {
				this.userStore = JSON.parse(userStoreResponse[0].StateJson);
			}
		});
	}

	public getUserItem(key: string): Observable<string> {
		if (this.http) {
			return this.http.get(`${environment.adminUrl}PortalAppState`, this.options()).pipe(
				map((userStoreResponse: UserStoreResponse[]) => {
					if (userStoreResponse?.length > 0) {
						this.userStore = JSON.parse(userStoreResponse[0].StateJson);
						if (this.findValue(key, this.userStore)) {
							return this.findValue(key, this.userStore);
						}
						return '';
					}
					return '';
				})
			);
		}
		return of('');
	}

	public setUserItem(key: string, value: string) {
		if (this.userStore.length) {
			if (this.findValue(key, this.userStore)) {
				this.replaceUserStoreItemAndSave(key, value);
			} else {
				this.userStore.push(this.createUserItemObj(key, value));
				this.saveUserDataStore();
			}
		} else {
			this.userStore.push(this.createUserItemObj(key, value));
			this.saveUserDataStore();
		}
	}

	public removeUserItem(key: string): void {
		this.userStore = this.userStore.filter((item) => item.key !== key);
		this.saveUserDataStore();
	}

	private createUserItemObj(key: string, value: string): UserStore {
		return {
			key: key,
			value: value
		};
	}

	private findValue(key: string, store: { key: string; value?: string }[]): string | null {
		const allItems = store.filter((item) => item.key === key);
		const itemHasValue = allItems.some((item) => 'value' in item);
		if (itemHasValue) {
			return allItems[allItems.length - 1].value;
		}
		return null;
	}

	private replaceUserStoreItemAndSave(key: string, value: string): void {
		const uniqueKeys = new Set<string>();

		// Remove duplicates from userStore
		this.userStore = this.userStore.filter((item) => {
			if (uniqueKeys.has(item.key)) {
				return false; // Remove duplicate key
			}
			uniqueKeys.add(item.key);
			return true; // Keep unique key
		});

		// Replace store item and save data
		for (let i = 0; i < this.userStore.length; i++) {
			if (this.userStore[i].key === key) {
				this.userStore[i].value = value;
			}
		}
		this.saveUserDataStore();
	}

	private getHeaders(): HttpHeaders {
		let headers = new HttpHeaders();

		// Check if authToken exists in sessionStorage
		const authToken = sessionStorage.getItem('authToken');
		if (authToken && authToken !== 'null') {
			headers = headers.set('Authorization', `Bearer ${authToken}`);
			headers = headers.set('Content-Type', 'application/json');
		}
		return headers;
	}

	private options(): { headers: HttpHeaders; withCredentials: boolean } {
		const headers = this.getHeaders();
		return {
			headers,
			withCredentials: true
		};
	}

	private saveUserDataStore() {
		this.http
			.put(
				`${environment.adminUrl}PortalAppState`,
				{
					userId: this.currentUser.UserId,
					clientId: this.currentClient.Id,
					stateJson: JSON.stringify(this.userStore)
				},
				this.options()
			)
			.subscribe();
	}
}
