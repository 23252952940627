import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DragulaService } from 'ng2-dragula';

import { GroupManagerService } from './group-manager.service';
import { AppStateService, BrowserDetectorService } from '../core/services';

@Component({
    selector: 'app-group-manager',
    templateUrl: './group-manager.component.html',
    styleUrls: ['./group-manager.component.scss'],
    providers: [GroupManagerService]
})
export class GroupManagerComponent implements OnInit, OnDestroy {

    constructor(
        private activatedRoute: ActivatedRoute,
        public appStateService: AppStateService,
        public browserDetectorService: BrowserDetectorService,
        private dragulaService: DragulaService,
        private groupManagerService: GroupManagerService
    ) {
        dragulaService.createGroup('group-bag', {
            copySortSource: false,
            copy: (el: Element, source: Element): boolean => {
                return source.id !== 'dragContainerId';
            },
            accepts: (el: Element, target: Element, source: Element, sibling: Element): boolean => {
                return !el.contains(target) && target.id === 'dragContainerId';
            },
            moves: (el: Element, target: Element, event): boolean => {
                return target.id !== 'dragContainerId' && event.classList.contains('draggable');
            }
        });
    }

    ngOnInit(): void {
        const productRoute = this.activatedRoute.snapshot.paramMap.get("productRoute");
        this.appStateService.setProductWrapper(productRoute);
        this.groupManagerService.getGroupsAndPlayers();
    }

    ngOnDestroy() {
        //fix error that said the 'group-bag' already existed when re-initializing this component
        this.dragulaService.destroy('group-bag');
    }
}
