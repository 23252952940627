import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppErrorService } from 'src/app/core/app-error/app-error.service';
import { AppStateService, ContentSerializerService, DateTimeService, Events, MessageService, TypeGuardService, CacheService, UtilitiesService } from '../../core/services';
import { environment } from '../../../environments/environment';
import { ContentFiles, PlaylistContentFileBridge } from '../api-models/content';
import { CompletePlaylist, ContentItem, PbPlaylist } from '../view-models/content';
import { DeliveryQueue } from '../api-models/delivery';
import { Users } from '../api-models/admin';
import { Hold, Video } from '../components/content-container/content/_models';


@Injectable({
    providedIn: 'root'
})
export class GetPlaylistDataService {

    private completePlaylist: CompletePlaylist;
    private contentItems: ContentItem[] = [];
    private finalPlaylistContentItems: any[] = [];
    private playlistContentFileBridgeArr: PlaylistContentFileBridge[] = [];

    constructor(
        private appErrorService: AppErrorService,
        private appStateService: AppStateService,
        private contentSerializerService: ContentSerializerService,
        private dateTimeService: DateTimeService,
        private httpClient: HttpClient,
        private messageService: MessageService,
        private typeGuardService: TypeGuardService,
        private cacheService: CacheService,
        private utilService: UtilitiesService) { }

  

    public getCompletePlaylist(playlistId: number, isFeaturedPlaylist?: boolean): Observable<PbPlaylist> {
        this.resetArraysToZero();
        
        return this.httpClient.get(`${environment.contentUrl}Playlists/${playlistId}/Complete`).pipe(
            mergeMap( (completePlaylist: CompletePlaylist) => {
                if(completePlaylist.Playlist?.CreatedByUserId) {
                    const apiCall = this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${completePlaylist.Playlist.CreatedByUserId}`);
                    return this.cacheService.user$(apiCall, completePlaylist.Playlist.CreatedByUserId).pipe(
                        map((user: Users) => {
                            this.completePlaylist = completePlaylist;
                            try {
                                if(completePlaylist.PlaylistFileBridge.length > 0) {
                                    this.playlistContentFileBridgeArr = this.utilService.sortItems(completePlaylist.PlaylistFileBridge, 'Order');
                                    this.contentItems = this.buildPlaylistContentItems();
                                    this.cacheService.setUserCache(user);
                                    return this.buildPlaylistView(user);
                                }
                                throw 'There are no content items in this playlist.';
                            } catch (error) {
                                this.appErrorService.showAppError = true;
                                const hideReloadPageBtn: boolean = true;
                                const errorPayload = [error, error, hideReloadPageBtn];
                                this.messageService.publish(Events.appError, errorPayload);
                                console.error(error);
                            }  
                        })
                    )
                }
                this.completePlaylist = completePlaylist;
                this.playlistContentFileBridgeArr = this.utilService.sortItems(completePlaylist.PlaylistFileBridge, 'Order');
                this.contentItems = this.buildPlaylistContentItems();
                return of(this.buildPlaylistView()) as Observable<PbPlaylist>;
            })
        )
    }

    private resetArraysToZero(): void {
        this.finalPlaylistContentItems.length = 0;
        this.playlistContentFileBridgeArr.length = 0;
        this.contentItems.length = 0;
    }

    private buildPlaylistView(user?: Users): PbPlaylist {
        const playlist: PbPlaylist = new PbPlaylist();
        let deliveryQueue: DeliveryQueue;
        playlist.Name = this.completePlaylist.Playlist.Name;
        playlist.Id = this.completePlaylist.Playlist.Id;
        playlist.status = this.completePlaylist.PlaylistStatus;
        playlist.ProductId = this.completePlaylist.Playlist.ProductId;
        playlist.ClientId = this.completePlaylist.Playlist.ClientId;
        playlist.CreateDate = this.completePlaylist.Playlist.CreateDate;
        playlist.CreatedByUserId = this.completePlaylist.Playlist.CreatedByUserId;
        playlist.IsDeleted = this.completePlaylist.Playlist.IsDeleted;
        playlist.ParagraphPause = this.completePlaylist.Playlist.ParagraphPause;
        playlist.UpdateDate = this.completePlaylist.Playlist.UpdateDate;
        playlist.isWritable = true;
        playlist.previewBtnIcon = 'fas fa-play';
        if (user) {
            playlist.createdByUserFriendlyName = user.ContactInformation.FirstName + ' ' + user.ContactInformation.LastName;
        }

        if (this.completePlaylist.DeliveryQueue[0]) {
            deliveryQueue = this.completePlaylist.DeliveryQueue[0];
            if (deliveryQueue.StartDate) {
                if (deliveryQueue.StartDate.slice(-1) !== 'Z') {
                    deliveryQueue.StartDate = deliveryQueue.StartDate + 'Z'; //must have Z suffix to convert correctly
                }
                playlist.startDate = deliveryQueue.StartDate;
                if (deliveryQueue.StopDate) {
                    if (deliveryQueue.StopDate.slice(-1) !== 'Z') {
                        deliveryQueue.StopDate = deliveryQueue.StopDate + 'Z'; //must have Z suffix to convert correctly
                    }
                    playlist.stopDate = deliveryQueue.StopDate;
                    playlist.isStopDateSelected = true;
                } else {
                    playlist.isStopDateSelected = false;
                }
                playlist.advancedSchedule = deliveryQueue.PlaylistAdvancedSchedule;
            }
            playlist.advancedSchedule = deliveryQueue.PlaylistAdvancedSchedule;
        }

        playlist.contentItems = this.contentItems;
        playlist.contentItems.forEach((contentItem) => {
            contentItem.contentFiles = [this.contentSerializerService.contentItemToContentFile(contentItem)];
        });
        return playlist;
    }

    private findBridgeRowMatch(bridge: PlaylistContentFileBridge, contentFile: ContentFiles): boolean {
        return bridge.ContentFileId === contentFile.Id;
    }

    private buildPlaylistContentItems(): ContentItem[] {
        const completeContentFiles = this.completePlaylist.PlaylistFiles;

        let playlistItemsJson: any[];
        if (this.completePlaylist.DeliveryQueue[0]) {
            playlistItemsJson = JSON.parse(this.completePlaylist.DeliveryQueue[0].PlaylistJson).PlaylistItems;
        }

        this.playlistContentFileBridgeArr.forEach((bridge) => {
            this.finalPlaylistContentItems.push(completeContentFiles.find(contentFile => this.findBridgeRowMatch(bridge, contentFile)));
        })

        const contentItems: ContentItem[] = this.finalPlaylistContentItems.map((completeContentFile, index) => {
            const contentItem: ContentItem = new ContentItem();

            contentItem.contentFileId = completeContentFile.Id;
            contentItem.contentId = completeContentFile.ContentId;
            contentItem.contentTypeId = completeContentFile.Content.ContentTypeId;
            contentItem.contentType = this.contentSerializerService.contentTypeIdToContentType(completeContentFile.Content.ContentTypeId);
            contentItem.name = completeContentFile.Content.Title;
            contentItem.script = completeContentFile.Content.Text;
            contentItem.libraryId = completeContentFile.Content.LibraryId;
            contentItem.createDate = completeContentFile.CreateDate;
            contentItem.isDeleted = completeContentFile.Content.IsDeleted;
            contentItem.create24OutputHtmlV4 = completeContentFile.Create24OutputHtmlV4;
            contentItem.create24ProjectJsonV4 = completeContentFile.Create24ProjectJsonV4;

            contentItem.startDate = this.playlistContentFileBridgeArr[index].StartDate ? this.playlistContentFileBridgeArr[index].StartDate : this.dateTimeService.todayAtMidnightUTC();

            if (contentItem.startDate.slice(-1) !== 'Z') {
                contentItem.startDate = contentItem.startDate + 'Z'; //must have Z suffix to convert correctly
            }

            if (this.playlistContentFileBridgeArr[index].StopDate) {
                contentItem.isStopDateSelected = true;
                contentItem.stopDate = this.playlistContentFileBridgeArr[index].StopDate;
            } else {
                contentItem.isStopDateSelected = false;
            }

            contentItem.contentName = completeContentFile.Content.Title;
            contentItem.isChannel = completeContentFile.Content.IsChannel;
            contentItem.silenceMusic = this.playlistContentFileBridgeArr[index].SilenceMusic;
            contentItem.contentFileStateId = completeContentFile.ContentFileStateId;
            contentItem.masterFileName = completeContentFile.MasterFileName;
            contentItem.createdByUserId = completeContentFile.Content.CreatedByUserId;
            if (this.playlistContentFileBridgeArr[index].IsMusicTrack) {
                contentItem.previewUrl = environment.contentUrl + 'File/Hold/Music/' + contentItem.contentFileId + '/Preview';
            }

            contentItem.previewFileName = completeContentFile.PreviewFileName;

            if (this.typeGuardService.filesAreHold(contentItem.contentFiles) && completeContentFile.VoiceTalent) {
                contentItem.VoiceTalent = completeContentFile.VoiceTalent;
                contentItem.voiceTalentName = completeContentFile.VoiceTalent.TalentName;
                contentItem.voiceTalentGender = completeContentFile.VoiceTalent.TalentGender;
                contentItem.voiceTalentId = completeContentFile.VoiceTalent.Id;
            }

            completeContentFile.DurationMs > 0 ? contentItem.duration = completeContentFile.DurationMs : contentItem.duration = 0;
            if (this.utilService.includes(contentItem.previewFileName, 'png')) {
                contentItem.stillDuration = this.playlistContentFileBridgeArr[index].StillDuration;
                contentItem.duration = completeContentFile.DurationMs;
            }
            contentItem.isMusicTrack = this.playlistContentFileBridgeArr[index].IsMusicTrack;

            
            if (!contentItem.isChannel) {
                contentItem.previewUrl = this.previewUrl(completeContentFile, contentItem.isMusicTrack);
                contentItem.thumbnailUrl = environment.contentUrl + 'File/' + this.previewUrlParam(contentItem.isMusicTrack) + completeContentFile.Id + '/Thumbnail';
            } else {
                contentItem.thumbnailUrl = '/img/channel-thumb.jpg';
            }
            contentItem.order = this.playlistContentFileBridgeArr[index].Order;
            contentItem.weatherOverride = this.playlistContentFileBridgeArr[index].WeatherOverride;
            if (contentItem.weatherOverride === null) {
                delete contentItem.weatherOverride;
            }

            contentItem.playlistContentFileBridgeId = this.playlistContentFileBridgeArr[index].Id;
            contentItem.advancedSchedule = this.playlistContentFileBridgeArr[index].ContentAdvancedSchedule;
            contentItem.silenceMusic = this.playlistContentFileBridgeArr[index].SilenceMusic;

            if (contentItem.silenceMusic === null) {
                delete contentItem.silenceMusic;
            }
            if (this.playlistContentFileBridgeArr[index].StillDuration) {
                contentItem.duration = this.playlistContentFileBridgeArr[index].StillDuration;
            }
            if (contentItem.advancedSchedule) {
                contentItem.enableAdvancedSchedule = true;
            }
            if (playlistItemsJson) {
                contentItem.advancedSchedule = playlistItemsJson[index]?.AdvancedSchedule;
            }
            return contentItem;
        });

        return contentItems;
    }

    public previewUrl(completeContentFile: any, isMusicTrack: boolean): string {
        const content: Hold | Video = completeContentFile.Content;
        const c24BgContent: Video = JSON.parse(completeContentFile.Create24ProjectJsonV4)?.bgContent;
        
        switch (content.ContentTypeId) {
            case 1000006: //c24 still
            case 1000007: //c24 video
            case 1000009: //Video-Sidebar-Create24
                if(c24BgContent) {
                    return environment.contentUrl + 'File/' + this.previewUrlParam(isMusicTrack) + c24BgContent?.contentFiles[0].Id + '/Preview';
                }
        
                //If no bg content exists, use hard-coded urls linked to blank bg in the db
                if(content.ContentTypeId === 1000009) { 
                    return `${environment.contentUrl}File/Video/1131995/Preview/?${new Date().toString()}`;
                }
                if(this.appStateService.product.Route === 'poster') {
                    return `${environment.contentUrl}File/Video/1131994/Preview/?${new Date().toString()}`;
                }
                return `${environment.contentUrl}File/Video/1131993/Preview/?${new Date().toString()}`;
            default:
                return environment.contentUrl + 'File/' + this.previewUrlParam(isMusicTrack, content) + completeContentFile.Id + '/Preview';
        }   
    }

    private previewUrlParam(isMusicTrack: boolean, content?: Hold | Video): string {
        const previewFileName = content?.contentFiles ? content?.contentFiles[0].PreviewFileName : null;
        if(this.appStateService.product?.Route === 'hold' || this.utilService.includes(previewFileName, 'mp3')) {
            return `Hold/${isMusicTrack ? 'Music' : 'Message'}/`;
        }
        return 'Video/';
    }
}
