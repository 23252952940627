<portal-header></portal-header>

<!--Promo Bar Popup-->
<ng-container *ngIf="clientNewsletterService.newsletter$ | async as newsletter">
	<div *ngIf="((newsletter.ProductsPagePopupActive && showPopup) || previewPopup) && !appStateService.clientHasAppleTvs" class="absolute left-0 right-0">
		<div class="modal-overlay" (click)="hidePopup()"></div>
		<div class="row">
			<div
				style="top: 260px; margin-bottom: 60px; z-index: 200"
				class="col-xs-12 col-md-8 col-lg-6 col-sm-offset-3 min-height600 margin mgn-bottom30 absolute left-0 right-0 slideUp"
			>
				<mp-popup [newsletter]="clientNewsletterService.newsletter$ | async" (onExitClick)="hidePopup()"></mp-popup>
			</div>
		</div>
	</div>

	<!--Newsletter Promo Bar-->
	<mp-promo-bar *ngIf="promoBarService.showPromoBar(newsletter)" [newsletter]="newsletter"></mp-promo-bar>

	<div class="container-fluid">
		<!--Promo options menu-->
		<div (mouseover)="onTitleOver()" (mouseleave)="showPromoBarEditBtn = false" class="row mgn-top15 mgn-bottom15 relative">
			<button
				*ngIf="appStateService.currentUser.Role === 'Administrator' && showPromoBarEditBtn"
				(click)="showPromoOptionsMenu = !showPromoOptionsMenu"
				style="width: initial"
				class="pd10 white-bg absolute left15"
			>
				<i class="fas fa-bullhorn mgn-right10"></i>
				{{ showPromoOptionsMenu ? 'Close' : 'Promo options' }}
			</button>

			<div
				*ngIf="appStateService.currentUser.Role === 'Administrator' && showPromoOptionsMenu"
				style="width: 500px"
				class="white-bg border-radius shadow pd15 absolute top50 left15 z"
			>
				<div class="row mgn-bottom30">
					<div class="col-sm-6">
						<h2 class="regular in-block">
							<i class="fas fa-window-minimize mgn-right12 relative topneg6"></i>
							Promo Bar
						</h2>
						<span
							style="border-radius: 12px"
							class="{{ newsletter.ProductsPagePromoBarActive ? 'limeGreen-bg' : 'gray-bg-darkest' }} white-text ft-size12 mgn-left10 pd5 relative topneg10"
						>
							{{ newsletter.ProductsPagePromoBarActive ? 'Active' : 'Inactive' }}
						</span>
					</div>
					<div class="col-sm-6">
						<h2 class="regular in-block">
							<i class="far fa-window-maximize mgn-right12"></i>
							Popup
						</h2>
						<span
							style="border-radius: 12px"
							class="{{ newsletter.ProductsPagePopupActive ? 'limeGreen-bg' : 'gray-bg-darkest' }} white-text ft-size12 mgn-left10 pd5 relative topneg10"
						>
							{{ newsletter.ProductsPagePopupActive ? 'Active' : 'Inactive' }}
						</span>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6">
						<button (click)="promoBarService.onEnableOrDisablePromoBarClick(newsletter)" class="red-bg-light white-text mgn-bottom30">
							<i class="fas fa-exclamation-triangle mgn-right10"></i>
							{{ newsletter.ProductsPagePromoBarActive ? 'Deactivate' : 'Activate' }} Promo Bar
						</button>
						<button
							*ngIf="!newsletter.ProductsPagePromoBarActive"
							(click)="promoBarService.previewPromoBar = !promoBarService.previewPromoBar"
							class="{{ promoBarService.previewPromoBar ? 'gray-bg-darker' : 'gray-bg' }} mgn-bottom30"
						>
							<i class="far fa-eye mgn-right15"></i>
							{{ promoBarService.previewPromoBar ? 'Hide' : 'Preview' }} Promo Bar
						</button>
						<button
							(click)="promoBarService.onPromoBarEditSaveClick(newsletter)"
							class="{{ promoBarService.promoBarState === 'edit' ? 'limeGreen-bg white-text' : 'gray-bg' }}"
						>
							<i class="fas fa-pencil-alt mgn-right15"></i>
							{{ promoBarService.promoBarState === 'edit' ? 'Save' : 'Edit' }} Promo Bar
						</button>
					</div>
					<div class="col-sm-6">
						<button (click)="onEnableOrDisablePopupClick(newsletter)" class="red-bg-light white-text mgn-bottom30">
							<i class="fas fa-exclamation-triangle mgn-right10"></i>
							{{ newsletter.ProductsPagePopupActive ? 'Deactivate' : 'Activate' }} Popup
						</button>
						<button (click)="previewPopup = !previewPopup" class="{{ previewPopup ? 'gray-bg-darker' : 'gray-bg' }} mgn-bottom30">
							<i class="far fa-eye mgn-right15"></i>
							{{ previewPopup ? 'Hide' : 'Preview' }} Popup
						</button>
					</div>
				</div>
			</div>

			<!--Begin main content-->
			<div class="col-xs-10 col-xs-offset-2 col-md-6 col-md-offset-6 text-right">
				<h1 class="semi-bold">
					<i class="fas fa-boxes red-text mgn-right12" aria-hidden="true"></i>
					<span>My Products</span>
				</h1>
			</div>
		</div>
		<div class="gray-bg-dark ht2 border-radius"></div>
	</div>
</ng-container>

<div *ngIf="adminDataService.noContractedProducts" class="row mgn-top30">
	<div class="col-sm-12">
		<h3 class="gray-text-darker mgn-left15"><em>This client does not have any active contracts.</em></h3>
	</div>
</div>

<div class="container-fluid mgn-top45 mgn-bottom20 fadeInUp">
	<div class="row">
		<db-card *ngFor="let card of myProductsStore.productCards$ | async" [card]="card"></db-card>
	</div>
</div>
