import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { CsrTasksView } from './_models/csr-tasks-view';
import { AppStateService, Events, MessageService } from '../../core/services';
import { ApiUrlsService, CsrTasksService, TableConfigService, ViewStateService } from './_services';
import { Column } from '../../shared/components/table/view-models';
import { SearchFilterPipe } from '../../shared/pipes';
import { UtilitiesService } from '../../core/services';
import { SortParams } from 'src/app/shared/view-models/common-types';

@Component({
	selector: 'st-csr-tasks',
	templateUrl: './csr-tasks.component.html',
	styleUrls: ['./csr-tasks.component.scss'],
	providers: [SearchFilterPipe]
})
export class CsrTasksComponent implements OnInit, OnDestroy {
	public byClientSearchResults: CsrTasksView[];
	public spinnerActive: boolean;
	public tasks: CsrTasksView[];

	private searchTermChanged: Subject<string> = new Subject<string>();
	private subs: Subscription[] = [];

	constructor(
		public apiUrlsService: ApiUrlsService,
		private appStateService: AppStateService,
		public csrTasksService: CsrTasksService,
		private messageService: MessageService,
		private router: Router,
		private searchFilterPipe: SearchFilterPipe,
		public tableConfigService: TableConfigService,
		public utilService: UtilitiesService,
		public viewStateService: ViewStateService
	) {
		this.onColumnSortSubscribe();
		this.searchTermChanged.pipe(debounceTime(400)).subscribe(() => {
			this.getTasks();
		});
	}

	ngOnInit() {
		const activeUrl = this.router.routerState.snapshot.url;
		this.setRouteState(activeUrl);
		this.getTasks();
	}

	getPage(pageNumber: number): void {
		this.utilService.scrollToTop();

		setTimeout(() => {
			this.viewStateService.state.pageNumber = pageNumber;
			if (this.viewStateService.state.route === 'my-tasks') {
				this.getTasks();
			}
		}, 400);
	}

	onCheckboxClick(str: string): void {
		this.viewStateService.state[str] = !this.viewStateService.state[str];
		if (str === 'dateRange') {
			this.viewStateService.state.showPast = false;
			this.viewStateService.state.showTodayAndFuture = false;
			if (this.viewStateService.state.dateRange) {
				this.tasks.length = 0;
			} else {
				this.getTasks();
			}
		} else {
			this.getTasks();
		}
	}

	onClientNameClick(payload: [any, Column]): void {
		let task: CsrTasksView = payload[0];
		localStorage.setItem('changeClientUrl', `/crm/client-details/locations/`);
		window.open(`/my-products/${task.ClientId}`);
	}

	onColumnSortSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.onColumnSort, (sortParams: SortParams) => {
				sortParams.order === 'asc' ? (this.viewStateService.state.orderDesc = false) : (this.viewStateService.state.orderDesc = true);
				this.getTasks();
			})
		);
	}

	onTableButtonClick([column, task]: [Column, CsrTasksView]): void {
		switch (column.key) {
			case 'editBtn':
				this.router.navigate([`system-tools/csr-tasks/edit-task/${task.Id}`]);
				break;

			case 'completeBtn':
				this.messageService.publish(Events.savingPreloader, 1);
				if (task.completeBtn === 'Complete') {
					task.CompleteDate = new Date().toISOString();
					task.Completed = true;
					this.updateTask(task);
					this.csrTasksService.postHistoryNote(task).subscribe();
				} else if (task.completeBtn === 'Uncomplete') {
					task.CompleteDate = null;
					task.Completed = false;
					this.updateTask(task);
				}
				break;
			case 'deleteBtn':
				const confirmOk: boolean = confirm(`Delete task "${task.Subject}"?`);
				if (confirmOk) {
					this.messageService.publish(Events.savingPreloader, 1);
					this.csrTasksService.deleteTask(task).subscribe(() => {
						this.getTasks();
						this.messageService.publish(Events.savingPreloader, 0);
					});
				}
				break;
		}
	}

	private updateTask(task: CsrTasksView): void {
		this.csrTasksService.updateTask(task).subscribe(() => {
			this.getTasks();
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	public onDateRangeGoClick(): void {
		this.getTasks();
	}

	public onSearchKeyup(): void {
		if (this.viewStateService.state.route === 'my-tasks') {
			this.searchTermChanged.next(this.viewStateService.state.searchTerm);
		} else if (this.viewStateService.state.route === 'by-client') {
			this.byClientSearchResults = this.searchFilterPipe.transform(this.tasks, this.viewStateService.state.searchTerm);
			this.csrTasksService.totalItems = this.byClientSearchResults.length;
		}
	}

	public onSearchClear(): void {
		this.viewStateService.state.searchTerm = '';
		if (this.viewStateService.state.route === 'my-tasks') {
			this.getTasks();
		} else if (this.viewStateService.state.route === 'by-client') {
			this.csrTasksService.totalItems = this.tasks.length;
		}
	}

	public onNewTaskClick(): void {
		this.router.navigate([`/new-csr-task/${this.appStateService.currentClient.Id}`]);
	}

	public byClientRouteAndSearchActive(): boolean {
		let state = this.viewStateService.state;
		if (state.route === 'by-client' && state.searchTerm.length > 0) {
			return true;
		}
		return false;
	}

	private getTasks(): void {
		this.spinnerActive = true;
		this.csrTasksService.getTasks(this.viewStateService.state.route).subscribe((tasks: CsrTasksView[]) => {
			this.tasks = tasks;
			this.spinnerActive = false;
		});
	}

	private setRouteState(activeUrl: string): void {
		if (this.utilService.includes(activeUrl, 'system-tools')) {
			this.viewStateService.state.route = 'my-tasks';
		} else {
			this.viewStateService.state.route = 'by-client';
		}
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
