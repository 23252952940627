import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Column } from '../../shared/components/table/view-models';
import { EmailBlastToolService, TableConfigService } from './_services';
import { SearchFilterPipe } from '../../shared/pipes';
import { UtilitiesService } from 'src/app/core/services';

@Component({
    selector: 'app-email-blast-tool',
    templateUrl: './email-blast-tool.component.html',
    styleUrls: ['./email-blast-tool.component.scss']
})
export class EmailBlastToolComponent implements OnInit {

    public route: string;
    public searchResults: any[] = [];
    public searchTerm: string = '';

    constructor(
        public emailBlastToolService: EmailBlastToolService,
        private router: Router,
        private searchFilterPipe: SearchFilterPipe,
        public tableConfigService: TableConfigService,
        private utilService: UtilitiesService
    ) { }

    ngOnInit(): void {
        let activeUrl: string = this.router.routerState.snapshot.url;
        this.route = this.utilService.getLastParam(activeUrl);
        this.viewInit();
    }

    private viewInit(): void {
        switch (this.route) {
            case 'my-templates': this.emailBlastToolService.getTemplates(); break;
            case 'my-mailings': this.emailBlastToolService.getMailings(); break;
        }
    }

    onTableButtonClick(payload: [Column, any, string]): void {
        let column: Column = payload[0];
        let item: any = payload[1];
        let value: string = payload[2];

        switch (column['key']) {
            case 'mailBtn':
                this.router.navigate([`/email-blast-tool/create-mailing/group-id/${item.Id}`]);
                break;

            case 'mailingsDeleteBtn':
            case 'templatesDeleteBtn':
                let confirmDelete: boolean = confirm(`Delete "${item.Name}?" This action cannot be undone.`);
                if (confirmDelete) {
                    this.emailBlastToolService.delete(this.route, item.Id);
                }
                break;
            case 'mailingsEditSendBtn':
                this.router.navigate([`/email-blast-tool/edit-mailing/${item.Id}`]);
                break;
            case 'editBtn':
                this.router.navigate([`/email-blast-tool/edit-template/${item.Id}`]);
                break;
        }

        //Updating an editable column
        if (value) {
            this.emailBlastToolService.updateGroupColumn(item, value, column['key']);
        }
    }

    public onNavClick(route: string): void {
        this.router.navigate([`/email-blast-tool/${route}`]);
        this.route = route;
    }

    public routeFriendlyView(): string {
        return this.route.replace('-', ' ');
    }

    public onSearchKeyup(): void {
        this.searchResults = this.searchFilterPipe.transform(this.searchArr(), this.searchTerm);
    }

    private searchArr(): any[] {
        switch (this.route) {
            case 'my-mailings': return this.emailBlastToolService.mailings;
            case 'my-templates': return this.emailBlastToolService.templates;
        }
    }
}
