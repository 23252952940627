import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AppStateService } from '../app-state.service';

@Injectable({
    providedIn: 'root'
})
export class VideoProductsOnlyGuardService implements CanActivate {

    constructor(private appStateService: AppStateService, private router: Router) { }

    canActivate() {
        if (this.appStateService.getSessionItem('atLeastOneVideoProduct')) {
            if (JSON.parse(this.appStateService.getSessionItem('atLeastOneVideoProduct'))) {
                return true;
            } else {
                this.router.navigate(['/access-denied']);
            }
        } else {
            this.router.navigate(['/my-products']);
        }
    }
}
