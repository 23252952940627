export enum ProductRoute {
	HOLD = 'hold',
	RADIO = 'radio',
	VIDEO = 'video',
	POSTER = 'poster',
	VWALL1x2V = 'vwall1x2v',
	VWALL4X1V = 'vwall4x1v',
	VWALL3X1H = 'vwall3x1h',
	VWALL2X2H = 'vwall2x2h',
	WEBH = 'webH',
	WEBV = 'webV',
	VWALL3X1V = 'vwall3x1v',
	VWALL2X1V = 'vwall2x1v',
	VWALL1X2H = 'vwall1x2h',
	VWALL2X1H = 'vwall2x1h',
	VWALL1X3H = 'vwall1x3h',
	VWALL2X2V = 'vwall2x2v',
	VWALL4X1H = 'vwall4x1h'
}
