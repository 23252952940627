import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminOrNatalieGuardService, AuthGuardService, IsEmployeeGuardService } from '../core/services/route-guards';
import { ClientBrowserComponent } from './client-browser/client-browser.component';
import { ContractRenewalsReportComponent } from './contract-renewals-report/contract-renewals-report.component';
import { HoldDeliveryQueueComponent } from './hold-delivery-queue/hold-delivery-queue.component';
import { LibraryEditorComponent } from './library-editor/library-editor.component';
import { NewsManagerComponent } from './news-manager/news-manager.component';
import { CreateEditFeedItemComponent } from './news-manager/create-edit-feed-item/create-edit-feed-item.component';
import { PlayerStatusReportComponent } from './player-status-report/player-status-report.component';
import { ReminderEmailsComponent } from './reminder-emails/reminder-emails.component';
import { TicketSystemComponent } from './ticket-system/ticket-system.component';
import { CreateEditMailingComponent } from './email-blast-tool/create-edit-mailing/create-edit-mailing.component';
import { EmailBlastToolComponent } from './email-blast-tool/email-blast-tool.component';
import { CreateEditTemplateComponent } from './email-blast-tool/create-edit-template/create-edit-template.component';
import { ClientMergeToolComponent } from './client-merge-tool/client-merge-tool.component';
import { UsageReportsComponent } from './usage-reports/usage-reports.component';
import { LibrarySearchComponent } from './usage-reports/library-search/library-search.component';
import { ReportComponent } from './usage-reports/report/report.component';

const routes: Routes = [
  { 
    path: 'client-browser', 
    component: ClientBrowserComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  { 
    path: 'contract-renewals-report', 
    component: ContractRenewalsReportComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  { 
    path: 'contract-renewals-report/salespersonId/:salespersonId', 
    component: ContractRenewalsReportComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  { 
    path: 'library-editor', 
    component: LibraryEditorComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  { 
    path: 'news-manager', 
    component: NewsManagerComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  { 
    path: 'news-manager/new-feed-item', 
    component: CreateEditFeedItemComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  { 
    path: 'news-manager/edit-feed-item/:feedId/:feedItemId', 
    component: CreateEditFeedItemComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  { 
    path: 'on-hold-delivery-queue', 
    component: HoldDeliveryQueueComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  { 
    path: 'player-status-report', 
    component: PlayerStatusReportComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  { 
    path: 'ticket-system', 
    component: TicketSystemComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  {
    path: 'reminder-emails', 
    component: ReminderEmailsComponent,
    canActivate: [AdminOrNatalieGuardService, AuthGuardService, IsEmployeeGuardService]
  },
  {
    path: 'email-blast-tool/my-templates', 
    component: EmailBlastToolComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  {
    path: 'email-blast-tool/my-mailings', 
    component: EmailBlastToolComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  {
    path: 'email-blast-tool/create-mailing/group-id/:groupId', 
    component: CreateEditMailingComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  {
    path: 'email-blast-tool/edit-mailing/:csrBlastId', 
    component: CreateEditMailingComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  {
    path: 'email-blast-tool/create-template', 
    component: CreateEditTemplateComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  {
    path: 'email-blast-tool/edit-template/:templateId', 
    component: CreateEditTemplateComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  {
    path: 'client-merge-tool', 
    component: ClientMergeToolComponent,
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  },
  {
    path: 'usage-reports', 
    component: UsageReportsComponent,
    children: [
      {
        path: 'library-search',
        component: LibrarySearchComponent,
        canActivate: [AuthGuardService, IsEmployeeGuardService]
      },
      {
        path: 'report/:libraryIds',
        component: ReportComponent,
        canActivate: [AuthGuardService, IsEmployeeGuardService]
      }
    ],
    canActivate: [AuthGuardService, IsEmployeeGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemToolsRoutingModule { }
