import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AppStateService } from '../app-state.service';
import { SecurityService } from '../security.service';

@Injectable({
    providedIn: 'root'
})
export class ContentCreatorGuardService implements CanActivate {

    constructor(
            private appStateService: AppStateService, 
            private router: Router, 
            private securityService: SecurityService) { }

    canActivate() {
        if (this.securityService.userRole(this.appStateService.currentUser.Role) !== 'contentcreator' || 
        this.appStateService.currentUser.Role === 'Video Content Creator') {
            return true;
        } else {
            this.router.navigate(['/access-denied']);
        }
    }
}
