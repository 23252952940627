import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../core/services/route-guards';
import { ClientDetailsComponent } from '../crm/client-details/client-details.component';
import { ClientViewComponent } from '../content-requests/create-edit-request/details/client-view/client-view.component';
import { ClipComponent } from '../content-requests/create-edit-request/clips/clip/clip.component';
import { ClipsComponent } from '../content-requests/create-edit-request/clips/clips.component';
import { CommentsComponent } from '../content-requests/create-edit-request/comments/comments.component';
import { ContentFileComponent } from '../content-requests/create-edit-request/clips/clip/hold-view/content-file.component';
import { ContentRequestsComponent } from '../content-requests/content-requests.component';
import { ContractComponent } from '../crm/contracts/contract/contract.component';
import { ContractsComponent } from '../crm/contracts/contracts.component';
import { CreateEditRequestComponent } from '../content-requests/create-edit-request/create-edit-request.component';
import { CreateEditTaskComponent } from '../system-tools/csr-tasks/create-edit-task/create-edit-task.component';
import { CsrTasksComponent } from '../system-tools/csr-tasks/csr-tasks.component';
import { DetailsComponent } from '../content-requests/create-edit-request/details/details.component';
import { HistoryNotesComponent } from '../crm/history-notes/history-notes.component';
import { HnXsViewComponent } from '../crm/history-notes/hn-xs-view/hn-xs-view.component';
import { HoldViewComponent } from '../content-requests/create-edit-request/clips/clip/hold-view/hold-view.component';
import { InternalHoldViewComponent } from '../content-requests/create-edit-request/details/internal-hold-view/internal-hold-view.component';
import { InternalVideoViewComponent } from '../content-requests/create-edit-request/details/internal-video-view/internal-video-view.component';
import { IsEmployeeGuardService, IsEmployeeContractorOrVoiceTalentGuardService } from '../core/services/route-guards';
import { LibrariesComponent } from '../crm/libraries/libraries.component';
import { LocXsViewComponent } from '../crm/locations/loc-xs-view/loc-xs-view.component';
import { LocationsComponent } from '../crm/locations/locations.component';
import { MasterButtonsComponent } from '../content-requests/create-edit-request/master-buttons/master-buttons.component';
import { NavBarComponent } from '../content-requests/nav-bar/nav-bar.component';
import { SharedModule } from './shared.module';
import { UploadModalComponent } from '../content-requests/create-edit-request/clips/clip/hold-view/upload-modal/upload-modal.component';
import { VideoViewComponent } from '../content-requests/create-edit-request/clips/clip/video-view/video-view.component';
import { ClientTicketsComponent } from '../crm/client-tickets/client-tickets.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: ClientDetailsComponent,
        children: [
          {
            path: 'locations',
            component: LocationsComponent,
            canActivate: [AuthGuardService, IsEmployeeGuardService]
          },
          {
            path: 'history-notes',
            component: HistoryNotesComponent
          },
          {
            path: 'libraries',
            component: LibrariesComponent
          },
          {
            path: 'contracts',
            component: ContractsComponent
          },
          { 
            path: 'content-requests-by-client',
            component: ContentRequestsComponent,
            canActivate: [AuthGuardService, IsEmployeeGuardService]
          },
          {
            path: 'csr-tasks-by-client',  
            component: CsrTasksComponent,           
            canActivate: [AuthGuardService, IsEmployeeGuardService]
          },
          {
            path: 'tickets',  
            component: ClientTicketsComponent,           
            canActivate: [AuthGuardService, IsEmployeeGuardService]
          }
        ]
      },
      {
        path: 'system-tools/content-requests',
        component: ContentRequestsComponent,
        canActivate: [AuthGuardService, IsEmployeeContractorOrVoiceTalentGuardService]
      },
      {
        path: 'system-tools/content-requests/:requestId',
        component: CreateEditRequestComponent,
        canActivate: [AuthGuardService, IsEmployeeContractorOrVoiceTalentGuardService]
      },
      { 
        path: 'client-details/content-requests/:requestId',
        component: CreateEditRequestComponent,
        canActivate: [AuthGuardService, IsEmployeeGuardService]
      },
      { 
        path: 'system-tools/content-requests/new/:param', 
        component: CreateEditRequestComponent,
        canActivate: [AuthGuardService, IsEmployeeGuardService]
      },
      {
        path: 'content-requests',
        component: ContentRequestsComponent,
        canActivate: [AuthGuardService]
      },
      { 
        path: 'content-requests/new/:param',
        component: CreateEditRequestComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'content-requests/:requestId',
        component: CreateEditRequestComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'content-requests/:requestId/:stateName',
        component: CreateEditRequestComponent,
        canActivate: [AuthGuardService]
      },
      { 
        path: 'system-tools/csr-tasks', 
        component: CsrTasksComponent,
        canActivate: [AuthGuardService, IsEmployeeGuardService]
      },
      { 
        path: 'system-tools/csr-tasks/edit-task/:taskId', 
        component: CreateEditTaskComponent,
        canActivate: [AuthGuardService, IsEmployeeGuardService]
      },
      {
        path: 'new-csr-task/:clientId',  
        component: CreateEditTaskComponent,           
        canActivate: [AuthGuardService, IsEmployeeGuardService]
      },
      
    ]),
    CommonModule,
    SharedModule
  ],
  declarations: [
    ClientDetailsComponent,
    ClientViewComponent,
    ClipComponent,
    ClipsComponent,
    CommentsComponent,
    ContractComponent,
    ContractsComponent,
    ContentFileComponent,
    ContentRequestsComponent,
    CreateEditRequestComponent,
    DetailsComponent,
    HistoryNotesComponent,
    HnXsViewComponent,
    HoldViewComponent,
    InternalHoldViewComponent,
    InternalVideoViewComponent,
    LibrariesComponent,
    LocationsComponent,
    LocXsViewComponent,
    MasterButtonsComponent,
    NavBarComponent,
    UploadModalComponent,
    VideoViewComponent],
  exports: [
    ClientDetailsComponent,
    ClientViewComponent,
    ClipComponent,
    ClipsComponent,
    CommentsComponent,
    ContentFileComponent,
    ContentRequestsComponent,
    ContractComponent,
    ContractsComponent,
    CreateEditRequestComponent,
    DetailsComponent,
    HistoryNotesComponent,
    HoldViewComponent,
    InternalHoldViewComponent,
    InternalVideoViewComponent,
    LibrariesComponent,
    MasterButtonsComponent,
    HnXsViewComponent,
    LocationsComponent,
    LocXsViewComponent,
    NavBarComponent,
    UploadModalComponent,
    VideoViewComponent]
})
export class SystemToolsCrmSharedModule { }
