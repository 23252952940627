import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppStateService } from 'src/app/core/services';
import { ClipService } from '../_services';
import { ClipsService } from '../../clips.service';
import { ClipsView } from '../../../_models';
import { CreateEditRequestService, CreateEditViewStateService } from '../../../_services';
import { FileUploadStateService } from 'src/app/shared/services';
import { ContentRequestStateName } from 'src/app/shared/api-models/admin';

@Component({
	selector: 'c-hold-view',
	templateUrl: './hold-view.component.html',
	styleUrls: ['./hold-view.component.scss']
})
export class HoldViewComponent implements OnInit {
	@Input() clip: ClipsView;
	@Input() clipIndex: number;

	public isMessageEditable: boolean;
	readonly statename = ContentRequestStateName;

	constructor(
		private appStateService: AppStateService,
		public clipService: ClipService,
		public clipsService: ClipsService,
		public createEditRequestService: CreateEditRequestService,
		public fileUploadStateService: FileUploadStateService,
		private router: Router,
		public viewStateService: CreateEditViewStateService
	) {}

	ngOnInit() {
		this.clip.eligibleForRevision = this.clipEligibleForRevision();
	}

	public clipEligibleForRevision(): boolean {
		const stateName: string = this.createEditRequestService.request.StateName;
		//Employee-only view
		if (this.viewStateService.route !== 'content-requests') {
			return stateName !== 'Assigned, Awaiting Production' && stateName !== 'Pending Script Approval';
		}
		//Client view
		return this.createEditRequestService.request.clientStatusView !== 'In Production';
	}

	public onScriptReadViewClick(): void {
		this.appStateService.setSessionItem('clipForReaderView', JSON.stringify(this.clip));
		this.router.navigate(['/system-tools/content-requests/script-read-view']);
	}

	public onUnsavedClipDelete(): void {
		this.createEditRequestService.request.clips.splice(this.clipIndex, 1);
	}
}
