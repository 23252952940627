<div class="shadow white-bg pd-bottom8 pd-top8 border-radius {{width}} relative noExit">
	<div style="z-index: 1000;" class="row mgn-bottom4 mgn0 relative">
		<div class="col-xs-10">
			<h4 class="ft-size12 dark-text semi-bold noExit"><em>{{title}}</em></h4>
		</div>
		<div class="col-xs-2">
			<i (click)="onPickerExitNotify.emit()" class="fa fa-times pd4 relative bottom2 pointer" aria-hidden="true"></i>		
		</div>
	</div>
	
	<hr class="mgn0 gray-border-darkest mgn-bottom12 noExit">
		
	<div *ngFor="let contentFile of contentItem.contentFiles" (mouseup)="onItemSelect(contentFile)" class="{{contentFile.previewActive ? 'yellow-bg yellow-bg-hover' : 'blue-bg-hover white-text-hover'}} pd-top2 noExit pointer">
		<h4  class=" ht22 noExit ln-height22 pd-left15 pd-right15 in-block  {{!contentFile.previewActive ? 'white-text-hover' : null}} mgn-bottom3 border-radius">
			<i class="fa {{contentFile.VoiceTalent.TalentGender === 'Female' ? 'fa-female' : 'fa-male'}} {{contentFile.VoiceTalent.TalentGender === 'Female' ? 'pink-text' : 'blue-text'}} mgn-right6" aria-hidden="true"></i> {{contentFile.VoiceTalent.TalentName}}
		</h4>
		<i *ngIf="contentFile.previewActive" (click)="onItemSelect(contentFile)" class="fa fa-times pull-right mgn-right10 relative top3 pointer noExit" aria-hidden="true"></i>
	</div>
	
</div>

