import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CrmRoutingModule } from './crm-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SystemToolsCrmSharedModule } from '../shared/system-tools-crm-shared.module';
import { CreateEditLocationComponent } from './locations/create-edit-location/create-edit-location.component';
import { CreateHistoryNoteComponent } from './history-notes/create-history-note/create-history-note.component';
import { LocationDetailsComponent } from './locations/location-details/location-details.component';
import { CreateEditPlayerComponent } from './create-edit-player/create-edit-player.component';
import { VideoViewComponent } from './create-edit-player/video-view/video-view.component';
import { HoldViewComponent } from './create-edit-player/hold-view/hold-view.component';
import { RadioViewComponent } from './create-edit-player/radio-view/radio-view.component';
import { CreateEditClientComponent } from './create-edit-client/create-edit-client.component';
import { CreateEditPlayerService, PlayerOptionsService, SaveService, ViewService } from './create-edit-player/_services';
import { ContractComponent } from './create-edit-client/contract/contract.component';
import { ErrorLogModalComponent } from './locations/location-details/error-log-modal/error-log-modal.component';
import { ClientTicketsComponent } from './client-tickets/client-tickets.component';


@NgModule({
  imports: [
    CommonModule,
    CrmRoutingModule,
    SharedModule,
    SystemToolsCrmSharedModule
  ],
  providers: [CreateEditPlayerService, PlayerOptionsService, SaveService, ViewService],
  declarations: [CreateEditLocationComponent, CreateHistoryNoteComponent, LocationDetailsComponent, CreateEditPlayerComponent, VideoViewComponent, HoldViewComponent, RadioViewComponent, CreateEditClientComponent, ContractComponent, ErrorLogModalComponent, ClientTicketsComponent]
})
export class CrmModule { }
