import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { CopyTagService } from '../_services';
import { CreateMailingService, TableConfigService } from './_services';
import { RichTextService, UtilitiesService } from 'src/app/core/services';
import { SearchFilterPipe } from '../../../shared/pipes';
import { MailerTemplates, CsrBlastRecipients } from 'src/app/shared/api-models/admin';

@Component({
	selector: 'app-create-edit-mailing',
	templateUrl: './create-edit-mailing.component.html',
	styleUrls: ['./create-edit-mailing.component.scss']
})
export class CreateEditMailingComponent implements OnInit {
	@ViewChild('emailSubject') EmailSubject: ElementRef;
	@ViewChild('historySubject') HistorySubject: ElementRef;
	@ViewChild('html') html: ElementRef;

	public searchTerm: string = '';
	public showEmailSubjectTags: boolean;
	public showHistoryNoteSubjectTags: boolean;
	public templates$: Observable<MailerTemplates>;

	public groupId: number;
	private selectionStart: number;

	constructor(
		public copyTagService: CopyTagService,
		public createMailingService: CreateMailingService,
		public richTextService: RichTextService,
		private router: Router,
		private searchFilterPipe: SearchFilterPipe,
		public tableConfigService: TableConfigService,
		public utilService: UtilitiesService
	) {}

	ngOnInit(): void {
		let activeUrl: string = this.router.routerState.snapshot.url;
		this.templates$ = this.createMailingService.getMailerTemplates();
		this.groupId = +this.utilService.getLastParam(activeUrl);
		switch (true) {
			case this.utilService.includes(activeUrl, 'create'):
				this.createMailingService.getGroupAndRecipients(this.groupId);
				this.createMailingService.mailing = this.createMailingService.csrBlast();
				this.createMailingService.mode = 'new';
				break;

			case this.utilService.includes(activeUrl, 'edit'):
				this.createMailingService.getMailingAndRecipients(+this.utilService.getLastParam(activeUrl));
				this.createMailingService.mode = 'edit';
				break;
		}
	}

	onDeleteRecipientClick(btnEvent): void {
		let recipient: CsrBlastRecipients = btnEvent[1];
		let confirmDelete: boolean = confirm(`Delete recipient "${recipient.UserEmail}"?`);

		if (confirmDelete) {
			this.createMailingService.recipientsView = this.createMailingService.recipientsView.filter((r) => r.UserId !== recipient.UserId);
			this.createMailingService.recipients = this.createMailingService.recipients.filter((r) => r.UserId !== recipient.UserId);

			if (this.createMailingService.mode === 'edit') {
				this.createMailingService.deleteRecipient(recipient.Id);
			}
		}
	}

	public onEmailFromSelect(option: { Name: string }): void {
		if (option.Name === 'Custom...') {
			this.createMailingService.mailing.FromType = 'Custom';
		} else {
			this.createMailingService.mailing.FromType = option.Name;
		}
	}

	public onIncludeMainContactsOnlyClick(): void {
		this.createMailingService.mailing.IncludePrimaryContactOnly = !this.createMailingService.mailing.IncludePrimaryContactOnly;
		if (this.createMailingService.mailing.IncludePrimaryContactOnly) {
			this.createMailingService.recipientsView = this.createMailingService.recipientsView.filter((r) => r.IsPrimaryContact);
		} else {
			this.createMailingService.recipientsView = this.createMailingService.recipients;
		}
	}

	public onInsertTagClick(tag: string, column: string): void {
		this.selectionStart = this[column].nativeElement.selectionStart;
		let c = this.createMailingService.mailing[column];
		//Inserts tag at cursor
		this.createMailingService.mailing[column] = [c.slice(0, this.selectionStart), tag, c.slice(this.selectionStart)].join('');
	}

	public onSendLiveEmailsClick(): void {
		let confirmSend: boolean = confirm(`This will send the email to all recipients listed below. If you're ready, fire away.`);
		if (confirmSend) {
			this.createMailingService.createMailing(this.groupId, 'sendEmail');
		}
	}

	public onSendTestEmailClick(): void {
		this.createMailingService.sendTestEmail();
	}

	public onSearchKeyup(): void {
		this.createMailingService.searchResults = this.searchFilterPipe.transform(this.createMailingService.recipients, this.searchTerm);
	}

	onTemplateSelect(template: MailerTemplates): void {
		this.createMailingService.mailing.Html = template.HtmlTemplate;
	}
}
