<div style="border-radius: 6px;" class="shadow relative {{isEven ? 'gray-bg' : 'white-bg'}}">
	<div>
		<div class="title pd12 gray-border relative z">
			<div class="row">
				<div class="col-xs-12">
					<h2 class="semi-bold">{{location.Name}}</h2>
				</div>
				
			</div>		
		</div>

		<div class="row mgn-top30 pd15">
			<div class="col-xs-6">
				<span class="semi-bold">Address:</span><br>
				<span class="ft-size18">{{location.Address1}}</span>
			</div>

			<div class="col-xs-6">
				<span class="semi-bold">City:</span><br>
				<span class="ft-size18">{{location.City}}</span>
			</div>
		</div>


		<div class="row mgn-top30 pd15">
			<div class="col-xs-6 text-left setOverflow">
				<span class="semi-bold">State:</span><br>
				<span class="ft-size18">{{location.State}}</span>
			</div>
			<div class="col-xs-6">
				<span class="semi-bold">Phone:</span><br>
				<span class="ft-size18">{{location.Phone}}</span>
			</div>
		</div>

		<div class="row mgn-top30 pd15">
			<div class="col-xs-6 text-left setOverflow">
				<span class="semi-bold"><i class="fa {{location.hold.icon}}" aria-hidden="true"></i> Hold Players:</span><br>
				<span class="ft-size18">{{location.hold.number}}</span>
			</div>
			<div class="col-xs-6">
				<span class="semi-bold"><i class="fa {{location.video.icon}}" aria-hidden="true"></i> Video Players:</span><br>
				<span class="ft-size18">{{location.video.number}}</span>
			</div>
		</div>

		<div class="row mgn-top30 pd15">
			<div class="col-xs-6 text-left setOverflow">
				<span class="semi-bold"><i class="fa {{location.poster.icon}}" aria-hidden="true"></i> Poster Players:</span><br>
				<span class="ft-size18">{{location.poster.number}}</span>
			</div>
			<div class="col-xs-6">
				<span class="semi-bold"><i class="fa {{location.radio.icon}}" aria-hidden="true"></i> Radio Players:</span><br>
				<span class="ft-size18">{{location.radio.number}}</span>
			</div>
		</div>

		<div class="row mgn-top15 pd15">
			<div class="col-xs-6">
				<button class="white-bg setWidth100 border-radius">View</button>
			</div>
			<div class="col-xs-6">
				<button  class="red-bg-light white-text setWidth100 border-radius">Delete</button>
			</div>
		</div>
	</div>
</div>
