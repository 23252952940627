import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { allowCreate24Guard, authGuard, CanDeactivateGuardService } from '../../core/services/route-guards';
import { ContentBrowserComponent } from 'src/app/shared/components/content-browser/content-browser.component';
import { Create24Component } from './create-24.component';
import { StartPageComponent } from './start-page/start-page.component';

const routes: Routes = [
	{
		path: 'create-24/editor/:productRoute/:c24ActiveArea',
		canActivate: [allowCreate24Guard, authGuard],
		canDeactivate: [CanDeactivateGuardService],
		component: Create24Component,
		children: [
			{
				path: 'new-video-replace',
				canActivate: [allowCreate24Guard, authGuard],
				canDeactivate: [CanDeactivateGuardService],
				component: ContentBrowserComponent,
				outlet: 'popup'
			},
			{
				path: 'new-image-replace',
				canActivate: [allowCreate24Guard, authGuard],
				canDeactivate: [CanDeactivateGuardService],
				component: ContentBrowserComponent,
				outlet: 'popup'
			},
			{
				path: 'new-sidebar-replace',
				canActivate: [allowCreate24Guard, authGuard],
				canDeactivate: [CanDeactivateGuardService],
				component: ContentBrowserComponent,
				outlet: 'popup'
			},
			{
				path: 'image-layer-create',
				canActivate: [allowCreate24Guard, authGuard],
				canDeactivate: [CanDeactivateGuardService],
				component: ContentBrowserComponent,
				outlet: 'popup'
			},
			{
				path: 'image-layer-replace',
				canActivate: [allowCreate24Guard, authGuard],
				canDeactivate: [CanDeactivateGuardService],
				component: ContentBrowserComponent,
				outlet: 'popup'
			}
		]
	},
	{
		path: 'create-24/editor/:productRoute/:c24ActiveArea/:contentType',
		canActivate: [allowCreate24Guard, authGuard],
		canDeactivate: [CanDeactivateGuardService],
		component: Create24Component,
		children: [
			{
				path: 'new-video-replace',
				canActivate: [allowCreate24Guard, authGuard],
				canDeactivate: [CanDeactivateGuardService],
				component: ContentBrowserComponent,
				outlet: 'popup'
			},
			{
				path: 'new-image-replace',
				canActivate: [allowCreate24Guard, authGuard],
				canDeactivate: [CanDeactivateGuardService],
				component: ContentBrowserComponent,
				outlet: 'popup'
			},
			{
				path: 'image-layer-create',
				canActivate: [allowCreate24Guard, authGuard],
				canDeactivate: [CanDeactivateGuardService],
				component: ContentBrowserComponent,
				outlet: 'popup'
			},
			{
				path: 'image-layer-replace',
				canActivate: [allowCreate24Guard, authGuard],
				canDeactivate: [CanDeactivateGuardService],
				component: ContentBrowserComponent,
				outlet: 'popup'
			},
			{
				path: 'sidebar-replace',
				canActivate: [allowCreate24Guard, authGuard],
				canDeactivate: [CanDeactivateGuardService],
				component: ContentBrowserComponent,
				outlet: 'popup'
			},
			{
				path: 'new-sidebar-replace',
				canActivate: [allowCreate24Guard, authGuard],
				canDeactivate: [CanDeactivateGuardService],
				component: ContentBrowserComponent,
				outlet: 'popup'
			}
		]
	},
	{
		path: 'create-24/dashboard',
		canActivate: [allowCreate24Guard, authGuard],
		component: StartPageComponent
	},
	{
		path: 'create-24/dashboard/:productRoute',
		canActivate: [allowCreate24Guard, authGuard],
		component: StartPageComponent
	},
	{
		path: 'create-24/dashboard/export-success/:productRoute',
		canActivate: [allowCreate24Guard, authGuard],
		component: StartPageComponent
	},
	{
		path: 'create-24/dashboard/content-browser/:productRoute/:c24ActiveArea',
		canActivate: [allowCreate24Guard, authGuard],
		component: ContentBrowserComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class Create24RoutingModule {}
