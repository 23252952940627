<div *ngIf="appStateService.currentUser.Role === 'Administrator'" class="row mgn-bottom30">
	<div class="col-sm-4">
		<button class="red-bg-light white-text" (click)="onEnableOrDisablePopupClick()">
			<i class="fas fa-exclamation-triangle mgn-right10"></i>
			{{ newsletter.ProductsPagePopupActive ? 'Deactivate' : 'Activate' }} Popup
		</button>
	</div>

	<div class="col-sm-4 col-sm-offset-4">
		<button class="{{ view === 'edit' ? 'limeGreen-bg white-text' : 'white-bg' }}" (click)="onEditContentClick()">
			<i class="fas {{ view === 'edit' ? 'fa-save' : 'fa-pencil-alt' }} mgn-right10"></i>
			{{ view === 'edit' ? 'Save' : 'Edit' }} Content
		</button>
	</div>
</div>

<div class="relative white-bg border-radius">
	<a class="ft-size26 dark-text absolute top15 right15 z" (click)="onExitClick.emit()"><i class="fa fa-times" aria-hidden="true"></i></a>
	<div *ngIf="view === 'read'" [innerHTML]="newsletter.ProductsPagePopupHtml | safeHtml"></div>
	<ng-container *ngIf="view === 'edit'">
		<textarea
			#popup
			(input)="onPopupChange(popup.value, newsletter)"
			rows="30"
			class="setWidth100"
			[innerHTML]="newsletter.ProductsPagePopupHtml | safeHtml"
		></textarea>
	</ng-container>
</div>
