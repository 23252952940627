import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppStateService, Events, MessageService, SecurityService, CacheService } from 'src/app/core/services';
import { ContentFilesHold, Hold, Video, ContentFilesVideo } from '../_models';
import { ContentLibraries, ContentVoiceTalents } from 'src/app/shared/api-models/content';
import { environment } from '../../../../../../environments/environment';
import { Users } from 'src/app/shared/api-models/admin';

@Injectable({
    providedIn: 'root'
})
export class DetailsService {

    
    public voiceTalentList: ContentVoiceTalents[];

    constructor(
            private appStateService: AppStateService,
            private httpClient: HttpClient,
            private messageService: MessageService,
            private securityService: SecurityService,
            private cacheService: CacheService,
    ) { }

    public getVoiceTalentList(): Observable<ContentVoiceTalents[]> {
        return this.httpClient.get(`${environment.contentUrl}ContentVoiceTalents`).pipe(
            map( (voiceTalentList: ContentVoiceTalents[]) => {
                return voiceTalentList.map( (voiceTalent) => {
                    voiceTalent.name = voiceTalent.TalentName;
                    return voiceTalent;
                })
            })
        )
    }

    public deleteContentFile(contentFile: ContentFilesHold | ContentFilesVideo, content: Hold | Video): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.httpClient.patch(`${environment.contentUrl}ContentFiles/${contentFile.Id}`, {IsDeleted: true})
            .subscribe( (res) => {
                const index = content.contentFiles.findIndex(c => c.Id === contentFile.Id);
                content.contentFiles.splice(index, 1);
                this.messageService.publish(Events.savingPreloader, 0);
            }) 
    }

    public isContentGlobalCheck(content: Hold | Video): Observable<boolean> {
        return this.httpClient.get(`${environment.contentUrl}ContentLibraries/${content.LibraryId}`).pipe(
            map( (library: ContentLibraries) => {
                return library.IsGlobal;
            })
        )
    }

    public atLeastOneItemDirty(content: Hold | Video): boolean {
        return content.isDirty || content.contentFiles.some(file => file.isDirty);
    }

    public updateContentAndContentFiles(content: Hold | Video): Observable<any> {
        this.messageService.publish(Events.savingPreloader, 1);
        const patchedContent: any = {};
        patchedContent.Title = content.Title;
        patchedContent.Text = content.Text;
        patchedContent.IsFeatured = content.IsFeatured;
        patchedContent.FeatureStartDate = content.FeatureStartDate;
        patchedContent.FeatureEndDate = content.FeatureEndDate;
        patchedContent.FeatureCodeStart = content.FeatureCodeStart;
        patchedContent.FeatureCodeEnd = content.FeatureCodeEnd;
        patchedContent.FeatureCodeId = content.FeatureCodeId;
        
        return this.httpClient.patch(`${environment.contentUrl}Content/${content.Id}`, patchedContent).pipe(
            mergeMap( () => {
                const dirtyContentFiles = content.contentFiles.filter(contentFile => contentFile.isDirty);

                if(dirtyContentFiles.length > 0) {
                    const contentFiles$ = dirtyContentFiles.map( (contentFile) => {
                        return this.httpClient.put(`${environment.contentUrl}ContentFiles/${contentFile.Id}`, contentFile)
                    })
    
                    return forkJoin(contentFiles$);
                }
                return of('');
            })
        )
    }

    public writeAccessCheck(content: Hold | Video, contentFile?: ContentFilesHold): Observable<boolean> {
        if(contentFile) {
            if (contentFile.CreatedByUserId && contentFile.CreatedByUserId !== 0) {
                //get user who created the content
                //if Works24 created the content, user cannot modify
                //if user uploaded themself, they can modify
                const apiCall = this.httpClient.get<Users>(environment.adminUrl + `CoreUsers/${contentFile.CreatedByUserId}`);
                return this.cacheService.user$(apiCall, contentFile.CreatedByUserId).pipe(
                    map( (createdByUser: Users) => {
                        contentFile.createdByUser = createdByUser;
                        this.cacheService.setUserCache(createdByUser);
                        return this.securityService.writeAccessEnabled(content, contentFile);
                    })
                )
            } else {
                if (this.appStateService.currentUser.IsEmployee && this.securityService.contentManagerCsrOrAdmin()) {
                    return of(true);
                } else {
                    return of(false);
                }
            }
        } else {
            return of(this.securityService.writeAccessEnabled(content));
        }
    }

    public disableReplaceFile(content: Hold | Video): boolean {
        //All dynamic content types and c24
        const disabledContentTypeIds: number[] = [10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,1000006,1000007];
        return disabledContentTypeIds.some(id => id === content.ContentTypeId);
    }
}
