import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AppStateService } from '../app-state.service';

@Injectable({
    providedIn: 'root'
})
export class AdminOrNatalieGuardService implements CanActivate {

    constructor(private appStateService: AppStateService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot) {
        if (this.currentUserIsAdmin() || this.currentUserIsNatalie()) {
            return true;
        } else {
            this.router.navigate(['/access-denied']);
        }
    }

    private currentUserIsAdmin(): boolean {
        return JSON.parse(this.appStateService.getSessionItem('currentUser')).Role === 'Administrator';
    }

    private currentUserIsNatalie(): boolean {
        return JSON.parse(this.appStateService.getSessionItem('currentUser')).UserId === 35;
    }

}
