import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AppStateService } from '../app-state.service';

export const LoginPageGuardService = (route: ActivatedRouteSnapshot): boolean | UrlTree => {
	const router = inject(Router);
	const appStateService = inject(AppStateService);

	const isEmployee = appStateService.currentUser?.IsEmployee ?? false;
	const clientRequire2fa = appStateService.currentClient?.Require2fa ?? false;
	const require2fa = isEmployee || clientRequire2fa;
	const hasValidAuthToken = sessionStorage.getItem('authToken') && sessionStorage.getItem('authToken') !== 'null';
	const hasValidPin = sessionStorage.getItem('validPin') === 'true';

	if (hasValidAuthToken && (!require2fa || (require2fa && hasValidPin))) {
		return router.createUrlTree(['/my-products']);
	}

	return true;
};
