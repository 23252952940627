import { Content } from '../../../../api-models/content';
import { ContentFilesHold, ContentFilesVideo } from '.';

export class Hold extends Content {
	breadcrumbs: string[];
	contentType: string;
	contentFiles: (ContentFilesHold | ContentFilesVideo)[];
	favoriteBtnTooltip: string;
	isFavorite: boolean;
	isSelected: boolean;
	previewUrl: string;
	detailsVisible: boolean;
	talentPickerVisible: boolean;
	talentPreviewSelectorVisible: boolean;
	processingStateJson: string;
	isGlobal: boolean;
	writeAccess: boolean;
	isDirty: boolean;
	order: number;
	startDate: string;
	stopDate: string;
	displayedFeatureCodeId: number;
	isDisabled: boolean;
}
