import { Component, OnInit } from '@angular/core';

import { AppStateService, SecurityService, UtilitiesService } from '../../../../core/services';
import { CreateEditRequestService, CreateEditViewStateService, HeightService } from '../../_services';
import { DetailsService, DetailsViewService } from '../_services';
import { ContentRequestStateName } from 'src/app/shared/api-models/admin';

@Component({
	selector: 'd-internal-hold-view',
	templateUrl: './internal-hold-view.component.html',
	styleUrls: ['./internal-hold-view.component.scss']
})
export class InternalHoldViewComponent implements OnInit {
	readonly stateName = ContentRequestStateName;

	constructor(
		public appStateService: AppStateService,
		public createEditRequestService: CreateEditRequestService,
		public detailsService: DetailsService,
		public detailsViewService: DetailsViewService,
		public heightService: HeightService,
		public securityService: SecurityService,
		public utilService: UtilitiesService,
		public viewStateService: CreateEditViewStateService
	) {}

	ngOnInit() {}
}
