import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AppStateService } from '../app-state.service';

@Injectable({
    providedIn: 'root'
})
export class UserGuardService implements CanActivate {

    constructor(private appStateService: AppStateService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot) {
        let currentUser = this.appStateService.currentUser;
        let currentClient = this.appStateService.currentClient;

        switch (true) {
            case currentUser.Role === 'User':
            case currentClient.Id === 1470 && currentUser.Role === 'Salesperson':
                this.router.navigate(['/access-denied']);
            default:
                return true;
        }
    }

}


//Employee content creators should access content manager but not feeds or user manager.
///Content Manager role should do everything except manage users