import { Component, Input, OnInit } from '@angular/core';

import { ContentFavoritesService, ContentViewService } from '../../_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { Hold } from '../../_models';
import { PlayerCapacityService } from 'src/app/playlist-builder/_services';

@Component({
	selector: 'vc-hold-pb-card-view',
	templateUrl: './hold-pb-card-view.component.html',
	styleUrls: ['./hold-pb-card-view.component.scss']
})
export class HoldPbCardViewComponent implements OnInit {
	@Input() canUseInPlaylist: boolean;
	@Input() content: Hold;
	@Input() isLastIndex: boolean;
	@Input() viewState: 'playlist-builder' | 'content-manager';

	public showScript: boolean;

	constructor(
		public contentFavoritesService: ContentFavoritesService,
		public contentViewService: ContentViewService,
		public cvStateService: ContentViewStateService,
		public playerCapacityService: PlayerCapacityService
	) {}

	ngOnInit(): void {}
}
