import { Component, Input, OnInit } from '@angular/core';

import { ServiceLocations } from '../../../shared/api-models/admin';

@Component({
  selector: 'loc-xs-view',
  templateUrl: './loc-xs-view.component.html',
  styleUrls: ['./loc-xs-view.component.scss']
})
export class LocXsViewComponent implements OnInit {

  @Input() isEven: boolean;
  @Input() location: ServiceLocations;

  constructor() { }

  ngOnInit() {
  }

}
