<portal-header></portal-header>

<div class="container-fluid mgn-top30 relative">
	<a (click)="location.back()" class="dark-text absolute topneg21 left15">
		<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
		<span class="ft-size16">Back</span>
	</a>
	<div class="ft-size16">
		<div class="row relative ht40 mgn-bottom17">
			<div class="col-sm-10 mgn-top10">
				<h1 class="in-block"><i class="{{ productIcon }} mgn-right12" aria-hidden="true"></i></h1>
				<h1 class="semi-bold in-block" [innerHTML]="title"></h1>
			</div>
			<div *ngIf="viewService.formChanged" class="col-sm-2">
				<div class="border-radius yellow-bg pd15 relative text-center shadow">
					<p>
						<i class="fas fa-exclamation-triangle mgn-right10"></i>
						<em>Unsaved changes</em>
					</p>
				</div>
			</div>
		</div>
		<cep-hold-view
			*ngIf="createEditPlayerService.player && createEditPlayerService.player?.ProductType.Route === 'hold'"
			(onCancelClickNotify)="location.back()"
		></cep-hold-view>
		<cep-video-view *ngIf="videoView()" (onCancelClickNotify)="location.back()"></cep-video-view>
		<cep-radio-view
			*ngIf="createEditPlayerService.player && createEditPlayerService.player?.ProductType.Route === 'radio'"
			(onCancelClickNotify)="location.back()"
		></cep-radio-view>
	</div>
</div>
