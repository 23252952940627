import { Injectable } from '@angular/core';

import { AppStateService } from 'src/app/core/services';
import { ClientNewsletter } from 'src/app/shared/api-models/content';
import { ClientNewsletterService } from 'src/app/client-newsletter/client-newsletter.service';

@Injectable({
    providedIn: 'root'
})
export class PromoBarService {

    public previewPromoBar: boolean;
    public promoBarState: 'read' | 'edit' = 'read';

    constructor(
        private appStateService: AppStateService,
        private clientNewsletterService: ClientNewsletterService
    ) { }

    public showPromoBar(newsletter: ClientNewsletter) {
        const isAdmin: boolean = this.appStateService.currentUser.Role === 'Administrator';
        if (!this.appStateService.clientHasAppleTvs) {
            return newsletter.ProductsPagePromoBarActive || (isAdmin && (this.previewPromoBar || this.promoBarState === 'edit'))
        }
        return false;
    }

    public onPromoBarEditSaveClick(newsletter: ClientNewsletter): void {
        this.promoBarState = this.promoBarState === 'edit' ? 'read' : 'edit';

        if (this.promoBarState === 'read') {
            const updatedNewsletter = { ...newsletter };
            this.clientNewsletterService.updateNewsletter(updatedNewsletter);
        }
    }

    public onEnableOrDisablePromoBarClick(newsletter: ClientNewsletter): void {
        const isActive = newsletter.ProductsPagePromoBarActive;
        if (!isActive) {
            const isOk = confirm('Active the promo bar for customers?');
            if (isOk) {
                const updatedNewsletter = { ...newsletter, ProductsPagePromoBarActive: true };
                this.clientNewsletterService.updateNewsletter(updatedNewsletter);
            }
        } else {
            const updatedNewsletter = { ...newsletter, ProductsPagePromoBarActive: false };
            this.clientNewsletterService.updateNewsletter(updatedNewsletter);
        }
    }

    public onPromoBarChange(value: string, newsletter: ClientNewsletter): void {
        newsletter.ProductsPagePromoBarHtml = value;
    }
}
