import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStateService } from '../app-state.service';
import { environment } from '../../../../environments/environment';
import { UtilitiesService } from '../utilities.service';
import { ContentLibraries } from 'src/app/shared/api-models/content';

@Injectable({
    providedIn: 'root'
})

//Allow navigation only if client has an active contract pertaining to the active url
//For Playlist Builder only, check if current user has at least one library
export class ContractGuardService implements CanActivate {

    private activeUrl: string;

    constructor(
        private appStateService: AppStateService,
        private httpClient: HttpClient,
        private router: Router,
        private utilService: UtilitiesService) { }

    //Route is ActivatedRouteSnapshot
    canActivate(route: any) {
        this.activeUrl = route._routerState.url;
        const isPlaylistBuilderRoute: boolean = this.utilService.includes(this.activeUrl, 'playlist-builder');
        const selectedProductType = this.appStateService.contractedProducts.find(productType => this.utilService.includes(this.activeUrl, productType.Route));

        //Only check for user libraries if navigating to playlist builder
        if (isPlaylistBuilderRoute) {
            return this.httpClient.get(`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${selectedProductType.Id}&clientId=${this.appStateService.currentClient.Id}&userId=${this.appStateService.currentUser.UserId}`).pipe(
                map( (libraries: ContentLibraries[]) => {
                    if (this.contractFound() && libraries.length > 0) {
                        //If hold, user/client has to have the global music library assigned
                        if (selectedProductType.Id === 1 && libraries.some(library => library.LibraryName === 'Music Library')) {
                            return true;
                        } else if (selectedProductType.Id === 1) {
                            this.router.navigate([`/playlist-builder-access-denied/no-music-library`]);
                        }
                        return true;
                    }

                    //If user has no contracts, access
                    //to playlist builder denied
                    if (!this.contractFound()) {
                        this.router.navigate([`/playlist-builder-access-denied/no-contract-found`]);
                    }
                    //If user has no libraries, access
                    //to playlist builder denied
                    if (libraries.length < 1) {
                        this.router.navigate([`/playlist-builder-access-denied/no-libraries-found`]);
                    }
                })
            )
        }
        if (this.contractFound()) {
            return of(true);
        }
        this.router.navigate([`/my-products`]);
    }

    private contractFound(): boolean {
        //returns true if at least one element passes test
        return this.appStateService.contractedProducts.some((productType) => {
            if (this.utilService.includes(this.activeUrl, productType.Route)) {
                return true;
            }
        });
    }
}