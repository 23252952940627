import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivationStart, NavigationStart, Router } from '@angular/router';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AppErrorService } from 'src/app/core/app-error/app-error.service';
import { AppStateService, Events, MessageService, SessionExpireService } from './core/services';
import { FileUploadStateService } from './shared/services';
import { FeatureArea } from './shared/view-models';
import { Create24Service } from './user-tools/create-24/create-24.service';
import { Create24Area } from './user-tools/create-24/_models/create-24-state.enum';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	public animateClass: string;
	public savingPreloaderVisible: boolean;
	public savingBubblesVisible: boolean;
	public savingText: string;
	public transitionOverlayMessage: string;
	public transitionOverlayVisible: boolean;

	private subs: Subscription[] = [];
	private activationStart$: Observable<any> = this.router.events.pipe(filter((event) => event instanceof ActivationStart));
	private navigationStart$: Observable<any> = this.router.events.pipe(filter((event) => event instanceof NavigationStart));
	private ngUnsubscribe: Subject<void> = new Subject();

	constructor(
		public appErrorService: AppErrorService,
		public appStateService: AppStateService,
		private create24Service: Create24Service,
		public fileUploadStateService: FileUploadStateService,
		private messageService: MessageService,
		private router: Router,
		public sessionExpireService: SessionExpireService
	) {
		this.savingPreloaderSubscribe();
		this.transitionOverlaySubscribe();
		this.onAppError();
	}

	ngOnInit(): void {
		combineLatest([this.activationStart$, this.navigationStart$])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(([activationStart, navigationStart]) => {
				const productRoute = activationStart.snapshot.params.productRoute;
				const activeUrl = navigationStart.url;
				const segments = activeUrl.split('/').filter((segment) => segment !== '');
				const featureAreaSegmentMatch: FeatureArea = segments.find((segment) => Object.values(FeatureArea).includes(segment));
				const c24AreaSegmentMatch = this.checkForPopup(segments) ?? segments.find((segment) => Object.values(Create24Area).includes(segment));
				const activeFeatureArea = featureAreaSegmentMatch ?? FeatureArea.NONE;
				const activeProduct = this.appStateService.contractedProducts?.find((product) => product.Route === productRoute);
				const routerOutlet = activationStart.snapshot.data.outlet;

				this.appStateService.setSessionItem('activeFeatureArea', JSON.stringify(activeFeatureArea));
				this.appStateService.setSessionItem('activeUrl', JSON.stringify(activeUrl));
				this.appStateService.setActiveUrl();
				this.appStateService.setActiveFeatureArea();
				this.create24Service.c24ActiveArea = c24AreaSegmentMatch ?? Create24Area.NONE;

				const locationId: string | undefined = this.appStateService.getSessionItem('locationId');
				if (!activeUrl.includes('crm') && locationId) {
					this.appStateService.removeSessionItem('locationId');
					this.appStateService.removeSessionItem('playerId');
				}
			});
	}

	private checkForPopup(segments: string[]): string | null {
		const containsPopup = segments.some((segment) => segment.includes('popup:'));

		if (containsPopup) {
			return this.removePopupPrefix(segments[segments.length - 1]);
		}
		return null;
	}

	private removePopupPrefix(segment: string): string {
		const startIndex = segment.indexOf(':') + 1;
		const endIndex = segment.indexOf(')');
		return segment.substring(startIndex, endIndex);
	}

	onAppError(): void {
		this.subs.push(
			this.messageService.subscribe(Events.appError, (payload) => {
				this.appErrorService.error = payload[0];
				this.appErrorService.friendlyMessage = payload[1];
				this.appErrorService.hideReloadPageBtn = payload[2];
			})
		);
	}

	savingPreloaderSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.savingPreloader, (savingPreloader: number) => {
				if (savingPreloader === 1) {
					this.savingPreloaderVisible = true;
					this.animateClass = 'fadeInUp';
					this.savingProps('Saving', true);
				} else if (savingPreloader === 0) {
					setTimeout(() => {
						this.savingProps('Saved.', false);
					}, 1400);

					setTimeout(() => {
						this.animateClass = 'fadeOutUp';
					}, 3600);
				}

				if (savingPreloader === 0) {
					setTimeout(() => {
						this.savingPreloaderVisible = false;
						this.savingText = '';
					}, 3800);
				}
			})
		);
	}

	private savingProps(savingPopoutText: string, savingBubblesVisible: boolean): void {
		this.savingText = savingPopoutText;
		this.savingBubblesVisible = savingBubblesVisible;
	}

	transitionOverlaySubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.transitionOverlay, (payload: [string, boolean]) => {
				const [message, overlayVisible] = [...payload];

				this.transitionOverlayMessage = message;
				this.transitionOverlayVisible = overlayVisible;
			})
		);
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
