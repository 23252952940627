import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthGuardService } from '../core/services/route-guards/auth-guard.service';
import { CardComponent } from './card/card.component';
import { MyProductsComponent } from './my-products.component';
import { SharedModule } from '../shared/shared.module';
import { PopupComponent } from './popup/popup.component';
import { PromoBarComponent } from './promo-bar/promo-bar.component';


@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'my-products',
        canActivate: [AuthGuardService],
        component: MyProductsComponent 
      },
      { path: 'my-products/:clientId',
        canActivate: [AuthGuardService],
        component: MyProductsComponent 
      }
    ]),
    SharedModule
  ],
  declarations: [MyProductsComponent, CardComponent, PopupComponent, PromoBarComponent, MyProductsComponent]
})
export class ProductsModule { }
