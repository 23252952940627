<portal-header *ngIf="viewStateService.route !== 'client-details'"></portal-header>

<!--************************************
	SYSTEM TOOLS OR CLIENT DETAILS VIEW
*************************************-->
<div
	*ngIf="viewStateService.route !== 'content-requests'"
	class="{{ viewStateService.route !== 'client-details' ? 'container-fluid mgn-top30 pd-top10 mx-4' : 'mgn-left15 mgn-right15' }} relative"
>
	<div *ngIf="viewStateService.route !== 'client-details'" class="row">
		<h1 class="semi-bold in-block">
			<i class="far fa-paper-plane mgn-right15" aria-hidden="true"></i>
			Content Requests | {{ viewStateService.pageTitle }}
		</h1>
		<span *ngIf="viewStateService.filterByStatus !== 'All'" class="ft-size28">-</span>
		<span *ngIf="viewStateService.filterByStatus !== 'All'" class="ft-size28">
			<em>{{ viewStateService.filterByStatus }}</em>
		</span>
	</div>

	<div *ngIf="securityService.userIsVideoContractor()" class="row red-gradient mgn-top15 ht2 border-radius"></div>

	<div
		*ngIf="viewStateService.route === 'system-tools' && appStateService.currentUser.Role !== 'Video Content Contractor'"
		class="row white-bg shadow ht64 ln-height64 border-radius mgn-top15"
	>
		<div class="col-xs-12 center">
			<cr-nav-bar></cr-nav-bar>
		</div>
	</div>

	<div *ngIf="!securityService.userIsContractor()" class="row mgn-top30 mgn-bottom30">
		<div *ngIf="viewStateService.route === 'system-tools'" class="col-xs-2 pd-left0 relative" (window:mouseup)="autoClose($event)">
			<button class="limeGreen-bg white-text no-exit" (click)="onNewRequestClick()">
				<i class="fa fa-plus mgn-right8" aria-hidden="true"></i>
				Create new request
				<i class="fa {{ newRequestDropdownVisible ? 'fa-angle-up' : 'fa-angle-down' }} mgn-left8" aria-hidden="true"></i>
			</button>

			<!--*****************************
				CREATE REQUEST OPTIONS
			******************************-->
			<div *ngIf="newRequestDropdownVisible" style="z-index: 99999" class="no-exit border-radius white-bg shadow absolute setWidth100">
				<ul class="pd-left0 no-exit">
					<li
						*ngFor="let requestType of contentRequestsService.requestTypes$ | async; last as isLast"
						class="{{ !isLast ? 'mgn-bottom6' : null }} no-exit request-type pd-top5 pd-bottom5 pd-left10 border-radius pointer"
						(mouseup)="onNewRequestOptionClick(requestType)"
					>
						<span class="no-exit">{{ requestType.Name }}</span>
					</li>
				</ul>
			</div>
		</div>

		<div *ngIf="viewStateService.route === 'client-details'" class="col-xs-4">
			<h2 class="semi-bold in-block">
				<i class="far fa-paper-plane mgn-right15" aria-hidden="true"></i>
				{{ viewStateService.pageTitle }}
			</h2>
		</div>

		<div *ngIf="mergeRequestsService.atLeastTwoSelected && viewStateService.route === 'system-tools'" class="col-xs-2 relative">
			<button class="blue-bg white-text" [disabled]="!mergeRequestsService.mergeValid" (click)="mergeRequestsService.onMergeRequestsClick()">
				Merge requests
			</button>
			<i
				class="far fa-question-circle absolute rightneg4"
				aria-hidden="true"
				ngxTippy
				[tippyProps]="{ appendTo: 'parent' }"
				tippyName="{{ utilService.guid | wrapFn }}"
				data-tippy-content='Client, voice talent, requested by, and target library must match. Status must be "Assigned, Awaiting Production" or "In Production".'
			></i>
		</div>

		<div class="col-xs-2 {{ !mergeRequestsService.atLeastTwoSelected ? 'col-xs-offset-2' : null }} pd-right0">
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[options]="[{ Name: 'All' }, { Name: 'Global' }, { Name: 'Custom' }]"
				[key]="'Name'"
				[scrollbarHidden]="true"
				[selectedOption]="viewStateService.filterByTargetLibrary ? viewStateService.filterByTargetLibrary : 'Filter by target library...'"
				(onOptionSelect)="onFilterSelect($event, 'filterByTargetLibrary')"
			></portal-dropdown-menu>
		</div>
		<div class="col-xs-2 pd-right0">
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[options]="[{ Name: 'All' }, { Name: 'On-Hold' }, { Name: 'Video' }]"
				[key]="'Name'"
				[scrollbarHidden]="true"
				[selectedOption]="viewStateService.filterByRequestType ? viewStateService.filterByRequestType : 'Filter by request type...'"
				(onOptionSelect)="onFilterSelect($event, 'filterByRequestType')"
			></portal-dropdown-menu>
		</div>
		<div class="col-xs-2 pd-right0">
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[options]="contentRequestsService.requestStates$ | async"
				[key]="'Name'"
				[scrollbarHidden]="true"
				[selectedOption]="viewStateService.filterByStatus !== 'All' ? viewStateService.filterByStatus : 'Filter by request status...'"
				(dropdownClickNotify)="onFilterByStatusClick()"
				(onOptionSelect)="onFilterSelect($event, 'filterByStatus')"
			></portal-dropdown-menu>
		</div>
		<div *ngIf="viewStateService.route !== 'client-details'" class="col-xs-2 pd-right0">
			<div class="relative">
				<i class="fas fa-search absolute gray-text-darkest ft-size18 top11 left10 {{ contentRequestsService.requests?.length < 1 && 'setOpacityLow' }}"></i>
				<input
					type="text"
					placeholder="Search"
					[disabled]="contentRequestsService.requests?.length < 1"
					class="pd-left38"
					[(ngModel)]="viewStateService.searchTerm"
					(keyup)="onSearchKeyup()"
				/>
			</div>

			<button *ngIf="viewStateService.searchTerm.length > 0" class="absolute top8 white-bg pd12 wdth28 right10 z" (click)="onSearchClearClick()">
				<i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
			</button>
		</div>
	</div>

	<div *ngIf="securityService.userIsContractor()" class="row mgn-top30 mgn-bottom30">
		<div class="col-xs-2 col-xs-offset-8 pd-right0">
			<portal-dropdown-menu
				[backgroundColor]="'gray-bg-dark'"
				[height]="'ht40'"
				[options]="contentRequestsService.requestStates$ | async"
				[key]="'Name'"
				[scrollbarHidden]="true"
				[selectedOption]="viewStateService.filterByStatus !== 'All' ? viewStateService.filterByStatus : 'Filter by request status...'"
				(dropdownClickNotify)="onFilterByStatusClick()"
				(onOptionSelect)="onFilterSelect($event, 'filterByStatus')"
			></portal-dropdown-menu>
		</div>
		<div *ngIf="viewStateService.route !== 'client-details'" class="col-xs-2 pd-right0">
			<input
				type="text"
				placeholder="Search"
				[disabled]="contentRequestsService.requests?.length < 1"
				[(ngModel)]="viewStateService.searchTerm"
				(keyup)="onSearchKeyup()"
			/>
			<button *ngIf="viewStateService.searchTerm.length > 0" class="absolute top8 white-bg pd12 wdth28 right10 z" (click)="onSearchClearClick()">
				<i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
			</button>
		</div>
	</div>

	<div class="row relative">
		<div *ngIf="contentRequestsService.spinnerActive" class="relative top200 z"><portal-spinner [height]="'htpx76'" [width]="'wdthpx76'"></portal-spinner></div>
		<portal-table
			*ngIf="contentRequestsService.requests && contentRequestsService.requests.length > 0; else noItems"
			[table]="viewStateService.table"
			[config]="viewStateService.config"
			[data]="contentRequestsService.requests"
			[isAllSelected]="mergeRequestsService.isAllSelected"
			[page]="viewStateService.pageNumber"
			[showJumpToPage]="true"
			[totalItems]="viewStateService.totalItems"
			(checkboxClickNotify)="mergeRequestsService.onCheckboxClick($event)"
			(currentPageNotify)="getPage($event)"
			(rowSelectNotify)="onRowSelect($event)"
			(selectAllClickNotify)="mergeRequestsService.onSelectAllClick()"
		></portal-table>

		<ng-template #noItems>
			<h3><em>No results found</em></h3>
		</ng-template>
	</div>
</div>

<!--*****************************
	CLIENT-FACING VIEW
******************************-->
<ng-container *ngIf="viewStateService.route === 'content-requests'">
	<div class="container-fluid">
		<div class="row mgn-bottom15 mgn-top15">
			<div class="col-xs-4 col-md-2">
				<div class="row">
					<div class="col-xs-12 col-lg-9">
						<button [routerLink]="['/my-players/hold']" class="white-bg">
							<i class="fas fa-chevron-left mgn-right12"></i>
							Dashboard
						</button>
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-xs-offset-2 col-md-offset-4 text-right">
				<i class="far fa-paper-plane mgn-right12 ft-size28 blue-text"></i>
				<h1 class="in-block">
					<span class="semi-bold">Content Requests</span>
					|
					<em>On Hold</em>
				</h1>
			</div>
		</div>

		<div class="blue-gradient mgn-bottom45 ht2 border-radius"></div>
	</div>

	<!--PENDING/COMPLETED BUTTONS-->
	<div class="container-fluid">
		<div class="row lt-gray-bg pd-bottom30">
			<div class="col-lg-4">
				<h2 class="in-block mgn-bottom-md semi-bold">
					<i class="fas fa-phone-volume blue-text mgn-right10" aria-hidden="true"></i>
					{{ viewStateService.workflowFilter === 'pending' ? 'Pending' : 'Completed' }} Requests
				</h2>
			</div>
			<div class="col-xs-5 col-md-3 col-lg-2 col-md-offset-3 mgn-bottom-sm">
				<button class="setWidth100 in-block turquoise-bg white-text border-radius" (mouseup)="onNewRequestClick()">
					<i class="fa fa-plus mgn-right8" aria-hidden="true"></i>
					Create new request
				</button>
			</div>

			<!--SEARCH-->
			<div class="col-xs-5 col-md-3 col-lg-2">
				<div class="relative">
					<i class="fas fa-search absolute gray-text-darkest ft-size18 top11 left10"></i>
					<input type="text" placeholder="Search" class="pd-left38" [(ngModel)]="viewStateService.searchTerm" (keyup)="onSearchKeyup()" />
					<button *ngIf="viewStateService.searchTerm.length > 0" class="absolute top8 white-bg pd12 wdth28 right10 z" (click)="onSearchClearClick()">
						<i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
					</button>
				</div>
			</div>

			<div class="col-md-1 hidden-xs hidden-sm text-right">
				<a
					(click)="onPendingOrCompletedClick('pending')"
					ngxTippy
					[tippyProps]="{ appendTo: 'parent' }"
					tippyName="{{ utilService.guid | wrapFn }}"
					data-tippy-content="Pending"
				>
					<i
						[ngClass]="viewStateService.workflowFilter === 'pending' ? 'dark-text' : 'gray-text-dark'"
						class="far fa-clock ft-size20 mgn-right10"
						aria-hidden="true"
					></i>
				</a>
				<a
					(click)="onPendingOrCompletedClick('completed')"
					ngxTippy
					[tippyProps]="{ appendTo: 'parent' }"
					tippyName="{{ utilService.guid | wrapFn }}"
					data-tippy-content="Completed"
				>
					<i [ngClass]="viewStateService.workflowFilter === 'completed' ? 'dark-text' : 'gray-text-dark'" class="fas fa-check ft-size20" aria-hidden="true"></i>
				</a>
			</div>
		</div>

		<div class="row relative hidden-xs hidden-sm">
			<div class="col-xs-12">
				<portal-table
					[table]="tableConfigService.clientTable"
					[config]="tableConfigService.clientConfig"
					[data]="contentRequestsService?.requests"
					[page]="viewStateService.pageNumber"
					[sortBy]="{ key: 'CreateDateTime', order: 'desc' }"
					[totalItems]="viewStateService.totalItems"
					(buttonClickNotify)="onRowSelect($event)"
					(currentPageNotify)="getPage($event)"
				></portal-table>
			</div>
		</div>

		<!--***********************
			MOBILE VIEW
		*************************-->
		<div class="hidden-md hidden-lg">
			<div
				*ngFor="let request of contentRequestsService?.requests; let isLastIndex = last; let isEvenIndex = even"
				class="{{ isLastIndex ? 'mgn-bottom100' : 'mgn-bottom30' }}"
			>
				<!-- <cr-sm-view 
						[isEven]="isEvenIndex"
						[request]="request"
						(viewBtnClickNotify)="onViewBtnClick($event)"
						(deleteItemNotify)="deleteFeedItem($event)">
				</cr-sm-view> -->
			</div>
		</div>
	</div>
</ng-container>
