import { Injectable } from '@angular/core';

import { AppStateService } from './app-state.service';
import { CategoriesTree, LibrariesTree } from '../../shared/components/library-tree/_models';
import { ContentFilesHold, ContentFilesVideo, Hold, Video } from '../../shared/components/content-container/content/_models';
import { UtilitiesService } from './utilities.service';

@Injectable({
    providedIn: 'root'
})
export class TypeGuardService {

    constructor(
        private appStateService: AppStateService,
        private utilService: UtilitiesService
    ) { }

    public contentIsHold(content: Hold | Video): content is Hold {
        return content?.ContentTypeId === 1 ||
        content?.ContentTypeId === 2 ||
        content?.ContentTypeId === 3;
    }

    public contentIsVideo(content: Hold | Video): content is Video {
        return content.ContentTypeId !== 1 &&
        content.ContentTypeId !== 2 &&
        content.ContentTypeId !== 3;
    }

    public contentFileIsHold(contentFile: ContentFilesHold | ContentFilesVideo): contentFile is ContentFilesHold {
        return this.appStateService.product?.Route === 'hold' || this.utilService.includes(contentFile.PreviewFileName, 'mp3');
    }

    public filesAreHold(contentFiles: ContentFilesHold[] | ContentFilesVideo[]): contentFiles is ContentFilesHold[] {
        return this.appStateService.product?.Route === 'hold';
    }

    public contentFileIsVideo(contentFile: ContentFilesHold | ContentFilesVideo): contentFile is ContentFilesVideo {
        return this.appStateService.product.Route !== 'hold';
    }

    public isCategory(libraryOrCategory: LibrariesTree | CategoriesTree): libraryOrCategory is CategoriesTree {
        return !(libraryOrCategory as CategoriesTree).isLibrary && !(libraryOrCategory as CategoriesTree).isSubCategory;
    }

    public isSubCategory(libraryOrCategory: LibrariesTree | CategoriesTree): libraryOrCategory is CategoriesTree {
        return !(libraryOrCategory as CategoriesTree).isLibrary && !this.isCategory(libraryOrCategory);
    }

    public isLibrary(libraryOrCategory: LibrariesTree | CategoriesTree): libraryOrCategory is LibrariesTree {
        return (libraryOrCategory as CategoriesTree).isLibrary;
    }
}
