import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
	AppStateService,
	AudioPreviewService,
	BrowserDetectorService,
	ContentSerializerService,
	DateTimeService,
	EncryptService,
	Events,
	MessageService,
	UtilitiesService,
	ViewportWidthService
} from '../../../core/services';
import { Content } from '../../api-models/content';
import { ContentViewState } from 'src/app/shared/view-models';
import { ContentViewStateService } from 'src/app/shared/services';
import { DeliveryService } from '../../services';
import { GetPlaylistDataService, PrintPlaylistService } from '../../../shared/services';
import { ModalService } from '../../../playlist-builder/_services';
import { PbPlaylist } from '../../view-models/content';
import { PlayersDragDrop } from '../../view-models/admin';

@Component({
	selector: 'portal-playlist-details',
	templateUrl: './playlist-details.component.html',
	styleUrls: ['./playlist-details.component.scss'],
	providers: [PrintPlaylistService]
})
export class PlaylistDetailsComponent implements OnInit, OnDestroy {
	public activeModal: string;
	public contentList: Content[];
	public modalVisible: boolean;
	public playlist: PbPlaylist = new PbPlaylist();
	public spinnerActive: boolean;

	private subs: Subscription[] = [];
	private urlPlaylistId: number;

	constructor(
		private activatedRoute: ActivatedRoute,
		public appStateService: AppStateService,
		private audioPreviewService: AudioPreviewService,
		public browserDetectorService: BrowserDetectorService,
		private contentSerializerService: ContentSerializerService,
		public cvStateService: ContentViewStateService,
		public dateTimeService: DateTimeService,
		private deliveryService: DeliveryService,
		private encryptService: EncryptService,
		private getPlaylistDataService: GetPlaylistDataService,
		public location: Location,
		private messageService: MessageService,
		public modalService: ModalService,
		public printPlaylistService: PrintPlaylistService,
		private router: Router,
		private utilService: UtilitiesService,
		public viewportWidthService: ViewportWidthService
	) {
		this.showModalClickSubscribe();
	}

	ngOnInit() {
		const productRoute = this.activatedRoute.snapshot.paramMap.get('productRoute');
		this.appStateService.setProductWrapper(productRoute);
		this.cvStateService.viewState = new ContentViewState();
		this.urlPlaylistId = JSON.parse(this.encryptService.decryptData(this.utilService.getLastParam(this.router.url)));
		this.viewportWidthService.checkViewportWidth();
		this.getPlaylist();
	}

	showModalClickSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.showPbModal, (payload: [string, number]) => {
				this.cvStateService.viewState.modalVisible = true;
			})
		);
	}

	public onEditClick(): void {
		this.messageService.publish(Events.transitionOverlay, ['Loading playlist...', true]);
		this.router.navigate([
			`/playlist-builder/${this.appStateService.product.Route}/saved-playlist/${this.encryptService.encryptData(JSON.stringify(this.playlist.Id))}`
		]);
	}

	public onPlayersClick(): void {
		this.modalVisible = true;
		this.activeModal = 'scheduled-players';
	}

	public onPreviewClick(): void {
		if (this.appStateService.product.Route !== 'hold') {
			this.appStateService.setSessionItem('playlistId', JSON.stringify(this.playlist.Id));
			this.modalVisible = true;
			this.activeModal = 'video-playlist-previewer';
		} else {
			this.audioPreviewService.getPlaylistPreview(this.playlist);
		}
	}

	public onPrintClick(): void {
		this.deliveryService.getPlaylistPlayers(this.playlist.Id).subscribe((players: PlayersDragDrop[]) => {
			const payload = { playlist: this.playlist, players };
			this.messageService.publish(Events.getScheduledPlayersComplete, payload);
		});
	}

	private getPlaylist(): void {
		this.getPlaylistDataService.getCompletePlaylist(this.urlPlaylistId).subscribe((playlist: PbPlaylist) => {
			this.playlist = playlist;
			this.playlist.previewBtnIcon = 'fas fa-play';
			this.contentList = this.playlist.contentItems.map((contentItem) => this.contentSerializerService.contentItemToContent(contentItem));
		});
	}

	ngOnDestroy() {
		//Stop an active preview
		if (this.audioPreviewService.activePreview()) {
			this.audioPreviewService.activePreview().audioObj.pause();
		}
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
