import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { AppStateService, EncryptService, SecurityService, UtilitiesService } from '../../core/services';
import { ContentRequestEdit } from './_models';
import { ContentRequestTable } from '../_models';
import { CreateEditRequestService, CreateEditViewStateService, MergedRequestService } from './_services';
import { DetailsViewService } from './details/_services';
import { ContentRequestStateName } from 'src/app/shared/api-models/admin';

@Component({
	selector: 'app-create-edit-request',
	templateUrl: './create-edit-request.component.html',
	styleUrls: ['./create-edit-request.component.scss']
})
export class CreateEditRequestComponent implements OnInit, OnDestroy {
	private subs: Subscription[] = [];
	readonly stateName = ContentRequestStateName;

	constructor(
		private activatedRoute: ActivatedRoute,
		private appStateService: AppStateService,
		public createEditRequestService: CreateEditRequestService,
		private detailsViewService: DetailsViewService,
		private encryptService: EncryptService,
		public location: Location,
		private mergedRequestService: MergedRequestService,
		public router: Router,
		public securityService: SecurityService,
		public utilService: UtilitiesService,
		public viewStateService: CreateEditViewStateService
	) {
		//Re-initiate ngOnInit after saving new request
		this.router.routeReuseStrategy.shouldReuseRoute = function () {
			return false;
		};
	}

	ngOnInit() {
		this.viewStateService.activeUrl = this.router.routerState.snapshot.url;
		this.viewStateService.route = this.viewStateService.checkRouteState();

		if (this.appStateService.getSessionItem('requestsForMerge')) {
			const requestsToMerge: ContentRequestTable[] = JSON.parse(this.appStateService.getSessionItem('requestsForMerge'));
			this.mergedRequestService.createMergedRequest(requestsToMerge).subscribe((mergedRequest: ContentRequestEdit) => {
				this.createEditRequestService.request = mergedRequest;
				this.viewStateService.setViewState('edit');
				this.viewStateService.isMergedRequest = true;
			});
			return;
		}

		//Is this a current request (not new)?
		if (!this.urlIndicatesNewRequest()) {
			this.activatedRoute.params.subscribe((res) => {
				const requestId: number = JSON.parse(this.encryptService.decryptData(res.requestId));
				this.createEditRequestService.getRequest(requestId).subscribe(() => {
					this.viewStateService.setViewState('edit');
					this.viewStateService.updateStatusIfContentCreator();
				});
			});
			return;
		}
		this.createEditRequestService.newRequestInit(this.viewStateService.route, this.viewStateService.activeUrl);
		this.viewStateService.setViewState('new');
	}

	public onBackClick(): void {
		this.location.back();
	}

	onFeatureDateChanged(date: string, prop: string): void {
		this.createEditRequestService.request[prop] = date;
		this.detailsViewService.key = prop;
		if (this.viewStateService.mode === 'edit') {
			this.detailsViewService.saveRequest();
		}
	}

	onMarkAsFeaturedClick(): void {
		this.createEditRequestService.request.IsFeatured = !this.createEditRequestService.request.IsFeatured;
		this.detailsViewService.key = 'IsFeatured';
		if (this.viewStateService.mode === 'edit') {
			this.detailsViewService.saveRequest();
		}
	}

	public titleCol(): string {
		return this.securityService.userIsNatalieOrKelsey() ? 'col-sm-5' : 'col-sm-10';
	}

	public urlIndicatesNewRequest(): boolean {
		return this.utilService.includes(this.viewStateService.activeUrl, 'new');
	}

	ngOnDestroy() {
		//something was hanging around when navigating away, causing clips to not render
		this.createEditRequestService.request = null;
		this.appStateService.removeSessionItem('requestsForMerge');
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
