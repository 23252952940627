import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Create24Area } from './_models/create-24-state.enum';

@Injectable({
	providedIn: 'root'
})
export class Create24Service {
	public popup: 'content-browser' | 'export-to-library' | '' = '';
	public c24ActiveArea: Create24Area;

	constructor(private router: Router) {}

	public contentType(): 'video' | 'image' {
		const { c24ActiveArea } = this;
		let contentType: 'video' | 'image' | 'sidebar' = this.router.routerState.snapshot.root.firstChild.params?.contentType;

		if (
			c24ActiveArea === Create24Area.EDIT_PROJECT ||
			c24ActiveArea === Create24Area.VIDEO_TEMPLATE ||
			c24ActiveArea === Create24Area.STILL_TEMPLATE ||
			c24ActiveArea === Create24Area.SIDEBAR_TEMPLATE ||
			c24ActiveArea === Create24Area.EDIT_PROJECT_SIDEBAR
		) {
			if (contentType === 'sidebar') {
				return 'image';
			}
			return contentType as 'video' | 'image';
		}
		switch (true) {
			case c24ActiveArea === Create24Area.NEW_VIDEO:
			case c24ActiveArea === Create24Area.NEW_VIDEO_REPLACE:
				return 'video';
			case c24ActiveArea === Create24Area.NEW_IMAGE_REPLACE:
			case c24ActiveArea === Create24Area.NEW_IMAGE:
			case c24ActiveArea === Create24Area.NEW_SIDEBAR:
				return 'image';
		}
	}
}
