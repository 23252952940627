import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ContentVoiceTalents } from '../../api-models/content';
import { ContentFilesHold, ContentFilesVideo } from '../content-container/content/_models';
import { environment } from '../../../../environments/environment';
import { AppStateService, Events, FileUploadService, MessageService, TypeGuardService } from '../../../core/services';
import { FileUploadStateService } from '../../services';

@Component({
    selector: 'portal-file-upload-progress-bar',
    templateUrl: './file-upload-progress-bar.component.html',
    styleUrls: ['./file-upload-progress-bar.component.scss']
})
export class FileUploadProgressBarComponent implements OnInit, OnDestroy {

    private subs: Subscription[] = [];

    constructor(
        private appStateService: AppStateService,
        public fileUploadService: FileUploadService,
        public fileUploadStateService: FileUploadStateService,
        private messageService: MessageService,
        private typeGuardService: TypeGuardService
    ) {
        this.uploadCompleteSubscribe();
        this.uploadProgressSubscribe();
    }

    ngOnInit() {
    }

    uploadProgressSubscribe(): void {
        this.subs.push(this.messageService.subscribe(Events.uploadProgress, (payload: [{type: number, loaded: number, total: number}, HttpRequest<any>]) => {
            const progress = payload[0];
            this.fileUploadStateService.percentComplete = Math.round(100 * progress.loaded / progress.total);
        }));
    }

    uploadCompleteSubscribe(): void {
        this.subs.push(this.messageService.subscribe(Events.uploadComplete, (event) => {
            const uploadedFile: ContentFilesHold | ContentFilesVideo = event.body;

            this.fileUploadStateService.uploadComplete = true;
            // //Push counterpart to contentItems so it updates immediately in the view
            if (this.fileUploadStateService.counterpartOrReplace === 'counterpart') {
                this.fileUploadStateService.selectedContent.contentFiles.push(this.contentFile(uploadedFile));
            }
            this.fileUploadService.getContentFile(`${environment.contentUrl}ContentFiles/${event.body.Id}`);
        }));
    }

    public onExitOrAbortClick(): void {
        this.fileUploadStateService.resetSteps();
        this.fileUploadStateService.percentComplete = 0;
        this.fileUploadStateService.uploadComplete = false;

        if(!this.fileUploadStateService.uploadComplete) {
            this.messageService.publish(Events.abortUpload, null);
        }
    }

    private contentFile(contentFile: ContentFilesHold | ContentFilesVideo): ContentFilesHold | ContentFilesVideo {
        contentFile.previewUrl = environment.contentUrl + 'File/' + `${this.appStateService.product.Route === 'hold' ? 'Hold/Message/' : 'Video/'}` + contentFile.Id + '/Preview';
        contentFile.ProcessingStateJson = contentFile.ProcessingStateJson;

        if (this.fileUploadStateService.voiceTalent && this.typeGuardService.contentFileIsHold(contentFile)) {
            contentFile.audioPreview = new Audio();
            contentFile.VoiceTalent = new ContentVoiceTalents();
            contentFile.VoiceTalent = this.fileUploadStateService.voiceTalent;

            contentFile.iconColor = contentFile.VoiceTalent.TalentGender === 'Male' ? 'blue-text' : 'pink-text';
            contentFile.previewBtnIcon = contentFile.VoiceTalent.TalentGender === 'Male' ? 'fa-male' : 'fa-female';
        }
        return contentFile;
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
    }
}
