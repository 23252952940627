<div class="row mgn-bottom15">
	<div class="col-sm-3">
		<h2 class="semi-bold"><i class="fas fa-map-marker-alt green-text mgn-right10" aria-hidden="true"></i>Service Locations</h2>
	</div>
	<div class="col-sm-3 col-sm-offset-6">
		<input style="border: 0.125rem solid #d6d9db;" class="ht40 setWidth100 gray-bg-dark" [(ngModel)]="searchTerm" (keyup)="onSearchKeyup()" placeholder="Search...">
		<button *ngIf="searchTerm?.length > 0" style="width: initial;" class="absolute top8 white-bg pd12 right24 z" (click)="onSearchClear()">
			<i class="fa fa-times absolute center-unknown-height-width" aria-hidden="true"></i>
		</button>
	</div>
</div>
<portal-table class="hidden-xs"
			[table]="tableConfigService.table"
			[config]="tableConfigService.configuration"
			[data]="searchResults.length ? searchResults : locationsService.locations"						
			(buttonClickNotify)="onTableButtonClick($event)">
</portal-table>

<!--***********************
	MOBILE VIEW
*************************-->
<div class="mgn-bottom60 hidden-sm hidden-md hidden-lg">
	<!-- <div *ngIf="spinnerActive" class="relative center-unknown-height-width top200 z"><portal-spinner [height]="'htpx90'" [width]="'wdthpx90'" ></portal-spinner></div> -->
	<div *ngFor="let location of locationsService.locations; let isEven = even;" class="mgn-bottom30">
		<loc-xs-view 
				[isEven]="isEven"
				[location]="location"
				(buttonClickNotify)="onTableButtonClick($event)">
		</loc-xs-view>
	</div>
</div>