<ng-container *ngIf="!isImage() && !layerDetailService.isCropping && !activeLayerIsLine()">
	<s-color-select [objectProp]="'fill'" [title]="'Fill'"></s-color-select>

	<hr class="mgn0 mgn-top30" />
</ng-container>

<ng-container *ngIf="!isImage() && !layerDetailService.isCropping && layersService.activeLayer.type !== 'Feed Text'">
	<s-color-select [objectProp]="'stroke'" [title]="'Border'"></s-color-select>

	<hr class="mgn0 mgn-top30" />
</ng-container>

<ng-container *ngIf="layersService.activeLayer.type !== 'Feed Text'">
	<div class="row mgn-top30">
		<div class="col-xs-6">
			<span class="ft-size16">Border Width</span>
		</div>
		<div class="col-xs-6">
			<span class="ft-size16">Border only</span>
		</div>
	</div>
	<div class="row mgn-top15">
		<div class="col-xs-6">
			<input
				style="border: 0.125rem solid #bbc0c3"
				type="number"
				[disabled]="layerDetailService.isCropping || isImage()"
				#strokeWidth
				[ngModel]="layersService.activeLayer.canvasObj.strokeWidth"
				(change)="onBorderWidthChange(strokeWidth.value)"
				class="{{ layerDetailService.isCropping || isImage() ? 'setOpacityLow' : null }} setWidth100 ht40 white-bg"
			/>
		</div>
		<div class="col-xs-6">
			<portal-checkbox
				[backgroundColor]="'white-bg'"
				[isChecked]="layersService.activeLayer.canvasObj.borderOnly"
				[isDisabled]="layerDetailService.isCropping || layersService.activeLayer.canvasObj.strokeWidth < 1 || activeLayerIsLine()"
				[type]="'checkbox'"
				(checkboxClickNotify)="onBorderOnlyClick()"
			></portal-checkbox>
		</div>
	</div>

	<hr class="mgn0 mgn-top30" />
</ng-container>

<ng-container *ngIf="layersService.activeLayer.type !== 'Feed Text' && layersService.activeLayer.type !== 'Line'">
	<div class="row mgn-top30">
		<div class="col-xs-6">
			<span class="ft-size16 mgn-right8">Drop shadow</span>
			<br />
			<div class="in-block relative top10">
				<portal-checkbox
					[backgroundColor]="'white-bg'"
					[isChecked]="layersService.activeLayer.canvasObj.includeDropShadow"
					[isDisabled]="dropShadowDisabled"
					[type]="'checkbox'"
					[width]="'wdth15'"
					(checkboxClickNotify)="onDropShadowClick()"
				></portal-checkbox>
			</div>
		</div>
		<div class="col-xs-6">
			<ld-drop-shadow></ld-drop-shadow>
		</div>
	</div>

	<hr class="mgn0 mgn-top30" />
</ng-container>

<ng-container *ngIf="layersService.activeLayer.type === 'Image'">
	<div class="row mgn-top30">
		<div class="{{ layerDetailService.isCropping ? 'col-xs-7' : 'col-xs-5' }}">
			<button class="{{ cropBtnBg() }}" [disabled]="layerDetailService.activeObj()?.isFeedText" (click)="onCropClick('Rectangle')">
				<i class="fas fa-crop mgn-right8"></i>
				{{ layerDetailService.isCropping ? 'Complete ' : null }}Crop
			</button>
		</div>
		<div *ngIf="layerDetailService.isCropping" class="col-xs-5">
			<button class="white-bg" (click)="onCancelCropClick()">Cancel</button>
		</div>
	</div>
	<hr class="mgn0 mgn-top30" />
</ng-container>

<div class="row mgn-top30">
	<div class="col-xs-6">
		<span class="ft-size12">Opacity:</span>
	</div>
</div>

<div class="row">
	<div class="col-xs-12">
		<input
			type="range"
			#opacity
			[ngModel]="layersService.activeLayer.canvasObj.opacity"
			min="0"
			max="1"
			step="0.01"
			value="{{ layersService.activeLayer.canvasObj.opacity }}"
			class="slider"
			(mouseup)="undoRedoService.recordState('Change opacity')"
			(input)="onOpacityChange(opacity.value)"
		/>
		<span class="absolute top20 right15 ft-size10">{{ opacityValueView() }}%</span>
	</div>
</div>

<ng-container *ngIf="layersService.activeLayer.type === 'Rectangle'">
	<hr class="mgn0 mgn-top30" />
	<div class="row mgn-top30">
		<div class="col-xs-6">
			<span class="ft-size12">Border Radius:</span>
		</div>
	</div>

	<div class="row">
		<div class="col-xs-12">
			<input
				type="range"
				#borderRadius
				[ngModel]="layersService.activeLayer.canvasObj.rx"
				min="0"
				max="20"
				step="1"
				value="{{ layersService.activeLayer.canvasObj.rx }}"
				class="slider"
				#opacity
				(input)="onBorderRadiusChange(borderRadius.value)"
			/>
			<span class="absolute top20 right15 ft-size10">{{ layersService.activeLayer.canvasObj.rx }}</span>
		</div>
	</div>
</ng-container>

<hr class="mgn0 mgn-top30" />

<ld-transition-select *ngIf="create24Service.contentType() === 'video'"></ld-transition-select>
