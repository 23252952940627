import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { authGuard, CanDeactivateGuardService, ContractGuardService, UserGuardService } from 'src/app/core/services/route-guards';
import { ContentBrowserComponent } from './content-browser.component';

const routes: Routes = [
	{
		path: '',
		canActivate: [authGuard, ContractGuardService, UserGuardService],
		canDeactivate: [CanDeactivateGuardService],
		component: ContentBrowserComponent
	},
	{
		path: 'playlist-in-progress',
		canActivate: [authGuard, ContractGuardService],
		canDeactivate: [CanDeactivateGuardService],
		component: ContentBrowserComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ContentBrowserRoutingModule {}
