import { Component, OnInit, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AppStateService, BrowserDetectorService, EncryptService, Events, MessageService, SecurityService, UtilitiesService } from '../core/services';
import { ContentRequestsService, MergeRequestsService, TableConfigService, TableViewStateService } from './_services';
import { ContentRequestStates, ContentRequestType } from '../shared/api-models/admin';
import { ContentRequestTable } from './_models';
import { SortParams } from '../shared/view-models/common-types';
import { UrlString } from './_models/url-string.enum';

@Component({
	selector: 'app-content-requests',
	templateUrl: './content-requests.component.html',
	styleUrls: ['./content-requests.component.scss']
})
export class ContentRequestsComponent implements OnInit, AfterViewInit, OnDestroy {
	public newRequestDropdownVisible: boolean;

	private searchTermChanged: Subject<string> = new Subject<string>();
	private subs: Subscription[] = [];

	constructor(
		public appStateService: AppStateService,
		public browserDetectorService: BrowserDetectorService,
		public contentRequestsService: ContentRequestsService,
		private encryptService: EncryptService,
		public mergeRequestsService: MergeRequestsService,
		private messageService: MessageService,
		private router: Router,
		public securityService: SecurityService,
		public tableConfigService: TableConfigService,
		public viewStateService: TableViewStateService,
		public utilService: UtilitiesService
	) {
		this.searchTermChanged.pipe(debounceTime(400)).subscribe((_) => {
			this.contentRequestsService.getRequests().subscribe((requests) => {
				this.contentRequestsService.requests = requests;
			});
		});
		this.onColumnSortSubscribe();
	}

	ngOnInit() {
		this.viewStateService.activeUrl = this.router.routerState.snapshot.url;
		const route = this.viewStateService.checkRouteState();
		this.viewStateService.route = route;
		//If any prop already has a value, we don't want to re-set the values.
		//Preserves state when navigating away/back.
		if (!this.viewStateService.pageTitleSnippet) {
			this.viewStateService.setState('My Active', 1, 'DueDate', 'All', 'All', UrlString.ACTIVE_VIEW, 'pending', route);
		}
		this.contentRequestsService.setPageTitle(this.viewStateService.pageTitleSnippet);
		this.contentRequestsService.setOrderDesc();
		let getUserItemFilterByRequestType: boolean;
		if (route === 'content-requests') {
			this.appStateService.product.backgroundColor = 'blue-bg';
			this.viewStateService.workflowFilter = 'pending';
			this.viewStateService.filterByRequestType = 'On-Hold';
		} else {
			getUserItemFilterByRequestType = true;
		}
		if (route === 'client-details') {
			this.viewStateService.filterByRequestType = 'All';
		}
		this.contentRequestsService.getRequests(getUserItemFilterByRequestType).subscribe((requests) => {
			this.contentRequestsService.requests = requests;
		});
	}

	@HostListener('window:scroll', [])
	onWindowScroll() {
		this.contentRequestsService.scrollY = window.scrollY;
	}

	ngAfterViewInit() {
		//Preserve scroll position when navigating away and back
		window.scrollTo(0, this.contentRequestsService.scrollY);
	}

	public autoClose(event) {
		if (!this.utilService.closest(event, '.no-exit')) {
			this.newRequestDropdownVisible = false;
		}
	}

	public onFilterByStatusClick(): void {
		this.contentRequestsService.requestStates$ = this.contentRequestsService.getRequestStates();
	}

	public onSearchKeyup(): void {
		this.searchTermChanged.next(this.viewStateService.searchTerm);
	}

	public onSearchClearClick(): void {
		this.viewStateService.searchTerm = '';
		this.contentRequestsService.getRequests().subscribe((requests) => {
			this.contentRequestsService.requests = requests;
		});
	}

	//Client-facing view only

	public onPendingOrCompletedClick(stringId: string): void {
		this.viewStateService.workflowFilter = stringId;
		this.contentRequestsService.getRequests().subscribe((requests) => {
			this.contentRequestsService.requests = requests;
		});
	}

	onFilterSelect(item: ContentRequestStates | any, filterByType: string): void {
		this.viewStateService[filterByType] = item.Name;
		this.viewStateService.setTable(item.Name);
		//Save so filterByRequestType setting will be remembered when logging out/back in.
		filterByType === 'filterByRequestType' ? this.appStateService.setUserItem('filterByRequestType', JSON.stringify(item.Name)) : null;
		this.contentRequestsService.setPageTitle(this.viewStateService.pageTitleSnippet);
		this.contentRequestsService.getRequests().subscribe((requests) => {
			this.contentRequestsService.requests = requests;
		});
	}

	public onNewRequestClick(): void {
		if (this.viewStateService.route !== 'content-requests') {
			this.newRequestDropdownVisible = !this.newRequestDropdownVisible;
			this.contentRequestsService.requestTypes$ = this.contentRequestsService.getRequestTypes();
		} else if (this.viewStateService.route === 'content-requests') {
			//client-facing route
			this.router.navigate([`/content-requests/new/On-Hold Message`]);
		}
	}

	public onNewRequestOptionClick(requestType: ContentRequestType): void {
		this.router.navigate([`/system-tools/content-requests/new/${requestType.Name}`]);
	}

	/*********************************
        TABLE METHODS
    **********************************/

	getPage(pageNumber: number): void {
		this.viewStateService.pageNumber = pageNumber;
		this.contentRequestsService.getRequests().subscribe((requests) => {
			this.contentRequestsService.requests = requests;
		});
	}

	onColumnSortSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.onColumnSort, (sortParams: SortParams) => {
				let { key } = sortParams;
				const keyHasDot = this.utilService.includes(key, '.');

				if (keyHasDot) {
					key = key.substring(key.indexOf('.') + 1);
				}
				this.viewStateService.orderByCol = key;
				sortParams.order === 'asc' ? (this.viewStateService.orderDesc = false) : (this.viewStateService.orderDesc = true);
				this.contentRequestsService.getRequests().subscribe((requests) => {
					this.contentRequestsService.requests = requests;
				});
			})
		);
	}

	onRowSelect(row: ContentRequestTable): void {
		if (this.viewStateService.route !== 'content-requests') {
			this.router.navigate([`/${this.viewStateService.route}/content-requests/${this.encryptService.encryptData(JSON.stringify(row.Id))}`]);
			return;
		}
		row = row[1];
		this.router.navigate([`/content-requests/${this.encryptService.encryptData(JSON.stringify(row.Id))}`]);
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
		this.viewStateService.searchTerm = '';
	}
}
