import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { fabric } from 'fabric';
import * as FontFaceObserver from 'fontfaceobserver';

import { AppStateService } from 'src/app/core/services';
import { CanvasService } from '../canvas/_services/canvas.service';
import { CountdownAndDateService } from '../create-layer-sidebar/countdown-and-date/countdown-and-date.service';
import { Create24Service } from '../../create-24.service';
import { EditorState, ICircle, IImage, ILine, IRect, IText } from '../_models';
import { LayersService } from './layers.service';
import { LayersTimelineWrapperService } from '../timeline/layers-timeline-wrapper/_services/layers-timeline-wrapper.service';
import { TextService } from '../layer-detail/_services/text.service';
import { Video } from 'src/app/shared/components/content-container/content/_models';

@Injectable({
	providedIn: 'root'
})
export class StateService {
	private imgLoadedSource = new Subject<number>();
	public c24Content: Video = new Video();
	public c24ContentId: number;
	public exportWindowVisible: boolean;
	public imgLoaded$ = this.imgLoadedSource.asObservable();
	public feedTextObjs: any;

	constructor(
		private appStateService: AppStateService,
		private canvasService: CanvasService,
		private countdownAndDateService: CountdownAndDateService,
		private create24Service: Create24Service,
		private layersService: LayersService,
		private ltwService: LayersTimelineWrapperService,
		private textService: TextService
	) {}

	public state(tooltip?: string): EditorState {
		return {
			eligibleForConversion: false,
			activeLayer: this.layersService.activeLayer,
			layers: this.layersService.layers,
			//Manually list custom attributes here or they won't export on stringify

			canvasObjs: JSON.stringify(
				this.canvasService.canvas.toJSON([
					'hasRotatingPoint',
					'isFeedImageBoundingBox',
					'isFeedText',
					'isFeedImage',
					'isFeedTextImage',
					'cornerColor',
					'fontFamily',
					'selectable',
					'hasBorders',
					'hasControls',
					'lockMovementX',
					'lockMovementY',
					'strokeWidth',
					'strokeUniform',
					'cornerStrokeColor',
					'borderColor',
					'transparentCorners',
					'fill',
					'top',
					'left',
					'hoverCursor',
					'width',
					'height',
					'layerId',
					'component',
					'name',
					'padding',
					'editable'
				])
			),
			timelineObjs: JSON.stringify(
				this.ltwService.canvas.toJSON([
					'cornerColor',
					'lockMovementX',
					'lockMovementY',
					'strokeWidth',
					'cornerStrokeColor',
					'borderColor',
					'transparentCorners',
					'fill',
					'layerId',
					'component',
					'name'
				])
			),
			bgContent: this.canvasService.bgContent,
			tooltip: tooltip,
			c24ContentId: this.c24ContentId,
			productType: this.appStateService.product,
			blankBgColor: this.layersService.layers.find((layer) => layer.id === 0).canvasObj.blankBgColor,
			currentCanvasWidth: this.canvasService.canvasContainerWidth,
			previousTimelineCanvasWidth: this.ltwService.previousCanvasWidth,
			feed: this.canvasService.feed,
			feedItem: this.canvasService.feedItem,
			countdownSettings: {
				countdownDate: this.countdownAndDateService.countdownDate,
				countdownInterval: this.countdownAndDateService.countdownInterval,
				mode: this.countdownAndDateService.mode,
				view: this.countdownAndDateService.view,
				displayOption: this.countdownAndDateService.displayOption
			},
			contentType: this.create24Service.contentType()
		};
	}

	public addEachCanvasObj(state: EditorState): void {
		JSON.parse(state.canvasObjs).objects.forEach((obj) => {
			let isWebSafeFont: boolean = this.textService.fontList.find((font) => font.name === obj.fontFamily)?.isWebSafe;
			let layer = this.layersService.layers.find((layer) => layer.id === obj.layerId);
			switch (obj.name) {
				case 'transparent':
					let transparentRect = new fabric.Rect(obj as IRect);
					this.canvasService.canvas.add(transparentRect);
					this.setActiveObjAndRender();
					break;
				case 'rect':
					let rect = new fabric.Rect(obj as IRect);
					//layerId should only be undefined if the crob obj is still hanging around
					if (obj.layerId !== undefined) {
						this.canvasService.canvas.add(rect);
					}
					rect.setCoords();
					this.setActiveObjAndRender();
					break;
				case 'circle':
					let circle = new fabric.Circle(obj as ICircle);
					this.canvasService.canvas.add(circle);
					circle.setCoords();
					this.setActiveObjAndRender();
					break;
				case 'line':
					let line = new fabric.Line([50, 400, 400, 400], obj as ILine);
					line.setControlsVisibility({
						tr: false,
						tl: false,
						br: false,
						bl: false,
						ml: true,
						mt: false,
						mr: true,
						mb: false,
						mtr: true
					});
					this.canvasService.canvas.add(line);
					line.setCoords();
					this.setActiveObjAndRender();
					break;
				case 'text':
					let text = new fabric.Textbox(obj.text, obj as IText);
					text.height = obj.height; //Height would NOT set correctly without this line...dumb

					text.setControlsVisibility({
						tr: false,
						tl: false,
						br: false,
						bl: false,
						ml: layer.type === 'Countdown' || layer.type === 'Current Date' || layer.type === 'Current Time' ? false : true,
						mt: true,
						mr: layer.type === 'Countdown' || layer.type === 'Current Date' || layer.type === 'Current Time' ? false : true,
						mb: true,
						mtr: true
					});
					text.selectable = true;
					if (!isWebSafeFont) {
						this.loadFontAndAddToCanvas(obj, text);
					} else {
						this.addToCanvas(text);
					}
					text.setCoords();
					break;
				case 'image':
					fabric.Image.fromURL(
						obj.src,
						(img) => {
							let image: IImage = img as IImage;
							image.set(obj);
							this.canvasService.canvas.add(image);
							if (layer.type === 'Feed Image') {
								this.canvasService.addFeedImageBoundingBox(layer, image);
							}
							this.setActiveObjAndRender();
							image.setControlsVisibility({
								tr: layer.type === 'Feed Image' ? false : true,
								tl: layer.type === 'Feed Image' ? false : true,
								br: layer.type === 'Feed Image' ? false : true,
								bl: layer.type === 'Feed Image' ? false : true,
								ml: false,
								mt: false,
								mr: false,
								mb: false,
								mtr: layer.type === 'Feed Image' ? false : true
							});
							this.imgLoadedSource.next();
						},
						{ crossOrigin: 'anonymous' }
					);
					break;
			}
		});
	}

	private loadFontAndAddToCanvas(obj, newObj): void {
		var myfont = new FontFaceObserver(obj.fontFamily);
		myfont
			.load()
			.then(() => {
				//clear character cache to ensure bounding boxes for fonts are correct
				fabric.util.clearFabricFontCache();
				// when font is loaded, use it.
				newObj.set('fontFamily', obj.fontFamily);
				newObj.set('height', obj.height);
				this.addToCanvas(newObj);
			})
			.catch((e) => {
				console.log(e);
				newObj.set('fontFamily', obj.fontFamily);
				this.addToCanvas(newObj);
			});
	}

	private addToCanvas(newObj): void {
		this.canvasService.canvas.add(newObj);
		this.setActiveObjAndRender();
	}

	public addEachTimelineObj(state: EditorState): void {
		JSON.parse(state.timelineObjs)
			.objects.filter((obj) => obj.layerId >= 0)
			.forEach((obj) => {
				//Lock x movement if layer is background layer (layerId === 0)
				let rect = new fabric.Rect(obj as IRect);
				rect.hoverCursor = obj.lockMovementX ? 'default' : 'move';
				//Disable ability for bg timeline bar to be selected as a group
				rect.selectable = obj.layerId === 0 ? false : true;
				this.ltwService.canvas.add(rect);
				rect.setControlsVisibility({
					tr: false,
					tl: false,
					br: false,
					bl: false,
					ml: obj.lockMovementX ? false : true,
					mt: false,
					mr: obj.lockMovementX ? false : true,
					mb: false,
					mtr: false
				});
			});
		this.ltwService.canvas.bringToFront(this.ltwService.playhead);
		this.ltwService.canvas.discardActiveObject(); //Timeline obj border would stay in original position w/out this function
		this.ltwService.canvas.renderAll();
	}

	private setActiveObjAndRender(): void {
		let obj = this.canvasService.canvas.getObjects().find((obj) => obj.layerId === this.layersService.activeLayer?.id);
		if (obj) {
			//Reset active (highlighted with border/controls) canvas object
			this.canvasService.canvas.setActiveObject(obj);
		}

		this.canvasService.canvas.renderAll();
	}
}
