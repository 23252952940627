import { Component, Input, OnInit } from '@angular/core';

import { ContentViewService } from '../../_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { Hold, Video } from '../../_models';
import { UtilitiesService } from 'src/app/core/services';
import { ContentType } from 'src/app/shared/view-models/content/content-type.enum';

@Component({
	selector: 'vc-newsletter-view',
	templateUrl: './newsletter-view.component.html',
	styleUrls: ['./newsletter-view.component.scss']
})
export class NewsletterViewComponent implements OnInit {
	@Input() content: Hold | Video;
	@Input() contentList: (Hold | Video)[];
	@Input() isEvenIndex: boolean;
	@Input() isFirstIndex: boolean;
	@Input() isLastIndex: boolean;

	readonly contentType = ContentType;

	constructor(public contentViewService: ContentViewService, public cvStateService: ContentViewStateService, public utilService: UtilitiesService) {}

	ngOnInit(): void {}

	public onPlayClick(
		content: Hold | Video,
		isLastIndex?: boolean,
		prop?: 'talentPreviewSelectorVisible' | 'talentPickerVisible' | 'playlist-details' | 'newsletter',
		contentList?: (Hold | Video)[]
	): void {
		this.contentViewService.onPlayClick(content as Hold, isLastIndex, prop, contentList as Hold[]);
	}
}
