import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ClientHistoryNotes } from '../../shared/api-models/log';
import { Column } from '../../shared/components/table/view-models';
import { HistoryNotesService } from './history-notes.service';
import { LocationsService } from '../locations/locations.service';
import { MessageService, Events } from '../../core/services';
import { ServiceLocations } from '../../shared/api-models/admin';
import { TableConfigService } from './table-config.service';
import { SortParams } from 'src/app/shared/view-models/common-types';

@Component({
	selector: 'app-history-notes',
	templateUrl: './history-notes.component.html',
	styleUrls: ['./history-notes.component.scss']
})
export class HistoryNotesComponent implements OnInit, OnDestroy {
	public searchResults: ClientHistoryNotes[] = [];
	public spinnerActive: boolean;

	private searchTermChanged: Subject<string> = new Subject<string>();
	private subs: Subscription[] = [];

	constructor(
		public historyNotesService: HistoryNotesService,
		public locationsService: LocationsService,
		private messageService: MessageService,
		public tableConfigService: TableConfigService
	) {
		this.onColumnSortSubscribe();
		this.searchTermChanged.pipe(debounceTime(800)).subscribe((_) => {
			this.getHistoryNotes(this.serviceLocationId(this.historyNotesService.viewState.locationId), true);
		});
	}

	ngOnInit() {
		this.getHistoryNotes(null, false);
		if (!this.locationsService.locationsDropdownView) {
			this.locationsService.getAllLocationsForClient().subscribe((locations: ServiceLocations[]) => {
				this.locationsService.locationsDropdownView = locations;
				this.locationsService.locationsDropdownView.unshift({ Name: 'All' });
			});
		}
	}

	private getHistoryNotes(serviceLocationId: number, refreshData: boolean): void {
		this.spinnerActive = true;
		this.historyNotesService.getHistoryNotes(serviceLocationId, refreshData).subscribe((historyNotes: ClientHistoryNotes[]) => {
			this.historyNotesService.historyNotes = historyNotes;
			this.spinnerActive = false;
		});
	}

	onColumnSortSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.onColumnSort, (sortParams: SortParams) => {
				this.historyNotesService.viewState.orderByCol = sortParams.key;
				this.historyNotesService.viewState.orderDesc = sortParams.order === 'asc' ? false : true;
				this.getHistoryNotes(this.historyNotesService.viewState.locationId, true);
			})
		);
	}

	onLocationSelect(location: ServiceLocations): void {
		this.historyNotesService.viewState.locationId = location.Id;
		this.getHistoryNotes(this.serviceLocationId(location.Id), true);
	}

	private serviceLocationId(id: number): number {
		return id !== -1 ? id : null;
	}

	onPageClick(page: number): void {
		this.historyNotesService.viewState.pageNumber = page;
		this.getHistoryNotes(this.historyNotesService.viewState.locationId, true);
	}

	/*****************************
        CRUD OPERATIONS
    ******************************/

	onTableButtonClick(payload: [Column, ClientHistoryNotes, string]): void {
		const [column, row, value] = payload;

		if (column['key'] === 'deleteBtn') {
			this.historyNotesService.deleteHistoryNote(row);
		}

		if (column['key'] === 'editBtn') {
			this.tableConfigService.columns[2].state = 'editMode';
			this.tableConfigService.columns[3].state = 'editMode';
		}
		//Updating an editable column
		if (value) {
			this.historyNotesService.updateHistoryNoteColumn(row, value, column['key']);
		}
	}

	public showSystemMessagesClick(): void {
		this.historyNotesService.viewState.includeSystemGenerated = !this.historyNotesService.viewState.includeSystemGenerated;
		this.historyNotesService.viewState.pageNumber = 1;
		if (this.historyNotesService.viewState.includeSystemGenerated) {
			this.getHistoryNotes(this.historyNotesService.viewState.locationId, true);
		} else {
			this.getHistoryNotes(this.historyNotesService.viewState.locationId, true);
		}
	}

	/**********************
        SEARCH
    **********************/

	onSearchKeyup(searchTerm: string) {
		this.spinnerActive = true;
		this.historyNotesService.searchTerm = searchTerm;
		this.searchTermChanged.next(this.historyNotesService.searchTerm);
	}

	public onSearchClear(): void {
		this.historyNotesService.searchTerm = '';
		this.historyNotesService.viewState.pageNumber = 1;
		this.getHistoryNotes(this.serviceLocationId(this.historyNotesService.viewState.locationId), true);
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
