import { Content, ContentFiles, ContentVoiceTalents } from '../../../../api-models/content';
import { Users } from '../../../../api-models/admin/users';

export class ContentFilesHold extends ContentFiles {
    VoiceTalent: ContentVoiceTalents;
    previewUrl: string;
    audioPreview: any;
    previewActive: boolean;
    previewBtnColor: 'blue-bg' | 'pink-bg' | 'yellow-bg';
    previewBtnTextColor: 'white-text' | 'dark-text';
    previewBtnIcon: 'fa-male' | 'fa-female' | 'fa-times';
    previewBtnText: string;
    isStopDateSelected: boolean;
	thumbnailUrl: string;
    isMusicTrack: boolean;
    iconColor: 'pink-text' | 'blue-text';
    isDeletable: boolean;
    isReplaceable: boolean;
    createdByUser: Users;
    writeAccess: boolean;
    isDirty: boolean;
    startDate: string;

    Content: Content;
}