export enum Create24Area {
	NEW_VIDEO = 'new-video',
	NEW_IMAGE = 'new-image',
	EDIT_PROJECT = 'edit-project',
	NEW_IMAGE_REPLACE = 'new-image-replace',
	NEW_VIDEO_REPLACE = 'new-video-replace',
	IMAGE_LAYER_CREATE = 'image-layer-create',
	IMAGE_LAYER_REPLACE = 'image-layer-replace',
	VIDEO_TEMPLATE = 'video-template',
	STILL_TEMPLATE = 'still-template',
	SIDEBAR_TEMPLATE = 'sidebar-template',
	NEW_SIDEBAR = 'new-sidebar',
	NEW_SIDEBAR_REPLACE = 'new-sidebar-replace',
	SIDEBAR_REPLACE = 'sidebar-replace',
	EDIT_PROJECT_SIDEBAR = 'edit-project-sidebar',
	FEED_ITEM = 'feed-item',
	NONE = 'none'
}

export enum ContentType {
	IMAGE = 'image',
	VIDEO = 'video',
	NONE = 'none'
}
