<div class="row border-radius {{ !isLastIndex ? 'border-first-index' : 'border-last-index border-first-index mgn-bottom1' }} white-bg pd-top15 pd-bottom15">
	<div class="col-md-9">
		<div class="row">
			<!--***************************
				VIDEO SCRIPT BUTTON
			****************************-->
			<div class="col-md-2 ft-size16">
				<button class="z gray-bg" (mouseup)="content.detailsVisible = !content.detailsVisible">
					<i class="{{ content.detailsVisible ? 'fas fa-times' : 'far fa-file-alt' }} icon-hidden mgn-right10" aria-hidden="true"></i>
					{{ content.detailsVisible ? 'Close' : 'Script' }}
				</button>
			</div>

			<!--***************************
				VIDEO PREVIEW
			****************************-->
			<div class="col-lg-4">
				<portal-preview-thumbnail [content]="content"></portal-preview-thumbnail>
			</div>

			<!--***************************
				CONTENT TITLE
			****************************-->
			<div class="col-lg-6 ft-size16 relative mgn-leftneg8 pd-left0">
				<i
					*ngIf="!!contentViewService.featuredContentProps(content)"
					class="{{ contentViewService.featuredContentProps(content).icon }} {{ contentViewService.featuredContentProps(content).color }} mgn-right10"
					ngxTippy
					tippyName="{{ utilService.guid | wrapFn }}"
					[tippyProps]="{ appendTo: 'parent' }"
					attr.data-tippy-content="{{ contentViewService.featuredContentProps(content).tooltip }}"
				></i>
				<span>{{ content.Title }}</span>
			</div>
		</div>
	</div>

	<div class="col-md-3">
		<div class="row">
			<!--***************************
				ADD CLIP BUTTON
			****************************-->
			<div class="col-md-8 col-md-offset-4">
				<button
					*ngIf="(content.contentFiles?.length > 0 || content.IsChannel) && canUseInPlaylist && !playerCapacityService.showWarning"
					class="{{ content.IsChannel ? 'ft-size14' : null }} gray-bg"
					(mouseup)="addToPlaylistService.onAddClick(content)"
				>
					{{ content.IsChannel ? 'Add channel' : 'Add clip' }}
					<i class="fa fa-angle-right mgn-left10" aria-hidden="true"></i>
				</button>
				<button
					*ngIf="!canUseInPlaylist || playerCapacityService.showWarning"
					class="{{ content.IsChannel ? 'ft-size14' : null }} setOpacityLow gray-bg cursor-not-allowed"
				>
					{{ content.IsChannel ? 'Add channel' : 'Add clip' }}
					<i class="fa fa-angle-right mgn-left10" aria-hidden="true"></i>
				</button>
			</div>
		</div>
	</div>

	<!--***************************
		SCRIPT
	****************************-->
	<div *ngIf="content.detailsVisible" class="col-lg-12 mgn-bottom15">
		<c-details [content]="content"></c-details>
	</div>
</div>
