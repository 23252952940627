import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppStateService, Events, MessageService, CacheService, UtilitiesService } from '../../../../core/services';
import { CreateEditTicketService } from '../../create-edit-ticket/_services/create-edit-ticket.service';
import { environment } from '../../../../../environments/environment';
import { Clients, Users, ProductTypes, ServiceLocations, TicketTypes } from '../../../../shared/api-models/admin';
import { InstallersView } from '../../_models';
import { TicketSystemService } from '../../_services/ticket-system.service';
import { TicketsView } from '../../create-edit-ticket/_models';
import { WorkOrdersView } from '../../create-edit-ticket/_models';

@Injectable({
    providedIn: 'root'
})
export class CreateEditWorkOrderService {


    public invalidZip: boolean;
    public locationsList: ServiceLocations[];
    public mode: 'edit' | 'new';
    public pdfGenerateInProcess: boolean;
    public saving: boolean;

    public genericProductTypes = [
        {
            Id: 1,
            name: 'On Hold'
        },
        {
            Id: 2,
            name: 'Works24 Radio'
        },
        {
            Id: 3,
            name: 'Video'
        },
        {
            Id: 8,
            name: 'Other Product or Service'
        }
    ]


    constructor(
        private appStateService: AppStateService,
        private ceTicketService: CreateEditTicketService,
        private httpClient: HttpClient,
        private messageService: MessageService,
        private router: Router,
        private ticketSystemService: TicketSystemService,
        private titleService: Title,
        private cacheService: CacheService,
        private utilService: UtilitiesService
    ) { }

    public newWorkOrderInit(activeUrl: string): WorkOrdersView {
        let workOrder = new WorkOrdersView();
        workOrder.CreatedBy = this.appStateService.currentUser.UserId;
        workOrder.createdByUsername = this.appStateService.currentUser.UserName;
        workOrder.CreatedDate = new Date().toISOString();
        workOrder.TicketId = JSON.parse(this.utilService.getLastParam(activeUrl));
        workOrder.IsApproved = false;
        workOrder.IsSent = false;
        workOrder.IsComplete = false;
        workOrder.currentUserFriendlyName = `${this.appStateService.currentUser.FirstName} ${this.appStateService.currentUser.LastName}`;
        return workOrder;
    }

    public getWorkOrder(workOrderId: number): Observable<WorkOrdersView> {
        return this.httpClient.get(`${environment.adminUrl}TicketsWorkOrders/${workOrderId}`).pipe(
            mergeMap((workOrder: WorkOrdersView) => {
                let createdByUser$ = this.cacheService.user$(this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${workOrder.CreatedBy}`), workOrder.CreatedBy);
                let productType$ = this.httpClient.get(`${environment.adminUrl}CoreProductTypes/${workOrder.ProductTypeId}`);
                let installer$ = this.httpClient.get(`${environment.adminUrl}CoreInstallers/${workOrder.InstallerId}`);
                let ticket$ = this.httpClient.get(`${environment.adminUrl}Tickets/${workOrder.TicketId}`);

                let arr = [];
                arr = workOrder.InstallerId ? [createdByUser$, productType$, ticket$, installer$] : [createdByUser$, productType$, ticket$];

                return forkJoin(arr).pipe(
                    mergeMap((res: [Users, ProductTypes, TicketsView, InstallersView]) => {
                        workOrder.createdByUsername = res[0].UserName;
                        workOrder.productTypeName = res[1].ProductName === 'Lobby Video' ? 'Video' : res[1].ProductName;
                        this.ceTicketService.ticket = res[2];

                        if(workOrder.InstallerId) {
                            workOrder.Installer = res[3];
                            workOrder.Installer.emailList = this.ticketSystemService.isStringifiedArray(res[3].Email) ? JSON.parse(res[3].Email) : res[3].Email;
                            workOrder.Installer.Email = this.ticketSystemService.isStringifiedArray(res[3].Email) ? this.ticketSystemService.formatedEmailList(res[3].Email) : res[3].Email;
                        }
                        this.cacheService.setUserCache(res[0]);
                        return this.getOtherData$(workOrder);
                    })
                )
            })
        )
    }

    public getOtherData$(workOrder: WorkOrdersView): Observable<WorkOrdersView> {
        let locations$ = this.httpClient.get(`${environment.adminUrl}CoreServiceLocations/Client/${this.ceTicketService.ticket.ClientId}`);
        let ticketType$ = this.httpClient.get(`${environment.adminUrl}TicketsTypes/${this.ceTicketService.ticket.TicketTypeId}`);
        let client$ = this.httpClient.get(`${environment.adminUrl}CoreClients/${this.ceTicketService.ticket.ClientId}`);
        let productType$ = this.httpClient.get(`${environment.adminUrl}CoreProductTypes/${workOrder.ProductTypeId}`);

        let observs$;
        if (this.mode === 'edit') {
            observs$ = [locations$, ticketType$, client$];
        } else if (this.mode === 'new') {
            observs$ = [locations$, ticketType$, client$, productType$];
        }

        return forkJoin(observs$).pipe(
            map((res: [ServiceLocations[], TicketTypes, Clients?, ProductTypes?]) => {

                this.locationsList = res[0];
                workOrder.ticketTypeName = res[1].Name;
                if(this.mode === 'new') {
                    workOrder.CompanyName = res[2].Name
                    workOrder.productTypeName = res[3].ProductName;
                }
                this.ceTicketService.ticket.client = res[2];
                this.titleService.setTitle(this.ceTicketService.ticket.client.Name);
                return workOrder;
            })
        )
    }

    public getTicket(workOrder: WorkOrdersView): Observable<WorkOrdersView> {
        return this.httpClient.get(`${environment.adminUrl}Tickets/${workOrder.TicketId}`).pipe(
            mergeMap( (ticket: TicketsView) => {
                this.ceTicketService.ticket = ticket;
                workOrder.ProductTypeId = this.ticketTypeIdToProductTypeId(this.ceTicketService.ticket.TicketTypeId);
                return this.getOtherData$(workOrder);
            })
        )
    }

    public getLocationPrimaryContact(location: ServiceLocations): Observable<ServiceLocations> {
        return this.cacheService.user$(this.httpClient.get<Users>(`${environment.adminUrl}CoreUsers/${location.PrimaryContactUserId}`), location.PrimaryContactUserId);
    }

    public installerSearch(term: string): Observable<InstallersView[]> {
        return this.httpClient.get(`${environment.adminUrl}CoreInstallers/Search/${term}?includeInactive=false`).pipe(
            map( (installers: InstallersView[]) => {
                return installers.map( (installer) => {
                    installer.Email = this.ticketSystemService.isStringifiedArray(installer.Email) ? this.ticketSystemService.formatedEmailList(installer.Email) : installer.Email;
                    return installer;
                }) 
            })
        )
    }

    public getInstaller(installerId): Observable<any> {
        return this.httpClient.get(`${environment.adminUrl}CoreInstallers/${installerId}`);
    }

    private ticketTypeIdToProductTypeId(ticketTypeId: number): number {
        switch (ticketTypeId) {
            //any on hold ticket
            case 1:
            case 5:
            case 9:
                return 1;
            //any overhead music ticket
            case 3:
            case 7:
            case 11:
                return 2;
            //any video ticket
            case 2:
            case 6:
            case 10:
                return 3;
            //all others
            default:
                return 8;
        }
    }

    public createWorkOrder(workOrder: WorkOrdersView): void {
        this.saveOps(workOrder);

        let patchServiceLocation$ = this.httpClient.patch(`${environment.adminUrl}CoreServiceLocations/${workOrder.ServiceLocationId}`, {PreviousInstallerId: workOrder.InstallerId});
        let postWorkOrder$ = this.httpClient.post(`${environment.adminUrl}TicketsWorkOrders`, workOrder);

        forkJoin([patchServiceLocation$, postWorkOrder$])
            .subscribe( () => {
                this.messageService.publish(Events.savingPreloader, 0);
                this.saving = false;
                this.router.navigate([`/ticket-system/edit-ticket/${this.ceTicketService.ticket.Id}`])
            })
    }

    public deleteWorkOrder(workOrderId: number, skipRouteToTicket?: boolean): void {
        this.messageService.publish(Events.savingPreloader, 1);
        this.httpClient.delete(`${environment.adminUrl}TicketsWorkOrders/${workOrderId}`)
            .subscribe(() => {
                this.messageService.publish(Events.savingPreloader, 0);
                !skipRouteToTicket ? this.router.navigate([`/ticket-system/edit-ticket/${this.ceTicketService.ticket.Id}`]) :
                    null;
            })
    }

    public updateWorkOrder(workOrder: WorkOrdersView, routeToTicket?: boolean): void {
        let workOrderClone: WorkOrdersView = JSON.parse(JSON.stringify(workOrder));
        workOrderClone.ScheduledDate = workOrder.ScheduledDate === 'Invalid dateZ' ? null : workOrder.ScheduledDate;
        this.saveOps(workOrderClone);
        this.httpClient.put(`${environment.adminUrl}TicketsWorkOrders/${workOrder.Id}`, workOrderClone)
            .subscribe(() => {
                this.messageService.publish(Events.savingPreloader, 0);
                this.saving = false;
                if (routeToTicket) {
                    this.router.navigate([`/ticket-system/edit-ticket/${this.ceTicketService.ticket.Id}`])
                }
            })
    }

    public deletePropsBeforeSaving(workOrder: WorkOrdersView): void {
        delete workOrder.locationsList;
        delete workOrder.productTypesList;
        delete workOrder.Installer;
    }

    private saveOps(workOrder: WorkOrdersView): void {
        this.saving = true;
        this.deletePropsBeforeSaving(workOrder);
        this.messageService.publish(Events.savingPreloader, 1);
    }

    public validateZip(zip: string): void {
        this.utilService.validateZip(zip)
            .subscribe( (isValid: boolean) => {
                this.invalidZip = !isValid;
            },
            (err) => {
                this.invalidZip = true;
            })
    }
}
