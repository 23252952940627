<!--*****************************
	ANY VIDEO TYPE
******************************-->

<div class="col-sm-12 ft-size-md mgn-bottom4">
	<div class="{{ clip.backgroundColor }} border-radius pd-left15 pd-right15 pd-top10 pd-bottom10 mgn-top15">
		<div (click)="clip.detailsVisible = !clip.detailsVisible" class="row">
			<div class="col-sm-10">
				<i class="fas {{ clip.detailsVisible ? 'fa-chevron-down' : 'fa-chevron-right' }} mgn-right15"></i>
				<span class="ft-size16">{{ clip.Title }}</span>
			</div>
			<div class="col-sm-2 text-right">
				<i class="fas ft-size16 {{ clip.IsApproved ? 'fa-thumbs-up lime-green-text' : 'fa-thumbs-down red-text-light' }}"></i>
			</div>
		</div>
	</div>

	<!--*****************************
		CLIP DETAILS
	******************************-->
	<div *ngIf="clip.detailsVisible" class="border border-radius box-shadow pd15 mgn-bottom15">
		<!--*********************************************************
			UPLOAD MODAL (bypassed, see component for description)
		**********************************************************-->
		<hv-upload-modal
			*ngIf="fileUploadStateService.currentStep()?.name === 'uploadModal' && fileUploadStateService.itemId === clip.Id"
			class="hidden"
			[bypassModal]="true"
			[clip]="clip"
			[isVideoLogo]="isVideoLogo()"
		></hv-upload-modal>

		<div class="row mgn-top20 mgn-bottom20">
			<div class="col-sm-12">
				<h4 class="semi-bold mgn-bottom6 in-block">Title</h4>
				<i
					*ngIf="createEditRequestService.request.RequestType === 'Video-Logo'"
					class="fa fa-asterisk ft-size8 red-text-light absolute top0 left48"
					aria-hidden="true"
				></i>
				<input type="text" (keyup)="clipService.onKeyup('Title', clip)" [(ngModel)]="clip.Title" />
			</div>
		</div>
		<div class="row mgn-top20 mgn-bottom20">
			<div class="col-sm-12">
				<h4 class="semi-bold mgn-bottom6">Text To Be Used</h4>
				<textarea type="text" rows="5" (keyup)="clipService.onKeyup('ScriptText', clip)" [(ngModel)]="clip.ScriptText"></textarea>
			</div>
		</div>
		<div class="row mgn-top20 mgn-bottom20">
			<div class="col-sm-12">
				<h4 class="semi-bold mgn-bottom6">Similar Clips</h4>
				<textarea type="text" rows="4" (keyup)="clipService.onKeyup('SimilarClip', clip)" [(ngModel)]="clip.SimilarClip"></textarea>
			</div>
		</div>
		<div class="row mgn-top20 mgn-bottom20">
			<div class="col-sm-12">
				<h4 class="semi-bold mgn-bottom6">Description</h4>
				<textarea type="text" rows="5" (keyup)="clipService.onKeyup('Description', clip)" [(ngModel)]="clip.Description"></textarea>
			</div>
		</div>
		<div *ngIf="clip.Id" class="row mgn-top20 mgn-bottom20">
			<div class="col-sm-12">
				<h4 class="semi-bold mgn-bottom6">Completed File Name</h4>
				<input type="text" (keyup)="clipService.onKeyup('CompletedFilename', clip)" [(ngModel)]="clip.CompletedFilename" />
			</div>
		</div>

		<!--*****************************
			COMPLETED FILE UPLOADER
		******************************-->
		<div *ngIf="!clip.completedVideoFile?.ContentId && !clip.completedFileUploadInProgress && clip.Id" class="row relative mgn-top20 mgn-bottom20">
			<div class="col-xs-6">
				<h4 class="semi-bold">Select Resize Method</h4>
				<button
					class="{{ fileUploadStateService.uploadData.ResizeMethod === 'STRETCH' ? 'active' : null }} border-radius mgn-bottom6 white-bg setWidth75"
					(mouseup)="onResizeMethodClick('STRETCH')"
				>
					Stretch
				</button>
				<button
					class="{{ fileUploadStateService.uploadData.ResizeMethod === 'CENTER' ? 'active' : null }} border-radius mgn-bottom6 white-bg setWidth75"
					(mouseup)="onResizeMethodClick('CENTER')"
				>
					Center
				</button>
				<button
					class="{{ fileUploadStateService.uploadData.ResizeMethod === 'LETTERBOX' ? 'active' : null }} border-radius white-bg setWidth75"
					(mouseup)="onResizeMethodClick('LETTERBOX')"
				>
					Letterbox
				</button>
			</div>
			<div class="col-xs-6 text-right">
				<h4 class="semi-bold">Upload Completed File</h4>
				<portal-file-upload-drop-target
					[activeUploadSequence]="'contentRequestCompletedFileSequenceVideo'"
					[borderHidden]="false"
					[dropTargetHeight]="'ht100'"
					[itemId]="clip.Id"
					[paddingTop]="'pd-top20'"
				></portal-file-upload-drop-target>
				<em>100mb limit</em>
			</div>
		</div>

		<!--*****************************
			COMPLETED FILE PREVIEWER
		******************************-->
		<div *ngIf="clip.completedVideoFile?.thumbnailUrl && !clip.completedFileUploadInProgress" class="row relative mgn-top20 mgn-bottom20">
			<div class="col-sm-6">
				<portal-preview-thumbnail [content]="clip.completedVideoFile"></portal-preview-thumbnail>
			</div>
			<div class="col-xs-6 col-sm-3">
				<button class="border-radius red-bg-light white-text setWidth100" (mouseup)="onCompletedFileDeleteClick(clip)">Delete</button>
			</div>
			<div class="col-xs-6 col-sm-3">
				<input #replaceFileInput type="file" ng2FileSelect [uploader]="fileUploadStateService.uploader" class="hidden" (change)="onReplaceInputClick()" />
				<button class="border-radius yellow-bg setWidth100" (mouseup)="fileUploadStateService.uploaderInit()" (click)="replaceFileInput.click()">
					Replace
				</button>
			</div>
		</div>

		<!--***********************************
			COMPLETED FILE UPLOAD PROGRESS BAR
		************************************-->
		<div *ngIf="clip.completedFileUploadInProgress" class="row red-gradient shadow pd-top15 pd-bottom15 border-radius">
			<div class="col-xs-6 white-text">
				<span>
					<em>
						<span *ngIf="clipService.percentCompleteCompletedFile < 100">Upload</span>
						<span *ngIf="clipService.percentCompleteCompletedFile > 99">Encoding</span>
						in process...
					</em>
				</span>
				<portal-spinner [height]="'htpx18'" [width]="'wdthpx18'" class="absolute right15 top10"></portal-spinner>
				{{ clipService.percentCompleteCompletedFile }}%
			</div>
			<div class="col-xs-6 white-text">
				<span *ngIf="clip.processingState !== 'Ready'">
					<em>{{ clip.processingState }}</em>
				</span>
			</div>
		</div>
		<span *ngIf="clip.completedFileError" class="red-text-light"><em>Something went wrong, possibly a wrong file type.</em></span>

		<!--*****************************
			CLIP ATTACHMENTS
		******************************-->
		<div *ngIf="clip.Id" class="row mgn-top30 mgn-bottom20">
			<div class="col-sm-6 relative">
				<h4 class="semi-bold in-block mgn-bottom6 mgn-right10">Attachments</h4>
				<portal-file-upload-drop-target
					[activeUploadSequence]="'contentRequestClipAttachmentSequence'"
					[dropTargetHeight]="'ht50'"
					[itemId]="clip.Id"
					[paddingTop]="'pd-top2'"
				></portal-file-upload-drop-target>
			</div>
			<div *ngIf="clipService.percentCompleteAttachment > 0" class="col-sm-5 relative">
				<span><em>Uploading...</em></span>
				<span>{{ clipService.percentCompleteAttachment }}%</span>
			</div>
		</div>

		<!--*****************************
			ATTACHMENTS LOOP
		******************************-->
		<perfect-scrollbar *ngIf="clip.Id" style="height: 116px" [config]="{ suppressScrollX: true }">
			<div *ngFor="let attachment of clip?.attachments" style="width: 103%" class="row">
				<div class="col-sm-8">
					<h4 class="ellipsis ft-size14 {{ attachment.textColor }}">{{ attachment.FriendlyName }}</h4>
					<br />
				</div>
				<div class="col-sm-2 pd-right0 text-right">
					<a
						class="border-radius gray-bg dark-text absolute top0 rightneg8 pd-top8 pd-bottom6 pd-right10 pd-left10"
						href="{{ attachmentDownloadUrl }}"
						(mouseup)="onDownloadAttachmentClick(attachment)"
					>
						<i class="fa fa-download" aria-hidden="true"></i>
					</a>
				</div>
				<div class="col-sm-2 pd-left0 text-right">
					<button
						class="wdth33 ht34 red-bg-light pd-top8 pd-bottom6 pd-right10 pd-left10 white-text relative"
						(mouseup)="onAttachmentDeleteClick(attachment, clip)"
					>
						<i class="far fa-trash-alt absolute center-unknown-height-width" aria-hidden="true"></i>
					</button>
				</div>
			</div>
		</perfect-scrollbar>
		<div *ngIf="clip.Id" class="row mgn-top10">
			<div class="col-sm-4">
				<span *ngIf="clip.attachments?.length > 3">
					<i class="fa fa-angle-down" aria-hidden="true"></i>
					<em class="ft-size10 mgn-left6">scroll for more</em>
				</span>
			</div>
			<div class="col-sm-8 text-right">
				<span>Total clip attachments: {{ clip.attachments?.length }}</span>
			</div>
		</div>
		<hr *ngIf="clip.Id" class="gray-border" />

		<!--*****************************
			CLIP DELETE BUTTON
		******************************-->
		<div class="row mgn-top20">
			<div class="col-xs-2">
				<div *ngIf="clip.Id">
					<button
						class="relative red-bg-light white-text setWidth75"
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ appendTo: 'parent' }"
						data-tippy-content="Delete"
					>
						<i class="far fa-trash-alt" aria-hidden="true" (click)="onClipDeleteClick()"></i>
					</button>
				</div>
			</div>
			<div *ngIf="createEditRequestService.allowVideoApproval()" class="col-xs-4 col-xs-offset-6">
				<button (click)="onApproveClick()" class="{{ clip.IsApproved ? 'yellow-bg dark-text' : 'limeGreen-bg white-text' }}">
					{{ clip.IsApproved ? 'Unapprove' : 'Approve' }}
				</button>
			</div>
		</div>
	</div>
</div>
