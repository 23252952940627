import { AfterViewInit, Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AdminDataService, AppStateService, DateTimeService, SecurityService, UtilitiesService } from '../../core/services';
import { ClientDetailsService } from './client-details.service';
import { ClientHealthDashboardService } from 'src/app/system-tools/client-health-dashboard/client-health-dashboard.service';
import { ClientHealthViewService } from 'src/app/system-tools/client-health-dashboard/client-health-view.service';
import { ContactInformation, ProductTypes, Users } from '../../shared/api-models/admin';
import { GumballPlayers } from 'src/app/shared/api-models/reporting';
import { HistoryNotesService } from '../history-notes/history-notes.service';
import { LocationsService } from '../locations/locations.service';

@Component({
	selector: 'app-client-details',
	templateUrl: './client-details.component.html',
	styleUrls: ['./client-details.component.scss'],
	providers: [HistoryNotesService, LocationsService]
})
export class ClientDetailsComponent implements OnInit, AfterViewInit {
	@ViewChild('clientDetailsSection') clientDetailsSection: ElementRef<any>;

	public activeTab: string;
	public contractedProducts$: Observable<ProductTypes[]>;
	public heading: string;
	public lastLoginUser$: Observable<Users>;
	public mainContact$: Observable<ContactInformation>;
	public requestCount$: Observable<number>;
	public logos$: Observable<{ name: string; url: string }[]>;
	public showEmailTooltip: boolean;
	public showLastLoginTooltip: boolean;
	public showWebsiteTooltip: boolean;

	public playerHealthBoxHeight: number;
	private selectedPlayerCircleElem: any;

	get showCustomerLogos() {
		const { contractedProducts } = this.appStateService;
		const hasVideo = contractedProducts.some((product) => product.IsVideoProduct);
		return hasVideo;
	}

	constructor(
		private adminDataService: AdminDataService,
		public appStateService: AppStateService,
		private clientDetailsService: ClientDetailsService,
		public clientHealthDashboardService: ClientHealthDashboardService,
		public clientHealthViewService: ClientHealthViewService,
		public dateTimeService: DateTimeService,
		public locationsService: LocationsService,
		private router: Router,
		public securityService: SecurityService,
		public utilService: UtilitiesService
	) {}

	ngOnInit() {
		this.contractedProducts$ = this.clientDetailsService.getContractedProducts$();
		this.activeTab = this.utilService.getLastParam(this.router.routerState.snapshot.url);
		this.lastLoginUser$ = this.clientDetailsService.getLastLogin$();
		this.requestCount$ = this.clientDetailsService.getLastYearRequestCount$();
		if (this.showCustomerLogos) {
			this.logos$ = this.clientDetailsService.getLogoContentFiles$();
		}

		if (this.appStateService.currentClient.PrimaryContactId) {
			this.mainContact$ = this.clientDetailsService.getMainContactInfo$();
		}
		this.getCsrAndSalesRepInfo();
	}

	//Preserve position of dark details box on scroll
	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (this.clientHealthViewService.persistInfoBox) {
			this.selectedPlayerCircleElem = document.getElementById(JSON.stringify(this.clientHealthViewService.selectedCircle.PlayerId));
		}
		if (this.selectedPlayerCircleElem) {
			this.clientHealthViewService.darkInfoBoxTop = this.selectedPlayerCircleElem.getBoundingClientRect().top + 24;
		}
	}

	ngAfterViewInit(): void {
		this.clientHealthDashboardService.getPlayersByClient(this.appStateService.currentClient.Id);
	}

	ngAfterViewChecked() {
		this.playerHealthBoxHeight = this.clientDetailsSection.nativeElement.offsetHeight;
	}

	public onClientDashboardClick(): void {
		this.router.navigate([`client-health-dashboard/client-details/${this.appStateService.currentClient.Id}`]);
	}

	public onPlayerCircleHover(player: GumballPlayers): void {
		if (!this.clientHealthViewService.persistInfoBox) {
			this.clientHealthViewService.hoveredElement = document.getElementById(JSON.stringify(player.PlayerId));
			const isCrmView: boolean = true;
			this.clientHealthViewService.onCircleHover(player, isCrmView);
		}
	}

	public onTabBtnClick(str: string): void {
		this.activeTab = str;
		this.router.navigate([`/crm/client-details/${str}`]);
	}

	public onNewTicketClick(): void {
		window.open('/ticket-system/new-ticket', '_blank');
	}

	private getCsrAndSalesRepInfo(): void {
		this.adminDataService
			.getCsrAndSalesRepByClient(this.appStateService.currentClient.Id)
			.subscribe(([csrArray, salesRepArray]: [ContactInformation[], ContactInformation[]]) => {
				const [csr] = csrArray;
				const [salesperson] = salesRepArray;
				this.heading = `<span class="semi-bold">${this.appStateService.currentClient.Name}</span> | <em>(${csr.FirstName} ${csr.LastName}/${salesperson.FirstName} ${salesperson.LastName})</em>`;
			});
	}
}
