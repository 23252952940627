import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AppStateService } from '../../services/app-state.service';

@Injectable({
    providedIn: 'root'
})
export class ScreenLayoutsGuardService implements CanActivate {

    constructor(
        private appStateService: AppStateService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot) {
        if (this.appStateService.screenLayoutsActive) {
            return true;
        }
        this.router.navigate(['/access-denied']);
    }
}
