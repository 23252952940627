import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService, ContractGuardService, UserGuardService } from '../core/services/route-guards';
import { GroupManagerComponent } from './group-manager.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService, ContractGuardService, UserGuardService],
    component: GroupManagerComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GroupManagerRoutingModule { }
