import { Component, ElementRef, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';

import { AppStateService, AudioPreviewService, ContentSerializerService, TypeGuardService, UtilitiesService } from 'src/app/core/services';
import { ContentFilesHold } from '../../../../../shared/components/content-container/content/_models';
import { ContentItem } from '../../../../../shared/view-models/content';
import { ContentService, ContentFavoritesService } from '../../../../../shared/components/content-container/content/_services';
import { ContentViewStateService } from '../../../../../shared/services';
import { ContentViewService } from 'src/app/shared/components/content-container/content/_services';
import { PlaylistService } from 'src/app/playlist-builder/_services';
import { FeatureArea } from 'src/app/shared/view-models';
import { ContentType } from 'src/app/shared/view-models/content/content-type.enum';

@Component({
	selector: 'c-preview-modal',
	templateUrl: './preview-modal.component.html',
	styleUrls: ['./preview-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class PreviewModalComponent implements OnInit, OnDestroy {
	@Input() removeHeaderFooter: boolean;
	@Output() exitClickNotify: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('video') video: ElementRef;

	public currentTime: number;
	public currentTimeView: string;
	public duration: string;
	public favoriteBtnTooltip: string;
	public maxVideoHeight: number;
	public showControls: boolean;
	readonly featureArea = FeatureArea;

	get contentIsC24() {
		const { contentTypeId } = this.playlistService.selectedContentItem;
		return [
			ContentType.CREATE24_IMAGE,
			ContentType.CREATE24V4_IMAGE,
			ContentType.CREATE24V4_VIDEO,
			ContentType.CREATE24V4_PLACED_IMAGE,
			ContentType.VIDEO_WIDGET_CREATE24
		].includes(contentTypeId);
	}

	constructor(
		public appStateService: AppStateService,
		private audioPreviewService: AudioPreviewService,
		private contentSerializerService: ContentSerializerService,
		public contentService: ContentService,
		private contentFavoritesService: ContentFavoritesService,
		public contentViewService: ContentViewService,
		private cvStateService: ContentViewStateService,
		public playlistService: PlaylistService,
		private typeGuardService: TypeGuardService,
		public utilService: UtilitiesService
	) {}

	ngOnInit() {
		const { Route } = this.appStateService.product;
		this.maxVideoHeight = Route === 'poster' ? null : 745; //745

		//If loaded from Saved Playlists, content items do not yet have content files
		if ((this.playlistService.playlist?.isLoadedPlaylist && Route === 'hold') || this.contentIsC24) {
			const content = this.contentSerializerService.contentItemToContent(this.playlistService.selectedContentItem);
			this.contentService.getContentFiles$(content).subscribe((content) => {
				this.playlistService.selectedContentItem.contentFiles = this.contentSerializerService.contentToContentItem(content).contentFiles;
				this.contentViewService.setC24Props(this.playlistService.selectedContentItem);
				this.contentViewService.c24SVG = this.contentViewService.sanitizeC24Html(this.playlistService.selectedContentItem);
			});
		}
		this.setFavoriteBtnTooltip();
		this.contentService.getContentBreadcrumbs(this.playlistService.selectedContentItem.contentId).subscribe((breadcrumbs: string[]) => {
			this.playlistService.selectedContentItem.breadcrumbs = breadcrumbs;
		});
	}

	onPlay() {
		this.contentViewService.videoPlaying = true;
	}

	public setCurrentTime(time): void {
		this.currentTimeView = this.utilService.formatTime(Math.floor(time.target.currentTime));
		this.currentTime = time.target.currentTime;
		this.duration = this.utilService.formatTime(Math.floor(this.video?.nativeElement.duration));
	}

	public onPreviewHover(str: 'over' | 'out'): void {
		if (this.playlistService.selectedContentItem?.contentType !== 'image/png') {
			this.showControls = str === 'over' ? true : false;
		}
	}

	public onVideoEnd(): void {
		const svg: any = document.getElementById('c24Svg');
		if (svg.getCurrentTime() > 10) {
			svg.setCurrentTime(0);
			svg.pauseAnimations();
		}
	}

	public onVideoControlClick(button: string): void {
		const svg: any = document.getElementById('c24Svg');
		switch (button) {
			case 'back':
				this.video.nativeElement.currentTime = 0;
				break;
			case 'play':
				if (this.video.nativeElement.paused) {
					this.video.nativeElement.play();
					if (svg) {
						svg.unpauseAnimations();
					}
				} else {
					this.video.nativeElement.pause();
					if (svg) {
						svg.pauseAnimations();
					}
				}
				break;
		}
	}

	public backBtnVisible(): boolean {
		if (this.cvStateService.viewState?.activeModals) {
			return this.cvStateService.viewState.activeModals.length > 1;
		}
		return false;
	}

	public heightWidth(): string {
		return this.appStateService.product?.Route !== 'poster' ? 'setWidth100 setHeight100' : 'setWidth50';
	}

	public onBackClick(): void {
		const index = this.cvStateService.viewState.activeModals.findIndex((modalName) => modalName === 'playlist-item-preview');
		this.cvStateService.viewState.activeModals.splice(index, 1);
	}

	public onFavoriteClick(): void {
		this.playlistService.selectedContentItem.Id = this.playlistService.selectedContentItem.contentId;
		this.contentFavoritesService.onFavoriteClick(this.playlistService.selectedContentItem);
	}

	public onSilenceMusicClick(): void {
		this.playlistService.selectedContentItem.silenceMusic = !this.playlistService.selectedContentItem.silenceMusic;
	}

	public onTalentPreviewClick(contentFile: ContentFilesHold): void {
		this.audioPreviewService.contentItemAudioPreview(this.audioPreviewArgs(contentFile).selectedContentItem, this.audioPreviewArgs(contentFile).contentFiles);
		contentFile.previewActive = !contentFile.previewActive;
	}

	private audioPreviewArgs(contentFile: ContentFilesHold): { selectedContentItem: ContentItem; contentFiles: ContentFilesHold[] } {
		const contentFiles = this.playlistService.selectedContentItem.contentFiles;
		return {
			selectedContentItem: this.contentSerializerService.contentFileToContentItem(
				this.contentSerializerService.contentItemToContent(this.playlistService.selectedContentItem),
				contentFile
			),
			contentFiles: this.typeGuardService.filesAreHold(contentFiles) ? contentFiles : null
		};
	}

	private setFavoriteBtnTooltip(): void {
		this.favoriteBtnTooltip = this.playlistService.selectedContentItem.isFavorite ? 'Remove from favorites' : 'Add to favorites';
	}

	ngOnDestroy() {
		const contentFiles = this.playlistService.selectedContentItem.contentFiles;
		this.contentViewService.c24SVG = null;
		if (this.typeGuardService.filesAreHold(contentFiles))
			contentFiles.forEach((contentFile) => {
				contentFile.previewActive = false;
				if (contentFile.audioPreview) {
					contentFile.audioPreview.pause();
				}
			});
	}
}
