<div class="container-fluid {{ applyContainerClassList() ? containerClassList : null }}">
	<!--NEW REQUEST-->
	<div *ngIf="btnsView === 'cancelSave'" class="row">
		<div class="col-sm-6">
			<button (click)="onCancelClick()" class="gray-bg">Cancel</button>
		</div>
		<div class="col-sm-6">
			<button (click)="onSaveNewRequestClick()" [disabled]="!masterButtonsService.formValid()" class="limeGreen-bg white-text">Save</button>
		</div>
	</div>

	<!--STATE NAME IS 'PENDING SCRIPT APPROVAL'-->
	<div *ngIf="createEditRequestService.request.StateName === stateName.PENDING_SCRIPT_APPROVAL" class="row mgn-bottom60">
		<div class="col-sm-6">
			<button [disabled]="createEditRequestService.request.approvalInstructionsVisible" (click)="onRequestRevisionsClick()" class="purple-bg white-text">
				Request script revisions
			</button>
		</div>
		<div class="col-sm-6">
			<button [disabled]="createEditRequestService.request.approvalInstructionsVisible" (click)="onApproveScriptsClick()" class="limeGreen-bg white-text">
				Approve scripts
			</button>
		</div>
	</div>

	<!--STATE NAME IS 'PENDING EDITOR APPROVAL' (VIDEO ONLY)-->
	<div *ngIf="createEditRequestService.request.StateName === stateName.PENDING_EDITOR_APPROVAL" class="row mgn-bottom60">
		<div class="col-sm-6">
			<button (click)="onRequestVideoRevisionsClick()" class="purple-bg white-text">Request revisions</button>
		</div>
		<div class="col-sm-6">
			<button (click)="changeStatusAndSave(stateName.PENDING_CSR_APPROVAL)" class="limeGreen-bg white-text">Approve</button>
		</div>
	</div>

	<!--EMPLOYEE ONLY VIEW AND CURRENT USER IS NOT A CONTENT CREATOR AND NOT A NEW REQUEST-->
	<div *ngIf="btnsView === 'cancelCloneSaveAsComplete'" class="row">
		<div class="{{ createEditRequestService.request.StateName === stateName.COMPLETE ? 'col-xs-6' : 'col-xs-4' }}">
			<button
				[disabled]="createEditRequestService.request.StateName === stateName.CANCELLED"
				class="ft-size14 red-bg-light white-text"
				(mouseup)="onCancelRequestClick()"
			>
				Cancel request
			</button>
		</div>
		<div class="{{ createEditRequestService.request.StateName === stateName.COMPLETE ? 'col-xs-6' : 'col-xs-4' }}">
			<button class="blue-bg white-text ft-size14" (click)="onCloneClick()">Clone</button>
		</div>
		<div *ngIf="createEditRequestService.request.StateName !== stateName.COMPLETE" class="col-xs-4">
			<button
				[disabled]="
					createEditRequestService.request.StateName !== stateName.PENDING_CLIENT_APPROVAL &&
					createEditRequestService.request.StateName !== stateName.PENDING_CSR_APPROVAL
				"
				class="yellow-bg ft-size14"
				(click)="onSaveAsCompleteClick()"
			>
				Save as complete
			</button>
		</div>
	</div>

	<!--ON HOLD, EMPLOYEE ONLY VIEW AND CURRENT USER IS AN AUDIO CONTENT CREATOR -->
	<ng-container *ngIf="createEditRequestService.request.RequestType === 'On-Hold Message'">
		<div *ngIf="viewStateService.route !== 'content-requests' && appStateService.currentUser.Role === 'Audio Content Creator'" class="row mgn-top60">
			<div class="col-sm-12">
				<button class="yellow-bg" (click)="changeStatusAndSave(stateName.PENDING_CSR_APPROVAL)">Save As Pending CSR Approval</button>
			</div>
		</div>
	</ng-container>

	<!--VIDEO, EMPLOYEE ONLY VIEW AND CURRENT USER IS A VIDEO CONTENT CREATOR -->
	<ng-container *ngIf="createEditRequestService.allowVideoApproval() && masterButtonsService.showPendingEditorApprovalBtn()">
		<div class="row mgn-top60">
			<div class="col-sm-12">
				<button class="yellow-bg" (click)="changeStatusAndSave(stateName.PENDING_EDITOR_APPROVAL)">Save As Pending Editor Approval</button>
			</div>
		</div>
	</ng-container>

	<div
		*ngIf="
			viewStateService.route !== 'content-requests' &&
			createEditRequestService.request.RequestType !== 'On-Hold Message' &&
			(createEditRequestService.request.StateName === stateName.PENDING_CSR_APPROVAL || createEditRequestService.request.StateName === stateName.COMPLETE) &&
			(appStateService.currentUser.Role === 'Video Content Creator' ||
				appStateService.currentUser.Role === 'CSR' ||
				appStateService.currentUser.Role === 'Administrator')
		"
		class="row mgn-top60"
	>
		<div class="col-sm-12">
			<button class="purple-bg white-text" (click)="onRequestVideoRevisionsClick()">Request revisions</button>
		</div>
	</div>
</div>
