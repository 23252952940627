import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStateService, DateTimeService, Events, MessageService, SecurityService, UtilitiesService } from 'src/app/core/services';
import { ClipsView } from '../_models';
import { CreateEditRequestService, CreateEditViewStateService } from '../_services';
import { environment } from '../../../../environments/environment';
import { ContentRequestStateName } from 'src/app/shared/api-models/admin';

@Injectable({
	providedIn: 'root'
})
export class ClipsService {
	public newClip: ClipsView;

	constructor(
		private appStateService: AppStateService,
		private createEditRequestService: CreateEditRequestService,
		private dateTimeService: DateTimeService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private securityService: SecurityService,
		private utilService: UtilitiesService,
		private viewStateService: CreateEditViewStateService
	) {}

	public getClips(): Observable<any> {
		if (this.createEditRequestService.request.Id) {
			return this.httpClient.get(`${environment.adminUrl}ContentRequests/${this.createEditRequestService.request.Id}/Clips`).pipe(
				map((clips: ClipsView[]) => {
					//Only sort video clips
					if (this.createEditRequestService.request.RequestType !== 'On-Hold Message') {
						this.createEditRequestService.request.clips = this.utilService.sortItems(clips, 'Title');
						return;
					}
					this.createEditRequestService.request.clips = clips;
				})
			);
		}
		return of([]);
	}

	public updateAllClipsToIsApproved(): void {
		const clips$ = this.createEditRequestService.request.clips.map((clip) => {
			clip.IsApproved = true;
			return this.httpClient.put(`${environment.adminUrl}ContentRequestClips/${clip.Id}`, clip);
		});

		forkJoin(clips$).subscribe();
	}

	public newClipInit(): void {
		if (this.createEditRequestService.request.Id) {
			this.newClip.ContentRequestId = this.createEditRequestService.request.Id;
		}
		this.newClip.IsApproved = false;
	}

	public saveNewClip(clip: ClipsView): void {
		this.messageService.publish(Events.savingPreloader, 1);
		clip.ContentRequestId = this.createEditRequestService.request.Id;
		this.httpClient.post(`${environment.adminUrl}ContentRequestClips`, clip).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	public pendingScriptApprovalClientView(): boolean {
		return (
			this.createEditRequestService.request.StateName === ContentRequestStateName.PENDING_SCRIPT_APPROVAL && this.viewStateService.route === 'content-requests'
		);
	}

	public showApprovalInstructionsCheck(): void {
		this.appStateService.getUserItem('approvalInstructionsVisible').subscribe((approvalInstructionsVisible: string) => {
			if (approvalInstructionsVisible) {
				this.createEditRequestService.request.approvalInstructionsVisible = JSON.parse(approvalInstructionsVisible);
				this.createEditRequestService.request.showInstructionsBtnVisible = !JSON.parse(approvalInstructionsVisible);
			} else {
				this.createEditRequestService.request.approvalInstructionsVisible = true;
				this.createEditRequestService.request.showInstructionsBtnVisible = false;
			}
		});
	}

	public buildContentString(contentString: string): string {
		this.createEditRequestService.request.clips.forEach((clip, index) => {
			contentString = contentString.concat(`<h3><b>
                                                ${index + 1}. ${clip.Title}</b>
                                              </h3>
                                              <span style="line-height: 30px; font-size: 22px;">${clip.ScriptText}</span>
                                              <hr style="border: 0.5px solid #d6d9db; margin-top: 25px; margin-bottom: 0px">
                                              <br>`);
		});

		return contentString;
	}

	public htmlString(contentString: string): string {
		let htmlString: string;
		const request = this.createEditRequestService.request;
		htmlString = `
                      <html>
                          <head>
                              <title>${request.Title}</title>
                          </head>
                         <body>
                             <span><strong>Client Name: </strong></span><span>${request.clientName}</span><br>                       
                             <span><strong>Target Library: </strong></span><span>${request.targetLibraryName}</span><br>
                             <span><strong>Request Title: </strong></span><span>${request.Title}</span><br>
                             <span><strong>Request ID: </strong></span><span>${request.Id}</span><br>
                             <span><strong>Created Date: </strong></span><span>${this.dateTimeService.dateAndTimeLocal(request.CreateDateTime)}</span><br>
                             <span><strong>Due Date: </strong></span><span>${this.dateTimeService.dateAndTimeLocal(request.DueDate)}</span><br>
                             <span><strong>Voice Talent: </strong></span><span>${request.VoiceTalent}</span><br>
                             <span><strong>CSR: </strong></span><span>${request.csrUsername}</span><br>
                             <span><strong>Notes: </strong></span><span>${request.Description}</span><br>

                             <div style="font-family:Arial,sans-serif; white-space: pre-line;">${contentString}</div>
                         </body>
                      </html>
            `;
		return htmlString;
	}

	//Print button for audio content creator to print scripts
	public showPrintBtn(): boolean {
		return (
			this.createEditRequestService.request.RequestType === 'On-Hold Message' &&
			this.viewStateService.route !== 'content-requests' &&
			this.securityService.userRole(this.appStateService.currentUser.Role) === 'contentcreator'
		);
	}
}
