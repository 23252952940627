import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators';

import { AppStateService, SecurityService } from '../../core/services';
import { ContentRequestStateName, ContentRequestStates, ContentRequestType } from '../../shared/api-models/admin';
import { ContentRequestTable } from '../_models';
import { environment } from '../../../environments/environment';
import { TableViewStateService } from './table-view-state.service';

@Injectable({
	providedIn: 'root'
})
export class ContentRequestsService {
	public requests: ContentRequestTable[];
	public requestStates$: Observable<ContentRequestStates[]>;
	public requestTypes$: Observable<ContentRequestType[]>;
	public scrollY: number;
	public spinnerActive: boolean;

	constructor(
		private appStateService: AppStateService,
		private httpClient: HttpClient,
		private securityService: SecurityService,
		private viewStateService: TableViewStateService
	) {}

	public getRequestTypes(): Observable<ContentRequestType[]> {
		if (!this.requestTypes$) {
			return this.httpClient.get<ContentRequestType[]>(`${environment.adminUrl}ContentRequestRequestTypes`).pipe(publishReplay(1), refCount());
		}
		return this.requestTypes$;
	}

	public getRequestStates(): Observable<ContentRequestStates[]> {
		if (!this.requestStates$) {
			return this.httpClient.get<ContentRequestStates[]>(`${environment.adminUrl}ContentRequestStates`).pipe(
				publishReplay(1), // tells Rx to cache the latest emitted
				refCount(), // tells Rx to keep the Observable alive as long as there are any Subscribers
				map((requestStates) => {
					const requestState: any = {};
					requestState['Name'] = 'All';
					if (requestStates[0].Name !== 'All') {
						requestStates.unshift(requestState);
					}
					return requestStates;
				})
			);
		}
		return this.requestStates$;
	}

	public getRequests(getUserItem?: boolean): Observable<any> {
		if (getUserItem) {
			return this.appStateService.getUserItem('filterByRequestType').pipe(
				switchMap((filterByRequestType) => {
					if (filterByRequestType) {
						this.viewStateService.filterByRequestType = JSON.parse(filterByRequestType);
					}
					this.viewStateService.setTable(this.viewStateService.filterByRequestType);
					return this.requests$();
				})
			);
		}
		return this.requests$();
	}

	private requests$(): Observable<ContentRequestTable[]> {
		this.spinnerActive = true;
		return this.httpClient.get(this.url(), { observe: 'response' }).pipe(
			switchMap((response: HttpResponse<ContentRequestTable[]>) => {
				const requests: ContentRequestTable[] = response.body;
				const userRole = this.appStateService.currentUser.Role;
				const { route } = this.viewStateService;
				this.viewStateService.totalItems = parseInt(response.headers.get('x-result-count'));
				this.spinnerActive = false;
				if (requests.length < 1) {
					this.requests = [];
					return of([]);
				}

				return of(
					requests.map((request) => {
						request.classList = 'white-bg t-row pd-top10 pd-bottom10';
						request.viewBtn = 'View';
						request.clientStatusView = this.clientStatus(request.StateName);
						if (userRole !== 'Video Content Contractor') {
							route !== 'content-requests' ? (request.rowBgColor = this.rowBgColor(request.RequestType, request.StateName)) : null;
						}
						return request;
					})
				);
			})
		);
	}

	// private requestsView(requests: ContentRequestTable[], clips: ContentRequestClips[][]): ContentRequestTable[] {
	// 	return requests.map((request, index) => {
	// 		request.clipCount = clips[index].length;
	// 		request['classList'] = 'white-bg t-row pd-top10 pd-bottom10';
	// 		request.CreateDateTime = request.ContentRequest.CreateDateTime;
	// 		request.DueDate = request.ContentRequest.DueDate;
	// 		request.StateName = request.ContentRequest.StateName;
	// 		request.approvedDate = this.approvedDate(request);
	// 		request.viewBtn = 'View';
	// 		request.clientStatusView = this.clientStatus(request.ContentRequest.StateName);
	// 		if (this.appStateService.currentUser.Role !== 'Video Content Contractor') {
	// 			this.viewStateService.route !== 'content-requests'
	// 				? (request.rowBgColor = this.rowBgColor(request.ContentRequest.RequestType, request.ContentRequest.StateName))
	// 				: null;
	// 		}
	// 		return request;
	// 	});
	// }

	public clientStatus(stateName: ContentRequestStateName): ContentRequestStateName {
		switch (stateName) {
			case ContentRequestStateName.AWAITING_SCRIPT_EDITS:
			case ContentRequestStateName.PENDING_SCRIPT_APPROVAL:
			case ContentRequestStateName.COMPLETE:
			case ContentRequestStateName.CANCELLED:
				return stateName;
			default:
				return ContentRequestStateName.IN_PRODUCTION;
		}
	}

	// private approvedDate(request: ContentRequestTable): any {
	// 	if (request.ContentRequest.ApprovedDateTime) {
	// 		return this.dateTimeService.dateLocal(request.ContentRequest.ApprovedDateTime);
	// 	} else {
	// 		return 'N/A';
	// 	}
	// }

	private rowBgColor(requestType: string, status: string): string {
		if (status === 'Cancelled' || status === 'Merged') {
			return 'gray-bg-darker';
		}
		if (requestType === 'On-Hold Message') {
			return 'blue-bg-light-theme';
		}
		return 'red-bg-light-theme';
	}

	public url(): string {
		//Thomas wants all results on one page
		const userIsAudioCreator: boolean = this.appStateService.currentUser.Role === 'Audio Content Creator';
		const userRole = this.securityService.userRole(this.appStateService.currentUser.Role);
		const stateParam = this.stateParam();
		const clientId = this.appStateService.currentClient.Id;
		const userId = this.appStateService.currentUser.UserId;
		const {
			searchTerm,
			pageTitleSnippet,
			orderByCol,
			filterByStatus,
			pageNumber,
			filterByRequestType,
			route,
			urlString,
			orderDesc,
			filterByTargetLibrary,
			workflowFilter
		} = this.viewStateService;
		if (searchTerm.length < 1) {
			if (route === 'system-tools') {
				switch (true) {
					case pageTitleSnippet.includes('My Active'):
						return `${environment.adminUrl}ContentRequests/${urlString}/${userRole}/${userId}?orderByCol=${orderByCol}&orderDesc=${orderDesc}&pageSize=${
							userIsAudioCreator ? 500 : 50
						}&pageNumber=${pageNumber}&requestType=${filterByRequestType}&targetLibrary=${filterByTargetLibrary}&state=${filterByStatus}`;
					case pageTitleSnippet.includes('My Completed'):
						return `${environment.adminUrl}ContentRequests/${urlString}/${userRole}/${userId}?orderByCol=${orderByCol}&orderDesc=${orderDesc}&pageSize=${
							userIsAudioCreator ? 500 : 50
						}&pageNumber=${pageNumber}&requestType=${filterByRequestType}&targetLibrary=${filterByTargetLibrary}&state=${filterByStatus}`;

					case pageTitleSnippet.includes('All Open'):
					case pageTitleSnippet.includes('All Assigned'):
					case pageTitleSnippet.includes('All Unassigned'):
						return `${environment.adminUrl}ContentRequests/${urlString}?orderByCol=${orderByCol}&orderDesc=${orderDesc}&pageSize=${
							userIsAudioCreator ? 500 : 50
						}&pageNumber=${pageNumber}&requestType=${filterByRequestType}&targetLibrary=${filterByTargetLibrary}&state=${filterByStatus}`;
					default:
						return `${environment.adminUrl}ContentRequests/View?orderByCol=${orderByCol}&orderDesc=${orderDesc}&pageSize=${
							userIsAudioCreator ? 500 : 50
						}&pageNumber=${pageNumber}&requestType=${filterByRequestType}&targetLibrary=${filterByTargetLibrary}${stateParam}`;
				}
			} else if (route === 'client-details') {
				return `${environment.adminUrl}ContentRequests/ClientView/${clientId}?orderByCol=${orderByCol}&orderDesc=${orderDesc}&pageSize=50&pageNumber=${pageNumber}&requestType=${filterByRequestType}&targetLibrary=${filterByTargetLibrary}${stateParam}`;
			} else if (route === 'content-requests') {
				return `${environment.adminUrl}ContentRequests/ClientView/${clientId}/${workflowFilter}?orderByCol=${orderByCol}&orderDesc=${orderDesc}&pageSize=50&pageNumber=${pageNumber}&requestType=${filterByRequestType}&targetLibrary=${filterByTargetLibrary}${stateParam}`;
			}
		} else if (route === 'content-requests') {
			return `${environment.adminUrl}ContentRequests/ClientView/${clientId}/Search/${searchTerm}/${workflowFilter}?orderByCol=${orderByCol}&orderDesc=${orderDesc}&pageSize=50&pageNumber=${pageNumber}`;
		} else {
			return `${environment.adminUrl}ContentRequests/SearchView/${searchTerm}?orderByCol=${orderByCol}&orderDesc=${orderDesc}&pageSize=50&pageNumber=${pageNumber}&requestType=${filterByRequestType}&targetLibrary=${filterByTargetLibrary}${stateParam}`;
		}
	}

	private stateParam(): string {
		const { filterByStatus } = this.viewStateService;
		if (filterByStatus === 'All') {
			return '';
		} else {
			return `&state=${filterByStatus}`;
		}
	}

	public setOrderDesc(): void {
		if (this.appStateService.currentUser.Role === 'Audio Content Creator') {
			this.viewStateService.orderDesc = false;
		} else {
			this.viewStateService.orderDesc = true;
		}
	}

	public setPageTitle(pageTitleSnippet: string): void {
		if (this.viewStateService.filterByRequestType && this.viewStateService.filterByRequestType !== 'All') {
			this.viewStateService.pageTitle = `${pageTitleSnippet} ${this.filterByTargetLibraryTitleSnippet()} ${this.viewStateService.filterByRequestType} Requests`;
		} else {
			this.viewStateService.pageTitle = `${pageTitleSnippet} Requests`;
		}
	}

	private filterByTargetLibraryTitleSnippet(): string {
		if (this.viewStateService.filterByTargetLibrary && this.viewStateService.filterByTargetLibrary !== 'All') {
			return this.viewStateService.filterByTargetLibrary;
		}
		return '';
	}
}
