import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { SecurityService } from '../security.service';

@Injectable({
    providedIn: 'root'
})
export class AdminOrCsrGuardService implements CanActivate {

    constructor(
            private router: Router,
            private securityService: SecurityService) { }

    canActivate(route: ActivatedRouteSnapshot) {
        if (this.securityService.userRoleAdminOrCsr()) {
            return true;
        } else {
            this.router.navigate(['/access-denied']);
        }
    }
}
