import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppStateService, BrowserDetectorService } from '../core/services';
import { DragDropLeftRightService } from '../shared/components/drag-drop-left-right/drag-drop-left-right.service';
import { LogoOverlayService, ViewStateService } from './_services';
import { PlayersView } from './_models';
import { SearchFilterPipe } from '../shared/pipes/search-filter.pipe';

@Component({
    selector: 'app-logo-overlay',
    templateUrl: './logo-overlay.component.html',
    styleUrls: ['./logo-overlay.component.scss'],
    providers: [DragDropLeftRightService, LogoOverlayService, SearchFilterPipe, ViewStateService]
})
export class LogoOverlayComponent implements OnInit, OnDestroy {

    public backBtnUrl: string;
    private subs: Subscription[] = [];

    constructor(
        private appStateService: AppStateService,
        public browserDetectorService: BrowserDetectorService,
        public dragDropLeftRightService: DragDropLeftRightService,
        private logoOverlayService: LogoOverlayService,
        private router: Router,
        public viewStateService: ViewStateService
    ) {
        this.onRemovePlayer();
     }

    ngOnInit() {
        this.backBtnUrl = `/my-players/${this.appStateService.product}`;
        this.appStateService.product.backgroundColor = 'red-bg';
        this.appStateService.product.ProductName = 'Logo Overlay';
        this.appStateService.product.DashIcon = 'fas fa-leaf';
        this.viewStateService.currentStep = this.viewStateService.steps[0];
        this.getPlayers();
    }

    public onNextBtnClick(): void {
        if(this.viewStateService.currentStep.number === 1) {
            this.viewStateService.advanceOneStep();
        } else if(this.viewStateService.currentStep.number === 2) {
            this.logoOverlayService.save();
            this.router.navigate(['/my-products']);
        }
    }

    private getPlayers(): void {
        this.logoOverlayService.getPlayers$()
            .subscribe( (players) => {
                const firstPlayerFound = players.find(player => player.logoPosition !== 0);

                //Look for any player with a logo turned on. They will all be the same, so grabbing any player 
                //to set the logo position in the view is fine.
                if(firstPlayerFound) {
                    this.logoOverlayService.logoPosition = players.find(player => player.logoPosition !== 0).logoPosition;
                } else {
                    //If all players have logo turned off, default to 1 (top left)
                    this.logoOverlayService.logoPosition = 1;    
                }
                
                //Set drag/drop available players view
                this.dragDropLeftRightService.availableItems = players.filter(player => this.logoOff(player));
                this.dragDropLeftRightService.availableItemsView = players.filter(player => this.logoOff(player));

                //Set drag/drop assigned players view
                this.dragDropLeftRightService.assignedItems = players.filter(player => this.logoOn(player));
                this.dragDropLeftRightService.assignedItemsView = players.filter(player => this.logoOn(player));
            })
    }

    private logoOff(player: PlayersView): boolean {
        return player.logoPosition === 0;
    }

    private logoOn(player: PlayersView): boolean {
        return player.logoPosition !== 0;
    }

    public onCancelClick(): void {
        let confirmOk: boolean = confirm(`Disregard changes?`);
        if(confirmOk) {
            this.router.navigate(['/my-products']);
        }
    }

    onRemovePlayer(): void {
        this.subs.push(this.dragDropLeftRightService.onRemoveItem$.subscribe((player: PlayersView) => {
            this.dragDropLeftRightService.availableItems.unshift(this.dragDropLeftRightService.assignedItems.splice(this.dragDropLeftRightService.assignedItemIndex, 1)[0]);
            this.dragDropLeftRightService.availableItemsView.unshift(this.dragDropLeftRightService.assignedItemsView.splice(this.dragDropLeftRightService.assignedItemViewIndex, 1)[0]);
        }))
    }

    public onRemoveFromAllPlayersClick(): void {
        const confirmOk: boolean = confirm(`This will remove the logo overlay from all players. Click "OK" to proceed.`);
        if(confirmOk) {
            //Move all assigned players to available array
            this.dragDropLeftRightService.assignedItems.forEach( (player: PlayersView, index) => {
                this.dragDropLeftRightService.availableItems.push(player);
            })
            this.dragDropLeftRightService.assignedItems.length = 0;
            this.logoOverlayService.save();
            this.router.navigate(['/my-products']);
        }
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
    }
}
