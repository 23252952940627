import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from '../app-state.service';
import { AuthUser } from '../../../shared/api-models/auth/auth-user';

export const allowCreate24Guard = () => {
	const appStateService = inject(AppStateService);
	const router = inject(Router);

	const currentUser: AuthUser = JSON.parse(appStateService.getSessionItem('currentUser'));

	const allowCreate24 = () => currentUser.Role !== 'User';

	if (allowCreate24()) {
		return true;
	} else {
		router.navigate(['/access-denied']);
		return false;
	}
};
