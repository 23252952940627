import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import {
	AppStateService,
	AudioPreviewService,
	ContentSerializerService,
	Events,
	MessageService,
	TypeGuardService,
	UtilitiesService
} from '../../../../core/services';
import { Content, ContentFiles } from '../../../api-models/content';
import { ContentViewState } from 'src/app/shared/view-models';
import { ContentViewStateService } from 'src/app/shared/services';
import { Hold, Video } from './_models';
import { ContentService, ContentViewService } from './_services';
import { environment } from '../../../../../environments/environment';
import { WizardService } from 'src/app/playlist-builder/_services';

@Component({
	selector: 'c-content',
	templateUrl: './content.component.html',
	styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnChanges, OnDestroy {
	@Input() bypassGetContentFiles: boolean;
	@Input() content: Hold | Video;
	@Input() contentList: (Hold | Video)[];
	@Input() contentViewState: ContentViewState;
	@Input() isEvenIndex: boolean;
	@Input() isFirstIndex: boolean;
	@Input() isLastIndex: boolean;

	public canUseInPlaylist: boolean;
	private subs: Subscription[] = [];

	constructor(
		private appStateService: AppStateService,
		private audioPreviewService: AudioPreviewService,
		public contentService: ContentService,
		private contentSerializerService: ContentSerializerService,
		public contentViewService: ContentViewService,
		private cvStateService: ContentViewStateService,
		private messageService: MessageService,
		private typeGuardService: TypeGuardService,
		private utilService: UtilitiesService,
		public wizardService: WizardService
	) {
		this.contentFileStateUpdateSubscribe();
		this.onAddMessageOrPlayClickSubscribe();
	}

	ngOnInit() {
		if (
			this.cvStateService.viewState.treeSelection === 'featured-playlist' &&
			this.appStateService.product.Route === 'hold' &&
			this.wizardService.currentStep().name !== 'choose-music'
		) {
			this.bypassGetContentFiles = true;
		}
		this.contentViewService.viewState = this.contentViewState;
		if (!this.bypassGetContentFiles) {
			this.contentService.getContentFilesLight$(this.content).subscribe(() => {
				this.canUseInPlaylist = this.contentViewService.canUse(this.content);
			});
		}
	}

	ngOnChanges() {
		this.contentViewService.viewState = this.contentViewState;
	}

	onAddMessageOrPlayClickSubscribe(): void {
		this.subs.push(
			this.contentViewService.onAddMessageOrPlayClick$.subscribe((payload: [Content, 'talentPreviewSelectorVisible' | 'talentPickerVisible']) => {
				let selectedContent: Content = payload[0];
				let prop = payload[1];
				if (this.content.Id === selectedContent.Id) {
					//toggle this talent select box
					selectedContent[prop] = !selectedContent[prop];

					//Close open talent select box when clicking on a different content item
					this.contentList.filter((content) => content.Id !== selectedContent.Id).forEach((filteredContent) => (filteredContent[prop] = false));
				}
			})
		);
	}

	contentFileStateUpdateSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.contentFileStateUpdate, (uploadedContentFile: ContentFiles) => {
				if (this.content.Id === uploadedContentFile.ContentId) {
					//look through this content's content files, find the one recently uploaded
					let classContentFile: ContentFiles = this.content.contentFiles.find((contentFile) => contentFile.Id === uploadedContentFile.Id);
					//as soon as contentFileStateId is 4, update class prop so we can use the file in playlist
					classContentFile.ContentFileStateId = uploadedContentFile.ContentFileStateId;

					if (this.typeGuardService.contentIsVideo(this.content)) {
						//set freshly generated thumbnail, icon, and preview url
						this.content.thumbnailUrl = environment.contentUrl + 'File/' + this.contentViewService.thumbnailString() + uploadedContentFile.Id + '/Thumbnail';
						this.content.thumbIcon = this.contentSerializerService.icon(this.content);
						this.content.previewUrl = environment.contentUrl + 'File/Video/' + this.content.contentFiles[0].Id + '/Preview';
						this.content.contentType = this.contentSerializerService.contentTypeIdToContentType(this.content.ContentTypeId);
					}
					this.canUseInPlaylist = this.contentViewService.canUse(this.content);
					uploadedContentFile.ProcessingStateJson ? (this.content.processingStateJson = JSON.parse(uploadedContentFile.ProcessingStateJson).Message) : null;
				}
			})
		);
	}

	public autoCloseTalentBoxes(event) {
		if (!this.utilService.closest(event, '.noExit')) {
			this.contentList?.forEach((content) => {
				if (this.typeGuardService.contentIsHold(content)) {
					content.talentPickerVisible = false;
					content.talentPreviewSelectorVisible = false;
				}
			});
			this.stopCurrentPreview();
		}
	}

	private stopCurrentPreview(): void {
		if (this.typeGuardService.filesAreHold(this.content.contentFiles)) {
			this.audioPreviewService.stopCurrentPreview(this.contentSerializerService.contentToContentItem(this.content), this.content.contentFiles);
		}
	}

	ngOnDestroy() {
		this.stopCurrentPreview();
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
