import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

import { AppStateService, DateTimeService } from '../../core/services';
import { CreateEditPlayerService, PlayerOptionsService, ViewService } from './_services';
import { ServiceLocations, PlayerModels } from '../../shared/api-models/admin';
import { PlayersView } from './_models';
import { ProductRoute } from 'src/app/shared/api-models/admin/product-route.enum';

@Component({
	selector: 'ld-create-edit-player',
	templateUrl: './create-edit-player.component.html',
	styleUrls: ['./create-edit-player.component.scss']
})
export class CreateEditPlayerComponent implements OnInit, OnDestroy {
	public productIcon: string;
	public title: string;
	private locationId: number | null;

	constructor(
		private activatedRoute: ActivatedRoute,
		private appStateService: AppStateService,
		public createEditPlayerService: CreateEditPlayerService,
		private dateTimeService: DateTimeService,
		public location: Location,
		private playerOptionsService: PlayerOptionsService,
		private router: Router,
		public viewService: ViewService
	) {}

	ngOnInit() {
		const url: string = this.router.routerState.snapshot.url;
		this.locationId = this.appStateService.getSessionItem('locationId') ? JSON.parse(this.appStateService.getSessionItem('locationId')) : null;
		const playerId: number | null = this.appStateService.getSessionItem('playerId') ? JSON.parse(this.appStateService.getSessionItem('playerId')) : null;

		if (url.includes('edit')) {
			this.createEditPlayerService.viewState = 'edit';
			this.getPlayer(playerId);
			this.viewService.ipConfigDisabled = true;
			this.viewService.hostnameDisabled = true;
		} else if (url.includes('new')) {
			this.createEditPlayerService.viewState = 'new';
			this.activatedRoute.params.subscribe(({ productRoute }: { productRoute: ProductRoute }) => {
				this.getLocation(this.locationId, productRoute);
			});
		}
	}

	canDeactivate() {
		if (this.createEditPlayerService.viewState === 'edit' && this.viewService.formChanged) {
			const confirmOk: boolean = confirm(`You have unsaved changes! Leave without saving?`);
			if (confirmOk) {
				return true;
			}
			return false;
		}
		return true;
	}

	private getPlayer(playerId: number): void {
		this.createEditPlayerService.getPlayer(playerId).subscribe((player: PlayersView) => {
			this.viewService.installStateIdOriginal = player.InstallStateId;
			this.viewService.lastUpdateDateTimeUtcOriginal = player.LastUpdateDateTimeUtc;
			this.createEditPlayerService.player = player;
			this.viewService.installStateDisabled = this.viewService.playerIsInstalled();
			if (this.createEditPlayerService.player.InstallDate) {
				this.createEditPlayerService.player.installDateView = this.dateTimeService.buildDatePickerDate(moment(this.createEditPlayerService.player.InstallDate));
			}
			this.getDropdownMenuOptions();
			this.productIcon = this.setProductIcon();
			const uppercaseParam = this.createEditPlayerService.player.ProductType.ProductNoun.replace(/^\w/, (c) => c.toUpperCase());
			this.title = `Edit ${uppercaseParam} Player | <em>${player.PlayerName}</em>`;
			if (JSON.parse(this.createEditPlayerService.player.playerStatus.SettingsJson)) {
				this.createEditPlayerService.settingsJson = JSON.parse(this.createEditPlayerService.player.playerStatus.SettingsJson);
			}
			if (player.PlayerModelId === 128) {
				//JS Web Player
				this.createEditPlayerService.getWebPlayerHtml(playerId);
			}
		});
	}

	private getLocation(locationId: number, route: ProductRoute): void {
		this.createEditPlayerService.getLocation(locationId).subscribe((location: ServiceLocations) => {
			this.createEditPlayerService.newPlayerInit(route, location);
			this.createEditPlayerService.newPlayerStatusInit(route);
			this.getDropdownMenuOptions();
			this.productIcon = this.setProductIcon();
			const uppercaseParam = route.replace(/^\w/, (c) => c.toUpperCase());
			this.title = `Create New ${uppercaseParam} Player`;
			this.createEditPlayerService.getPlayerModel$(this.createEditPlayerService.player.PlayerModelId).subscribe((playerModel: PlayerModels) => {
				const productRoute = this.createEditPlayerService.player.ProductType.Route;
				this.createEditPlayerService.player.PlayerModel = playerModel;
				this.createEditPlayerService.player.playerStatus.TargetVersion = this.createEditPlayerService.player.PlayerModel.CurrentSoftwareVersion;
				this.createEditPlayerService.settingsJson =
					productRoute === 'radio' ? this.createEditPlayerService.defaultSettingsJsonRadio() : this.createEditPlayerService.defaultSettingsJson();
			});
		});
	}

	private getDropdownMenuOptions(): void {
		this.playerOptionsService.getInstallStates();
		this.playerOptionsService.getRepairStates();
		this.playerOptionsService.getProductTypes();
		this.playerOptionsService.getOutputTypes();
		this.playerOptionsService.getServiceLocations();
	}

	private setProductIcon(): string {
		switch (this.createEditPlayerService.player.ProductTypeId) {
			case 1:
				return 'fas fa-phone-volume';
			case 2:
				return 'fas fa-volume-up';
			case 3:
				return 'fas fa-tv';
			case 4:
				return 'far fa-square';
			case 6:
			case 7:
				return 'fa-th';
			case 8:
				return 'fa-book';
		}
	}

	public videoView(): boolean {
		const player = this.createEditPlayerService.player;

		if (player && player.ProductType) {
			return (
				this.createEditPlayerService.player &&
				this.createEditPlayerService.player.ProductType.Route !== 'hold' &&
				this.createEditPlayerService.player.ProductType.Route !== 'radio'
			);
		}
	}

	ngOnDestroy() {
		this.viewService.installStateDisabled = null;
		this.viewService.formChanged = false;
	}
}
