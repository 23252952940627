import { Component } from '@angular/core';

import { AppStateService } from 'src/app/core/services';
import { LoginService } from '../login.service';
import { AlertMessage, LoginView } from '../_models/login-models';
import { DataTestIds } from 'src/app/shared/helpers/data-test-ids';
import { tap } from 'rxjs/operators';

@Component({
	selector: 'l-auth-code-view',
	templateUrl: './auth-code-view.component.html',
	styleUrls: ['./auth-code-view.component.scss']
})
export class AuthCodeViewComponent {
	public pin: number;
	readonly DataTestIds = DataTestIds;

	constructor(public appStateService: AppStateService, public loginService: LoginService) {}

	public onSubmitClick(): void {
		this.loginService
			.onQrPinSubmit$(this.pin)
			.pipe(
				tap(() => {
					if (this.appStateService.currentUser.Show2faQRCode) {
						this.loginService.updateShow2faQRCode().subscribe((error) => console.error('Error in patch request:', error));
					}
				})
			)
			.subscribe(
				() => {
					sessionStorage.setItem('validPin', 'true');
					sessionStorage.removeItem('awaitingPin');
					this.loginService.routeOnLoginSuccess();
				},
				(err) => {
					sessionStorage.removeItem('authToken');
					this.loginService.viewState = LoginView.MainView;
					this.loginService.setAlert({
						visible: true,
						bgColor: 'gray-bg-dark',
						textColor: 'red-text-light',
						message: `${AlertMessage.IncorrectPin} ${AlertMessage.Default}`,
						icon: 'fa-exclamation'
					});
					setTimeout(() => {
						this.loginService.setAlert({
							visible: true,
							bgColor: 'yellow-bg-light',
							textColor: 'red-text',
							message: AlertMessage.Default,
							icon: 'fa-exclamation-triangle'
						});
					}, 6000);
				}
			);
	}

	public onCancelClick(): void {
		sessionStorage.removeItem('authToken');
		this.loginService.viewState = LoginView.MainView;
		this.loginService.alert = { ...this.loginService.alert, visible: true };
	}
}
