import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

import { AppStateService, Events, MessageService, SecurityService } from '../../core/services';
import { CategoriesTree, LibrariesTree } from '../components/library-tree/_models';
import { Create24Service } from 'src/app/user-tools/create-24/create-24.service';
import { environment } from '../../../environments/environment';
import { Hold, Video } from '../components/content-container/content/_models';
import { ContentViewState, FeatureArea } from '../view-models';
import { Create24Area } from 'src/app/user-tools/create-24/_models/create-24-state.enum';
import { PaginationInstance } from 'ngx-pagination';

@Injectable({
	providedIn: 'root'
})
export class ContentViewStateService {
	public breadcrumbs: string;
	public contentList: (Hold | Video)[];
	public totalItemsContentList: number;
	public viewState: ContentViewState;

	public categoryName: string;
	public libraryName: string;
	public subCategoryName;

	constructor(
		private appStateService: AppStateService,
		private create24Service: Create24Service,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private securityService: SecurityService
	) {}

	public contentDeletable(content): boolean {
		const { c24ActiveArea } = this.create24Service;
		if (c24ActiveArea === Create24Area.VIDEO_TEMPLATE || content.isGlobal) {
			return this.appStateService.currentUser.IsEmployee && this.securityService.userRoleAdminOrCsr();
		}
		return true;
	}

	public onContentSearchClear(): void {
		this.viewState.contentSearchActive = false;
		this.viewState.contentListVisible = false;
		this.contentList = null;
		this.messageService.publish(Events.onContentSearchClear, null);
	}

	public getLibraryAndParentCategory(libraryId: number, libraryOrCategory: LibrariesTree | CategoriesTree, prop: string, payload): void {
		const library$ = this.httpClient.get(`${environment.contentUrl}ContentLibraries/${libraryId}`);
		const category$ = this.httpClient.get(`${environment.contentUrl}ContentLibraryCategoriesLight/${libraryOrCategory.ParentCategoryId}`);
		let observs$ = [];

		//Get parent category so the breadcrumbs will refresh correctly
		if (libraryOrCategory.ParentCategoryId) {
			observs$ = [library$, category$];
		} else {
			observs$ = [library$];
		}

		forkJoin(observs$).subscribe((res: any) => {
			const library: LibrariesTree = res[0];
			const category: CategoriesTree = res[1];
			this[prop] = libraryOrCategory.name;
			this.libraryName = library.LibraryName;
			this.breadcrumbs = `${this.libraryName} > ${category ? category.CategoryName : this.categoryName} ${
				this.subCategoryName ? `> ${this.subCategoryName}` : ''
			}`;
			this.messageService.publish(Events.onLibraryOrCategorySelect, payload);
		});
	}

	public onSearchClearClick(): void {
		this.onContentSearchClear();
	}

	public startWithBlankCanvas(): boolean {
		const { c24ActiveArea } = this.create24Service;
		return c24ActiveArea === Create24Area.NEW_IMAGE || c24ActiveArea === Create24Area.NEW_SIDEBAR;
	}

	public showSelectC24BackgroundInstructions(): boolean {
		const { c24ActiveArea } = this.create24Service;
		return (
			c24ActiveArea !== Create24Area.EDIT_PROJECT &&
			c24ActiveArea !== Create24Area.VIDEO_TEMPLATE &&
			c24ActiveArea !== Create24Area.STILL_TEMPLATE &&
			c24ActiveArea !== Create24Area.SIDEBAR_TEMPLATE &&
			c24ActiveArea !== Create24Area.IMAGE_LAYER_CREATE &&
			c24ActiveArea !== Create24Area.IMAGE_LAYER_REPLACE
		);
	}

	public paginationConfig(): Partial<PaginationInstance> {
		const itemsPerPage = this.viewState.treeSelection === 'featured-playlist' ? 1000 : 25;
		return {
			itemsPerPage,
			currentPage: this.viewState.contentListCurrentPage,
			totalItems: this.totalItemsContentList
		};
	}
}
