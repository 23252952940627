import { Component, OnInit, Input } from '@angular/core';

import { ContentViewService } from '../../_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { Hold, Video } from '../../_models';

@Component({
    selector: 'vc-featured-content-view',
    templateUrl: './featured-content-view.component.html',
    styleUrls: ['./featured-content-view.component.scss']
})
export class FeaturedContentViewComponent implements OnInit {

    @Input() content: Hold | Video;
    @Input() contentList: (Hold | Video)[];
    @Input() isEvenIndex: boolean;
    @Input() isFirstIndex: boolean;
    @Input() isLastIndex: boolean;

    public previewActive: boolean;

    constructor(
        public contentViewService: ContentViewService,
        public cvStateService: ContentViewStateService
    ) { }

    ngOnInit(): void {
    }

    public onPreviewClick(): void {
        if (this.content.contentType === 'video/mp4') {
            this.previewActive = true;
        }
    }
}
