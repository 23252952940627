import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from '../app-state.service';
import { AuthUser } from '../../../shared/api-models/auth/auth-user';

export const allowRecord24Guard = () => {
	const appStateService = inject(AppStateService);
	const router = inject(Router);

	const currentUser: AuthUser = JSON.parse(appStateService.getSessionItem('currentUser'));
	const currentClient = JSON.parse(appStateService.getSessionItem('currentClient'));

	const allowRecord24 = () => currentClient.AllowRecord24 && currentUser.Role !== 'User';

	if (allowRecord24()) {
		return true;
	} else {
		router.navigate(['/access-denied']);
		return false;
	}
};
