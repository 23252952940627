import { Content, ContentFiles } from '../../../../api-models/content';

export class ContentFilesVideo extends ContentFiles {
    previewUrl: string;
    previewActive: boolean;
    isStopDateSelected: boolean;
    thumbnailUrl: string;
    writeAccess: boolean;
    isDirty: boolean;
    startDate: string;

    Content: Content;
}