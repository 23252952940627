import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AppStateService } from '../app-state.service';
import { AuthUser } from '../../../shared/api-models/auth/auth-user';

@Injectable({
	providedIn: 'root'
})
export class IsEmployeeContractorOrVoiceTalentGuardService implements CanActivate {

  constructor(private appStateService: AppStateService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
  	  let currentUser: AuthUser = JSON.parse(this.appStateService.getSessionItem('currentUser'));
      if (currentUser.IsEmployee || currentUser.Role === 'Video Content Contractor' || currentUser.Role === 'Voice Talent') {
          return true;
      } else {
          this.router.navigate(['/access-denied']);
      } 
      return true;
  }
}