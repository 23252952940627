import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Events, MessageService } from '../../core/services';
import { AppStateService } from '../../core/services/app-state.service';
import { LibrariesView } from './libraries-view';
import { LibraryEditorService } from './library-editor.service';
import { PrintData } from './print-data';

@Component({
	selector: 'st-library-editor',
	templateUrl: './library-editor.component.html',
	styleUrls: ['./library-editor.component.scss']
})
export class LibraryEditorComponent implements OnInit {
	public detailsBoxPostion: string;
	public currentPage: number;
	public filteredLibraries$: Observable<LibrariesView[]>;
	public filteringByProductText: string;
	public filteringByProductTextColor: string;
	public index: number;
	public isHovered: boolean;
	public libraries$: Observable<LibrariesView[]>;
	public productFilterActive: boolean;
	public searchTerm: string;
	public selectedProductTypeId: number;
	public selectedLibrary: LibrariesView;

	private selectedRowElement: any;
	public top: number;
	public printData: Map<string, Map<string, PrintData[]>>;

	constructor(public appStateService: AppStateService, public libraryEditorService: LibraryEditorService, private messageService: MessageService) {}

	ngOnInit() {
		this.libraries$ = this.libraryEditorService.getLibraries$();
	}

	//Set details box to be the same position
	//in the view as the selected library
	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (this.selectedLibrary) {
			this.selectedRowElement = document.getElementById(JSON.stringify(this.selectedLibrary.Id));
		}
		if (this.selectedRowElement) {
			this.top = this.selectedRowElement.getBoundingClientRect().top;
			this.detailsBoxPostion = 'fixed';
		} else {
			this.detailsBoxPostion = 'relative';
		}
	}

	onPageChange(pageNumber: number): void {
		this.selectedLibrary = null;
		this.currentPage = pageNumber;
	}

	public onCheckboxClick(stringId: string): void {
		this.selectedLibrary[stringId] = !this.selectedLibrary[stringId];
	}

	public onDeleteClick(): void {
		let confirmOk: boolean = confirm(`Delete library "${this.selectedLibrary.LibraryName}"?`);
		if (confirmOk) {
			this.selectedLibrary.IsDeleted = true;
			this.saveSelectedLibrary(true);
		}
	}

	public onProductFilterClick(productTypeId: number, productText: string): void {
		this.selectedLibrary = null;
		//turn off filter if clicking active filter button
		if (this.selectedProductTypeId === productTypeId) {
			this.productFilterActive = false;
			this.selectedProductTypeId = null;
			return;
		}
		this.filteringByProductText = productText;
		this.filteringByProductTextColor = this.libraryEditorService.setFilteringByProductTextColor(productTypeId);
		this.selectedProductTypeId = productTypeId;
		this.productFilterActive = true;
		this.filteredLibraries$ = this.libraryEditorService.filterLibrariesByProduct(this.libraries$, productTypeId);
	}

	//highlight hovered row
	public onRowHover(index: number): void {
		this.index = index;
		this.isHovered = true;
	}

	public onRowSelect(index: number, library: LibrariesView): void {
		this.index = index;
		this.selectedRowElement = document.getElementById(JSON.stringify(library.Id));
		this.selectedLibrary = JSON.parse(JSON.stringify(library));
		this.top = this.selectedRowElement.getBoundingClientRect().top;
		this.detailsBoxPostion = 'fixed';
	}

	public onSaveClick(): void {
		this.saveSelectedLibrary(true);
	}

	private saveSelectedLibrary(refreshData?: boolean): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.libraryEditorService.updateLibrary(this.selectedLibrary).subscribe(() => {
			this.selectedLibrary = null;
			this.messageService.publish(Events.savingPreloader, 0);
			if (refreshData) {
				this.libraries$ = this.libraryEditorService.getLibraries$();
			}
		});
	}

	public onPrintClick(): void {
		this.libraryEditorService.getPrintLibrary$(this.selectedLibrary.Id).subscribe((printData) => {
			this.printData = printData;
			this.selectedLibrary.LibraryName;
			this.openPrintWindow();
		});
	}

	private openPrintWindow(): void {
		const printWindow = window.open('', '_blank');
		printWindow.document.write('<html><head><title>Print Library</title></head><body>');
		printWindow.document.write(this.getPrintContent());
		printWindow.document.write('</body></html>');
		printWindow.document.close();
		printWindow.print();
	}

	private getPrintContent(): string {
		let content = '';
		// Iterate over the outer map
		content += `<h1>${this.selectedLibrary.LibraryName}</h1>`;
		this.printData.forEach((innerMap, parentCategory) => {
			parentCategory = parentCategory ?? '(No parent category set)';
			content += `<h2 style="margin-left: 20px;">${parentCategory}</h2>`;
			// Iterate over the inner map
			innerMap.forEach((printItems, categoryName) => {
				content += `<h3 style="margin-left: 30px;">${categoryName}</h3>`;
				// Iterate over the array of PrintData
				printItems.forEach((printItem) => {
					content += `<div style="font-weight: 600; margin-top: 10px; margin-bottom: 10px; margin-left: 60px;">${printItem.Title}</div>`;
					content += `<div style="margin-left: 60px; margin-bottom: 10px;">${printItem.Text}</div>`;
					content += `<hr style="border-color: #f6f6f6; border-width: 0.5px;">`;
				});
			});
		});
		return content;
	}
}
