import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ClientHistoryNotes } from '../../../shared/api-models/log';

@Component({
	selector: 'hn-xs-view',
	templateUrl: './hn-xs-view.component.html',
	styleUrls: ['./hn-xs-view.component.scss']
})
export class HnXsViewComponent implements OnInit {
	@Input() historyNote: ClientHistoryNotes;
	@Input() isEven: boolean;

	@Output() buttonClickNotify: EventEmitter<[{ key: string }, ClientHistoryNotes]> = new EventEmitter<[{ key: string }, ClientHistoryNotes]>();

	constructor() {}

	ngOnInit() {}

	public onTableButtonClick(): void {
		const column = { key: 'deleteBtn' };
		this.buttonClickNotify.emit([column, this.historyNote]);
	}
}
