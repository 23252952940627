import { Component, Input, OnInit } from '@angular/core';

import { AddToPlaylistService } from '../../_services';
import { ContentFilesHold, Hold, Video } from '../../_models';
import { ContentFavoritesService, ContentViewService } from '../../_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { PlayerCapacityService } from 'src/app/playlist-builder/_services';
import { WizardService } from 'src/app/playlist-builder/_services';
import { UtilitiesService } from 'src/app/core/services';
import { ContentFiles } from 'src/app/shared/api-models/content';

@Component({
	selector: 'vc-hold-pb-list-view',
	templateUrl: './hold-pb-list-view.component.html',
	styleUrls: ['./hold-pb-list-view.component.scss']
})
export class HoldPbListViewComponent implements OnInit {
	@Input() content: Hold;
	@Input() contentList: (Hold | Video)[];
	@Input() isEvenIndex: boolean;
	@Input() isFirstIndex: boolean;
	@Input() isLastIndex: boolean;

	constructor(
		public addToPlaylistService: AddToPlaylistService,
		public contentFavoritesService: ContentFavoritesService,
		public contentViewService: ContentViewService,
		public cvStateService: ContentViewStateService,
		public playerCapacityService: PlayerCapacityService,
		public utilService: UtilitiesService,
		public wizardService: WizardService
	) {}

	ngOnInit() {}

	public onPlayClick(content: Hold, isLastIndex?: boolean, prop?: 'talentPreviewSelectorVisible' | 'talentPickerVisible'): void {
		this.contentViewService.onPlayClick(content, isLastIndex, prop, this.contentList);
	}

	public onSelectVoiceTalentClick(selectedContentFile: ContentFiles, content: Hold, prop: 'talentPreviewSelectorVisible' | 'talentPickerVisible'): void {
		this.contentViewService.onSelectVoiceTalentClick(selectedContentFile as ContentFilesHold, content, prop);
	}
}
