export class ClientHistoryNotes {
	Id?: number;
	ClientId?: number;
	ServiceLocationId?: number;
	PlayerId?: number;
	UserId?: number;
	UnitIdentifier?: string;
	TicketId?: number;
	EntrySource?: string;
	EntryType?: string;
	LogDate?: string;
	LogSubject?: string;
	LogMessage?: string;
	LogJson?: string;
	IsSystemGenerated?: boolean;
	IsError?: boolean;
	UserName?: string;

	deleteBtn?: string;
}
