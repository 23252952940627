import { Injectable } from '@angular/core';

import { AppStateService } from './app-state.service';
import { ContentFilesHold, Hold, Video } from '../../shared/components/content-container/content/_models';
import { ContentLibraries } from '../../shared/api-models/content';
import { FeatureArea } from 'src/app/shared/view-models';

@Injectable({
	providedIn: 'root'
})
export class SecurityService {
	constructor(private appStateService: AppStateService) {}

	public filterLibraries(libraries: ContentLibraries[], featureArea: FeatureArea = FeatureArea.CONTENT_MANAGER): any[] {
		const currentClient = this.appStateService.currentClient;

		return libraries
			.filter((userLibrary) => {
				//Filter out global libraries when an employee is logged in as a different client
				if (currentClient.Id !== 1470) {
					return !userLibrary.IsGlobal;
				}

				switch (true) {
					//Filter out globals if user role does not have write access
					case !this.userRoleHasWriteAccess():
						if (!userLibrary.IsGlobal) {
							return true;
						}
						return;

					//return all libraries if user has write access
					case this.userRoleHasWriteAccess():
						return true;
				}
			})
			.filter((lib) => {
				const hideFromPortal: boolean = currentClient.Id !== 1470 && currentClient.Name !== 'Creative Office'; //Works24 Office, Creative Office

				if (featureArea === FeatureArea.CONTENT_MANAGER && hideFromPortal) {
					return lib.WriteAccess && !lib.HideFromPortal;
				}
				return lib.WriteAccess;
			});
	}

	private userRoleHasWriteAccess(): boolean {
		const currentUser = this.appStateService.currentUser;
		return (
			currentUser.Role === 'Administrator' ||
			currentUser.Role === 'CSR' ||
			currentUser.Role === 'Script Writer' ||
			this.userRole(currentUser.Role) === 'contentcreator'
		);
	}

	//This method for editing content title, script, deleting content,
	//dragging content to new category, updating gender label
	public writeAccessEnabled(content: Hold | Video, contentFile?: ContentFilesHold): boolean {
		if (this.appStateService.currentUser.IsEmployee && this.userIsContentManagerCsrOrAdmin()) {
			return true;
		}

		if (!this.appStateService.currentUser.IsEmployee && !content.isGlobal && this.userIsContentManagerOrSupervisor()) {
			if (contentFile && !contentFile.createdByUser.IsEmployee) {
				return true;
			}
			if (contentFile && contentFile.createdByUser.IsEmployee) {
				return false;
			}
			if (!contentFile) {
				return this.userIsAdmin() || this.userIsContentManagerOrSupervisor();
			}
		}
		return false;
	}

	public contentManagerCsrOrAdmin(): boolean {
		return (
			this.appStateService.currentUser.Role === 'Content Manager' ||
			this.appStateService.currentUser.Role === 'CSR' ||
			this.appStateService.currentUser.Role === 'Administrator' ||
			this.appStateService.currentUser.Role === 'Audio Content Creator' ||
			this.appStateService.currentUser.Role === 'Video Content Creator'
		);
	}

	public userIsContentManagerCsrOrAdmin(): boolean {
		return (
			this.appStateService.currentUser.Role === 'Content Manager' ||
			this.appStateService.currentUser.Role === 'CSR' ||
			this.appStateService.currentUser.Role === 'Administrator' ||
			this.appStateService.currentUser.Role === 'Audio Content Creator' ||
			this.appStateService.currentUser.Role === 'Video Content Creator'
		);
	}

	public userSeesAllLibraries(): boolean {
		const userRole: string = this.appStateService.currentUser.Role;
		const currentClient = this.appStateService.currentClient;
		return (currentClient.Id === 1470 || currentClient.Name === 'Creative Office') && this.userIsContentManagerCsrOrAdmin() && userRole !== 'Content Manager';
	}

	public userRole(userRole: string): string {
		switch (userRole) {
			case 'Video Content Contractor':
			case 'Voice Talent':
			case 'Audio Content Creator':
			case 'Video Content Creator':
				return 'contentcreator';
			default:
				return userRole;
		}
	}

	public userRoleSalesperson(): boolean {
		return this.appStateService.currentUser.Role === 'Salesperson';
	}

	public userRoleAdminOrCsr(): boolean {
		const userRole = this.appStateService.currentUser.Role;
		return userRole === 'Administrator' || userRole === 'CSR';
	}

	public userIsAdmin(): boolean {
		const currentUser = this.appStateService.currentUser;
		return currentUser.Role === 'Administrator';
	}

	public userRoleAdminOrVideo(userRole: string): boolean {
		return userRole === 'Administrator' || userRole === 'Video Content Creator';
	}

	public userRoleAdminCsrOrSalesman(userRole: string): boolean {
		return userRole === 'Administrator' || userRole === 'CSR' || userRole === 'Salesperson';
	}

	public userIsContractor(): boolean {
		return this.appStateService.currentUser.Role === 'Video Content Contractor' || this.appStateService.currentUser.Role === 'Voice Talent';
	}

	public userIsVideoContractor(): boolean {
		return this.appStateService.currentUser.Role === 'Video Content Contractor';
	}

	public userIsAudioContentCreatorOrVoiceTalent(): boolean {
		return this.appStateService.currentUser.Role === 'Audio Content Creator' || this.appStateService.currentUser.Role === 'Voice Talent';
	}

	public userIsNatalieOrKelsey(): boolean {
		const currentUser = this.appStateService.currentUser;
		//Kelsey Hunt/Natalie Van Oosten/Austin Harms (dev)
		return (currentUser.UserId === 19 || currentUser.UserId === 35 || currentUser.UserId === 106) && this.appStateService.currentClient.Id === 1470; //Works24 Office Account
	}

	public userIsAdminOrNatalie(): boolean {
		return this.appStateService.currentUser.Role === 'Administrator' || this.appStateService.currentUser.UserId === 35;
	}

	private userIsContentManagerOrSupervisor(): boolean {
		return this.appStateService.currentUser.Role === 'Content Manager' || this.appStateService.currentUser.Role === 'Supervisor';
	}

	public userIsEmployee(): boolean {
		return this.appStateService.currentUser.IsEmployee;
	}
}
