import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { AppErrorService } from 'src/app/core/app-error/app-error.service';
import { Events, MessageService } from '../../../core/services';
import { CreateEditPlayerService } from './create-edit-player.service';
import { environment } from '../../../../environments/environment';
import { HistoryNoteService } from './history-note.service';
import { PlayersView } from '../_models';
import { ViewService } from './view.service';
import { Players } from 'src/app/shared/api-models/admin';

@Injectable()
export class SaveService {
	public savingInProgress: boolean;

	private counter: number;

	constructor(
		private appErrorService: AppErrorService,
		private createEditPlayerService: CreateEditPlayerService,
		private historyNoteService: HistoryNoteService,
		private httpClient: HttpClient,
		private location: Location,
		private messageService: MessageService,
		private viewService: ViewService
	) {}

	public onSaveClick(): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.savingInProgress = true;

		if (this.createEditPlayerService.viewState === 'edit') {
			this.viewService.formChanged = false;
			this.counter = 2;
			this.updatePlayer();
			this.updatePlayerStatus();
		} else if (this.createEditPlayerService.viewState === 'new') {
			this.counter = 0;

			let playerClone: PlayersView = JSON.parse(JSON.stringify(this.createEditPlayerService.player));
			delete playerClone.Location;
			delete playerClone.InstallState;
			delete playerClone.RepairState;
			delete playerClone.ProductType;
			delete playerClone.OutputType;
			delete playerClone.PlayerModel;
			delete playerClone.VoipFormat;
			delete playerClone.playerStatus;

			this.postNewPlayer(playerClone);
		}
	}

	/********************************************
      SAVE NEW PLAYER
     *******************************************/

	private postNewPlayer(playerClone: PlayersView): void {
		if (this.viewService.tempInstallStateId) {
			playerClone.InstallStateId = this.viewService.tempInstallStateId;
		}
		this.httpClient.post(environment.adminUrl + `CorePlayers`, playerClone).subscribe((player: { id: number; UnitIdentifier: string }) => {
			this.createEditPlayerService.player.playerStatus.PlayerId = player.id;
			this.createEditPlayerService.player.playerStatus.PlayerIdentifier = player.UnitIdentifier;
			this.createEditPlayerService.player.playerStatus.PlayerName = playerClone.PlayerName;
			this.postNewPlayerStatus();
		});
	}

	private postNewPlayerStatus(): void {
		//If player is Halo, include settings JSON
		if (this.isHaloOrPlay24()) {
			this.createEditPlayerService.player.playerStatus.SettingsJson = JSON.stringify(this.createEditPlayerService.settingsJson);
		}

		this.httpClient.post(environment.deliveryUrl + `PlayerStatus`, this.createEditPlayerService.player.playerStatus).subscribe(() => {
			this.saveSuccess();
		});
	}

	/********************************************
      UPDATE EXISTING PLAYER
     *******************************************/

	private updatePlayer(): void {
		if (this.viewService.tempInstallStateId) {
			this.createEditPlayerService.player.InstallStateId = this.viewService.tempInstallStateId;
		}

		this.httpClient.get(`${environment.adminUrl}CorePlayers/${this.createEditPlayerService.player.Id}`).subscribe((res: Players[]) => {
			const player: Players = res[0];
			if (player.LastUpdateDateTimeUtc !== this.viewService.lastUpdateDateTimeUtcOriginal) {
				this.appErrorService.showAppError = true;
				let errorPayload = ['', `Someone else swooped in and made an update before you! How dare they. Refresh and try again.`];
				this.messageService.publish(Events.appError, errorPayload);
				return;
			}
			if (player.InstallStateId !== this.viewService.installStateIdOriginal) {
				this.appErrorService.showAppError = true;
				let errorPayload = ['', `Install state has been changed since you came to this page, unable to save.`];
				this.messageService.publish(Events.appError, errorPayload);
				return;
			}
			this.httpClient.put(environment.adminUrl + `CorePlayers/${this.createEditPlayerService.player.Id}`, this.createEditPlayerService.player).subscribe(() => {
				this.counter--;
				this.historyNoteService.postHistoryNote().subscribe();
				this.saveSuccess();
			});
		});
	}

	private updatePlayerStatus(): void {
		//If player is Halo, include settings JSON
		if (this.isHaloOrPlay24() || this.createEditPlayerService.showIpConfigOptions()) {
			for (let prop in this.createEditPlayerService.settingsJson) {
				if (this.createEditPlayerService.settingsJson[prop] === '') {
					this.createEditPlayerService.settingsJson[prop] = null;
				}
			}
			if (this.createEditPlayerService.player.ProductType.Route === 'hold') {
				this.createEditPlayerService.settingsJson.Volume = this.createEditPlayerService.player.HoldVolume;
			}

			this.createEditPlayerService.player.playerStatus.SettingsJson = JSON.stringify(this.createEditPlayerService.settingsJson);
		}

		delete this.createEditPlayerService.player.playerStatus.Stations;
		this.createEditPlayerService.player.playerStatus.PlayerName = this.createEditPlayerService.player.PlayerName;
		this.httpClient
			.patch(environment.deliveryUrl + `PlayerStatus/${this.createEditPlayerService.player.Id}`, this.createEditPlayerService.player.playerStatus)
			.subscribe(() => {
				this.counter--;
				this.saveSuccess();
			});
	}

	private isHaloOrPlay24(): boolean {
		return [56, 1000000, 1000001, 1000005, 1000006, 1000007, 1000009, 1000011].some((modelId) => modelId === this.createEditPlayerService.player.PlayerModelId);
	}

	private saveSuccess(): void {
		if (this.counter === 0) {
			this.messageService.publish(Events.savingPreloader, 0);
			this.savingInProgress = false;
			this.location.back();
		}
	}
}
