import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CategoriesTree, LibrariesTree } from '../_models';

import { AppStateService } from 'src/app/core/services';
import { CacheService } from 'src/app/core/services';
import { CategoriesService } from '../_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { LibrariesService } from '../_services';
import { LibraryService } from './library.service';
import { FeatureArea } from 'src/app/shared/view-models';

@Component({
	selector: 'lt-library',
	templateUrl: './library.component.html',
	styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {
	@Input() isFavoriteLibrary: boolean;
	@Input() library: LibrariesTree;
	@Input() treeSelection: 'featured-playlist' | 'favorites' | 'regular-content';
	@ViewChild('libraryInput') libraryInput: ElementRef;

	public mouseover: boolean;
	public newCategory: CategoriesTree;

	constructor(
		private appStateService: AppStateService,
		private cacheService: CacheService,
		private categoriesService: CategoriesService,
		public cvStateService: ContentViewStateService,
		private librariesService: LibrariesService,
		public libraryService: LibraryService
	) {}

	ngOnInit(): void {
		this.library.state = 'read';
		this.newCategory = new CategoriesTree();
		this.categoriesService.clickedCategory = null;
		//Get categories and expand music library on init
		if (this.library.ContentLibraryTypeId === 1 && this.appStateService.activeFeatureArea === FeatureArea.PLAYLIST_BUILDER) {
			this.getCategories();
		}
	}

	public onMouseover(): void {
		this.mouseover = true;
		this.librariesService.hoveredName = this.library.LibraryName;
	}

	public onMouseleave(): void {
		this.mouseover = false;
		this.librariesService.hoveredName = null;
	}

	public onCreateCategoryClick(): void {
		this.library.state = 'new';
		if (!this.library.isExpanded) {
			this.onLibraryClick();
		}
		setTimeout(() => this.libraryInput?.nativeElement.focus(), 20);
	}

	public onSaveNewCategoryClick(): void {
		this.library.state = 'read';
		this.categoriesService.saveNewCategory$(this.newCategory.CategoryName, this.library).subscribe(() => {
			this.getCategories();
		});
	}

	public onSaveNameClick(): void {
		this.libraryService.updateLibraryName(this.library);
		this.library.state = 'read';
	}

	public onLibraryClick(): void {
		this.cvStateService.viewState.treeSelection = this.treeSelection;
		if (this.treeSelection !== 'favorites') {
			this.librariesService.favoritesIsExpanded = false;
		}
		this.library.isExpanded = !this.library.isExpanded;

		//Get categories if we don't already have them
		if (!this.library.categories) {
			this.categoriesService.getCategories(this.library.Id).subscribe((categories: CategoriesTree[]) => {
				this.library.categories = categories;
			});
		}

		//Close all other category folders when closing this library
		this.librariesService.libraries.forEach((lib) => {
			if (this.library.isExpanded) {
				lib.categories?.filter((cat) => cat.Id !== this.categoriesService.clickedCategory?.Id).forEach((category) => (category.isExpanded = false));
			}
		});

		//If no clicked categories, set clicked library
		if (!this.categoriesService.clickedCategory && this.library.isExpanded) {
			this.librariesService.clickedLibraryId = this.library.Id;
		}

		//When closing a library with an open category, hide the content list and reset clicked category
		if (this.categoriesService.clickedCategory?.LibraryId === this.library.Id && !this.library.isExpanded) {
			this.cvStateService.viewState.contentListVisible = false;
			this.categoriesService.clickedCategory = null;
		}

		//Cache library so we can build breadcrumbs without making an API call
		this.cacheService.storeLibrary(this.library);
		this.librariesService.selectedLibrary = this.library;

		//Removes new category input
		if (!this.library.isExpanded) {
			this.library.state = 'read';
		}
	}

	private getCategories(): void {
		this.categoriesService.getCategories(this.library.Id).subscribe((categories: CategoriesTree[]) => {
			this.library.categories = categories;
			this.library.isExpanded = true;
			//Cache the music library automatically since it's already expanded (user won't click on it)
			this.cacheService.libraries.push(this.library);
		});
	}
}
