export class TicketAttachments {
    Id: number;
    TicketId: number;
    FriendlyName: string;
    FileId: number;
    AttachmentTypeId: number;

    constructor(TicketId: number, FriendlyName: string, AttachmentTypeId: number) {
        this.TicketId = TicketId;
        this.FriendlyName = FriendlyName;
        this.AttachmentTypeId = AttachmentTypeId;
    }
}