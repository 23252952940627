import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AppStateService } from '../app-state.service';
import { AuthUser } from '../../../shared/api-models/auth/auth-user';

@Injectable({
	providedIn: 'root'
})
export class ContentManagerGuardService implements CanActivate {

	constructor(private appStateService: AppStateService, private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot) {
		let currentUser: AuthUser = JSON.parse(this.appStateService.getSessionItem('currentUser'));
		if (currentUser.Role !== 'Content Manager' && currentUser.Role !== 'User') {
			return true;
		} else {
			this.router.navigate(['/access-denied']);
		}
	}
}
