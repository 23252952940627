import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppStateService, DateTimeService, DurationOptionsViewService, EncryptService, Events, MessageService } from '../../../core/services';
import { CreateEditFeedService } from '../../_services';
import { environment } from '../../../../environments/environment';
import { FeedItems, FeedSources, Feeds, Stock, FeedStockSymbol, ContentAdvancedSchedules, FeedProperties } from '../../../shared/api-models/content';
import { UploadData } from '../../../shared/components/file-upload-drop-target/upload-data';
import { Video } from 'src/app/shared/components/content-container/content/_models';

@Injectable({
	providedIn: 'root'
})
export class CreateEditFeedItemService {
	public advancedScheduleActive: boolean;
	public feed: Feeds;
	public feedItem: FeedItems;
	public resizeMethod: string = 'STRETCH';
	public stock: Stock;
	public stockSymbols: any[];
	public uploadPercentComplete: number;
	public viewStateMode: 'edit' | 'new';

	constructor(
		private appStateService: AppStateService,
		private createEditFeedService: CreateEditFeedService,
		private dateTimeService: DateTimeService,
		private durationOptionsViewService: DurationOptionsViewService,
		private encryptService: EncryptService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private router: Router
	) {}

	public getCreate24FeedItem(content: Video): Observable<any> {
		return this.httpClient.get<any>(`${environment.contentUrl}ContentFiles/GetCreate24FeedItem/${content.contentFiles[0].Id}`);
	}

	public getFeedItem(feedItemId: number): Observable<void> {
		return this.httpClient.get<FeedItems>(`${environment.contentUrl}FeedItems/${feedItemId}`).pipe(
			mergeMap((feedItem: FeedItems) => {
				this.feedItem = feedItem;
				const feedSource$ = this.getFeedSource(feedItem);
				const feed$ = this.httpClient.get(`${environment.contentUrl}Feeds/${feedItem.FeedId}`);
				const advancedSchedule$ = this.httpClient.get(`${environment.contentUrl}contentadvancedSchedules/${feedItem.AdvancedScheduleId}`);

				let observs$;

				if (feedItem.AdvancedScheduleId) {
					this.advancedScheduleActive = true;
					observs$ = [feedSource$, feed$, advancedSchedule$];
				} else {
					observs$ = [feedSource$, feed$];
				}

				return forkJoin(observs$).pipe(
					map((res: [FeedSources, Feeds, ContentAdvancedSchedules]) => {
						this.feed = res[1];
						this.feed.feedSource = res[0];
						this.setItemContentProps(this.feedItem);
						this.feedItem.previewUrl = this.feedItem.contentFileId ? environment.contentUrl + 'File/Video/' + this.feedItem.contentFileId + '/Preview' : null;
						this.feedItem.advancedSchedule = res[2];
					})
				);
			})
		);
	}

	public getFeedSource(feedItem: FeedItems): Observable<FeedSources> | Observable<string> {
		const isArray: boolean = Array.isArray(JSON.parse(feedItem.ItemContent));
		const feedSourceId: number = isArray ? JSON.parse(feedItem.ItemContent)[0].SourceId : JSON.parse(feedItem.ItemContent).SourceId;
		return this.httpClient.get<FeedSources>(`${environment.contentUrl}FeedSources/${feedSourceId}`);
	}

	public setItemContentProps(feedItem: FeedItems): void {
		if (JSON.parse(feedItem.ItemContent)[0]) {
			feedItem.title = JSON.parse(feedItem.ItemContent)[0].Title;
			feedItem.body = JSON.parse(feedItem.ItemContent)[0].Body;
			feedItem.contentFileId = JSON.parse(feedItem.ItemContent)[0].ContentFileId;
			if (this.isSidebarFeed()) {
				feedItem.widgetDurationView = this.durationOptionsViewService.clipDurationsView.find(
					(duration) => duration.time === JSON.parse(feedItem.ItemContent)[0].WidgetDuration
				)?.name;
			}
		} else {
			feedItem.title = JSON.parse(feedItem.ItemContent).Title;
			feedItem.body = JSON.parse(feedItem.ItemContent).Body;
			feedItem.contentFileId = JSON.parse(feedItem.ItemContent).ContentFileId;
			if (this.isSidebarFeed()) {
				feedItem.widgetDurationView = this.durationOptionsViewService.clipDurationsView.find(
					(duration) => duration.time === JSON.parse(feedItem.ItemContent).WidgetDuration
				)?.name;
			}
		}
	}

	public uploadData(resizeMethod?: string): UploadData {
		const uploadData: UploadData = new UploadData();
		uploadData.Title = `Feed item image, ${this.appStateService.currentClient.Name}, ${this.appStateService.currentUser.UserName}`;
		uploadData.TargetLibraryId = 1000050; //client-agnostic library for sole purpose of feed item images
		uploadData.Text = 'Test';
		uploadData.ResizeMethod = resizeMethod ? resizeMethod : 'STRETCH';
		uploadData.LetterboxSize = '485,725';
		return uploadData;
	}

	public createUpdateFeedItem(method: string): void {
		this.feedItem.AdvancedScheduleId = this.feedItem.advancedSchedule?.Id;
		this.feedItem.ItemContent = JSON.stringify(this.itemContent());
		this.feedItem.SortOrder = 1;
		const feedItemUrl = method === 'post' ? `${environment.contentUrl}feeditems` : `${environment.contentUrl}feeditems/${this.feedItem.Id}`;

		if (this.advancedScheduleActive) {
			this.httpClient[this.advancedSchedule().method](this.advancedSchedule().url, this.feedItem.advancedSchedule)
				.pipe(
					mergeMap((advancedSchedule: ContentAdvancedSchedules) => {
						this.feedItem.AdvancedScheduleId = advancedSchedule ? advancedSchedule.Id : this.feedItem.advancedSchedule.Id;

						return this.httpClient[method](feedItemUrl, this.feedItem);
					})
				)
				.subscribe(() => {
					this.routeToFeedManager();
				});
		} else {
			this.feedItem.AdvancedScheduleId = null;
			delete this.feedItem.advancedSchedule;

			this.httpClient[method](feedItemUrl, this.feedItem).subscribe((feedItem: FeedItems) => {
				if (this.feed.FeedSourceId === 8) {
					//Since we're pushing new stock onto end of array, add the ID upon creation so we can delete
					let matchingFeedItem = this.createEditFeedService.feedItems[this.createEditFeedService.feedItems.length - 1];
					matchingFeedItem.Id = feedItem.Id;
					this.stock = null;
				} else {
					this.routeToFeedManager();
				}
			});
		}
	}

	//This method only for adding/deleting stock items
	public updateFeedProperties(): void {
		//View was adding duplicate feed item, create deep copy solves this
		const feedItems = JSON.parse(JSON.stringify(this.createEditFeedService.feedItems));
		const itemContentArr = feedItems.map((feedItem) => {
			return JSON.parse(feedItem.ItemContent);
		});
		const feedProperties: FeedProperties = {
			FeedId: this.feed.Id,
			IsEditable: false,
			DisplayOrdinal: 0,
			Key: 'StockSymbols',
			Value: JSON.stringify(itemContentArr)
		};
		feedProperties.Id = this.feed.properties.Id;
		this.httpClient.put(`${environment.contentUrl}feedProperties/${this.feed.properties?.Id}`, feedProperties).subscribe();
	}

	private advancedSchedule(): any {
		if (this.feedItem.advancedSchedule) {
			if (this.feedItem.advancedSchedule.Id) {
				return { method: 'put', url: `${environment.contentUrl}contentadvancedSchedules/${this.feedItem.AdvancedScheduleId}` };
			}
			return { method: 'post', url: `${environment.contentUrl}contentadvancedSchedules` };
		}
	}

	private routeToFeedManager(): void {
		this.messageService.publish(Events.savingPreloader, 0);
		this.router.navigate([`/custom-data-feeds/feed-items/${this.encryptService.encryptData(JSON.stringify(this.feed.Id))}`]);
	}

	public newFeedItemInit(): FeedItems {
		const feedItem = new FeedItems();
		this.viewStateMode = 'new';
		feedItem.CreateDate = this.dateTimeService.todayWithCurrentTime();
		feedItem.IsEnabled = true;
		feedItem.EnabledBy = this.appStateService.currentUser.UserId;
		feedItem.EnabledDate = this.dateTimeService.todayWithCurrentTime();
		feedItem.ClientNotified = true;
		feedItem.IsNew = false;
		feedItem.body = '';
		feedItem.title = '';
		feedItem.StartDate = this.dateTimeService.todayAtMidnightUTC();
		feedItem.FeedId = this.feed?.Id;

		return feedItem;
	}

	public itemContent(): any {
		const itemContent: any = {
			SourceId: this.feed.FeedSourceId,
			Title: this.feedItem.title,
			Body: this.feedItem.body,
			MediaUrl: null,
			ContentFileId: this.feedItem.contentFileId
		};
		if (this.isSidebarFeed()) {
			itemContent.WidgetDuration = this.feedItem.widgetDuration;
		}
		if (this.feed.FeedSourceId === 8) {
			return this.newStockSymbol();
		}
		return itemContent;
	}

	private newStockSymbol(): FeedStockSymbol {
		return {
			StockName: this.stock.Security.Name,
			StockSymbol: this.stock.Security.Symbol.toUpperCase(),
			MarketName: this.stock.Security.Market,
			MarketSymbol: this.stock.Security.MarketIdentificationCode
		};
	}

	public showImageUploader(): boolean {
		return this.feed.FeedSourceId === 1000002 || this.feed.FeedSourceId === 1000008;
	}

	public isSelfManagedFeed(): boolean {
		return this.feed.FeedSourceId === 1000002 || this.appStateService.currentUser.Role === 'Administrator';
	}

	public isSidebarFeed(): boolean {
		return this.feed.FeedSourceId === 1000008;
	}

	public sidebarImageSize(): any {
		if (!this.isSidebarFeed()) {
			return { height: null, width: null };
		}
		if (this.resizeMethod === 'STRETCH') {
			return { height: 355.25, width: 232 };
		}
		return { height: null, width: 232 };
	}
}
