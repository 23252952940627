import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { AppStateService, BrowserDetectorService, EncryptService, UtilitiesService } from '../core/services';
import { PlayerPlaylistHistoryService, PphViewService, TableConfigService } from './_services';
import { Table } from '../shared/components/table/view-models/';
import { ProductRoute } from '../shared/api-models/admin/product-route.enum';

@Component({
	selector: 'app-player-playlist-history',
	templateUrl: './player-playlist-history.component.html',
	styleUrls: ['./player-playlist-history.component.scss']
})
export class PlayerPlaylistHistoryComponent implements OnInit, OnDestroy {
	public loadSpinnerActive: boolean;
	public modalVisible: boolean;
	public table: Table;
	readonly productRoute = ProductRoute;

	constructor(
		public appStateService: AppStateService,
		public browserDetectorService: BrowserDetectorService,
		private encryptService: EncryptService,
		public location: Location,
		public pphService: PlayerPlaylistHistoryService,
		public pphViewService: PphViewService,
		private router: Router,
		public tableConfigService: TableConfigService,
		private utilService: UtilitiesService
	) {}

	ngOnInit(): void {
		const { Route } = this.appStateService.product;
		const activeUrl = this.router.routerState.snapshot.url;
		const playerId: number = JSON.parse(this.encryptService.decryptData(this.utilService.getLastParam(activeUrl)));

		this.tableConfigService.config.headerColor = this.pphService.setTableHeaderColor();
		if (Route === ProductRoute.RADIO) {
			this.table = this.tableConfigService.tableRadio;
			this.pphService.getRadioHistoryLog$(playerId).subscribe((log) => {
				this.pphService.spinnerActive = false;
				this.pphService.radioHistoryLog = log;
			});
			this.pphService.getPlayer$(playerId).subscribe((player) => (this.pphService.player = player[0]));
		} else {
			this.table = this.appStateService.currentClient.UseAdvancedScheduling
				? this.tableConfigService.tableAdvancedSchedule
				: this.tableConfigService.tableSimpleSchedule;
			this.pphService.getData(playerId).subscribe();
		}
	}

	onPageClick(pageNumber: number) {
		this.pphService.pageNumber = pageNumber;
		this.pphService.getPlaylistHistory(this.pphService.player.Id).subscribe();
	}

	ngOnDestroy() {
		//Make sure no old playlist is hanging around
		this.pphService.playlists = [];
		this.pphService.radioHistoryLog = [];
	}
}
