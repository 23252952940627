import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AppStateService } from '../app-state.service';

@Injectable({
  providedIn: 'root'
})
export class IsEmployeeGuardService {

  constructor(private appStateService: AppStateService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot) {
      if (this.appStateService.currentUser.IsEmployee) {
          return true;
      } else {
          this.router.navigate(['/access-denied']);
      } 
      return true;
  }
}
