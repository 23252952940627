import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { DateTimeService } from '../../../../core/services';
import { DeliveryService } from '../../../services';
import { Players } from '../../../api-models/admin';
import { Playlists } from '../../../api-models/content';

@Component({
  selector: 'pd-scheduled-players',
  templateUrl: './scheduled-players.component.html',
  styleUrls: ['./scheduled-players.component.scss']
})
export class ScheduledPlayersComponent implements OnInit {

  @Input() playlist: Playlists;
  @Output() exitClickNotify = new EventEmitter<any>();

  public players$: Observable<Players[]>

  constructor(
          public dateTimeService: DateTimeService,
          public deliveryService: DeliveryService) { }

  ngOnInit() {
  	this.players$ = this.deliveryService.getPlaylistPlayers(this.playlist.Id);
  }

}
