import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { adminOrNatalieGuard, authGuard, IsEmployeeGuardService } from '../core/services/route-guards';
import { ClientBrowserComponent } from './client-browser/client-browser.component';
import { ContractRenewalsReportComponent } from './contract-renewals-report/contract-renewals-report.component';
import { HoldDeliveryQueueComponent } from './hold-delivery-queue/hold-delivery-queue.component';
import { LibraryEditorComponent } from './library-editor/library-editor.component';
import { NewsManagerComponent } from './news-manager/news-manager.component';
import { CreateEditFeedItemComponent } from './news-manager/create-edit-feed-item/create-edit-feed-item.component';
import { PlayerStatusReportComponent } from './player-status-report/player-status-report.component';
import { ReminderEmailsComponent } from './reminder-emails/reminder-emails.component';
import { TicketSystemComponent } from './ticket-system/ticket-system.component';
import { CreateEditMailingComponent } from './email-blast-tool/create-edit-mailing/create-edit-mailing.component';
import { EmailBlastToolComponent } from './email-blast-tool/email-blast-tool.component';
import { CreateEditTemplateComponent } from './email-blast-tool/create-edit-template/create-edit-template.component';
import { ClientMergeToolComponent } from './client-merge-tool/client-merge-tool.component';
import { UsageReportsComponent } from './usage-reports/usage-reports.component';
import { LibrarySearchComponent } from './usage-reports/library-search/library-search.component';
import { ReportComponent } from './usage-reports/report/report.component';

const routes: Routes = [
	{
		path: 'client-browser',
		component: ClientBrowserComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'contract-renewals-report',
		component: ContractRenewalsReportComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'contract-renewals-report/salespersonId/:salespersonId',
		component: ContractRenewalsReportComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'library-editor',
		component: LibraryEditorComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'news-manager',
		component: NewsManagerComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'news-manager/new-feed-item',
		component: CreateEditFeedItemComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'news-manager/edit-feed-item/:feedId/:feedItemId',
		component: CreateEditFeedItemComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'on-hold-delivery-queue',
		component: HoldDeliveryQueueComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'player-status-report',
		component: PlayerStatusReportComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'ticket-system',
		component: TicketSystemComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'reminder-emails',
		component: ReminderEmailsComponent,
		canActivate: [adminOrNatalieGuard, authGuard, IsEmployeeGuardService]
	},
	{
		path: 'email-blast-tool/my-templates',
		component: EmailBlastToolComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'email-blast-tool/my-mailings',
		component: EmailBlastToolComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'email-blast-tool/create-mailing/group-id/:groupId',
		component: CreateEditMailingComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'email-blast-tool/edit-mailing/:csrBlastId',
		component: CreateEditMailingComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'email-blast-tool/create-template',
		component: CreateEditTemplateComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'email-blast-tool/edit-template/:templateId',
		component: CreateEditTemplateComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'client-merge-tool',
		component: ClientMergeToolComponent,
		canActivate: [authGuard, IsEmployeeGuardService]
	},
	{
		path: 'usage-reports',
		component: UsageReportsComponent,
		children: [
			{
				path: 'library-search',
				component: LibrarySearchComponent,
				canActivate: [authGuard, IsEmployeeGuardService]
			},
			{
				path: 'report/:libraryIds',
				component: ReportComponent,
				canActivate: [authGuard, IsEmployeeGuardService]
			}
		],
		canActivate: [authGuard, IsEmployeeGuardService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SystemToolsRoutingModule {}
