import { ContentFilesHold, ContentFilesVideo } from '../../components/content-container/content/_models';
import { ContentVoiceTalents } from '../../api-models/content';

export class ContentItem {
	Id: number; //content Id
	categoryId: number;
	libraryId: number;
	duration: number;
	name: string;
	script: string;
	createDate: string;
	isDeleted: boolean;
	productTypeId: number;
	isChannel: boolean;
	isContent: boolean;
	isContentFile: boolean;
	isFavorite?: boolean;
	isGlobal?: boolean;
	thumbnailUrl?: string;
	previewUrl?: string;
	previewActive?: boolean;
	isMusicContent?: boolean;
	talentPickerVisible?: boolean;
	audioPreviewPickerVisible?: boolean;
	order: number;
	voiceTalentId?: number;
	gender?: 'male' | 'female';
	previewFileName: string;
	contentId: number;
	isSelected?: boolean;
	contentName: string;
	isDraggable?: boolean;
	isMusicTrack: boolean;
	silenceMusic: boolean;
	startDate: any;
	stopDate: any;
	advancedSchedule: any;
	stillDuration: number;
	isStopDateSelected: boolean;
	enableAdvancedSchedule: boolean;
	scheduleValid: boolean;
	scheduleDirty: boolean;
	playlistContentFileBridgeId?: number;
	contentFileId: number;
	weatherOverride?: string;
	contentTypeId: number;
	contentType: string;
	processingStateJson: any;
	contentFileStateId: number;
	masterFileName: string;
	contentUnavailable: boolean;
	feedId: number;
	createdByUserId: number;
	libraryName: string;
	libraryTypeColorCode: string;
	weatherOverrideValid: boolean;
	breadcrumbs: string[];
	favoriteBtnTooltip: string;
	voiceTalentGender: string;
	voiceTalentName: string;
	contentFiles: (ContentFilesHold | ContentFilesVideo)[];
	audioPreview: any;
	directory: string;
	thumbIcon: string;
	VoiceTalent: ContentVoiceTalents;
	create24OutputHtmlV4: string;
	create24ProjectJsonV4: string;
	create24BlankBgColor: string;
	description: string;
}
