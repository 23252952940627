import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { AppStateService, DateTimeService, Events, MessageService } from '../../../core/services';
import { ClipsService } from '../clips/clips.service';
import { CloneRequestService, CreateEditRequestService, CreateEditViewStateService, EmailService, MergedRequestService } from '../_services';
import { MasterButtonsService } from './master-buttons.service';
import { ContentRequestStateName } from 'src/app/shared/api-models/admin';

type BtnsView = 'cancelCloneSaveAsComplete' | 'cancelSave' | 'requestRevisionsApprove';

@Component({
	selector: 'cer-master-buttons',
	templateUrl: './master-buttons.component.html',
	styleUrls: ['./master-buttons.component.scss']
})
export class MasterButtonsComponent implements OnInit {
	public btnsView: BtnsView;
	public containerClassList: string = 'shadow border-radius white-bg pd-top15 pd-bottom15 margin-bottom-sm mgn-top30 mgn-bottom60';
	readonly stateName = ContentRequestStateName;

	constructor(
		public appStateService: AppStateService,
		private clipsService: ClipsService,
		public cloneRequestService: CloneRequestService,
		public createEditRequestService: CreateEditRequestService,
		private dateTimeService: DateTimeService,
		private emailService: EmailService,
		private location: Location,
		public masterButtonsService: MasterButtonsService,
		private mergedRequestService: MergedRequestService,
		private messageService: MessageService,
		private router: Router,
		public viewStateService: CreateEditViewStateService
	) {}

	ngOnInit() {
		this.btnsView = this.setBtnsView();
	}

	public applyContainerClassList(): boolean {
		return (
			this.viewStateService.route !== 'content-requests' ||
			this.viewStateService.mode === 'new' ||
			this.createEditRequestService.request.StateName === ContentRequestStateName.PENDING_SCRIPT_APPROVAL
		);
	}

	public onApproveScriptsClick(): void {
		this.createEditRequestService.request.producerUsername = 'tharkin';
		this.createEditRequestService.request.ProducerId = 14;
		this.changeStatusAndSave(ContentRequestStateName.ASSIGNED_AWAITING_PRODUCTION);
	}

	public onCancelRequestClick(): void {
		const confirmOk: boolean = confirm(`Cancel this request? Click "OK" to cancel.`);
		if (confirmOk) {
			this.changeStatusAndSave(ContentRequestStateName.CANCELLED);
		}
	}

	//Exiting new request page without saving
	public onCancelClick(): void {
		const confirmOk: boolean = confirm('Disregard new request?');
		if (confirmOk) {
			if (this.viewStateService.route === 'content-requests') {
				this.router.navigate(['/content-requests']);
			} else {
				this.router.navigate(['/system-tools/content-requests']);
			}
		}
	}

	public onCloneClick(): void {
		this.cloneRequestService.cloneRequest();
		this.btnsView = this.setBtnsView();
	}

	public onRequestRevisionsClick(): void {
		this.changeStatusAndSave(ContentRequestStateName.AWAITING_SCRIPT_EDITS);
		this.emailService.sendEmail(this.emailService.revisionRequestEmail()).subscribe();
	}

	public onRequestVideoRevisionsClick(): void {
		this.changeStatusAndSave(ContentRequestStateName.REVISION_REQUESTED);
		this.emailService.sendEmail(this.emailService.videoRevisionRequestEmail()).subscribe();
	}

	public onSaveNewRequestClick(): void {
		if (this.viewStateService.isMergedRequest) {
			this.mergedRequestService.onSaveMergedRequestClick$().subscribe(() => {
				this.messageService.publish(Events.savingPreloader, 0);
				this.location.back();
			});
		} else {
			this.masterButtonsService.saveNewRequestClick();
		}
	}

	public onSaveAsCompleteClick(): void {
		const confirmOk: boolean = confirm('Change this request status to complete? All other pending changes will also be saved.');
		if (confirmOk) {
			this.createEditRequestService.request.CompletedDateTime = this.dateTimeService.todayWithCurrentTime();
			this.changeStatusAndSave(ContentRequestStateName.COMPLETE);
		}
	}

	public changeStatusAndSave(stateName: ContentRequestStateName): void {
		if (stateName === 'Assigned, Awaiting Production') {
			this.createEditRequestService.request.DueDate = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(); //generates 7 days out
			this.createEditRequestService.request.ApprovedDateTime = new Date().toISOString();
		}
		this.createEditRequestService.request.StateName = stateName;
		this.btnsView = this.setBtnsView();
		this.masterButtonsService.updateRequest().subscribe();

		//Video only
		if (stateName === 'Pending CSR Approval') {
			this.emailService.sendEmail(this.emailService.requestPendingCsrApprovalEmail()).subscribe();

			//Update all clips to IsApproved true
			this.clipsService.updateAllClipsToIsApproved();

			//Change IsApproved flag for each corresponding content to true
			this.createEditRequestService.updateContentToIsApprovedAllClips();
		}

		if (stateName === 'Pending Editor Approval') {
			this.emailService.sendEmail(this.emailService.requestPendingEditorApprovalEmail()).subscribe();
		}

		if (this.viewStateService.route !== 'content-requests') {
			this.router.navigate(['/system-tools/content-requests']);
			return;
		}
		this.router.navigate(['/content-requests']);
	}

	private setBtnsView(): BtnsView {
		switch (true) {
			case this.viewStateService.mode === 'new' || this.viewStateService.isMergedRequest:
				return 'cancelSave';

			case this.viewStateService.route !== 'content-requests' &&
				this.appStateService.currentUser.IsEmployee &&
				!this.createEditRequestService.request.isMergedRequest:
				return 'cancelCloneSaveAsComplete';
		}
	}
}
