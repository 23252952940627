import { Component, Input, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';

import { AppStateService, CacheService, Events, MessageService } from 'src/app/core/services';
import { CatService } from './cat.service';
import { CategoriesService } from '../_services';
import { CategoriesTree, LibrariesTree } from '../_models';
import { ContentViewService } from '../../content-container/content/_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { Content } from 'src/app/shared/api-models/content';
import { Hold, Video } from '../../../components/content-container/content/_models';
import { LibrariesService } from '../_services';
import { FeatureArea } from 'src/app/shared/view-models';
import { FileUploadModalService } from '../../file-upload-modal/file-upload-modal.service';

@Component({
	selector: 'l-cat',
	templateUrl: './cat.component.html',
	styleUrls: ['./cat.component.scss']
})
export class CatComponent implements OnInit {
	@Input() category: CategoriesTree;
	@Input() library: LibrariesTree;
	@Input() subCategories: CategoriesTree[];
	@ViewChild('categoryInput') categoryInput: ElementRef;

	public mouseover: boolean;

	public showSubCategories: boolean;
	public showCategoryInput: boolean;
	public showDropArea: boolean;
	private draggedContent: Hold | Video;
	public isMouseDown: boolean;
	public categoryDropTarget: CategoriesTree;
	public cursorNotAllowed: boolean;
	public newSubCategory: CategoriesTree;

	constructor(
		private appStateService: AppStateService,
		private cacheService: CacheService,
		public catService: CatService,
		public categoriesService: CategoriesService,
		private contentViewService: ContentViewService,
		public cvStateService: ContentViewStateService,
		private fileUploadModalService: FileUploadModalService,
		private librariesService: LibrariesService,
		private messageService: MessageService
	) {}

	ngOnInit(): void {
		this.category.state = 'read';
		this.newSubCategory = new CategoriesTree();
	}

	//listen to mouse events outside of this component
	@HostListener('window:mousedown', ['$event'])
	mouseDown() {
		this.isMouseDown = true;
	}

	@HostListener('window:mouseup', ['$event'])
	mouseUp() {
		this.onDrop();
		this.isMouseDown = false;
		this.showDropArea = false;
	}

	public onCreateCategoryClick(): void {
		this.category.state = 'new';
		setTimeout(() => this.categoryInput?.nativeElement.focus(), 20);
	}

	public onSaveNameClick(): void {
		this.category.state = 'read';
		this.catService.updateCategoryName(this.category);
	}

	public onSaveNewSubCategoryClick(): void {
		this.category.state = 'read';
		this.catService.saveNewCategory(this.newSubCategory.CategoryName, this.category).subscribe(() => {
			this.categoriesService.getCategories(this.category.LibraryId).subscribe((categories: CategoriesTree[]) => {
				this.library.categories = categories;
			});
		});
	}

	public onNameEditClick(): void {
		this.category.state = 'edit';
		setTimeout(() => this.categoryInput?.nativeElement.focus(), 20);
	}

	public onCategoryDeleteClick(): void {
		this.catService.isCategoryDeletableCheck(this.category, true);
	}

	//Click handler to expand category/get content
	public onCategoryClick(): void {
		//Only toggle the parent categories' folder icon, and only if they have sub categories
		if (!this.category.isSubCategory && this.category.InverseParentCategory?.length > 0) {
			this.category.isExpanded = !this.category.isExpanded;
		} else {
			this.category.isExpanded = true;
		}

		this.librariesService.libraries.forEach((library) => {
			//Close all other category folders that do not have sub categories
			library.categories?.forEach((category) => {
				if (category.InverseParentCategory.length === 0 && category.Id !== this.category.Id) {
					category.isExpanded = false;
				}

				//Close all other sub category folders
				category.InverseParentCategory.filter((sub) => sub.Id !== this.category.Id).forEach((sub) => (sub.isExpanded = false));
			});
		});

		//Close folders of sub categories that are not this category
		this.subCategories.filter((sub) => sub.Id !== this.category.Id).forEach((sub) => (sub.isExpanded = false));

		this.cvStateService.viewState.contentListCurrentPage = 1;
		this.catService.getContentList(this.category);

		this.categoriesService.clickedCategory = this.category;
		this.librariesService.clickedLibraryId = this.category.LibraryId;
		this.librariesService.selectedLibrary = this.cacheService.libraries.find((lib) => lib.Id === this.category.LibraryId);
		this.cvStateService.breadcrumbs = this.catService.buildBreadcrumbs(this.category);
		this.fileUploadModalService.getVoiceTalentList();
		this.contentViewService.onCategoryClick();
		this.cvStateService.viewState.contentSearchActive = false;
		this.messageService.publish(Events.onContentSearchClear, null);
	}

	public onMouseover(): void {
		this.enforceDropRestrictions();
		this.mouseover = true;
		this.catService.isCategoryDeletableCheck(this.category);
		this.librariesService.hoveredName = this.category.CategoryName;
	}

	public onMouseleave(): void {
		this.mouseover = false;
		this.showDropArea = false;
		this.cursorNotAllowed = false;
		this.librariesService.hoveredName = null;
	}

	public enforceDropRestrictions(): void {
		if (this.isMouseDown && this.appStateService.getSessionItem('draggedContent')) {
			this.draggedContent = JSON.parse(this.appStateService.getSessionItem('draggedContent'));
			//Employees can drop content between libraries
			if (this.appStateService.currentUser.IsEmployee) {
				this.allowDrop();
			} else if (!this.appStateService.currentUser.IsEmployee) {
				//Only allow dropping within the same library
				if (this.draggedContent.LibraryId === this.category.LibraryId) {
					this.allowDrop();
				} else {
					this.cursorNotAllowed = true;
				}
			}
		}
	}

	private allowDrop(): void {
		this.categoryDropTarget = this.category;
		this.showDropArea = true;
	}

	public onDrop() {
		if (this.showDropArea) {
			//Change category id from current category to dropped category
			//only if library id matches
			this.messageService.publish(Events.savingPreloader, 1);
			let content: Content = new Content();
			content.CategoryId = this.categoryDropTarget.Id;
			content.LibraryId = this.categoryDropTarget.LibraryId;
			this.catService.updateCategoryOnDrop(content, this.categoriesService.clickedCategory, this.draggedContent, this.categoryDropTarget.Id);
		}
	}
}
