<div *ngIf="item" class="container-fluid {{ draggable }}">
	<div
		style="width: 103%"
		id="{{ item.id }}"
		class="row {{ draggable }} {{ draggable === 'draggable' ? 'cursor-move' : null }} {{ setBackgroundColor() }} player border-radius pd8 mgn-bottom6 shadow"
	>
		<!--ITEM NAME-->
		<div class="{{ includeCheckbox || showDuration ? 'col-xs-7' : 'col-xs-10' }} pd-top3 white-text {{ draggable }}">
			<i class="{{ item.icon }} mgn-right10 {{ draggable }} ft-size24" aria-hidden="true"></i>
			<span class="{{ draggable }}" [innerHTML]="item.name"></span>
		</div>

		<div
			*ngIf="showDuration && item?.PlayerModel && item.PlayerModel.Id === 4"
			ngxTippy
			tippyName="{{ utilService.guid | wrapFn }}"
			data-tippy-content="The maximum length of a VOIP production is set by your phone system, not Works24. Please check with your phone system provider if you have difficulty uploading large files"
			[tippyProps]="{ offset: [0, -20] }"
			class="{{ isAssigned ? 'col-xs-4' : 'col-xs-5' }} {{ draggable }} pd-right0 mgn-top5 text-right white-text"
		>
			<span class="{{ draggable }} ft-size12">Duration: VOIP Length Variable</span>
		</div>

		<div
			*ngIf="showDuration && item?.PlayerModel && item.PlayerModel.Id !== 4"
			class="{{ isAssigned ? 'col-xs-4' : 'col-xs-5' }} {{ draggable }} pd-right0 mgn-top5 text-right white-text"
		>
			<span class="{{ draggable }}">Duration: {{ item?.PlayerModel.MaxDurationSeconds / 60 }} minutes</span>
		</div>

		<div *ngIf="includeCheckbox" class="col-xs-3 pd-right0 text-right white-text">
			<span class="relative top5">Write Access</span>
		</div>

		<div *ngIf="includeCheckbox" class="col-xs-1 pd-top4 pd-left0 text-right">
			<portal-checkbox
				[backgroundColor]="'white-bg'"
				[isChecked]="item.WriteAccess"
				[type]="'checkbox'"
				(checkboxClickNotify)="onCheckboxClick()"
			></portal-checkbox>
		</div>

		<!--REMOVE BUTTON-->
		<div
			*ngIf="isAssigned && !item.deleteDisabled"
			(mouseup)="removeItemClickNotify.emit(item)"
			class="{{ includeCheckbox || showDuration ? 'col-xs-1' : 'col-xs-2' }} text-right pd-top3 pointer {{ draggable }}"
		>
			<i class="fas fa-times white-text ft-size24" aria-hidden="true"></i>
		</div>
	</div>
</div>
