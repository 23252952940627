import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import { AppStateService, Events, MessageService, SecurityService, UtilitiesService } from 'src/app/core/services';
import { Content } from 'src/app/shared/api-models/content';
import { CsrNewReleaseSchedule, Mailer } from 'src/app/shared/api-models/admin';
import { environment } from 'src/environments/environment';
import { Hold, Video } from 'src/app/shared/components/content-container/content/_models';

@Injectable({
	providedIn: 'root'
})
export class FeaturedContentService {
	public schedule: any = new CsrNewReleaseSchedule();
	public csrSendDateView: number;
	public holdContentList: Hold[];
	public newReleaseEmail: Mailer = new Mailer();
	public videoContentList: Video[];

	public holdSpinnerActive: boolean;
	public videoSpinnerActive: boolean;

	constructor(
		private appStateService: AppStateService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private securityService: SecurityService,
		private utilService: UtilitiesService
	) {}

	public getContent(): void {
		const clientId: number = this.appStateService.currentClient.Id;
		const holdContent$ = this.httpClient.get<Content[]>(`${environment.contentUrl}Content/GetFeaturedHoldContentForClient/${clientId}`);
		const videoContent$ = this.httpClient.get<Content[]>(`${environment.contentUrl}Content/GetFeaturedVideoContentForClient/${clientId}`);

		forkJoin([holdContent$, videoContent$])
			.pipe(
				switchMap(([holdContentList, videoContentList]: [Hold[], Video[]]) => {
					//Sort by Title
					holdContentList = this.utilService.sortAscOrDesc(holdContentList, 'Title', 'asc');
					videoContentList = this.utilService.sortAscOrDesc(videoContentList, 'Title', 'asc');

					//Get breadcrumbs for each video content
					const getBreadcrumbs$ = videoContentList.map((content) => {
						return this.httpClient.get<string[]>(`${environment.contentUrl}Content/${content.Id}/Breadcrumbs`);
					});

					//Get only music content from list
					const musicContent = holdContentList.filter((content) => content.ContentTypeId === 1);

					//Put music content at the end of the array
					musicContent.forEach((content) => {
						holdContentList.push(holdContentList.splice(holdContentList.indexOf(content), 1)[0]);
					});

					if (videoContentList.length < 1) {
						return of([holdContentList, videoContentList]);
					}

					return forkJoin(getBreadcrumbs$).pipe(
						map((breadcrumbs: [string[]]) => {
							videoContentList = videoContentList.map((content, index) => {
								content.breadcrumbs = breadcrumbs[index];
								return content;
							});
							return [holdContentList, videoContentList];
						})
					);
				})
			)
			.subscribe((res: [Hold[], Video[]]) => {
				this.holdContentList = res[0];
				this.videoContentList = res[1];
				this.holdSpinnerActive = false;
				this.videoSpinnerActive = false;
			});
	}

	public getContentBreadcrumbs(contentId: number): Observable<string[]> {
		return this.httpClient.get<string[]>(`${environment.contentUrl}Content/${contentId}/Breadcrumbs`);
	}

	//SET SEND DATE
	public getCsrSchedule(routeState: 'non-apple-tvs' | 'apple-tvs'): void {
		const { UserId } = this.appStateService.currentUser;
		const path = routeState === 'apple-tvs' ? 'CsrNewReleaseSchedule' : 'CsrClientNewsletterSchedule';
		this.httpClient.get(`${environment.adminUrl}${path}/GetScheduleForCsr/${UserId}`).subscribe(
			(schedule: CsrNewReleaseSchedule) => {
				if (schedule.Id) {
					this.schedule = schedule;
				} else {
					this.createSchedule();
				}
			},
			(error) => {
				//If above call fails, post a new record for the CsrId
				this.httpClient.post(`${environment.adminUrl}CsrClientNewsletterSchedule`, { CsrId: UserId, SendDay: 0 }).subscribe();
			}
		);
	}

	public minDay(): number {
		const lastSent: string = this.schedule.LastSent;
		const firstOfCurrentMonth: Date = new Date(new Date(new Date().setDate(1)));

		if (moment(lastSent).isBefore(firstOfCurrentMonth)) {
			return new Date().getDate() + 1;
		}
		return 1;
	}

	private createSchedule(): void {
		const csrId: number = this.appStateService.currentUser.UserId;
		this.schedule = new CsrNewReleaseSchedule(csrId, 15);
	}

	public save(routeState: 'non-apple-tvs' | 'apple-tvs'): void {
		this.messageService.publish(Events.savingPreloader, 1);
		if (this.schedule.Id) {
			this.patchDate$(routeState).subscribe(() => {
				this.messageService.publish(Events.savingPreloader, 0);
			});
		} else {
			this.postDate$(routeState).subscribe(() => {
				this.messageService.publish(Events.savingPreloader, 0);
			});
		}
	}

	private patchDate$(routeState: 'non-apple-tvs' | 'apple-tvs'): Observable<any> {
		const path = routeState === 'apple-tvs' ? 'CsrNewReleaseSchedule' : 'CsrClientNewsletterSchedule';
		return this.httpClient.patch(`${environment.adminUrl}${path}/${this.schedule.Id}`, { SendDay: this.schedule.SendDay }).pipe(
			switchMap(() => {
				if (this.securityService.userIsNatalieOrKelsey() || this.securityService.userIsAdmin()) {
					return this.updateMailer$();
				}
				return of('');
			})
		);
	}

	private postDate$(routeState: 'non-apple-tvs' | 'apple-tvs'): Observable<any> {
		const path = routeState === 'apple-tvs' ? 'CsrNewReleaseSchedule' : 'CsrClientNewsletterSchedule';
		return this.httpClient.post(`${environment.adminUrl}${path}`, this.schedule).pipe(
			switchMap(() => {
				if (this.securityService.userIsNatalieOrKelsey() || this.securityService.userIsAdmin()) {
					return this.updateMailer$();
				}
				return of('');
			})
		);
	}

	public getMailerTemplate(mailerName: string): void {
		this.httpClient.get(`${environment.adminUrl}Mailer/GetMailerByName/${mailerName}`).subscribe((email: Mailer) => {
			this.newReleaseEmail = email;
		});
	}

	public updateMailer$(): Observable<any> {
		return this.httpClient.put(`${environment.adminUrl}Mailer/${this.newReleaseEmail.Id}`, this.newReleaseEmail);
	}
}
