export class CsrTasks {
    Id: number;
    CsrId: number;
    ClientId: number;
    Created: string;
    Reminder: string;
    DueDate: string;
    CsrTaskTypeId: number;
    Completed: boolean;
    CompleteDate: string;
    Subject: string;
    Notes: string;
}