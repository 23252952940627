import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DateTimeService } from '../core/services';
import { LoginService } from './login.service';
import { BrowserDetectorService } from '../core/services';
import { PasswordResetVerify } from './password-reset-verify';
import { AlertMessage, LoginView } from './_models/login-models';

@Component({
	selector: 'portal-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	public unsupportedBrowserOverlayVisible: boolean;
	readonly loginView = LoginView;

	get todayIsBetweenSetDates() {
		return this.dateTimeService.todayIsAfter(new Date('2022/12/06').toString()) && this.dateTimeService.todayIsBefore(new Date('2022/12/10').toString());
	}

	constructor(
		public browserDetectorService: BrowserDetectorService,
		public dateTimeService: DateTimeService,
		public loginService: LoginService,
		private router: Router
	) {}

	ngOnInit() {
		this.sessionExpireCheck();
		this.loginService.viewState = LoginView.MainView;
		this.unsupportedBrowserOverlayVisible = !this.browserDetectorService.isMajorBrowser() || this.browserDetectorService.isMajorBrowserOldVersion();
		this.loginService.setAlert({
			visible: true,
			bgColor: 'yellow-bg-light',
			textColor: 'red-text',
			message: AlertMessage.Default,
			icon: 'fa-exclamation-triangle'
		});
		this.checkPasswordResetRoute();
	}

	private checkPasswordResetRoute(): void {
		if (this.urlContainsPasswordReset()) {
			this.loginService.viewState = LoginView.ResetPassword;
			this.verifyPasswordResetGuid();
		}
	}

	private verifyPasswordResetGuid(): void {
		const url = window.location.href;
		const guid = url.substr(url.lastIndexOf('/') + 1);
		this.loginService.setAlert({ visible: true, bgColor: 'blue-bg-darkest', textColor: 'white-text', message: AlertMessage.PasswordCreationInstructions });

		this.loginService.passwordResetVerify(guid).subscribe((response: PasswordResetVerify) => {
			this.loginService.userName = response.UserName;

			if (!response.ValidToken) {
				this.loginService.viewState = LoginView.ForgotPassword;
				this.loginService.setAlert({
					visible: true,
					bgColor: 'red-bg-lighter',
					textColor: 'red-text',
					message: AlertMessage.ResetLinkExpired,
					icon: 'fa-exclamation-triangle'
				});
			}
		});
	}

	private sessionExpireCheck(): void {
		switch (true) {
			case window.location.href.indexOf('session-expired') !== -1:
				this.loginService.setAlert({
					visible: true,
					bgColor: 'blue-bg-darkest',
					textColor: 'white-text',
					message: AlertMessage.InactivitySignOut,
					icon: 'fa-desktop'
				});
				break;

			case window.location.href.indexOf('user-sign-out') !== -1:
				this.loginService.setAlert({
					visible: true,
					bgColor: 'blue-bg-darkest',
					textColor: 'white-text',
					message: AlertMessage.SuccessfulSignOut,
					icon: 'fa-check'
				});
				break;
		}
	}

	private urlContainsPasswordReset(): boolean {
		return this.router.url.indexOf('PasswordReset') >= 0;
	}
}
