import { Injectable } from '@angular/core';
import * as uuid from 'uuid/v4';

import { Column, Table, TableConfig } from '../../../shared/components/table/view-models';
import { ViewStateService } from './view-state.service';
import { PageTitle } from '../_models/tickets.enum';

@Injectable({
	providedIn: 'root'
})
export class TableConfigService {
	public nonPostponedViewColumns: Column[] = [
		new Column('TicketTypeName', 'Type', 'string').setIsSortable(true),
		new Column('ClientName', 'Client', 'string').setElementClassList('semi-bold').setIsSortable(true),
		new Column('AssignedToUserName', 'Assigned To', 'string').setIsSortable(true),
		new Column('TicketStateName', 'Status', 'string').setIsSortable(true),
		new Column('ReminderTime', 'Reminder Time', 'date').setIsSortable(true).setElementClassList('semi-bold'),
		new Column('lastActionDateView', 'Last Action', 'string').setIsSortable(true).setSortKey('LastTicketActionDate'),
		new Column('isHighPriorityString', 'High Priority', 'string').setIsSortable(true).setSortKey('IsHighPriority'),
		new Column('Title', 'Summary', 'string').setIsSortable(true),
		new Column('LocationCount', 'Locations', 'string').setIsSortable(true),
		new Column('CreatedDate', 'Created', 'date').setIsSortable(true)
	];
	public nonPostponedViewTable: Table = new Table(this.nonPostponedViewColumns, uuid());

	public postponedViewColumns: Column[] = [
		new Column('ticketTypeName', 'Type', 'string').setIsSortable(true).setSortKey('TicketType'),
		new Column('client.Name', 'Client', 'string').setElementClassList('semi-bold'),
		new Column('assignedToUsername', 'Assigned To', 'string'),
		new Column('status', 'Status', 'string').setIsSortable(true).setSortKey('TicketState'),
		new Column('ReminderTime', 'Reminder Time', 'string').setIsSortable(true),
		new Column('lastActionDateView', 'Last Action', 'string').setIsSortable(true).setSortKey('LastTicketActionDate'),
		new Column('isHighPriorityString', 'High Priority', 'string').setIsSortable(true).setSortKey('IsHighPriority'),
		new Column('Title', 'Summary', 'string').setIsSortable(true),
		new Column('PostponeUntil', 'Postpone Until', 'string'),
		new Column('locationsCount', 'Locations', 'string'),
		new Column('CreatedDate', 'Created', 'date').setIsSortable(true)
	];

	public postponedViewTable: Table = new Table(this.postponedViewColumns, uuid());

	public config: TableConfig = {
		itemsPerPage: 10,
		rowSelect: true,
		headerColor: 'white-bg',
		rowFontSize: 'ft-size14',
		headerFontSize: 'ft-size12',
		disableScrollToTop: true
	};

	//UNCLAIMED TABLE
	public unclaimedColumns: Column[] = [
		new Column('take', 'Take', 'button').setElementClassList('white-bg relative z'),
		new Column('TicketTypeName', 'Type', 'string').setIsSortable(true),
		new Column('ClientName', 'Client', 'string').setElementClassList('semi-bold').setIsSortable(true),
		new Column('lastActionDateView', 'Last Action', 'string').setIsSortable(true).setSortKey('LastTicketActionDate'),
		new Column('isHighPriorityString', 'High Priority', 'string').setIsSortable(true).setSortKey('IsHighPriority'),
		new Column('LocationCount', 'Locations', 'string').setIsSortable(true),
		new Column('Title', 'Summary', 'string').setIsSortable(true)
	];

	public unclaimedTable: Table = new Table(this.unclaimedColumns, uuid());

	public unclaimedTableConfig: TableConfig = {
		itemsPerPage: 10,
		rowSelect: true,
		headerColor: 'white-bg',
		rowFontSize: 'ft-size14',
		headerFontSize: 'ft-size12'
	};

	//INSTALLERS TABLE
	public installersColumns: Column[] = [
		new Column('isActive', 'Active', 'string').setIsSortable(true).setSortKey('IsActive'),
		new Column('CompanyName', 'Company Name', 'string').setElementClassList('semi-bold').setIsSortable(true),
		new Column('ContactName', 'Contact', 'string').setIsSortable(true),
		new Column('Address', 'Address', 'string').setIsSortable(true),
		new Column('City', 'City', 'string').setIsSortable(true),
		new Column('State', 'State', 'string').setIsSortable(true),
		new Column('Zip', 'Zip', 'string').setIsSortable(true),
		new Column('Phone', 'Phone', 'string').setIsSortable(true),
		new Column('Email', 'Email', 'string').setIsSortable(true),
		new Column('Notes', 'Notes', 'string').setIsSortable(true)
	];

	public installersTable: Table = new Table(this.installersColumns, uuid());

	constructor(private viewStateService: ViewStateService) {}

	public setTable(): void {
		if (this.viewStateService.pageTitle === PageTitle.MANAGE_INSTALLERS) {
			this.viewStateService.table = this.installersTable;
		} else if (this.viewStateService.postponedVisible) {
			this.viewStateService.table = this.postponedViewTable;
		} else {
			this.viewStateService.table = this.nonPostponedViewTable;
		}
	}

	public setTableConfig(): void {
		if (this.viewStateService.pageTitle === 'Manage Installers') {
			this.config.headerColor = 'green-bg';
		} else {
			this.config.headerColor = 'white-bg';
		}
	}
}
