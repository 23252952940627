<div class="row mgn-bottom10">
    <div class="col-sm-6">
        <div class="row relative" (mouseover)="deleteReplaceVisible = true;" (mouseleave)="deleteReplaceVisible = false;">
            <div class="col-sm-12">
                <button class="{{contentFile.previewActive ? 'yellow-bg dark-text' : 'blue-bg-darkest white-text'}} noExit shadow border-radius wdth24 ht24 relative mgn-right4" (click)="onPreviewClick()">
                    <i class="absolute center-unknown-height-width fa {{contentFile.previewActive ? 'fa-times' : 'fas fa-play'}} noExit ft-size16" aria-hidden="true"></i>
                </button>
                <i class="fa {{contentFile.previewBtnIcon}} {{contentFile.iconColor}} mgn-right4" aria-hidden="true"></i>
                {{contentFile.VoiceTalent.TalentName}}
            </div>
            <div *ngIf="deleteReplaceVisible" class="in-block absolute right0 setWidth25">
                <button *ngIf="replaceButtonVisible()" (mouseup)="fileUploadStateService.uploaderInit()" (click)="selectedIndex = index;" (click)="replaceFileInput.click()"  (mouseenter)="onHoverBtnMouseenter('replace')" (mouseleave)="onHoverBtnMouseleave()" class="wdth24 ht24 yellow-bg border-radius relative mgn-right6"><i class="fas fa-sync-alt absolute center-unknown-height-width" aria-hidden="true"></i></button>
                <div *ngIf="replaceTooltipVisible" style="opacity: 0.9; z-index: 200;" class="absolute center setWidth100 right30 ft-size12 bottomneg26 border-radius pd3 dark-bg white-text">
                        Replace
                </div>
                <button *ngIf="index > 1" (mouseup)="onDeleteClick()" (mouseenter)="onHoverBtnMouseenter('delete')" (mouseleave)="onHoverBtnMouseleave()" class="wdth24 ht24 red-bg-light white-text border-radius relative"><i class="far fa-trash-alt absolute center-unknown-height-width" aria-hidden="true"></i></button>
                <div *ngIf="deleteTooltipVisible" style="opacity: 0.9; width: 90%; right: 0%; z-index: 200;" class="absolute center ft-size12 bottomneg26 border-radius pd3 dark-bg white-text">
                        Delete
                </div>
                
            </div>
            <div *ngIf="fileUploadStateService.validationError && index === selectedIndex" class="red-text-light ft-size10 text-right">
                Unsupported file type!
            </div>
        </div>
        
    </div>
    <div *ngIf="contentFile.writeAccess" class="col-sm-6">
        <portal-dropdown-menu
                            [backgroundColor]="'gray-bg-dark'"
                            [height]="'ht36'"
                            [options]="detailsService.voiceTalentList"
                            [key]="'TalentName'"
                            [selectedOption]="contentFile.VoiceTalent.TalentName"
                            (onOptionSelect)="onVoiceTalentSelect($event)">				
        </portal-dropdown-menu>
    </div>
    <div *ngIf="!contentFile.writeAccess" class="col-sm-4 gray-text-darker"><em>Setting not available.</em></div>
</div>
<input #replaceFileInput name="file" type="file" onclick="value = null" ng2FileSelect [uploader]="fileUploadStateService.uploader" class="hidden" (change)="onReplaceInputClick()" />
    