import { Component, Input, OnInit } from '@angular/core';

import { AppStateService, UtilitiesService } from '../../../../../../core/services';
import { Hold, Video } from '../../_models';
import { ContentViewService } from '../../_services';

@Component({
	selector: 'vc-playlist-details-view',
	templateUrl: './playlist-details-view.component.html',
	styleUrls: ['./playlist-details-view.component.scss']
})
export class PlaylistDetailsViewComponent implements OnInit {
	@Input() content: Hold | Video;
	@Input() contentList: (Hold | Video)[];
	@Input() isFirstIndex: boolean;

	constructor(public appStateService: AppStateService, public contentViewService: ContentViewService, public utilService: UtilitiesService) {}

	ngOnInit() {}

	public onPlayClick(): void {
		this.contentViewService.onPlayClick(this.content as Hold, null, 'playlist-details', this.contentList as Hold[]);
	}
}
