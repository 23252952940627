import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, Subject, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AdminDataService, AppStateService, UtilitiesService } from '../../core/services';
import { environment } from '../../../environments/environment';
import { PlayerGroups, PlayerModels, ServiceLocations } from '../../shared/api-models/admin';
import { Players, Users } from '../../shared/api-models/admin';
import { PlayersDragDrop, PlayerGroupsDragDrop } from '../../shared/view-models/admin';
import { PlaylistService } from './playlist.service';

@Injectable({
	providedIn: 'root'
})
export class ChoosePlayersDataService {
	//Observable sources
	private getPlaylistDeliveryQueueRecordsSource = new Subject<any>();

	//Observable streams
	public getPlaylistDeliveryQueueRecords$ = this.getPlaylistDeliveryQueueRecordsSource.asObservable();

	public clientHasAtLeastOneVoipPlayer: boolean;
	public players: PlayersDragDrop[] = [];
	public playerGroups: PlayerGroupsDragDrop[] = [];
	public voipRecipientsEmailList: { friendlyView: string; email: string }[]; //List of users, first/last name and email by client

	public leftHeading: string;
	public rightHeading: string;

	constructor(
		private adminDataService: AdminDataService,
		private appStateService: AppStateService,
		private httpClient: HttpClient,
		private playlistService: PlaylistService,
		private utilService: UtilitiesService
	) {}

	public getAvailableHoldPlayers(): void {
		this.httpClient
			.get<Players[]>(`${environment.adminUrl}CorePlayers/AvailablePlayers?productTypeId=1&clientId=${this.appStateService.currentClient.Id}`)
			.subscribe((players: Players[]) => {
				this.clientHasAtLeastOneVoipPlayer = players.some((player) => player.PlayerModelId === 4);
			});
	}

	public getUsersByClient(): void {
		this.httpClient.get(`${environment.adminUrl}CoreUsers/GetUsersByClientId/${this.appStateService.currentClient.Id}`).subscribe((users: Users[]) => {
			const voipRecipientsEmailList = users
				.filter((user) => !user.IsDeleted && user.ContactInformation)
				.map((user) => {
					const obj: any = {};
					obj.friendlyView = `${user.ContactInformation.FirstName} ${user.ContactInformation.LastName} (${user.ContactInformation.Email})`;
					obj.Id = user.ContactInformation.Id;
					obj.FirstName = user.ContactInformation.FirstName;
					obj.LastName = user.ContactInformation.LastName;
					obj.Email = user.ContactInformation.Email;
					return obj;
				});
			this.voipRecipientsEmailList = this.utilService.sortItems(voipRecipientsEmailList, 'friendlyView');
		});
	}

	public getPlayersByProductType(productTypeId: number): Observable<PlayersDragDrop[]> {
		return this.httpClient.get<PlayersDragDrop[]>(`${environment.adminUrl}CoreUsers/${this.appStateService.currentUser.UserId}/Players`).pipe(
			mergeMap((players: PlayersDragDrop[]) => {
				const filteredPlayers = players.filter((player) => player.ProductTypeId === productTypeId && player.PlayerModelId !== 99); //Filter License/Pull players
				const locations$ = filteredPlayers.map((player) => {
					return this.httpClient.get(`${environment.adminUrl}CoreServiceLocations/${player.LocationId}`).pipe(
						map((location: ServiceLocations[]) => {
							return location[0];
						})
					);
				});
				return forkJoin(locations$).pipe(
					map((locations: ServiceLocations[]) => {
						const mappedPlayers = filteredPlayers.map((player, index) => {
							player.Location = locations[index];

							return this.adminDataService.setPlayerViewModel(player, null, this.playerDisabled(player.PlayerModel));
						});
						return this.utilService.sortItems(mappedPlayers, 'PlayerName');
					})
				);
			})
		);
	}

	public getPlayerGroups(productTypeId: number): Observable<PlayerGroups[]> {
		return this.httpClient.get<PlayerGroupsDragDrop[]>(`${environment.adminUrl}CoreUsers/${this.appStateService.currentUser.UserId}/PlayerGroups`).pipe(
			mergeMap((playerGroups: PlayerGroupsDragDrop[]) => {
				//Array  of GET calls
				const players$ = playerGroups.map((group) => {
					return this.httpClient.get(`${environment.adminUrl}CorePlayerGroups/${group.Id}/Players`);
				});

				//Get players for each group
				return forkJoin(players$).pipe(
					mergeMap((players: PlayersDragDrop[][]) => {
						//Assign group.players
						playerGroups = playerGroups.map((group, index) => {
							group.players = players[index];
							return group;
						});

						//Array of GET calls
						const tempLocations$ = playerGroups.map((group) => {
							return group.players.map((player) => {
								if (player && player.UnitIdentifier) {
									return this.httpClient.get(`${environment.adminUrl}CoreServiceLocations/ByPlayerIdentifier/${player.UnitIdentifier}`);
								}
								return of('');
							});
						});
						//Flatten array
						const locations$ = [].concat.apply([], tempLocations$);

						//Get location for each player
						return forkJoin(locations$).pipe(
							map((locations: ServiceLocations[]) => {
								//Assign player.Location
								return playerGroups.map((group) => {
									group.players.map((player) => {
										player.Location = locations.find((loc) => loc.Id === player.LocationId);
										return this.adminDataService.setPlayerViewModel(player, null, this.playerDisabled(player.PlayerModel));
									});
									if (group.ProductTypeId === productTypeId) {
										//If at least one player in the group is disabled, disable the group
										const groupDisabled: boolean = group.players.some((player) => this.playerDisabled(player.PlayerModel));
										return this.adminDataService.setGroupViewModel(group, groupDisabled);
									}
								});
							})
						);
					})
				);
			})
		);
	}

	public playerDisabled(playerModel: PlayerModels): boolean {
		if (this.appStateService.product.Route === 'hold') {
			return this.playlistService.getPlaylistDuration() > playerModel.MaxDurationSeconds * 1000;
		}
		return false;
	}
}
