<div *ngIf="todayIsBetweenSetDates" class="red-bg white-text center pd15">
	<p class="semi-bold ft-size18">Works24 will close at 1 pm on Friday, Dec. 9, to celebrate some of your favorite people for a job well done in 2022!</p>
</div>

<div class="container-fluid">
	<div class="row mgn-top60">
		<div class="col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4">
			<div style="border-radius: 10px; max-width: 536px" class="ht660 white-bg relative login-box shadow center mgn-bottom60">
				<!--*****************************
				UNSUPPORTED BROWSER WARNING
			******************************-->
				<div *ngIf="unsupportedBrowserOverlayVisible" style="border-radius: 10px; max-width: 536px" class="browser-overlay z white-text center">
					<div class="row relative mgn0 top124">
						<div class="col-xs-12">
							<h2 class=""><em>This website is supported by the latest versions of the following browsers:</em></h2>
						</div>
						<div class="col-xs-12 mgn-top30 ft-size20 supported-browsers">
							<a href="https://www.google.com/chrome/" target="_blank">Chrome</a>
							<br />
							<a href="https://www.microsoft.com/en-us/edge" target="_blank">Edge</a>
							<br />
							<a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a>
							<br />
							<a href="https://support.apple.com/downloads/safari" target="_blank">Safari</a>
							<br />
							<span>Internet Explorer</span>
						</div>
						<div class="col-xs-12 mgn-top60 ft-size20">
							<span *ngIf="!browserDetectorService.isMajorBrowserOldVersion()">
								Since you are seeing this message, you are currently using an unsupported browser. Continuing with an unsupported browser may result in some
								quirky behavior.
							</span>
							<span *ngIf="browserDetectorService.isMajorBrowserOldVersion()">
								It looks like you are using a browser listed above but it's an old version. Continuing with an old browser version may result in some quirky
								behavior.
							</span>
							<button class="green-bg mgn-top30 ht40 white-text" (click)="unsupportedBrowserOverlayVisible = false">Dismiss</button>
						</div>
					</div>
				</div>
				<!--****************
					LOGO
				*****************-->
				<div class="w24-logo-container dark-bg pd-top15 mb-8">
					<img id="logo" src="assets/login-logo.png" />
				</div>

				<!--************************************
					ALERTS
				**************************************-->
				<div *ngIf="loginService.alert.visible" class="row mgn-top12">
					<div class="col-xs-10 col-xs-offset-1">
						<div class="pd10 border-radius relative {{ loginService.alert.bgColor }} {{ loginService.alert.textColor }}">
							<!--ALERT MESSAGE-->
							<span class="semi-bold">
								<i *ngIf="loginService.alert.icon" class="fa {{ loginService.alert.icon }} mgn-right10" aria-hidden="true"></i>
								{{ loginService.alert.message }}
							</span>
						</div>
					</div>
				</div>

				<l-main-view *ngIf="loginService.viewState === loginView.MainView"></l-main-view>
				<l-forgot-password-view *ngIf="loginService.viewState === loginView.ForgotPassword"></l-forgot-password-view>
				<l-reset-password-view *ngIf="loginService.viewState === loginView.ResetPassword"></l-reset-password-view>
				<l-auth-code-view *ngIf="loginService.viewState === loginView.AuthCode"></l-auth-code-view>
				<div *ngIf="loginService.viewState !== loginView.AuthCode" class="row mgn-top40 absolute col-centered setWidth100 bottom30">
					<div class="col-xs-12">
						<span>
							Not yet a client?
							<br />
							Click
							<a href="http://www.works24.com" target="_new">here</a>
							to find out what Works24
							<br />
							can do for your business
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
