import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, concat, forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { CsrBlasts, CsrBlastGroups, CsrBlastRecipients, MailerTemplates } from 'src/app/shared/api-models/admin';
import { AppStateService, DateTimeService, Events, MessageService, UtilitiesService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root'
})
export class CreateMailingService {
	public group: CsrBlastGroups;
	public mailing: CsrBlasts = new CsrBlasts();
	public mailerTemplate: MailerTemplates = this.template();
	public mode: 'edit' | 'new';
	public recipients: CsrBlastRecipients[];
	public recipientsView: CsrBlastRecipients[];
	public saveAsTemplate: boolean;
	public searchResults: any[];
	public spinnerActive: boolean;

	constructor(
		private appStateService: AppStateService,
		private dateTimeService: DateTimeService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private router: Router,
		private utilService: UtilitiesService
	) {}

	//Post mailing, post recipients, post template, send email
	public createMailing(groupId: number, str?: 'sendEmail' | null): void {
		this.mailing.CreatedDate = new Date().toISOString();
		this.mailing.TotalRecipients = this.recipients.length;
		this.messageService.publish(Events.savingPreloader, 1);
		const method: string = this.mode === 'new' ? 'post' : 'put';
		const url: string = this.mode === 'new' ? `${environment.adminUrl}CsrBlasts` : `${environment.adminUrl}CsrBlasts/${this.mailing.Id}`;

		this.httpClient[method](url, this.mailing)
			.pipe(
				switchMap((mailing: CsrBlasts) => {
					const mailingId: number = this.mode === 'new' ? mailing.Id : this.mailing.Id;
					return concat(...this.observs$(mailingId, str));
				})
			)
			.subscribe(() => {
				this.messageService.publish(Events.savingPreloader, 0);
				if (this.mode === 'new') {
					this.httpClient.delete(`${environment.adminUrl}CsrBlastGroups/${groupId}`).subscribe();
				}
				this.router.navigate(['/email-blast-tool/my-mailings']);
			});
	}

	private observs$(mailingId: number, str?: 'sendEmail' | null): any[] {
		this.mailerTemplate.HtmlTemplate = this.mailing.Html;
		const postRecipients$ = this.httpClient.post(`${environment.adminUrl}CsrBlastRecipients/PostRecipientList/${mailingId}`, this.recipients);
		const postTemplate$ = this.httpClient.post(`${environment.adminUrl}MailerTemplate`, this.mailerTemplate);
		const sendEmail$ = this.httpClient.get(`${environment.adminUrl}CsrBlasts/SendCsrBlast/${mailingId}`);

		return [this.mode === 'new' ? postRecipients$ : of(''), this.saveAsTemplate ? postTemplate$ : of(''), str === 'sendEmail' ? sendEmail$ : of('')];
	}

	public getGroupAndRecipients(groupId: number): any {
		this.httpClient
			.get(`${environment.adminUrl}CsrBlastGroups/${groupId}`)
			.pipe(
				switchMap((group: CsrBlastGroups) => {
					this.group = group;
					const queryString: string = group.ClientBrowserUrl.replace(/^([^?]+)/, '');
					return this.httpClient.get(`${environment.adminUrl}Reports/GetCsrBlastRecipients/${queryString}`);
				})
			)
			.subscribe((recipients: CsrBlastRecipients[]) => {
				this.recipients = this.utilService.sortItems(this.recipientsViewModel(recipients), 'ClientName');
				this.recipientsView = this.utilService.sortItems(this.recipientsViewModel(recipients), 'ClientName');
			});
	}

	private recipientsViewModel(recipients: CsrBlastRecipients[]): any[] {
		return recipients.map((recipient) => {
			recipient.deleteBtn = 'Delete';
			recipient.classList = 't-row';
			recipient.friendlyName = `${recipient.UserFirstName} ${recipient.UserLastName}`;
			recipient.mainContact = recipient.IsPrimaryContact ? 'fas fa-check' : '';
			return recipient;
		});
	}

	public getMailerTemplates(): Observable<MailerTemplates> {
		return this.httpClient.get<MailerTemplates>(`${environment.adminUrl}MailerTemplate/GetCsrBlastTemplatesForCsr/${this.appStateService.currentUser.UserId}`);
	}

	public getMailingAndRecipients(blastId: number): void {
		const mailing$ = this.httpClient.get(`${environment.adminUrl}CsrBlasts/${blastId}`);
		const recipients$ = this.httpClient.get(`${environment.adminUrl}CsrBlastRecipients/GetRecipientsByBlastId/${blastId}`);
		forkJoin([mailing$, recipients$]).subscribe((res: [CsrBlasts, CsrBlastRecipients[]]) => {
			this.mailing = res[0];
			this.recipients = this.recipientsViewModel(res[1]);
			this.recipientsView = this.recipientsViewModel(res[1]);
			if (this.mailing.IncludePrimaryContactOnly) {
				this.recipientsView = this.recipientsView.filter((r) => r.IsPrimaryContact);
			}
		});
	}

	public deleteRecipient(recipientId: number): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.httpClient.delete(`${environment.adminUrl}CsrBlastRecipients/${recipientId}`).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	public csrBlast(): CsrBlasts {
		const blast: CsrBlasts = new CsrBlasts();
		blast.CsrId = this.appStateService.currentUser.UserId;
		blast.IncludePrimaryContactOnly = false;
		return blast;
	}

	private template(): MailerTemplates {
		const template: MailerTemplates = new MailerTemplates();
		template.Name = `My Template - ${this.dateTimeService.dateLocal(new Date().toISOString())}`;
		template.HtmlTemplate = this.mailing.Html;
		template.CsrBlastCsrId = this.appStateService.currentUser.UserId;
		return template;
	}

	public sendTestEmail(): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.httpClient.post(environment.adminUrl + 'Email/Html', this.testEmail()).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	private testEmail(): any {
		return {
			To: this.mailing.TestEmail,
			FromName: 'Test Email Blast',
			FromEmail: 'testemailblast@works24.com',
			Subject: this.mailing.EmailSubject,
			Body: this.mailing.Html,
			BodyHtml: this.mailing.Html
		};
	}
}
