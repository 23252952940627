import { Component, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppStateService } from 'src/app/core/services';
import { CanvasService } from '../canvas/_services';
import { ContentViewService } from 'src/app/shared/components/content-container/content/_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { CountdownAndDateService } from './countdown-and-date/countdown-and-date.service';
import { Create24Service } from '../../create-24.service';
import { CreateLayerSidebarService } from './create-layer-sidebar.service';
import { FeedService } from '../layer-detail/feed/feed.service';
import { LayersService, UndoRedoService } from '../_services';
import { Layer, LayerType } from '../_models';
import { UtilitiesService } from 'src/app/core/services';
import { Video } from 'src/app/shared/components/content-container/content/_models';
import { c24CountdownLinks } from 'src/app/shared/tooltips/tooltips';
import { Create24Area } from '../../_models/create-24-state.enum';

@Component({
	selector: 'me-create-layer-sidebar',
	templateUrl: './create-layer-sidebar.component.html',
	styleUrls: ['./create-layer-sidebar.component.scss']
})
export class CreateLayerSidebarComponent implements OnInit, OnDestroy, AfterContentInit {
	public contentType: string;

	public showFeedPicker: boolean;
	public showShapePicker: boolean;
	public showDynamicOptionsIcon: boolean = false; // Feeds, Countdown, Current Date, Current Time
	private subs: Subscription[] = [];
	public c24CountdownLinks = c24CountdownLinks;
	readonly create24Area = Create24Area;

	constructor(
		private activatedRoute: ActivatedRoute,
		public appStateService: AppStateService,
		private canvasService: CanvasService,
		private contentViewService: ContentViewService,
		private countdownAndDateService: CountdownAndDateService,
		public create24Service: Create24Service,
		public createLayerSidebarService: CreateLayerSidebarService,
		public cvStateService: ContentViewStateService,
		private feedService: FeedService,
		public layersService: LayersService,
		private router: Router,
		public undoRedoService: UndoRedoService,
		public utilService: UtilitiesService
	) {
		this.onContentSelect();
	}

	ngOnInit(): void {
		this.contentType = this.activatedRoute.snapshot.paramMap.get('contentType');
	}

	ngAfterContentInit() {
		//Dynamic content not allowed on web players and sidebar
		this.showDynamicOptionsIcon =
			!this.utilService.includes(this.appStateService.product.ProductName, 'Web') && !this.utilService.includes(this.create24Service.c24ActiveArea, 'sidebar');
	}

	public autoClose(event): void {
		if (!this.utilService.closest(event, '.noExit')) {
			this.showShapePicker = false;
			this.showFeedPicker = false;
			this.createLayerSidebarService.showCountdownOptionsPopup = false;
		}
	}

	public onContentSelect(): void {
		this.subs.push(
			this.contentViewService.onC24LayerContentSelect$.subscribe((content: Video) => {
				const activeUrl: string = this.router.routerState.snapshot.url;
				this.create24Service.popup = '';
				//Add new image layer
				if (!this.utilService.includes(activeUrl, 'replace')) {
					const layer: Layer = new Layer(
						this.layersService.firstLayerId() ? this.layersService.firstLayerId() + 1 : 1,
						'Image',
						'far fa-image',
						'yellow',
						'Image',
						this.create24Service.contentType()
					);
					layer.previewUrl = content.previewUrl;
					this.layersService.onCreateLayerClick(layer);
					//Replace image src
				} else {
					const activeObject = this.canvasService.canvas.getActiveObject();

					//Remove and re-add instead of replace src, due to canvas still being "tainted"
					//when trying to crop an image. Deleting/re-adding seems to fix this issue.
					this.canvasService.canvas.remove(activeObject);
					this.layersService.activeLayer.previewUrl = content.previewUrl;
					this.canvasService.addImage(this.layersService.activeLayer);
				}
			})
		);
	}

	public onAddImageClick(): void {
		if (!this.disabled()) {
			this.layersService.onChangeContentClick(Create24Area.IMAGE_LAYER_CREATE);
		}
	}

	public onAddTextClick(): void {
		if (!this.disabled()) {
			const layer: Layer = new Layer(
				this.layersService.firstLayerId() ? this.layersService.firstLayerId() + 1 : 1,
				'Text',
				'fas fa-font',
				'blue',
				'Text',
				this.create24Service.contentType()
			);
			layer.canvasObj.text = 'New text layer';
			this.layersService.onCreateLayerClick(layer);
			this.undoRedoService.recordState('Add text layer');
		}
	}

	public onCountdownClick(): void {
		//If all three layers exist, disable the popup
		//Only one countdown and date layer allowed per project
		if (
			!this.createLayerSidebarService.currentDateExists() ||
			!this.createLayerSidebarService.countdownExists() ||
			!this.createLayerSidebarService.currentTimeExists()
		) {
			this.createLayerSidebarService.showCountdownOptionsPopup = !this.createLayerSidebarService.showCountdownOptionsPopup;
			this.countdownAndDateService.mode = 'new';
		}
	}

	public onShapePickerClick(): void {
		if (!this.disabled()) {
			this.showShapePicker = !this.showShapePicker;
		}
	}

	public onShapeClick(name: string, icon: string, timelineColor: string): void {
		if (!this.disabled()) {
			this.layersService.onCreateLayerClick(
				new Layer(
					this.layersService.firstLayerId() ? this.layersService.firstLayerId() + 1 : 1,
					name,
					icon,
					timelineColor,
					name as LayerType,
					this.create24Service.contentType()
				)
			);
			this.showShapePicker = false;
			this.undoRedoService.recordState(`Add ${name.toLowerCase()} layer`);
		}
	}

	public onShowFeedPickerClick(): void {
		if (!this.disabled()) {
			this.showFeedPicker = !this.showFeedPicker;
		}
	}

	public onAddFeedTextClick(): void {
		if (!this.disabled()) {
			this.layersService.onCreateLayerClick(
				new Layer(
					this.layersService.firstLayerId() ? this.layersService.firstLayerId() + 1 : 1,
					'Feed Text',
					'fas fa-sync-alt',
					'pink',
					'Feed Text',
					this.create24Service.contentType()
				)
			);
			this.undoRedoService.recordState('Add feed text layer');
			this.showFeedPicker = false;
		}

		//If no feeds list, get feeds
		if (!this.canvasService.feedsList) {
			this.feedService.getFeeds();
		}
	}

	public onAddFeedImageClick(): void {
		if (!this.disabled()) {
			this.layersService.onCreateLayerClick(
				new Layer(
					this.layersService.firstLayerId() ? this.layersService.firstLayerId() + 1 : 1,
					'Feed Image',
					'far fa-image',
					'pink',
					'Feed Image',
					this.create24Service.contentType()
				)
			);
			this.undoRedoService.recordState('Add feed image layer');
			this.showFeedPicker = false;
		}

		//If no feeds list, get feeds
		if (!this.canvasService.feedsList) {
			this.feedService.getFeeds();
		}
	}

	//Limit layer count to 30
	private disabled(): boolean {
		return this.layersService.layers.length >= 30;
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
