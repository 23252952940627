import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

import { AppStateService, Events, MessageService, SecurityService, UtilitiesService } from 'src/app/core/services';
import { CanvasService } from '../main-editor/canvas/_services';
import { Content, ContentFiles, ContentLibraries, ContentLibraryCategories, Feeds } from 'src/app/shared/api-models/content';
import { ContentFilesHold, ContentFilesVideo, Video } from 'src/app/shared/components/content-container/content/_models';
import { Create24Service } from '../create-24.service';
import { CreateEditFeedItemService } from 'src/app/my-feeds/edit-feed/create-edit-feed-item/create-edit-feed-item.service';
import { environment } from '../../../../environments/environment';
import { ExportSVGService } from '../main-editor/_services/export-svg.service';
import { ExportSvgScriptsService } from '../main-editor/_services/export-svg-scripts.service';
import { FeatureArea } from 'src/app/shared/view-models';
import { LayersService } from '../main-editor/_services/layers.service';
import { StateService } from '../main-editor/_services/state.service';
import { FileUploadModalService } from 'src/app/shared/components/file-upload-modal/file-upload-modal.service';
import { Create24Area } from '../_models/create-24-state.enum';

@Injectable({
	providedIn: 'root'
})
export class ExportToLibraryService {
	public file: File;
	public categories: ContentLibraryCategories[];
	public projectState: 'new' | 'edit';
	public c24ContentLibrary: ContentLibraries; //Library for an existing c24 project
	public c24ContentCategory: ContentLibraryCategories;
	public feeds: Feeds[];
	public libraries: ContentLibraries[];
	public prevCreate24Area: Create24Area;
	public saveState: 'overwrite' | 'copy';

	constructor(
		private appStateService: AppStateService,
		private canvasService: CanvasService,
		private create24Service: Create24Service,
		private createEditFeedItemService: CreateEditFeedItemService,
		private exportSvgScriptsService: ExportSvgScriptsService,
		private exportSVGService: ExportSVGService,
		private httpClient: HttpClient,
		private layersService: LayersService,
		private messageService: MessageService,
		private router: Router,
		private securityService: SecurityService,
		private stateService: StateService,
		private fileUploadModalService: FileUploadModalService,
		private utilService: UtilitiesService
	) {}

	public getCategories(libraryId: number): void {
		this.httpClient
			.get<ContentLibraryCategories[]>(`${environment.contentUrl}ContentLibraries/${libraryId}/Categories`)
			.subscribe((categories: ContentLibraryCategories[]) => {
				this.categories = categories;
			});
	}

	//Previously saved C24 content..re-get here since we can't save to session storage for browser refresh...too large
	public getContentAndContentFile(): Observable<Video> {
		return this.httpClient.get<Video>(`${environment.contentUrl}Content/${this.stateService.state().c24ContentId}`).pipe(
			mergeMap((content: Video) => {
				return this.httpClient.get<(ContentFilesHold | ContentFilesVideo)[]>(`${environment.contentUrl}Content/${content.Id}/ContentFiles`).pipe(
					map((contentFiles: (ContentFilesHold | ContentFilesVideo)[]) => {
						content.contentFiles = contentFiles;
						return content;
					})
				);
			})
		);
	}

	//Get libraries list and existing project content library
	public getLibraries(): void {
		const saveFromC24: boolean = true;
		const librariesList$ = this.fileUploadModalService.getLibraries$(saveFromC24);
		const contentLibrary$ = this.httpClient.get(`${environment.contentUrl}ContentLibraries/${this.stateService.c24Content?.LibraryId}`);
		const contentLibraryCategory$ = this.httpClient.get(`${environment.contentUrl}ContentLibraryCategoriesLight/${this.stateService.c24Content?.CategoryId}`);

		let observs$;
		if (this.projectState === 'edit') {
			observs$ = [librariesList$, contentLibrary$, contentLibraryCategory$];
		} else {
			observs$ = [librariesList$];
		}

		forkJoin(observs$)
			.pipe(
				map((res: [ContentLibraries[], ContentLibraries, ContentLibraryCategories]) => {
					const [librariesList, contentLibrary, contentLibraryCategory] = res;
					this.libraries = librariesList.filter((lib) => lib.AllowCreate24);

					//Only select the template library by default if feature area is c24-video-template, AND user is AdminOrVideo
					if (
						this.prevCreate24Area === Create24Area.VIDEO_TEMPLATE &&
						this.appStateService.currentUser.IsEmployee &&
						this.securityService.userRoleAdminOrVideo(this.appStateService.currentUser.Role)
					) {
						this.c24ContentLibrary = contentLibrary;
						this.c24ContentCategory = contentLibraryCategory;
					}

					//If feature area is not c24-video-template, select content's library by default
					if (this.prevCreate24Area !== Create24Area.VIDEO_TEMPLATE) {
						this.c24ContentLibrary = contentLibrary;
						this.c24ContentCategory = contentLibraryCategory;
					}
				})
			)
			.subscribe();
	}

	public getFeeds(): void {
		this.httpClient
			.get(`${environment.contentUrl}Feeds/Client/${this.appStateService.currentClient.Id}`)
			.pipe(
				map((feeds: Feeds[]) => {
					let filteredFeeds = feeds.filter((feed) => feed.FeedSourceId === 1000008 && !feed.IsDeleted);
					return this.utilService.sortItems(filteredFeeds, 'Name');
				})
			)
			.subscribe((feeds) => (this.feeds = feeds));
	}

	public createFeedItem(): void {
		this.createEditFeedItemService.feedItem.FeedId = this.createEditFeedItemService.feed.Id;
		this.createEditFeedItemService.feedItem.ItemContent = JSON.stringify(this.createEditFeedItemService.itemContent());

		this.httpClient.post(`${environment.contentUrl}FeedItems`, this.createEditFeedItemService.feedItem).subscribe();
	}

	private newContentCategory(): ContentLibraryCategories {
		return {
			LibraryId: this.c24ContentLibrary.Id,
			ParentCategoryId: null,
			CategoryName: 'New Content',
			IsDeleted: false
		};
	}

	private contentTypeId(): number {
		switch (this.prevCreate24Area) {
			case Create24Area.NEW_VIDEO:
				return 1000007;
			case Create24Area.NEW_IMAGE:
				return 1000006;
			case Create24Area.NEW_SIDEBAR:
				return 1000009;
			default:
				return this.stateService.c24Content.ContentTypeId;
		}
	}

	private createNewContentCategory$(): Observable<any> {
		return this.httpClient.post(`${environment.contentUrl}ContentLibraryCategories`, this.newContentCategory());
	}

	private createContentAndContentFile$(categoryId: number): Observable<any> {
		let content: Content = this.content(categoryId);
		this.saveState !== 'overwrite' ? delete content.Id : null;

		//Save Content
		return this.httpClient[this.apiMethodAndUrl().method](this.apiMethodAndUrl().contentUrl, this.content(categoryId)).pipe(
			mergeMap((res: Content | null) => {
				let savedContent: Content;
				switch (true) {
					case this.projectState === 'new':
					case this.saveState === 'copy':
						savedContent = res;
						this.stateService.c24ContentId = savedContent.Id;
						break;
					case this.saveState === 'overwrite':
						savedContent = this.stateService.c24Content;
						this.stateService.c24ContentId = this.stateService.c24Content.Id;
						break;
				}
				//Save ContentFile
				return this.httpClient[this.apiMethodAndUrl().method](this.apiMethodAndUrl().contentFileUrl, this.contentFile(savedContent.Id)).pipe(
					mergeMap((res: ContentFiles | null) => {
						let savedContentFile: ContentFiles;

						switch (true) {
							case this.projectState === 'new':
							case this.saveState === 'copy':
								savedContentFile = res;
								break;
							case this.saveState === 'overwrite':
								savedContentFile = this.stateService.c24Content.contentFiles[0];
								break;
						}

						//Upload thumbnail
						return this.httpClient.put(
							`${environment.contentUrl}ContentFiles/ReplaceThumbnail/${savedContentFile.Id}?isWidgetImage=${this.isSidebar()}`,
							this.formData(this.file)
						);
					})
				);
			})
		);
	}

	public isSidebar(): boolean {
		return this.utilService.includes(this.create24Service.c24ActiveArea, 'sidebar');
	}

	private apiMethodAndUrl(): { method: string; contentUrl: string; contentFileUrl: string } {
		switch (true) {
			case this.projectState === 'new':
			case this.saveState === 'copy':
				return { method: 'post', contentUrl: `${environment.contentUrl}Content`, contentFileUrl: `${environment.contentUrl}ContentFiles` };

			case this.saveState === 'overwrite':
				return {
					method: 'put',
					contentUrl: `${environment.contentUrl}Content/${this.stateService.c24Content.Id}`,
					contentFileUrl: `${environment.contentUrl}ContentFiles/${this.stateService.c24Content.contentFiles[0].Id}`
				};
		}
	}

	public export(productRoute: string): void {
		const { c24ActiveArea } = this.create24Service;
		const newContentCategory = this.categories?.find((category) => category.CategoryName === 'New Content')?.Id || 0;

		this.messageService.publish(Events.savingPreloader, 1);
		if (this.projectState === 'new') {
			this.createCatAndContent(productRoute);
		} else {
			if (this.c24ContentCategory) {
				//If saving template, use new content category. Otherwise use existing category id
				const categoryId: number = this.utilService.includes(c24ActiveArea, 'template') ? newContentCategory : this.c24ContentCategory.Id;
				this.createContentAndContentFile$(categoryId).subscribe(() => {
					this.messageService.publish(Events.savingPreloader, 0);
					this.router.navigate([`/create-24/dashboard/export-success/${productRoute}`]);
				});
				//If we've changed libraries, place in New Content category
			} else {
				this.createCatAndContent(productRoute);
			}
		}
	}

	private createCatAndContent(productRoute: string): void {
		const newContentCategoryExists: boolean = this.categories.some((category) => category.CategoryName === 'New Content');

		//If New Content category does not exist, create it
		if (!newContentCategoryExists) {
			this.createNewContentCategory$()
				.pipe(
					mergeMap((category: ContentLibraryCategories) => {
						return this.createContentAndContentFile$(category.Id);
					})
				)
				.subscribe(() => {
					this.messageService.publish(Events.savingPreloader, 0);
					this.router.navigate(['/create-24/dashboard/export-success']);
				});
			//If New Content Category exists
		} else {
			const newContentCategory: ContentLibraryCategories = this.categories.find((category) => category.CategoryName === 'New Content');
			this.createContentAndContentFile$(newContentCategory.Id).subscribe(() => {
				this.messageService.publish(Events.savingPreloader, 0);
				this.router.navigate([`/create-24/dashboard/export-success/${productRoute}`]);
			});
		}
	}

	public formData(fileToUpload: any, uploadData?: any): FormData {
		const formData: FormData = new FormData();

		formData.append('data', JSON.stringify(uploadData));
		formData.append('file', fileToUpload);
		return formData;
	}

	private content(categoryId: number): Content {
		switch (true) {
			case this.projectState === 'new':
			case this.saveState === 'copy':
				return this.contentPOST(categoryId);
			case this.saveState === 'overwrite':
				return this.contentPUT(categoryId);
		}
	}

	private contentFile(contentId: number): ContentFiles {
		switch (true) {
			case this.projectState === 'new':
			case this.saveState === 'copy':
				return this.contentFilePOST(contentId);
			case this.saveState === 'overwrite':
				return this.contentFilePUT(contentId);
		}
	}

	private contentPOST(categoryId: number): Content {
		return {
			LibraryId: this.stateService.c24Content.LibraryId,
			ContentTypeId: this.contentTypeId(),
			CategoryId: categoryId,
			IsChannel: false,
			IsEditable: true,
			Title: this.stateService.c24Content.Title,
			Text: this.stateService.c24Content.Text ? this.stateService.c24Content.Text : '',
			CreateDate: new Date().toISOString(),
			CreatedByUserId: this.appStateService.currentUser.UserId,
			UpdateDate: new Date().toISOString(),
			UpdatedByUserId: this.appStateService.currentUser.UserId,
			IsDeleted: false,
			IsFeatured: false,
			IsApproved: true,
			HideFromWebPlayer: this.hideFromWebPlayer()
		};
	}

	private contentFilePOST(contentId: number): ContentFiles {
		return {
			ContentId: contentId,
			Directory: this.appStateService.currentClient.ContentDirectory,
			MasterFileName: 'create24-content',
			PreviewFileName: 'create24-content',
			ThumbnailFileName: 'create24-content',
			CreateDate: new Date().toISOString(),
			CreatedByUserId: this.appStateService.currentUser.UserId,
			UpdateDate: new Date().toISOString(),
			UpdatedByUserId: this.appStateService.currentUser.UserId,
			LastAccessDate: new Date().toISOString(),
			IsDeleted: false,
			ContentFileStateId: 4, //Ready
			PreviewAccessCount: 0,
			MasterAccessCount: 0,
			IsMigrated: false,
			FeedIsRequired: false,
			MasterMp4FileName: 'create24-content',
			Create24ProjectJsonV4: JSON.stringify(this.stateService.state()),
			Create24OutputHtmlV4: this.exportSVGService.finalOutput(this.canvasService.canvas.toSVG()),
			Create24BgContentId: this.bgContentId(),
			FeedId: this.layersService.atLeastOneFeedLayer() ? this.canvasService.feed?.Id : null
		};
	}

	private contentPUT(categoryId: number): Content {
		return {
			Id: this.stateService.c24Content.Id,
			LibraryId: this.stateService.c24Content.LibraryId,
			ContentTypeId: this.contentTypeId(),
			CategoryId: categoryId,
			IsChannel: false,
			IsEditable: true,
			Title: this.stateService.c24Content.Title,
			Text: this.stateService.c24Content.Text ? this.stateService.c24Content.Text : '',
			CreateDate: this.stateService.c24Content.CreateDate,
			CreatedByUserId: this.stateService.c24Content.CreatedByUserId,
			UpdateDate: new Date().toISOString(),
			UpdatedByUserId: this.appStateService.currentUser.UserId,
			IsDeleted: false,
			IsFeatured: this.stateService.c24Content.IsFeatured,
			IsApproved: true,
			HideFromWebPlayer: this.hideFromWebPlayer()
		};
	}

	private contentFilePUT(contentId: number): ContentFiles {
		return {
			Id: this.stateService.c24Content.contentFiles[0].Id,
			ContentId: contentId,
			Directory: this.appStateService.currentClient.ContentDirectory,
			MasterFileName: 'create24-content',
			PreviewFileName: 'create24-content',
			ThumbnailFileName: 'create24-content',
			CreateDate: this.stateService.c24Content.CreateDate,
			CreatedByUserId: this.stateService.c24Content.CreatedByUserId,
			UpdateDate: new Date().toISOString(),
			UpdatedByUserId: this.appStateService.currentUser.UserId,
			LastAccessDate: new Date().toISOString(),
			IsDeleted: false,
			ContentFileStateId: 4, //Ready
			PreviewAccessCount: 0,
			MasterAccessCount: 0,
			IsMigrated: false,
			FeedIsRequired: false,
			MasterMp4FileName: 'create24-content',
			Create24ProjectJsonV4: JSON.stringify(this.stateService.state()),
			Create24OutputHtmlV4: this.exportSVGService.finalOutput(this.canvasService.canvas.toSVG()),
			Create24BgContentId: this.bgContentId(),
			FeedId: this.layersService.atLeastOneFeedLayer() ? this.canvasService.feed?.Id : null
		};
	}

	private bgContentId(): number {
		switch (true) {
			case !!this.canvasService.bgContent:
				return this.canvasService.bgContent.Id;
			case this.utilService.includes(this.prevCreate24Area, 'sidebar'):
				return 1025547; //Id of a blank content item in the correct sidebar dimensions
			case this.utilService.includes(this.appStateService.product.Route, 'video'):
				return 1025545;
			case this.utilService.includes(this.appStateService.product.Route, 'poster'):
				return 1025546;
		}
	}

	private hideFromWebPlayer(): boolean {
		// Content with feeds, countdown, or current date are not compatible with web players
		if (this.exportSvgScriptsService.scriptFunctions().execution === '') {
			return false;
		}
		return true;
	}
}
