import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStateService, UtilitiesService } from '../../../../core/services';
import { environment } from '../../../../../environments/environment';
import { ContentLibraries } from 'src/app/shared/api-models/content';
import { ClientNameSearch, ContentRequestType, Users } from 'src/app/shared/api-models/admin';
import { CreateEditRequestService, CreateEditViewStateService } from '../../_services';


@Injectable({
    providedIn: 'root'
})
export class DetailsService {

    public attachmentDownloadUrl: string;
    public clientList: ClientNameSearch[];
    public libraries: ContentLibraries[];
    public producers$: Observable<Users[]>
    public requestTypes$: Observable<ContentRequestType[]>;
    public usersByClient$: Observable<Users[]>;

    constructor(
        private appStateService: AppStateService,
        private createEditRequestService: CreateEditRequestService,
        private httpClient: HttpClient,
        private utilService: UtilitiesService,
        private viewStateService: CreateEditViewStateService,
    ) { }

    public getAttachments(): Observable<any>  {
        return this.httpClient.get(`${environment.adminUrl}ContentRequests/${this.createEditRequestService.request.Id}/Attachments`);
    }

    public getLibraries(): Observable<any> {
        let url: string;
        const currentClient = this.appStateService.currentClient;
        const currentUser = this.appStateService.currentUser;
        const request = this.createEditRequestService.request;

        //client view
        if (this.viewStateService.route === 'content-requests') {
            url = `${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=1&clientId=${currentClient.Id}&userId=${currentUser.UserId}`;
        } else {
            url = `${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${this.requestTypeToProductTypeId(request.RequestType)}&clientId=${request.ClientId}`
        }
        return this.httpClient.get(url).pipe(
            map((libraries: ContentLibraries[]) => {
                if (libraries && libraries.length > 0) {
                    this.libraries = this.utilService.sortItems(this.filteredLibraries(libraries), 'LibraryName');
                    if(this.viewStateService.mode === 'new') {
                        //Assign first library with name "Custom" by default, only if new
                        //9/9/2021 Natalie requested to leave blank so CSRs would be forced to choose a library
                        // this.setDefaultLibraryNewRequest();
                    }
                }
            })
        )
    }

    private setDefaultLibraryNewRequest(): void {
        if(this.libraries.length === 1) {
            this.createEditRequestService.request.targetLibraryName = this.libraries[0].LibraryName;
            this.createEditRequestService.request.TargetLibraryId = this.libraries[0].Id;
        } else {
            this.createEditRequestService.request.targetLibraryName = this.libraries.find(library => this.utilService.includes(library.LibraryName, 'Custom')).LibraryName;
            this.createEditRequestService.request.TargetLibraryId = this.libraries.find(library => this.utilService.includes(library.LibraryName, 'Custom')).Id;
        }
    }

    private filteredLibraries(libraries: ContentLibraries[]): ContentLibraries[] {
        return libraries.filter((library) => {
            if(library.ContentLibraryTypeId === 1 ) { //filter music libraries
                return false;
            }
            if (this.appStateService.currentClient.Id !== 1470 && !library.IsGlobal) {
                return true;
            } else if (this.appStateService.currentClient.Id === 1470) {
                if (this.createEditRequestService.request.RequestType === 'On-Hold Message') {
                    return library.ContentLibraryTypeId === 1 || library.ContentLibraryTypeId === 2;
                } else {
                    return library.ContentLibraryTypeId !== 1 && library.ContentLibraryTypeId !== 2;
                }
            }
        })
    }

    private requestTypeToProductTypeId(requestType: string): number {
        switch (requestType) {
            case 'On-Hold Message':
                return 1;
            case 'Video-Lobby':
            case 'Video-Logo':
            case 'Video-Create24 Template':
            case 'Video-Other':
                return 3;
            case 'Video-Poster':
                return 4;
        }
    }

    public getAllProducers(): Observable<Users[]> {
        return this.httpClient.get(`${environment.adminUrl}CoreUsers/ActiveContentCreators`).pipe(
            map( (producers: Users[]) => {
                const currentUser = this.appStateService.currentUser;
                //If user is not employee, they can only assign request to an employee
                if(!currentUser.IsEmployee) {
                    return producers.filter(producer => producer.IsEmployee);
                }
                return this.utilService.sortItems(producers, 'UserName');
            })
        )
    }

    public getUsersByClient(): Observable<Users[]> {
        return this.httpClient.get(`${environment.adminUrl}CoreUsers/GetUsersByClientId/${this.createEditRequestService.request.ClientId}`).pipe(
            map( (users: Users[]) => {
                return this.utilService.sortItems(users.filter(u => !u.IsDeleted), 'UserName');
            })
        )
    }
}
