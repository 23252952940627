export class Clients {
	Id: number;
	CoopParentClientId?: number;
	PrimaryContactId?: number;
	PrimaryLocationId: number;
	IsActive: boolean;
	IsInternal: boolean;
	IndustryTypeId: number;
	CsrId?: number;
	SalespersonId?: number;
	Name: string;
	ContentDirectory: string;
	MainPhoneNumber?: string;
	MainFaxNumber?: string;
	Notes?: string;
	Record24TargetVersion?: string;
	AllowedIps?: string;
	MaxOnHoldContentReq: number;
	MaxVideoContentReq: number;
	MaxPosterContentReq: number;
	DefaultVoipFormat?: number;
	DefaultVoipFileMode: number;
	UseAdvancedScheduling: boolean;
	DefaultParagraphPauseMs: number;
	DefaultStillDurationMs: number;
	Website?: string;
	BillingAddress1: string;
	BillingAddress2: string;
	BillingCity: string;
	BillingState: string;
	BillingZip: string;
	BillingCountry: string;
	ShippingAddress1: string;
	ShippingAddress2: string;
	ShippingCity: string;
	ShippingState: string;
	ShippingZip: string;
	ShippingCountry: string;
	MaxVideoPlaylistLength: number;
	RssdId: number;
	IndustryType: any;
	SendVideoReminders: boolean;
	SendHoldReminders: boolean;
	SendNewReleases: boolean;
	AllowRecord24: boolean;
	AdditionalVoipEmail: string;
	IsDemoAccount: boolean;
	IsBetaTester: boolean;
	BugLogoContentId: number;
	ContentLogoContentId: number;
	HasCustomFonts: boolean;
	CustomFontNotes: string;
	CustomFontJson: string;
	name: string;
}
