import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Column } from '../../shared/components/table/view-models/';
import { AppStateService, AudioPreviewService, EncryptService, Events, MessageService } from '../../core/services';
import { SavedPlaylists } from '../../shared/view-models/content';

@Injectable({
	providedIn: 'root'
})
export class PphViewService {
	public modalVisible: boolean;

	constructor(
		private appStateService: AppStateService,
		private audioPreviewService: AudioPreviewService,
		private encryptService: EncryptService,
		private messageService: MessageService,
		private router: Router
	) {}

	onTableButtonClick(payload: [Column, SavedPlaylists]): void {
		let column: Column = payload[0];
		let playlist: SavedPlaylists = payload[1];

		if (column.key === 'detailsBtn') {
			this.router.navigate([
				`/saved-playlists/${this.appStateService.product.Route}/playlist-details/${this.encryptService.encryptData(JSON.stringify(playlist.Id))}`
			]);
		}

		if (column.key === 'editBtn') {
			this.messageService.publish(Events.transitionOverlay, ['Loading playlist...', true]);
			this.router.navigate([
				`/playlist-builder/${this.appStateService.product.Route}/saved-playlist/${this.encryptService.encryptData(JSON.stringify(playlist.Id))}`
			]);
		}

		if (column.key === 'previewBtnIcon' && this.appStateService.product.Route !== 'hold') {
			this.appStateService.setSessionItem('playlistId', JSON.stringify(playlist.Id));
			this.modalVisible = true;
		}

		if (column.key === 'previewBtnIcon' && this.appStateService.product.Route === 'hold') {
			//this function handles getting preview as well as toggling on/off previews
			this.audioPreviewService.getPlaylistPreview(playlist);
			this.audioPreviewService.previouslySelectedPlaylist = playlist;
		}
	}
}
