<!--*****************************
	ON-HOLD MESSAGE TYPE
******************************-->
<div class="ft-size-md border gray-bg border-radius box-shadow pd15 mgn-bottom30 relative">
	<!--*****************************
		VOICE TALENT PICKER MODAL
	******************************-->
	<div
		*ngIf="fileUploadStateService.currentStep()?.name === 'uploadModal' && fileUploadStateService.itemId === clip.Id"
		class="container-fluid upload-overlay setZ border-radius"
	>
		<hv-upload-modal [clip]="clip"></hv-upload-modal>
	</div>

	<!--*********************
		SCRIPT READ VIEW
	**********************-->
	<i *ngIf="clipsService.showPrintBtn()" class="far fa-file-alt ft-size18 absolute top8 right15 z pd5 pointer" (click)="onScriptReadViewClick()"></i>

	<!--*******************
		TITLE
	********************-->
	<div class="row">
		<div class="col-sm-12">
			<h4 class="gray-text-darkest mgn-bottom2 in-block">Title</h4>
			<i *ngIf="viewStateService.mode === 'new'" class="fa fa-asterisk ft-size7 red-text-light relative topneg10 left2" aria-hidden="true"></i>
			<br />
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<input
				*ngIf="clip.eligibleForRevision"
				class="gray-border-darker white-bg"
				type="text"
				(keyup)="clipService.onKeyup('Title', clip)"
				[(ngModel)]="clip.Title"
			/>
			<span *ngIf="!clip.eligibleForRevision" class="regular ft-size18">{{ clip.Title }}</span>
		</div>
	</div>

	<!--****************
		SCRIPT
	*****************-->
	<div class="row mgn-top15">
		<div class="col-sm-12">
			<h4 class="gray-text-darkest in-block mgn-bottom2">Script</h4>
			<i *ngIf="viewStateService.mode === 'new'" class="fa fa-asterisk ft-size7 red-text-light relative topneg10 left2" aria-hidden="true"></i>
			<br />
			<textarea
				*ngIf="clip.eligibleForRevision"
				class="white-bg gray-border-darker"
				type="text"
				rows="6"
				(keyup)="clipService.onKeyup('ScriptText', clip)"
				[(ngModel)]="clip.ScriptText"
			></textarea>
			<textarea *ngIf="!clip.eligibleForRevision" class="white-bg gray-border-darker" type="text" rows="6" readonly [(ngModel)]="clip.ScriptText"></textarea>
		</div>
	</div>
	<div *ngIf="clip.eligibleForRevision && createEditRequestService.request.StateName !== statename.IN_PRODUCTION" class="row mgn-top15 mgn-bottom15">
		<div class="col-sm-12">
			<span class="blue-text">
				<em>
					If you wish to revise this clip, mark at least one completed file for which the revisions should apply. Mouse over individual files and click the
					green "Mark for revision" button.
				</em>
			</span>
		</div>
	</div>

	<div *ngIf="createEditRequestService.request.StateName === statename.REVISION_REQUESTED" class="row mgn-top15 mgn-bottom15">
		<div class="col-sm-12">
			<span *ngIf="viewStateService.route !== 'content-requests'" class="blue-text">
				<em>Files with the hourglass icon are marked as needing revision.</em>
			</span>
			<span *ngIf="viewStateService.route === 'content-requests'" class="blue-text">
				<em>Files with the hourglass icon are currently being reproduced.</em>
			</span>
		</div>
	</div>

	<!--********************************
		COMPLETED FILES
	*********************************-->

	<!--If selected request is not new-->
	<div *ngIf="createEditRequestService.request.Id">
		<div
			*ngIf="!clip.completedFileUploadInProgress && (clip.contentFiles?.length > 0 || viewStateService.route !== 'content-requests')"
			class="row mgn-top30 mgn-bottom2"
		>
			<div *ngIf="clip.contentFiles?.length > 0" class="col-sm-6">
				<h4 class="semi-bold">Completed Files</h4>
			</div>
			<div
				*ngIf="viewStateService.route !== 'content-requests' && !viewStateService.isStatusBeforeInProduction"
				class="{{ clip.contentFiles?.length > 0 ? 'col-sm-6' : 'col-sm-12' }} text-right"
			>
				<h4 class="semi-bold">Upload Completed Files</h4>
			</div>
		</div>
		<div [ngStyle]="{ 'min-height.px': viewStateService.isStatusBeforeInProduction ? '0' : '60' }" *ngIf="!clip.completedFileUploadInProgress" class="row">
			<portal-file-upload-drop-target
				*ngIf="
					createEditRequestService.request.TargetLibraryId && viewStateService.route !== 'content-requests' && !viewStateService.isStatusBeforeInProduction
				"
				class="absolute z setWidth100"
				[activeUploadSequence]="'contentRequestCompletedFileSequenceHold'"
				[borderHidden]="true"
				[counterpartProps]="{ contentId: clip.CompletedContentId, targetLibraryId: createEditRequestService.request.TargetLibraryId }"
				[counterpartOrReplace]="clip.contentFiles?.length > 0 ? 'counterpart' : null"
				[dropTargetHeight]="'ht100'"
				[itemId]="clip.Id"
				[textPosition]="'absolute top0 right15 center'"
			></portal-file-upload-drop-target>

			<div class="col-xs-8">
				<div class="row">
					<div *ngFor="let contentFile of clip.contentFiles; let i = index" class="col-xs-6 mgn-bottom15 relative">
						<hv-content-file [clip]="clip" [clipIndex]="clipIndex" [contentFile]="contentFile" [index]="i"></hv-content-file>
					</div>
				</div>
			</div>

			<div
				*ngIf="
					!createEditRequestService.request.TargetLibraryId && viewStateService.route !== 'content-requests' && !viewStateService.isStatusBeforeInProduction
				"
				class="col-sm-4 center"
			>
				<span>Please save a Target Library before uploading!</span>
			</div>
		</div>

		<!--***********************************
			COMPLETED FILE UPLOAD PROGRESS BAR
		************************************-->
		<div *ngIf="clip.completedFileUploadInProgress" class="row mgn-top30 blue-gradient shadow pd-top15 pd-bottom15 border-radius">
			<div class="col-sm-6 white-text">
				<div class="row">
					<div class="col-xs-8">
						<span><em>Upload in process...</em></span>
					</div>
					<div class="col-xs-2">
						<portal-spinner [height]="'htpx18'" [width]="'wdthpx18'" class="absolute top10"></portal-spinner>
					</div>
					<div class="col-xs-2">
						<span class="">{{ clipService.percentCompleteCompletedFile }}%</span>
					</div>
				</div>
			</div>
			<div class="col-sm-6 white-text">
				<span>
					<em>{{ clip.processingState }}</em>
				</span>
			</div>
		</div>

		<hr *ngIf="clip.contentFiles?.length > 0 || viewStateService.route !== 'content-requests'" class="gray-border mgn-top36" />
	</div>
	<div class="row {{ !createEditRequestService.request.Id ? 'mgn-top15' : null }}">
		<div class="col-sm-10">
			<span class="gray-text-darker">{{ clipIndex + 1 }}</span>
		</div>
		<div *ngIf="!createEditRequestService.request.Id" class="col-sm-2 text-right">
			<button (mouseup)="onUnsavedClipDelete()" class="red-bg-light white-text border-radius setWidth100">
				<i class="far fa-trash-alt" aria-hidden="true"></i>
			</button>
		</div>
	</div>
</div>
