import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppStateService, CacheService, Events, MessageService, UtilitiesService } from 'src/app/core/services';
import { CategoriesTree, LibrariesTree } from '../_models';
import { Content } from '../../../api-models/content';
import { ContentViewStateService } from 'src/app/shared/services';
import { Create24Service } from 'src/app/user-tools/create-24/create-24.service';
import { environment } from 'src/environments/environment';
import { Hold, Video } from '../../../components/content-container/content/_models';
import { Observable } from 'rxjs';
import { FeatureArea } from 'src/app/shared/view-models';
import { ContentService } from '../../content-container/content/_services';
import { Create24Area } from 'src/app/user-tools/create-24/_models/create-24-state.enum';

@Injectable({
	providedIn: 'root'
})
export class CatService {
	public categoryDeletable: boolean;

	constructor(
		private appStateService: AppStateService,
		private cacheService: CacheService,
		private contentService: ContentService,
		private create24Service: Create24Service,
		private cvStateService: ContentViewStateService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private utilService: UtilitiesService
	) {}

	public getContentList(clickedCategory: CategoriesTree): void {
		// let cachedEntry = this.cacheService.contentList.find((entry) => entry.category.Id === category.Id);
		// //Don't re-get content if it's in the cache
		// if (!cachedEntry) {
		// 	this.cvStateService.getContentForCategories(category).subscribe((contentList: (Hold | Video)[]) => {
		// 		this.cvStateService.contentList = contentList;
		// 		this.cacheService.storeContent(category, contentList, this.cvStateService.totalItemsContentList);
		// 	});
		// } else {
		// 	this.cvStateService.contentList = this.cacheService.contentList.find((entry) => entry.category.Id === category.Id).contentList;
		// 	this.cvStateService.viewState.contentListCurrentPage = 1;
		// 	this.cvStateService.viewState.contentListVisible = true;
		// 	this.cvStateService.totalItemsContentList = this.cacheService.contentList.find((entry) => entry.category.Id === category.Id).totalItemsContentList;
		// 	if (this.cvStateService.viewState.hideDeleted) {
		// 		this.cvStateService.contentList = this.cvStateService.contentList.filter((content) => !content.IsDeleted);
		// 		this.cvStateService.totalItemsContentList = this.cvStateService.contentList.length;
		// 	}
		// }

		//TODO: Create a flag tripped by deleting/undeleting content or filtering by voice talent
		//to disable the cache. Below is a quick fix to get content on every category click
		//so the voice talent filter holds.
		const create24ContentOnly =
			this.appStateService.activeFeatureArea === FeatureArea.CREATE_24 && this.create24Service.c24ActiveArea === Create24Area.EDIT_PROJECT;
		this.contentService.getContentForCategories({ clickedCategory, create24ContentOnly }).subscribe((contentList: (Hold | Video)[]) => {
			this.cvStateService.contentList = contentList;

			this.cacheService.storeContent(clickedCategory, contentList, this.cvStateService.totalItemsContentList);
		});
	}

	public buildBreadcrumbs(category: CategoriesTree): string {
		let isSubCategory: boolean = category.isSubCategory;
		let library: LibrariesTree = this.cacheService.libraries.find((library) => library.Id === category.LibraryId);
		let parentCategory: CategoriesTree = !isSubCategory ? category : null;
		let subCategory: CategoriesTree = isSubCategory ? category : null;

		if (isSubCategory) {
			parentCategory = this.cacheService.contentList.find((entry) => entry.category.Id === category.ParentCategoryId).category;
			return `${library.LibraryName} > ${parentCategory.CategoryName} > ${subCategory.CategoryName}`;
		}
		return `${library.LibraryName} > ${parentCategory.CategoryName}`;
	}

	public isCategoryDeletableCheck(category: CategoriesTree, deleteBtnClicked?: boolean): void {
		this.httpClient.get<boolean>(`${environment.contentUrl}ContentLibraryCategories/${category.Id}/isDeletable`).subscribe((categoryDeletable: boolean) => {
			this.categoryDeletable = category.CategoryName !== 'New Content' && categoryDeletable ? true : false;
			if (deleteBtnClicked) {
				if (categoryDeletable) {
					this.deleteCategory(category);
					return;
				}
				alert('All content must be removed before deleting a category!');
			}
		});
	}

	private deleteCategory(category: CategoriesTree): void {
		let confirmDelete = confirm('Are you sure you want to delete this category?');
		if (confirmDelete) {
			this.messageService.publish(Events.savingPreloader, 1);
			this.httpClient.delete(environment.contentUrl + 'ContentLibraryCategories/' + category.Id).subscribe(() => {
				category.IsDeleted = true;
				this.messageService.publish(Events.savingPreloader, 0);
			});
		}
	}

	public updateCategoryOnDrop(content: Content, selectedCategory: CategoriesTree, draggedContent: Hold | Video, categoryDropTargetId: number): void {
		this.httpClient.patch(`${environment.contentUrl}Content/${draggedContent.Id}`, content).subscribe(() => {
			this.updateCacheOnContentDrag(selectedCategory, draggedContent, categoryDropTargetId);

			this.messageService.publish(Events.savingPreloader, 0);
			//Refresh view after category has been updated
			this.contentService.displayContent([selectedCategory]);
		});
	}

	private updateCacheOnContentDrag(selectedCategory: CategoriesTree, draggedContent: Hold | Video, categoryDropTargetId: number): void {
		//Find matching selected category in the cache
		let selectedCachedEntry = this.cacheService.contentList.find((entry) => entry.category.Id === selectedCategory.Id);

		//Remove draggedContent from this cache entry
		selectedCachedEntry.contentList = selectedCachedEntry.contentList.filter((c) => c.Id !== draggedContent.Id);

		//Find matching drop category in the cache
		let dropTargetCachedEntry = this.cacheService.contentList.find((entry) => entry.category.Id === categoryDropTargetId);

		if (!!dropTargetCachedEntry) {
			//Add content to correct content list in cache
			let alreadyExists = dropTargetCachedEntry.contentList.some((c) => c.Id === draggedContent.Id);
			if (!alreadyExists) {
				dropTargetCachedEntry.contentList.push(draggedContent);
			}

			//Sort list by Title
			dropTargetCachedEntry.contentList = this.utilService.sortItems(dropTargetCachedEntry.contentList, 'Title');
		}
	}

	public saveNewCategory(newCategoryName: string, category: CategoriesTree): Observable<any> {
		this.messageService.publish(Events.savingPreloader, 1);
		return this.httpClient.post(`${environment.contentUrl}ContentLibraryCategories`, this.newCategory(newCategoryName, category)).pipe(
			map(() => {
				this.messageService.publish(Events.savingPreloader, 0);
			})
		);
	}

	private newCategory(categoryName: string, category: CategoriesTree): CategoriesTree {
		let newCategory: CategoriesTree = new CategoriesTree();

		newCategory.LibraryId = category.LibraryId;
		newCategory.ParentCategoryId = category.Id;
		newCategory.CategoryName = categoryName;
		return newCategory;
	}

	public updateCategoryName(category: CategoriesTree): void {
		this.messageService.publish(Events.savingPreloader, 1);

		let cat: CategoriesTree = new CategoriesTree();
		cat.CategoryName = category.CategoryName;
		this.httpClient.patch(`${environment.contentUrl}ContentLibraryCategories/${category.Id}`, cat).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	public showHoverButtons(mouseover: boolean): boolean {
		return !!mouseover && this.appStateService.activeFeatureArea === FeatureArea.CONTENT_MANAGER;
	}
}
