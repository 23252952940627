import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AppStateService } from '../app-state.service';
import { AuthUser } from '../../../shared/api-models/auth/auth-user';

@Injectable({
    providedIn: 'root'
})
export class AllowRecord24GuardService implements CanActivate {

    constructor(private appStateService: AppStateService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot) {
        if (this.allowRecord24()) {
            return true;
        } else {
            this.router.navigate(['/access-denied']);
        }
    }

    private allowRecord24(): boolean {
        let currentUser: AuthUser = JSON.parse(this.appStateService.getSessionItem('currentUser'));
        return JSON.parse(this.appStateService.getSessionItem('currentClient')).AllowRecord24 && currentUser.Role !== 'User';
    }
}
