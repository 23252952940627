import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ContentFiles } from '../api-models/content';
import { Hold, Video } from '../components/content-container/content/_models';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class GetContentDataService {
	constructor(private httpClient: HttpClient) {}

	public getContentForChannel(contentId: number): Observable<(Hold | Video)[]> {
		return this.httpClient.get<(Hold | Video)[]>(`${environment.contentUrl}Content/Channel/${contentId}`);
	}

	public getContentFiles(contentId: number): Observable<ContentFiles[]> {
		return this.httpClient.get<ContentFiles[]>(`${environment.contentUrl}Content/${contentId}/ContentFiles`);
	}

	public getChannelContent(contentId: number): Observable<(Hold | Video)[]> {
		return this.httpClient.get<(Hold | Video)[]>(`${environment.contentUrl}Content/Channel/${contentId}`);
	}
}
